import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

const NotLoggedInRedirection = ({ isLoggedIn }) => (
    <React.Fragment>
        { !isLoggedIn && <Redirect to="/" /> }
    </React.Fragment>
)

const mapStateToProps = state => ({
    isLoggedIn  :   state.Auth.isLoggedIn
})

export default connect(mapStateToProps, {})(NotLoggedInRedirection);