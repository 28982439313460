import React, { Component } from "react";
import Select from 'react-select';
import { searchByUserKeywords } from "../../../api/search/search";
import { Keyring } from '@polkadot/api';
import { mnemonicValidate } from '@polkadot/util-crypto';
import { connect } from "react-redux";
import CryptoJS from 'crypto-js';
import { generateRedeemOrderNFTApi } from "../../../api/wallet/wallet";
import { setCypherSecret } from "../../../actions/auth";
import voucherCodeGenerator from 'voucher-code-generator';

class GeneratePromoCode extends Component {

    state = {
        mnemonicsPwd: null,
        mnemonics: null,
        public_address: null,
        isLoading: false,
        message: null,
        isError: false,
        success: null,
        saveMyMnemonics: false,
        promoCode: null
    }

    generatePromocode() {
            const [ promoCode ] = voucherCodeGenerator.generate({
                prefix: "promo-",
                charset: 'alphanumeric',
                length: 20
            });

            this.setState({ ...this.state, promoCode: promoCode });
    }

    submit = async () => {

        await new Promise(resolve => this.setState({ ...this.state, isLoading: true }, resolve));

        if(this.state.saveMyMnemonics) {
            const cypher = CryptoJS.AES.encrypt(this.state.mnemonics, this.state.mnemonicsPwd).toString();
            this.props.setCypherSecret(cypher);
        }

        if(this.props.cypherSecret !== null) {
            try {
                const mnemonics = CryptoJS.AES.decrypt(this.props.cypherSecret, this.state.mnemonicsPwd).toString(CryptoJS.enc.Utf8);
                if(mnemonics == '') throw new Error('Wrong password entered!');
                this.setState({ ...this.state, mnemonics: mnemonics, mnemonicsPwd: '', message: null, isError: false });
            } catch(e) {
                this.setState({ ...this.state, isLoading: false, isError: true, message: 'Wrong password entered!' });
                return;
            }
        }
    

        try {

            const { token_id, user_id } = this.props;
         
            const { data } = await generateRedeemOrderNFTApi({ token_id: token_id, redeem_code: this.state.promoCode, secret: this.state.mnemonics }, this.props.token);
            
            if (data.status === 'ExtrinsicSuccess') {
                this.setState({
                    ...this.state,
                    isLoading: false,
                    isError: false,
                    message: <p>Promocode Created!<br/><a style={{ color: '#0f0f53b8' }} target="_blank" href={`${process.env.REACT_APP_NFT_EXPLORER}/block/${data.blockHash}`}>{data.blockHash}</a></p>
                });

                if(this.props.callback) this.props.callback();
            }
            else return this.setState({
                ...this.state,
                isLoading: false,
                isError: true,
                message: <p>Transaction unsuccessfull!<br/><a style={{ color: '#0f0f53b8' }} target="_blank" href={`${process.env.REACT_APP_NFT_EXPLORER}/block/${data.blockHash}`}>{data.blockHash}</a></p>
            });
        } catch(e) {
            console.log('tester ', e)
            this.setState({
                ...this.state,
                isLoading: false,
                isError: true,
                message: `Unable to create promo code.`
            });
        }
        
    }

    onChangeMnemonics = e => {
        e.preventDefault();

        if (mnemonicValidate(e.target.value)) {
            const keyring = new Keyring({
                type: 'sr25519',
                ss58Format: 42,
            });
            const newPair = keyring.addFromUri(e.target.value);

            this.setState({ ...this.state, mnemonics: e.target.value, public_address: newPair.address });
        } else this.setState({ ...this.state, mnemonics: e.target.value, public_address: null });
    }

    copyText() {
        const copyText = document.getElementById('promo-code');

        copyText.select();
        copyText.setSelectionRange(0, 99999);   

        navigator.clipboard.writeText(copyText.placeholder);
    }

    render() {

        const { message, isError, isLoading, promoCode } = this.state;

        return (
            <React.Fragment>
                <button onClick={() => {
                    this.generatePromocode();
                }} style={{display: 'none'}} id="GeneratePromoCodeModalModalBtn" type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#GeneratePromoCodeModalModal">
                    Launch demo modal
                </button>
                <div className="modal fade" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" id="GeneratePromoCodeModalModal" tabIndex="-1" aria-labelledby="GeneratePromoCodeModalModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '30%' }}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="GeneratePromoCodeModalModalLabel">Generate Promo Code</h5>
                                <button type="button" className="btn-close d-none" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="mb-3">
                                    <label className="mb-1">Generate Code</label>
                                    <div className="row">
                                        <div className="col-10">
                                            <input id="promo-code" readOnly type="text" placeholder={promoCode} className="form-control shadow-none" />
                                        </div>
                                        <div className="col-2">
                                            <i onClick={this.copyText} title="Copy" style={{ marginLeft: '-10px', fontSize: '20px', cursor: 'pointer' }} className="fa fa-copy"></i>
                                            <i title="Generate Promo Code" style={{ marginLeft: '10px', fontSize: '20px', cursor: 'pointer' }} onClick={this.generatePromocode.bind(this)} className="fa fa-undo" aria-hidden="true"></i>

                                        </div>
                                    </div>
                                </div>
                                {/* <div className="mb-3"> */}
                                    <div className={ this.props.cypherSecret === null ? "" : 'container d-none'}>
                                    <h4 className="mb-4 text-center d-none">Please enter your account mnemonics to confirm
                                        transaction</h4>
                                    <div className="mb-3">
                                        <label className="form-label CeraMed d-block mb-1">Enter account mnemonics to confirm transaction</label>
                                        <textarea
                                            style={{ borderColor: !this.state.mnemonics ? '' : mnemonicValidate(this.state.mnemonics) ? 'green' : 'red' }}
                                            onChange={this.onChangeMnemonics} name="mnemonics" rows="4"
                                            className="form-control shadow-none"
                                            placeholder="Paste your mnemonics here"></textarea>
                                        <p className="form-text mb-0">We are not storing your private key, this can be use
                                            one time for this transaction.</p>
                                    </div>
                                    <React.Fragment>
                                        <div className="w-100 mb-3">
                                        {
                                            this.state.public_address && this.state.public_address === this.props.currentUser.public_address && (
                                                <React.Fragment>
                                                    <strong className={'text-success d-block'}> Looks Good!</strong>
                                                    Your public address matched
                                                    <strong className={'break-all d-block'}>{this.state.public_address}</strong>
                                                </React.Fragment>
                                            )
                                        }
                                        {
                                            this.state.public_address && this.state.public_address !== this.props.currentUser.public_address && (
                                                <React.Fragment>
                                                    <strong className={'text-danger d-block'}>Something Wrong!</strong> Your profile address is not matching with the
                                                    provided Mnemonics, <br/>Please add the mnemonics which you give us on sign
                                                    up.
                                                </React.Fragment>
                                            )
                                        }
                                        </div>
                                    </React.Fragment>
                                        <div className="form-check pt-3">
                                            <input checked={this.state.saveMyMnemonics} onClick={() => this.setState({ ...this.state, saveMyMnemonics: !this.state.saveMyMnemonics })} className="form-check-input shadow-none" type="checkbox" id="mnemonicsForGeneratePassword" />
                                            <label className="form-check-label" htmlFor="mnemonicsForGeneratePassword">Encrypt and save my mnemonics on browser for future transactions! </label>
                                        </div>
                                    <div className="px-0">
                                        {
                                            this.state.saveMyMnemonics && (
                                                <input onChange={e => this.setState({ ...this.state, mnemonicsPwd: e.target.value })} className="form-control BorderDarkGrey shadow-none mt-4" type="password" name="password" placeholder="Enter Password"/>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className={ this.props.cypherSecret !== null ? "container" : 'container d-none'}>
                                    <label className="text-secondary w-100 text-center" style={{ fontSize: '14px' }}>Enter password to unlock</label>
                                    <label className="text-secondary text-center w-100" style={{ fontSize: '14px' }}>"{this.props.currentUser.public_address}"</label>
                                    <input onChange={e => this.setState({ ...this.state, mnemonicsPwd: e.target.value })} className="form-control BorderDarkGrey shadow-none mt-4" type="password" name="password" placeholder="Enter Password"/>
                                    {/* <ContinueWithPassword callback={(mnemonics) => {
                                        this.setState({ ...this.state, mnemonics, mnemonics }, () => {
                                            this.submit();
                                        });
                                    }} /> */}
                                </div>
                                {/* </div> */}
                                {
                                    message && (
                                        <div className="mb-3">
                                            {
                                                !isError ? (
                                                    <div className="alert alert-success w-100 Fsize_12 mt-2" role="alert">
                                                        { message }
                                                    </div>
                                                ) : (
                                                    <div className="alert alert-danger Fsize_12 mt-2" role="alert">
                                                        { message }
                                                    </div>
                                                )
                                            }
                                        </div>
                                    )
                                }
                            </div>
                            <div className="modal-footer">
                                <button  disabled={isLoading} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close
                                </button>
                                {
                                    this.props.cypherSecret !== null ? (
                                        <button onClick={this.submit.bind(this)} type="button" disabled={ message && !isError || isLoading || !this.state.mnemonicsPwd } className="btn BtnBlack py-2 px-3">Generate { isLoading && <i className="fas fa-asterisk fa-spin"/> }</button>

                                    ) : (
                                        <button onClick={this.submit.bind(this)} type="button" disabled={ message && !isError || isLoading || this.state.public_address && this.state.public_address !== this.props.currentUser.public_address || !mnemonicValidate(this.state.mnemonics) } className="btn BtnBlack py-2 px-3">Generate { isLoading && <i className="fas fa-asterisk fa-spin"/> }</button>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStatetoProps = state => ({
    isLoggedIn: state.Auth.isLoggedIn,
    token: state.Auth.token,
    currentUser: state.Profile.profile,
    balance:    state.Wallet.balances,
    cypherSecret:    state.Auth.cypherSecret
})

export default connect(mapStatetoProps, { setCypherSecret })(GeneratePromoCode);