import invoke from '../../utils/invoke';

const BASE_PATH = process.env.REACT_APP_LISTING_SERVICE || 'http://localhost:4001';

export const searchByKeywords = (query = null) => {
    return invoke({
        method: 'GET',
        baseURL: BASE_PATH,
        route:  `v1/search${query}`
    });
}

export const searchByUserKeywords = (query = null) => {
    return invoke({
        method: 'GET',
        baseURL: BASE_PATH,
        route:  `v1/search/user${query}`
    });
}