import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { setProfile } from "../../../actions/profile";
import { getOwnProfile } from "../../../api/profile/profile";
import { getNSTBalanceApi, getNUCBalanceApi } from "../../../api/wallet/wallet";
import { isAuthRoute } from "../../../utils/utils";
import { setNUCBalance, setNSTBalance } from "../../../actions/wallet";
import { setToggle, setThemeToggle } from "../../../actions/settings";
import { getToggleStatus } from "../../../api/toggle/toggle";
import NotificationDropDown from "../../Notification/NotificationDropDown/NotificationDropDown";
import $ from "jquery";
import { CSSTransition } from "react-transition-group";

const REACT_APP_PROFILE_SERVICE =
  process.env.REACT_APP_PROFILE_SERVICE || "http://localhost:4002";

const Header = (props) => {
  const [showHeader, setShowHeader] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [isConnected, setIsConnected] = useState(true);
  useEffect(() => {
    if (localStorage.getItem("theme") === "dark") {
      $("body").toggleClass("DarkMode");
      $(".dropdown-menu").addClass("dropdown-menu-dark");
      $(".modal").addClass("DarkModal");
    }

    function changeStatus() {
      console.log("navigator.onLine):", navigator.onLine);
      setIsConnected(navigator.onLine);
    }

    window.addEventListener("online", changeStatus);
    window.addEventListener("offline", changeStatus);
    return () => {
      window.removeEventListener("online", changeStatus);
      window.removeEventListener("offline", changeStatus);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location.pathname]);

  useEffect(() => {
    if (props.isLoggedIn && props.profile.user_name) {
      getToggleStatus(
        props.profile.user_name,
        props.profile.country,
        "toggle_create_nft"
      )
        .then(({ data: { calculated_toggle } }) => {
          props.setToggle({
            key: "toggle_create_nft",
            value:
              props.profile.user_name === "hussfarajnft" ||
              props.profile.user_name === "hussfaraj"
                ? 1
                : calculated_toggle,
          });
        })
        .catch((e) => {
          props.setToggle({ key: "toggle_create_nft", value: 1 });
        });
      getToggleStatus(
        props.profile.user_name,
        props.profile.country,
        "toggle_kyc"
      )
        .then(({ data: { calculated_toggle } }) => {
          props.setToggle({ key: "toggle_kyc", value: calculated_toggle });
        })
        .catch((e) => {
          props.setToggle({ key: "toggle_kyc", value: 0 });
        });
    }
  }, [props.isLoggedIn, props.profile.user_name]);

  useEffect(async () => {
    setShowHeader(!isAuthRoute(window.location.pathname));
    try {
      if (props.isLoggedIn) {
        if (!props.profile.user_name) {
          const { data } = await getOwnProfile(props.token);
          props.setProfile(data);
        }

        getNUCBalanceApi(props.token)
          .then(({ data: { balance } }) => {
            props.setNUCBalance(balance);
          })
          .catch((e) => {});

        getNSTBalanceApi(props.token)
          .then(({ data: { balance } }) => {
            props.setNSTBalance(balance);
          })
          .catch((e) => {});
      }
    } catch (e) {}
  }, [window.location.pathname]);

  if (!showHeader) return null;

  const {
    location: { pathname },
  } = props;

  const { user_name, display_name, profile_image } = props.profile;

  const onChangeSearchText = (e) => {
    e.preventDefault();

    setSearchText(e.target.value);
  };

  return (
    <header>
      <nav className="navbar navbar-expand-xl">
        <div className="container-fluid justify-content-start">
          <Link className="navbar-brand me-2 order-1 order-xl-1" to="/">
            <img className={"img-fluid"} src="/images/logo.png" alt="Logo" />
          </Link>
          <button
            className={
              "btn btn-danger pt-2 pb-2 px-3 text-uppercase d-xl-none me-auto order-2"
            }
          >
            Beta v4
          </button>
          <button
            onClick={() => {
              $("body").toggleClass("DarkMode");
              if ($("body").hasClass("DarkMode")) {
                $(".dropdown-menu").addClass("dropdown-menu-dark");
                $(".modal").addClass("DarkModal");
                props.setThemeToggle("dark");
              } else {
                $(".dropdown-menu").removeClass("dropdown-menu-dark");
                $(".modal").removeClass("DarkModal");
                props.setThemeToggle("light");
              }
            }}
            type="button"
            className="p-0 bg-transparent ChangeTheme rounded-circle me-3 order-3"
          >
            <svg
              viewBox="0 0 24 24"
              fill="none"
              width="24"
              height="24"
              xlmns="http://www.w3.org/2000/svg"
              className="Moon"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.75788 17.4844C5.07158 16.7559 6.12135 15.7797 7.1112 15.928C7.42793 15.9754 7.75214 16 8.08209 16C11.6719 16 14.5821 13.0899 14.5821 9.5C14.5821 8.42871 14.3302 7.41794 13.8791 6.52697C13.4263 5.63282 14.0229 4.31312 14.9328 4.73338C17.6775 6.00109 19.5821 8.77812 19.5821 12C19.5821 16.4183 16.0004 20 11.5821 20C9.28652 20 7.21678 19.0331 5.75788 17.4844Z"
                fill="#1BD3ED"
              ></path>
            </svg>
            <svg
              viewBox="0 0 24 24"
              fill="none"
              width="24"
              height="24"
              xlmns="http://www.w3.org/2000/svg"
              className="Sun"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.25 4.75C11.25 4.33579 11.5858 4 12 4C12.4142 4 12.75 4.33579 12.75 4.75V6.25C12.75 6.66421 12.4142 7 12 7C11.5858 7 11.25 6.66421 11.25 6.25V4.75ZM16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12ZM12 17C11.5858 17 11.25 17.3358 11.25 17.75V19.25C11.25 19.6642 11.5858 20 12 20C12.4142 20 12.75 19.6642 12.75 19.25V17.75C12.75 17.3358 12.4142 17 12 17ZM19.25 11.25C19.6642 11.25 20 11.5858 20 12C20 12.4142 19.6642 12.75 19.25 12.75H17.75C17.3358 12.75 17 12.4142 17 12C17 11.5858 17.3358 11.25 17.75 11.25H19.25ZM7 12C7 11.5858 6.66421 11.25 6.25 11.25H4.75C4.33579 11.25 4 11.5858 4 12C4 12.4142 4.33579 12.75 4.75 12.75H6.25C6.66421 12.75 7 12.4142 7 12ZM16.5962 6.34314C16.8891 6.05024 17.364 6.05024 17.6569 6.34314C17.9497 6.63603 17.9497 7.1109 17.6569 7.4038L16.5962 8.46446C16.3033 8.75735 15.8284 8.75735 15.5355 8.46446C15.2426 8.17156 15.2426 7.69669 15.5355 7.4038L16.5962 6.34314ZM8.46447 15.5355C8.17158 15.2426 7.69671 15.2426 7.40381 15.5355L6.34315 16.5962C6.05026 16.8891 6.05026 17.3639 6.34315 17.6568C6.63605 17.9497 7.11092 17.9497 7.40381 17.6568L8.46447 16.5962C8.75737 16.3033 8.75737 15.8284 8.46447 15.5355ZM17.6569 16.5962C17.9498 16.8891 17.9498 17.364 17.6569 17.6569C17.364 17.9497 16.8891 17.9497 16.5962 17.6569L15.5355 16.5962C15.2427 16.3033 15.2427 15.8284 15.5355 15.5355C15.8284 15.2426 16.3033 15.2426 16.5962 15.5355L17.6569 16.5962ZM8.46448 8.46447C8.75738 8.17158 8.75738 7.69671 8.46448 7.40381L7.40382 6.34315C7.11093 6.05026 6.63605 6.05026 6.34316 6.34315C6.05027 6.63605 6.05027 7.11092 6.34316 7.40381L7.40382 8.46447C7.69671 8.75737 8.17159 8.75737 8.46448 8.46447Z"
                fill="#1BD3ED"
              ></path>
            </svg>
          </button>
          {props.isLoggedIn && (
            <div
              className="dropdown d-xl-flex align-items-center order-3"
              id="welcome"
            >
              <NotificationDropDown></NotificationDropDown>
              <div className="d-none d-xl-flex">
                <Link
                  id="ProfileDropDownButton"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  className={"d-flex justify-content-center align-items-center"}
                  to="my-items"
                >
                  {profile_image ? (
                    <img
                      className={"rounded-circle me-2"}
                      src={`${REACT_APP_PROFILE_SERVICE}/v1/profile/image/${user_name}`}
                      alt="Profile"
                    />
                  ) : (
                    <img
                      className={"rounded-circle"}
                      src="/images/user.png"
                      alt="Profile"
                    />
                  )}
                  <h6 className={"mb-0"}>
                    Hi {display_name || user_name}
                    <span className={"fw-normal Fsize_12 d-block"}>
                      Welcome Back
                    </span>
                  </h6>
                </Link>
                <div
                  className="dropdown-menu border-0 dropdown-menu-end pt-3"
                  aria-labelledby="ProfileDropDownButton"
                >
                  <div className="text-center GreyColor-2">
                    {profile_image ? (
                      <img
                        className="ImgProfile mb-2"
                        src={`${REACT_APP_PROFILE_SERVICE}/v1/profile/image/${user_name}`}
                        alt="Profile"
                      />
                    ) : (
                      <img
                        className="ImgProfile mb-2"
                        src="/images/user.png"
                        alt="Profile"
                      />
                    )}
                    <h5 className="mb-0">{display_name}</h5>
                    <p>{user_name}</p>
                  </div>
                  <ul className="list-unstyled mb-0">
                    <li>
                      <Link
                        style={{ cursor: "pointer" }}
                        className="dropdown-item"
                        to="/user/profile/edit"
                      >
                        <svg
                          className="me-2 mb-1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 448 512"
                        >
                          <path
                            fill="#7B7B7B"
                            d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"
                          ></path>
                        </svg>
                        Edit Profile
                      </Link>
                    </li>
                    {/* <li>
                                                    <a className="dropdown-item" href="#">
                                                        <svg className="me-2 mb-1" xmlns="http://www.w3.org/2000/svg" width="16"
                                                            height="16"
                                                            viewBox="0 0 16 16">
                                                            <path fill="#7B7B7B" className="b"
                                                                d="M3.793,3.376A1.339,1.339,0,0,0,3,4.6V7.729a8.3,8.3,0,0,0,6,8,8.3,8.3,0,0,0,6-8V4.6a1.339,1.339,0,0,0-.793-1.22L9.54,1.3a1.325,1.325,0,0,0-1.08,0ZM9,5.063H9a.669.669,0,0,1,.667.667h0A.669.669,0,0,1,9,6.4H9a.669.669,0,0,1-.667-.667h0A.669.669,0,0,1,9,5.063ZM9,7.729H9a.669.669,0,0,1,.667.667v2.667A.669.669,0,0,1,9,11.729H9a.669.669,0,0,1-.667-.667V8.4A.669.669,0,0,1,9,7.729Z"
                                                                transform="translate(-1 -0.396)"/>
                                                        </svg>
                                                        Privacy Policy</a>
                                                </li> */}
                    <li>
                      <a
                        style={{ cursor: "pointer" }}
                        className="dropdown-item"
                        onClick={() => {
                          const theme = localStorage.getItem("theme");
                          localStorage.clear();
                          localStorage.setItem("theme", theme);
                          window.location.href = "/";
                        }}
                      >
                        <svg
                          className="me-2 mb-1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                        >
                          <g transform="translate(2 2)">
                            <path
                              fill="#7B7B7B"
                              d="M4.333,4.333h4A.669.669,0,0,0,9,3.667H9A.669.669,0,0,0,8.333,3h-4A1.337,1.337,0,0,0,3,4.333v9.333A1.337,1.337,0,0,0,4.333,15h4A.669.669,0,0,0,9,14.333H9a.669.669,0,0,0-.667-.667h-4Z"
                              transform="translate(-3 -3)"
                            />
                            <path
                              fill="#7B7B7B"
                              d="M16.767,10.669l-1.86-1.86a.334.334,0,0,0-.573.233v1.193H9.667A.669.669,0,0,0,9,10.9H9a.669.669,0,0,0,.667.667h4.667v1.193A.33.33,0,0,0,14.9,13l1.86-1.86A.328.328,0,0,0,16.767,10.669Z"
                              transform="translate(-5 -4.903)"
                            />
                          </g>
                        </svg>
                        Logout
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}
          <button
            className="navbar-toggler px-2 order-4 collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="navbar-collapse flex-column flex-lg-row collapse order-4 order-xl-2"
            id="navbarSupportedContent"
          >
            <div className={"d-flex align-items-center"}>
              <div className="position-relative fg--search my-2 my-xl-0 me-4">
                <Link
                  id="search-redirect"
                  to={`/search?q=${searchText}`}
                ></Link>
                <input
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      document.getElementById("search-redirect").click();
                      setSearchText("");
                    }
                  }}
                  onChange={onChangeSearchText}
                  value={searchText}
                  className="form-control rounded-pill TTNormsReg"
                  placeholder="Search nfts, users..."
                />
                <button
                  className={
                    "p-0 border-0 bg-transparent shadow-none Fsize_18 z-index-2"
                  }
                  onClick={() =>
                    document.getElementById("search-redirect").click()
                  }
                  type="submit"
                >
                  <i className="fas fa-search"></i>
                </button>
              </div>
              <Link
                onClick={() => {
                  if (props.isLoggedIn)
                    getToggleStatus(
                      props.profile.user_name,
                      props.profile.country,
                      "toggle_create_nft"
                    )
                      .then(({ data: { calculated_toggle } }) => {
                        props.setToggle({
                          key: "toggle_create_nft",
                          value:
                            props.profile.user_name === "hussfarajnft" ||
                            props.profile.user_name === "hussfaraj"
                              ? 1
                              : calculated_toggle,
                        });
                      })
                      .catch((e) => {
                        props.setToggle({ key: "toggle_create_nft", value: 1 });
                      });
                }}
                className={`btn BtnBlack fw-bold py-2 px-3 rounded-pill d-xl-none`}
                to={"/create"}
              >
                Create
              </Link>
            </div>
            <button
              className={
                "btn btn-danger pt-2 pb-2 px-4 text-uppercase d-none d-xl-block"
              }
            >
              Beta v4
            </button>
            <ul className="navbar-nav ms-xl-auto">
              <li className="nav-item me-2 me-xl-3">
                <Link
                  className={`${
                    pathname === "/" ? "nav-link active" : "nav-link"
                  }`}
                  aria-current="page"
                  to="/"
                >
                  Explore
                </Link>
              </li>
              {props.isLoggedIn && (
                <li className="nav-item me-2 me-xl-3">
                  <Link
                    className={`${
                      pathname === "/my-items" ? "nav-link active" : "nav-link"
                    }`}
                    to="/my-items"
                  >
                    My items
                  </Link>
                </li>
              )}
              <li className="nav-item me-2 me-xl-3">
                <Link
                  className={`${
                    pathname === "/swap" ? "nav-link active" : "nav-link"
                  }`}
                  to="/swap"
                >
                  Swap
                </Link>
              </li>
              {/* <li className="nav-item me-2 me-xl-3">
                <Link
                  className={`${
                    pathname === "/invest" ? "nav-link active" : "nav-link"
                  }`}
                  to="/invest"
                >
                  Invest
                </Link>
              </li> */}
              {!props.isLoggedIn && (
                <li className="nav-item me-2 me-xl-3">
                  <Link className="nav-link" to="/login">
                    Login
                  </Link>
                </li>
              )}
              {!props.isLoggedIn && (
                <li className="nav-item me-2 me-xl-3">
                  <Link className="nav-link" to="/signup">
                    Sign Up
                  </Link>
                </li>
              )}
              {props.isLoggedIn && (
                <li className="nav-item dropdown d-xl-none">
                  <a
                    className="nav-link dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Profile
                  </a>
                  <ul className="dropdown-menu border-0 shadow-none p-0">
                    <li>
                      <Link className="dropdown-item" to="/user/profile/edit">
                        Edit Profile
                      </Link>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => {
                          localStorage.clear();
                          window.location.href = "/";
                        }}
                      >
                        Logout
                      </a>
                    </li>
                  </ul>
                </li>
              )}
            </ul>
            <Link
              onClick={() => {
                if (props.isLoggedIn)
                  getToggleStatus(
                    props.profile.user_name,
                    props.profile.country,
                    "toggle_create_nft"
                  )
                    .then(({ data: { calculated_toggle } }) => {
                      props.setToggle({
                        key: "toggle_create_nft",
                        value:
                          props.profile.user_name === "hussfarajnft" ||
                          props.profile.user_name === "hussfaraj"
                            ? 1
                            : calculated_toggle,
                      });
                    })
                    .catch((e) => {
                      props.setToggle({ key: "toggle_create_nft", value: 1 });
                    });
              }}
              className={`btn BtnBlack py-2 px-3 rounded-pill me-3 d-none d-xl-inline-block`}
              to={"/create"}
            >
              Create
            </Link>
            <a
              className={`btn BtnBlack py-2 px-3 rounded-pill me-3 d-none d-xl-inline-block`}
              target="_blank"
              href="https://explorer.nftcity.exchange"
            >
              NFT Explorer
            </a>
          </div>
        </div>
      </nav>

      <CSSTransition
        in={!isConnected}
        timeout={200}
        classNames={"internet-error"}
        unmountOnExit
      >
        <div
          style={{
            width: "100%",
            textAlign: "center",
            padding: 3,
            backgroundColor: "red",
            color: "white",
            fontSize: 13,
            position: "absolute",
          }}
        >
          Internet connection is down!
        </div>
      </CSSTransition>
    </header>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.Auth.isLoggedIn,
  token: state.Auth.token,
  profile: state.Profile.profile,
});

export default connect(mapStateToProps, {
  setProfile,
  setNSTBalance,
  setNUCBalance,
  setToggle,
  setThemeToggle,
})(Header);
