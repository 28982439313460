import React, { Component, useEffect, useState, useRef } from "react";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { addCommentApi } from "../../../../api/nft/nft";
import InputIntellisense from "../InputIntellisense/InputIntellisense";


export default (props) => {

  const inputFieldRef = useRef();
  const inputComponentRef = useRef();

  const [state, setState] = useState({
    textValue: undefined,
    isSearching: false,
    searchedUsersToTag: [],
    mentions: [],
    isSubmiting: false
  });

  const onChangeListener = (event) => {
    setState((prev) => ({ ...prev, textValue: event.target.value }));
  };
  
  const onKeyPressedListener = (event) => {
    
    if (event.key === "Escape") return setState({ ...state, isEditing: false });

    if(event.key === 'Enter' && event.shiftKey) return;

    if (event.keyCode === 13) {
      event.preventDefault();
      submitComment();
      return;
    }
  };

  const onTagItemSelected = ({ item: { user_name } }) => {
    setState((prev) => ({ ...prev, mentions: [ ...state.mentions, user_name ], isSubmiting: false }));
  };

  const submitComment = (e) => {
    if (!props.userToken) {
      toast.error("Please login to write your comment.");
      return;
    }

    const { textValue, mentions, isSubmiting } = state;

    if(isSubmiting) return;

    try {
      setState((prev) => ({ ...prev, isSubmiting: true }));
      addCommentApi(props.nftID, { comment: textValue.trim(), mentions: mentions }, props.userToken).then(({ data: { id } }) => {
          props.commentAddCallback?.({ id, user_name: props.userName, comment: textValue.trim(), mentions: mentions, create_time: Date.now() });
          setState((prev) => ({ ...prev, textValue: "", isSubmiting: false, mentions: [] }));
        })
        .catch(() => {
          toast.error("Somthing is wroing. Please check your internet connection!");
          // if (inputFieldRef.current) inputFieldRef.current.value = null;
          setState((prev) => ({ ...prev, textValue: "", isSubmiting: false, mentions: [] }));
        });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="d-flex justify-content-between align-self-start border-top pt-4 pb-4" style={{maxWidth: "94%"}}>
      {props.userToken ? (
        <>
        <InputIntellisense onChangeListener={onChangeListener} onKeyPressedListener={onKeyPressedListener} textValue={state.textValue} onTagItemSelected={onTagItemSelected} inputComponentRef={inputComponentRef} />
          <button className={`btn ${state.textValue?.trim()?.length > 0 ? "BtnBlack" : "BtnBlack"} pt-2 pb-2 px-4 text-uppercase  d-xl-block ms-2`} style={{ width: 120 }} onClick={submitComment}>
            { state.isSubmiting ? <Spinner animation="border" style={{ width: 20, height: 20 }} /> : "Post" }
          </button>
        </>
      ) : (
        <Link to="/login" className="text-primary Fsize_12 d-flex align-items-center">
          Please login to write your comment.
        </Link>
      )}
    </div>
  );
};