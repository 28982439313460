import JSEncrypt from 'jsencrypt';
import { CERTIFICATE_PUB, FIAT_PAYMENT_CERTIFICATE_PUB } from '../security/certificate';

const jsEncrypt = new JSEncrypt();
jsEncrypt.setPublicKey(CERTIFICATE_PUB);

const fiatJSEncrypt = new JSEncrypt();
fiatJSEncrypt.setPublicKey(FIAT_PAYMENT_CERTIFICATE_PUB);

export function isAuthRoute(location) {
    return location === '/login' || location === '/signup' || location === '/forget-credential' || location === '/update-password';
}

export function isItemDetailSection(location) {
    try {
        return location.split('/')[1] === 'detail';
    } catch (e) { return false; }
}

export function encrypt(message) {
    if (typeof message === 'object') return { data: jsEncrypt.encrypt(JSON.stringify(message)) };
    else return jsEncrypt.encrypt(message);
}

export function encryptFiatPaymentData(message) {
    if (typeof message === 'object') return fiatJSEncrypt.encrypt(JSON.stringify(message)).toString();
    else return fiatJSEncrypt.encrypt(message);
}

export function validateEmail(email = '') {
    return /^(([^<>()[\]\\.,;:\s@"](\.[^<>()[\]\\.,;:\s@"])*)|("."))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]\.)[a-zA-Z]{2,}))$/.test(email.toLowerCase());
}

export function validatePhoneNumber(phone) {
    return /1?\W*([2-9][0-8][0-9])\W*([2-9][0-9]{2})\W*([0-9]{4})(\se?x?t?(\d*))?/.test(phone);
}

export function validateWebsite(website) {
    return /^((https?|http):\/\/)?(www.)?[a-z0-9]\.[a-z](\/[a-zA-Z0-9#]\/?)*$/.test(website);
}

export function validatedNumber(value) {
    return /^(?=.)([-]?([0-9]*)(\.([0-9]))?)$/.test(value);
}

export function cleanObject(obj) {
    for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
            delete obj[propName];
        }
    }
    return obj
}

export function isVideo(type) {
    return RegExp('video|VIDEO|mp4|MP4|MOV|mov').test(type);
}

export function onlyAdmins(username) {
    return username === 'luaymm' || username === 'hussfaraj';
}

export function parseQueryParamsToObject(query) {

    if(!query) return {};

    query = query.replace('?', '');
    query = query.split('&');

    let object = {};

    for(let param of query) {
        const [ key, value ] = param.split('=');
        object[key] =value;
    }
    return object;
}