import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getInvesterInfoApi, bookInvesterSlotApi, bookInvesterSlotWithoutAuthApi } from '../../api/invest/invest';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import ModalAcceptEquityTerms from '../Layouts/Modals/ModalAcceptEquityTerms';
import MessageModal from '../Layouts/Modals/MessageModal';
import { getOwnProfile } from '../../api/profile/profile';
import { setProfile } from '../../actions/profile';
import InvestSubmissionConfirmationModal from '../Layouts/Modals/InvestSubmissionConfirmationModal';

class Invest extends Component {


    state = {
        silver: {
            limit: 3000,
            value: null,
            equity_share_value: null
        },
        gold: {
            limit: 1200,
            value: null,
            equity_share_value: null
        },
        diamond: {
            limit: 600,
            value: null,
            equity_share_value: null
        },
        platinum: {
            limit: 300,
            value: null,
            equity_share_value: null
        },
        selected: null,
        isLoading: false,
        disabled: false,
        isShowAcceptEquityTermsModal:false,
        ignoreModal: false,
        isConfirmed: false
    }

    async componentDidMount() {
        this.getInvesterInformation();
        // this.updateUserInformation()
       
    }

    updateUserInformation = async () => {
        try {
            const { data } = await getOwnProfile(this.props.token);
            this.props.setProfile(data);
        } catch(e) {
            console.log('tester ', e)
        }
    }
    getInvesterInformation = async () => {
        try {
            const { data } = await getInvesterInfoApi();
            await Promise.all(Object.keys(data).map(key => this.state[key].value = (data[key].count || 0)));
            this.setState({ ...this.state });
        } catch(e) {
            console.log('tester ', e)
        }
    }

    onChange = e => {
        this.setState({ ...this.state, [e.target.name]: { ...this.state[e.target.name], equity_share_value: e.target.value }});
    }

    sendEmail = async () => {
       
            try {

                let value = null;
    
                try {
                    value = parseInt(this.state[this.state.selected].equity_share_value)
                    if(Number.isNaN(value)) {
                        toast.info("Equity share value must be a positive integer!");
                        return;
                    }
                    if(value > (this.state[this.state.selected].limit - this.state[this.state.selected].value)) {
                        toast.info(`Equity share value must be less then the available equity ${this.state[this.state.selected].limit - this.state[this.state.selected].value}`);
                        return;
                    }
                    
                    if(!this.state.ignoreModal)
                        if(this.props.profile?.nft_equity_acceptance !== 1) {
                            this.setState({ ...this.state, isShowAcceptEquityTermsModal: true });
                            return;
                        }
                } catch(e) {
                    toast.info("Equity share value must be a positive integer!");
                    return;
                }

                if(!this.props.token && !this.state.isConfirmed) {
                    document.getElementById('investSubmissionModalBtn').click();
                    return;
                }
                
                this.setState({ ...this.state, isLoading: true });
                
    
                if(this.props.token) await bookInvesterSlotApi({ equity_share_value: value, nft_equity_class: this.state.selected === 'silver' ? 'Silver' : this.state.selected === 'gold' ? 'Gold' : this.state.selected === 'diamond' ? 'Diamond' : 'Platinum' }, this.props.token);
                else {
                    const { display_name, email, country_code, mobile_number } = this.state.data;
                    await bookInvesterSlotWithoutAuthApi({ equity_share_value: value, nft_equity_class: this.state.selected === 'silver' ? 'Silver' : this.state.selected === 'gold' ? 'Gold' : this.state.selected === 'diamond' ? 'Diamond' : 'Platinum', name: display_name, email, mobile_number: `${country_code}${mobile_number}` });
                }
                
                this.componentDidMount();
   
               this.setState((state)=>({...state, isLoading: false, isShowAcceptEquityTermsModal: false, ignoreModal: false, isConfirmed: false, disabled: false,showConfirmation:true, modalMsg:"Your expression of interest has been submitted. A member of our On-boarding team will reach out to you shortly",modalTitle:"", modalType:"SUBMITED", showCancelModalBtn:false}))

               this.refreshState();
            } catch(e) {
                if(e.response?.data?.message)
                {
                    toast.error(e.response.data.message);
                    if(e.response.data.type === "MOBILE_NOT_CONFIRMED")
                    {
                        this.setState({ ...this.state, isConfirmed: false, data: null }, () => {
                            document.getElementById('investSubmissionModalBtn').click();
                        })
                    }
                   
                }
                else{
                    toast.info("Unable to send the email! Please try again later!");
                }
             
                this.setState({ ...this.state, isLoading: false, disabled: false });
            }
    }

    self = this;


    refreshState = ()=>{

      
            this.setState((state)=>({...state,
                silver: {
                    limit: 3000,
                    value: this.state.silver.value,
                    equity_share_value: ''
                },
                gold: {
                    limit: 1200,
                    value: this.state.gold.value,
                    equity_share_value: ''
                },
                diamond: {
                    limit: 600,
                    value: this.state.diamond.value,
                    equity_share_value: ''
                },
                platinum: {
                    limit: 300,
                    value: this.state.platinum.value,
                    equity_share_value: ''
                },
                selected: null,
                isLoading: false,
                disabled: false,
                isShowAcceptEquityTermsModal:false
            }), () => {
                this.getInvesterInformation();
            })
        
    }
    render() {
        // const { user_name, public_address, profile_image, display_name, twitter_username, site_or_portfolio, cover_image } = this.props.profile;
        // const { itemsOnSale, itemsOwned, itemsCreated, itemsPending, itemsEquity, activities } = this.state;

        const { silver, gold, diamond, platinum, selected, isLoading,isShowAcceptEquityTermsModal } = this.state;


        return (
            <main>
                <section id="ItemsProfile" className="py-5 BgGrey">
                    <div className="container">
                        <div className='row' id='zIndexMob'>
                            <div className='container'>
                                <table className="table InvestCat mb-3" border="1">
                                    <thead>
                                        <tr>
                                            <th scope="col">NFT Class</th>
                                            <th scope="col">Equity Share Cost</th>
                                            <th scope="col">Max. Available Equity Shares</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="text-center"><strong>Silver</strong></td>
                                            <td className="text-center">$1000.00</td>
                                            <td className="text-center">3000</td>
                                        </tr>
                                        <tr>
                                            <td className="text-center"><strong>Gold</strong></td>
                                            <td className="text-center">$2,500.00</td>
                                            <td className="text-center">1200</td>
                                        </tr>
                                        <tr>
                                            <td className="text-center"><strong>Diamond</strong></td>
                                            <td className="text-center">$5,000.00</td>
                                            <td className="text-center">600</td>
                                        </tr>
                                        <tr>
                                            <td className="text-center"><strong>Platinum</strong></td>
                                            <td className="text-center">$10,000.00</td>
                                            <td className="text-center">300</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <section className="pt-5">
                                <div className=''>
                                    <div className='row'>
                                        <div className='col-sm-12 col-md-6 col-lg-3 mb-3'>
                                            <div onClick={() => { if(!this.state.disabled) this.setState((state)=>({ ...state, selected: 'silver' })) }} style={{ cursor: 'pointer' }} className={`top_earning m-0 ${selected && selected != 'silver' ? 'disable' : ''}`}>
                                                <a >
                                                    <div className="image">
                                                        <img className='w-100' src="/images/Silver_NFT_Equity.jpeg" />
                                                    </div>
                                                </a>
                                                <div className="main my-2 align-items-center nucin">
                                                    <div className="left">
                                                        <h6 className="HeadingEllipsis mb-0">Silver </h6>
                                                    </div>
                                                    <div className="right block-typ">
                                                        <div className="fl-image">
                                                        </div>
                                                        <div className="category-icon ms-auto">
                                                            {/* <span id="numbers">2689</span> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="main">
                                                    <div className="left text-center w-100 mb-2">
                                                        <h4 style={{ fontSize: "12px", margin: "0px" }}>{silver.value !== null ? silver.limit - silver.value : 'N/A'} / <strong>{this.state.silver.limit}</strong> available equality shares</h4>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        Equity Interest
                                                    </div>
                                                    <div className="col-6">
                                                        <input disabled={this.state.disabled} name="silver" onChange={this.onChange} type="number" className="form-control shadow-none" placeholder="0" value={silver.equity_share_value}/>
                                                    </div>
                                                </div>
                                                <button onClick={this.sendEmail.bind(this)} style={{ fontSize: "12px" }} className="btn btn-danger pt-2 pb-2 px-2 text-uppercase  d-xl-block" disabled={selected === 'silver' && isLoading ? true : this.state.disabled}>{ selected === 'silver' && isLoading ? 'Sending...' : 'Submit Interest'}</button>
                                            </div>
                                        </div>
                                        <div className='col-sm-12 col-md-6 col-lg-3 mb-3'>
                                            <div onClick={() => { if(!this.state.disabled)  this.setState((state)=>({ ...state, selected: 'gold' })) }} style={{ cursor: 'pointer' }} className={`top_earning m-0 ${selected && selected != 'gold' ? 'disable' : ''}`}>
                                                <a >
                                                    <div className="image">
                                                        <img className='w-100' src="/images/Gold_NFT_Equity.jpeg" />
                                                    </div>
                                                </a>
                                                <div className="main my-2 align-items-center nucin">
                                                    <div className="left">
                                                        <h6 className="HeadingEllipsis mb-0">Gold</h6>
                                                    </div>
                                                    <div className="right block-typ">
                                                        <div className="fl-image">
                                                        </div>
                                                        <div className="category-icon ms-auto">
                                                            {/* <span id="numbers">2689</span> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="main">
                                                    <div className="left text-center w-100 mb-2">
                                                        <h4 style={{ fontSize: "12px", margin: "0px" }}>{gold.value !== null ? gold.limit - gold.value : 'N/A'} / <strong>{this.state.gold.limit}</strong> available equality shares</h4>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        Equity Interest
                                                    </div>
                                                    <div className="col-6">
                                                        <input disabled={this.state.disabled} name="gold" onChange={this.onChange} type="number" className="form-control shadow-none" placeholder="0" value={gold.equity_share_value}/>
                                                    </div>
                                                </div>
                                                <button onClick={this.sendEmail.bind(this)} style={{ fontSize: "12px" }} className="btn btn-danger pt-2 pb-2 px-2 text-uppercase  d-xl-block"disabled={selected === 'gold' && isLoading ? true : this.state.disabled}>{ selected === 'gold' && isLoading ? 'Sending...' : 'Submit Interest'}</button>
                                            </div>
                                        </div>
                                        <div className='col-sm-12 col-md-6 col-lg-3 mb-3'>
                                            <div onClick={() => { if(!this.state.disabled)  this.setState((state)=>({ ...state, selected: 'diamond' })) }} style={{ cursor: 'pointer' }} className={`top_earning m-0 ${selected && selected != 'diamond' ? 'disable' : ''}`}>
                                                <a >
                                                    <div className="image">
                                                        <img className='w-100' src="/images/Diamond_NFT_Equity.jpeg" />
                                                    </div>
                                                </a>
                                                <div className="main my-2 align-items-center nucin">
                                                    <div className="left">
                                                        <h6 className="HeadingEllipsis mb-0">Diamond </h6>
                                                    </div>
                                                    <div className="right block-typ">
                                                        <div className="fl-image">
                                                        </div>
                                                        <div className="category-icon ms-auto">
                                                            {/* <span id="numbers">2689</span> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="main">
                                                    <div className="left text-center w-100 mb-2">
                                                        <h4 style={{ fontSize: "12px", margin: "0px" }}>{diamond.value !== null ? diamond.limit - diamond.value : 'N/A'} / <strong>{this.state.diamond.limit}</strong> available equality shares</h4>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        Equity Interest
                                                    </div>
                                                    <div className="col-6">
                                                        <input disabled={this.state.disabled} name="diamond" onChange={this.onChange} type="number" className="form-control shadow-none" placeholder="0" value={diamond.equity_share_value}/>
                                                    </div>
                                                </div>
                                                <button onClick={this.sendEmail.bind(this)} style={{ fontSize: "12px" }} className="btn btn-danger pt-2 pb-2 px-2 text-uppercase  d-xl-block"disabled={selected === 'diamond' && isLoading ? true : this.state.disabled}>{ selected === 'diamond' && isLoading ? 'Sending...' : 'Submit Interest'}</button>
                                            </div>
                                        </div>
                                        <div className='col-sm-12 col-md-6 col-lg-3 mb-3'>
                                            <div onClick={() => { if(!this.state.disabled) this.setState((state)=>({ ...state, selected: 'platinum' })) }} style={{ cursor: 'pointer' }} className={`top_earning m-0 ${selected && selected != 'platinum' ? 'disable' : ''}`}>
                                                <a >
                                                    <div className="image">
                                                        <img className='w-100' src="/images/Platinum_NFT_Equity.jpeg" />
                                                    </div>
                                                </a>
                                                <div className="main my-2 align-items-center nucin">
                                                    <div className="left">
                                                        <h6 className="HeadingEllipsis mb-0">Platinum</h6>
                                                    </div>
                                                    <div className="right block-typ">
                                                        <div className="fl-image">
                                                        </div>
                                                        <div className="category-icon ms-auto">
                                                            {/* <span id="numbers">2689</span> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="main">
                                                    <div className="left text-center w-100 mb-2">
                                                        <h4 style={{ fontSize: "12px", margin: "0px" }}>{platinum.value !== null ? platinum.limit - platinum.value : 'N/A'} / <strong>{this.state.platinum.limit}</strong> available equality shares</h4>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        Equity Interest
                                                    </div>
                                                    <div className="col-6">
                                                        <input disabled={this.state.disabled} name="platinum" onChange={this.onChange} type="number" className="form-control shadow-none" placeholder="0" value={platinum.equity_share_value}/>
                                                    </div>
                                                </div>
                                                <button onClick={this.sendEmail.bind(this)} style={{ fontSize: "12px" }} className="btn btn-danger pt-2 pb-2 px-2 text-uppercase  d-xl-block"disabled={selected === 'platinum' && isLoading ? true : this.state.disabled}>{ selected === 'platinum' && isLoading ? 'Sending...' : 'Submit Interest'}</button>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div className='row mt-4 text-center'>
                                        <div>
                                            <a className="btn BtnBlack rounded-0 py-2 px-4 px-md-4" onClick={this.refreshState}>Refresh</a>
                                            {/* <a className="btn BtnBorderBlack2 d-inline-block ms-2 rounded-0 py-2 px-4 px-md-4" >Close</a> */}
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>

                        
                    </div>
                </section>
                <ModalAcceptEquityTerms isLoggedIn={this.props.token} isShow={isShowAcceptEquityTermsModal} callBack={{termsAccepted:()=>{
                    
                    this.setState({ ...this.state, ignoreModal: true, isShowAcceptEquityTermsModal: false }, () => {
                        this.sendEmail()
                    });

                }, termsRejected:()=>{
                    this.refreshState();
                    
                }}}/>
           
            <MessageModal  showCancel = {this.state.showCancelModalBtn} msg={this.state.modalMsg ?? ""} title={this.state.modalTitle ?? ""} showModal={this.state.showConfirmation ?? false}  onCloseYesOrNoModal={(value) => {
                    if (value === true) {
                       
                        
                        this.setState((state)=>({...state, showConfirmation: false, modalMsg:null, modalTitle:null, modalType:null, showCancelModalBtn:false }))
                    } else {
                        this.setState((state)=>({...state, showConfirmation: false, modalMsg:null, modalTitle:null, modalType:null, showCancelModalBtn:false }))
                    }
                }}/>
            <InvestSubmissionConfirmationModal callback={(data) => {
                this.setState({ ...this.state, isConfirmed: true, data: data }, () => {
                    this.sendEmail();
                })
            }} profile={this.props.profile || null}/>
            </main>
        )
    }
}

const mapStateToProps = state => ({
    token: state.Auth.token,
    profile: state.Profile.profile
})

export default connect(mapStateToProps, { setProfile})(Invest);