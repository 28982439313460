import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import NotificationListCard from './NotificationListCard/NotificationListCard';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import DeleteModal from '../../Layouts/DeleteModal/DeleteModal';
import { markNotificationAsRemoveById } from 'notificationsdk';

class NotificationList extends Component {

    state = {
        id: null
    }

    deleteNotification = () => {
        return markNotificationAsRemoveById(this.state.id);
    }

    setDeleteId = (id) => {
        this.setState({ ...this.state, id });
    }

    render() {
        return (
            <>
                <section className='py-5 BgGrey'>
                    <div className='container'>
                        <div className="row mb-40" id='zIndexMob'>
                            <div className="col-md-8 offset-md-2 col-sm-12">
                                <div className="NotificationList">
                                    <div className="card Box top_earning">
                                        <div className="card-header bg-transparent border-bottom pl-0">
                                            <div className="d-flex flex-wrap align-items-start">
                                                <div className="me-2">
                                                    <h3 className="card-title mt-1 mb-0 WhiteColor"><strong>Notifications</strong></h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body p-0">
                                            <Tabs className="TabsList NotificationTabsList">
                                                <TabList className="p-0 mt-3 border-0">
                                                    <Tab>Announcement</Tab>
                                                    <Tab>All</Tab>
                                                    <Tab>Unread</Tab>
                                                </TabList>
                                                <TabPanel>
                                                    <ul className="list-group list-group-flush p-0 list-inline">
                                                        {
                                                            this.props.announcements.data.map(({ id, title, message, userpreferences, category, create_time, ttl }) => <NotificationListCard key={id} id={id} title={title} message={message} is_read={true} category={category} create_time={create_time} setDeleteId={this.setDeleteId} ttl={ttl} />)
                                                        }
                                                        {
                                                            this.props.announcements.data.length === 0 && <label className="text-secondary mb-2 text-center">No announcements found!</label>
                                                        }
                                                    </ul>
                                                </TabPanel>
                                                <TabPanel>
                                                    <ul className="list-group list-group-flush p-0 list-inline">
                                                        {/* <InfiniteScroll
                                                            dataLength={items.length}
                                                        > */}
                                                            
                                                        {/* </InfiniteScroll> */}
                                                        {
                                                            this.props.notifications.all.data.map(({ id, title, message, userpreferences, category, create_time, ttl }) => <NotificationListCard key={id} id={id} title={title} message={message} is_read={userpreferences.is_read} category={category} create_time={create_time} setDeleteId={this.setDeleteId} ttl={ttl} />)
                                                        }
                                                        {
                                                            this.props.notifications.all.data.length === 0 && <label className="text-secondary mb-2 text-center">No notifications found!</label>
                                                        }
                                                    </ul>

                                                </TabPanel>
                                                <TabPanel>
                                                    <ul className="list-group list-group-flush p-0 list-inline">
                                                        {
                                                            this.props.notifications.unread.data.map(({ id, title, message, userpreferences, category, create_time, ttl }) => <NotificationListCard key={id} id={id} title={title} message={message} is_read={userpreferences.is_read} category={category} create_time={create_time} setDeleteId={this.setDeleteId} ttl={ttl} />)
                                                        }
                                                        {
                                                            this.props.notifications.unread.data.length === 0 && <label className="text-secondary mb-2 text-center">No notifications found!</label>
                                                        }
                                                    </ul>
                                                </TabPanel>
                                            </Tabs>
                                        </div>
                                    </div>
                                    <DeleteModal callback={this.deleteNotification} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

const mapStateTopProps = state => ({
    announcements: state.Notification.announcements,
    notifications: state.Notification.notifications,
})

export default connect(mapStateTopProps, {})(NotificationList);