import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import PhoneInput from 'react-phone-input-2';

export default (props) => {

    const [ profile, setProfile ] = useState(null);
    const [ state, setState ] = useState({});

    useEffect(() => {
        setProfile(props.profile);
    }, [ props.profile ])

    const onChange = e => {
        setProfile({ ...(profile || {}), [e.target.name]: e.target.value });
    }

    const { user_name, display_name, email, mobile_number } = profile || {};

    const submit = () => {

        try {
            if(display_name == undefined || display_name == null || display_name == '') return setState({ ...state, isError: 'Display name is required!' });
            if(email == undefined || email == null || email == '') return setState({ ...state, isError: 'Email is required!' });
            if(mobile_number == undefined || mobile_number == null || mobile_number == '') return setState({ ...state, isError: 'Phone number is required!' });
            
            if(!profile.country_code) profile.country_code = '+61';

            props?.callback(profile);

            document.getElementById('investSubmissionModalBtn').click();
            
            setState({ ...state, isError: null });
        } catch(e) {

        }
    }

    return (
        <React.Fragment>
            <button style={{ display: 'none' }} id="investSubmissionModalBtn" type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#investSubmissionModal">
                Launch demo modal
            </button>
            <div className="modal fade DarkModal" data-bs-backdrop="static" data-bs-keyboard="false" id="investSubmissionModal" tabindex="-1" aria-labelledby="messageBoxModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md" style={{ maxWidth: '60%' }}>
                    <div className="modal-content">
                        <div className="modal-header align-items-start">
                            <h5 className="modal-title" id="investSubmissionModalLabel">Investment Submission</h5>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="mb-3">
                                                <label className="form-label CeraMed d-block mb-1">Full Name</label>
                                                <input name="display_name" onChange={onChange} type="text" className="form-control shadow-none mb-3" placeholder="Full Name" />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label CeraMed d-block mb-1">Email</label>
                                                <input name="email" onChange={onChange} value={email} type="text" className="form-control shadow-none mb-3" placeholder="Email" />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="mb-3">
                                                <div className="row location mb-4">
                                                    <div className="col-md-6 col-lg-6 col-xl-4 mb-4 mb-md-0">
                                                        <label className="form-label CeraMed d-block mb-1">Country</label>
                                                        <PhoneInput
                                                            inputProps={{
                                                                required: true,
                                                                disabled: true
                                                            }}
                                                            inputClass={'w-100'}
                                                            country={'au'}
                                                            // value={mobile_number}
                                                            onChange={(country_code, { name }) => setProfile({
                                                                ...(profile || {}), country_code, country_name: name
                                                            })}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 col-lg-6 col-xl-8">
                                                        <label className="form-label CeraMed d-block mb-1">Phone</label>
                                                        <input onChange={onChange}
                                                            className="form-control shadow-none"
                                                            id="phone" value={mobile_number} name="mobile_number" placeholder="Enter phone number"/>
                                                    </div>
                                                </div>
                                                {/* <label className="form-label CeraMed d-block mb-1">Phone Number</label>
                                                <input name="phone" type="text" value={phone} onChange={onChange} className="form-control shadow-none mb-3" placeholder="Phone Number" /> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {
                                            state.isError && (
                                                <div className="alert alert-danger" role="alert">
                                                    {state.isError}
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button data-bs-toggle="modal" data-bs-target="#investSubmissionModal" type="button" className="btn py-2 px-5">Cancel</button>
                            <button onClick={() => {
                                submit();
                            }} type="button" className="btn BtnBlack py-2 px-5">Confirm</button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}