import React, { useEffect, useState } from 'react';

export default ({ id = "messageBoxBtn", title, message, isReload = false, isError = false, isProcessing = false, callback = null, style = {} }) => {
    
    return (
        <React.Fragment>
            <button style={{display: 'none'}} id={id} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#messageBoxModal">
                Launch demo modal
            </button>
            <div className="modal fade DarkModal" data-bs-backdrop="static" data-bs-keyboard="false" id="messageBoxModal" tabindex="-1" aria-labelledby="messageBoxModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md">
                    <div className="modal-content">
                        <div className="modal-header align-items-start">
                            <h5 className="modal-title" id="messageBoxModalLabel">{ isProcessing ? 'Processing...' : title}</h5>
                            {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                        </div>
                        <div className="modal-body">
                            <div className="mb-3">
                                { isProcessing && <label className="text-secondary">{message || 'We are working on it...'}</label> }
                                { isError && <label className="text-danger break-all">{message === '1010: Invalid Transaction: Inability to pay some fees , e.g. account balance too low' || message === 'Not enough amount to purchase!' ? (
                                    <React.Fragment>
                                        Balance too low you can Top Up your account by clicking 'Top Up Account' option.
                                    </React.Fragment>
                                ) : message}</label> }
                                { !isError && <label className="text-success break-all">{message}</label> }
                            </div>
                        </div>
                        <div className="modal-footer">
                            {/* <button type="button" className="btn BtnBorderBlack py-2 px-5" data-bs-dismiss="modal">Close</button> */}
                            { (message === '1010: Invalid Transaction: Inability to pay some fees , e.g. account balance too low' || message === 'Not enough amount to purchase!') && <button data-bs-toggle="modal" data-bs-target="#messageBoxModal" onClick={() => {
                                document.getElementById('messageBoxModal').click();
                                setTimeout(() => {
                                    document.getElementById('topUpModalBtn').click();
                                }, 300)
                            }} type="button" className="btn BtnBorder pt-2 pb-2 px-4 text-uppercase d-none d-xl-block">Top Up Account </button> }
                            { !isProcessing && isReload && !isError && <button onClick={() => window.location.reload()} data-bs-toggle="modal" data-bs-target="#messageBoxModal" type="button" className="btn BtnBlack py-2 px-5">Refresh </button>}
                            { callback && <button onClick={() => { 
                                if(callback) callback();
                                document.getElementById(id).click();
                            }} data-bs-toggle="modal" data-bs-target="#messageBoxModal" type="button" className="btn BtnBlack py-2 px-5">OK</button> }
                            { !isProcessing && isReload && isError && !callback && <button data-bs-toggle="modal" data-bs-target="#messageBoxModal" type="button" className="btn BtnBlack py-2 px-5">OK </button> }
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}