import React, { Component } from "react";
import { Keyring } from '@polkadot/api';
import { mnemonicValidate } from '@polkadot/util-crypto';
import { connect } from "react-redux";
import { setCypherSecret } from "../../../actions/auth";
import ContinueWithPassword from "../ContinueWithPassword/ContinueWithPassword";
import { encryptFiatPaymentData } from "../../../utils/utils";
import CryptoJS from 'crypto-js';

const FIAT_PAYMENT_PAYPAL = process.env.REACT_APP_FIAT_PAYMENT_PAYPAL || 'http://localhost:3000/payment/paypal/nft/checkout'

class PayPalPaymentModal  extends Component {

    refIframe = null;

    state = {
        public_address: null,
        saveMyMnemonics: false,
        mnemonicsPwd: '',
        isError: false,
        success: null,
        isLoading: true,
        mnemonics: null,
        isConfirmed: true,
        query: null,
        isRedirecting: false
    }

    onChangeMnemonics = (e, isRedirect = false) => {
        e.preventDefault && e.preventDefault();

        if (mnemonicValidate(e.target.value)) {
            const keyring = new Keyring({
                type: 'sr25519',
                ss58Format: 42,
            });
            const newPair = keyring.addFromUri(e.target.value);

            const { id, title, price, user_id, user_name } = this.props;
            const encryptedQuery = encryptFiatPaymentData({ id, title,  subscriber_id: 1, payment_subscription_id: 1, price, user_id, user_name, mnemonics: e.target.value, redirect: `${process.env.REACT_APP_DOMAIN_URL}/detail/${id}` });

            this.setState({ ...this.state, mnemonics: e.target.value, public_address: newPair.address, query: encryptedQuery }, () => {
                if(isRedirect) this.setState({ ...this.state, isRedirecting: true }, () => {
                    window.location.href = `${FIAT_PAYMENT_PAYPAL}?query=${this.state.query}`; 
                });
            });
            
        } else this.setState({ ...this.state, mnemonics: e.target.value, public_address: null });
    }

    isValidMnemonics = () => !(!mnemonicValidate(this.state.mnemonics) || this.props.currentUser.public_address !== this.state.public_address || (this.state.saveMyMnemonics ? this.state.mnemonicsPwd.length < 6 : false));

    render() {
        return (
            <React.Fragment>
                <button style={{ display: 'none' }} id="payPalPaymentModalBtn" type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#payPalPaymentModal">
                    Launch demo modal
                </button>
                <div className="modal fade" id="payPalPaymentModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md">
                        <div className="modal-content">
                            <div className={'modal-body'}>
                                <div className={ this.state.isRedirecting ? 'd-none' : this.props.cypherSecret === null ? "" : 'd-none'}>
                                    <React.Fragment className={this.isValidMnemonics() ? 'd-none' : ''}>
                                        <h3 className="mb-4 text-center">Please enter your account mnemonics to confirm transaction</h3>
                                        <div className="mb-3">
                                            <label className="form-label CeraMed d-block mb-1">Account Mnemonics</label>
                                            <textarea
                                                style={{ borderColor: !this.state.mnemonics ? '' : mnemonicValidate(this.state.mnemonics) ? 'green' : 'red' }}
                                                onChange={this.onChangeMnemonics} name="mnemonics" rows="4"
                                                className="form-control shadow-none"
                                                placeholder="Paste your mnemonics here"></textarea>
                                            <p className="form-text mb-0">We are not storing your private key, this can be use
                                                one time for this transaction.</p>
                                        </div>
                                        <React.Fragment>
                                            <div className="mb-3">
                                                {
                                                    this.state.public_address && this.state.public_address === this.props.currentUser.public_address && (
                                                        <React.Fragment>
                                                            <strong className={'text-success d-block'}>Looks Good!</strong>
                                                            Your public address matched <strong className={'break-all d-block'}>{this.state.public_address}</strong>
                                                        </React.Fragment>
                                                    )
                                                }
                                                {
                                                    this.state.public_address && this.state.public_address !== this.props.currentUser.public_address && (
                                                        <React.Fragment>
                                                            <strong className={'text-danger d-block'}>Something
                                                                Wrong!</strong> Your profile address is not matching with the
                                                            provided Mnemonics, Please add the mnemonics which you give us on sign
                                                            up.
                                                        </React.Fragment>
                                                    )
                                                }
                                            </div>
                                        </React.Fragment>
                                        <div className="form-check pt-3">
                                            <input className="form-check-input shadow-none" type="checkbox" value="" id="PaypalPaymentCheckBox" checked={this.state.saveMyMnemonics} onClick={() => this.setState({ ...this.state, saveMyMnemonics: !this.state.saveMyMnemonics })} />
                                            <label className="form-check-label" htmlFor="PaypalPaymentCheckBox">Encrypt and save my mnemonics on browser for future transactions!</label>
                                        </div>
                                        <div className="">
                                            {
                                                this.state.saveMyMnemonics && (
                                                    <input onChange={e => this.setState({ ...this.state, mnemonicsPwd: e.target.value })} className="form-control BorderDarkGrey shadow-none mt-4" type="password" name="password" placeholder="Enter Password"/>
                                                )
                                            }
                                        </div>
                                    </React.Fragment>
                                    <div className="mb-3 mb-md-0">
                                        <button
                                            disabled={!this.isValidMnemonics()}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                if(this.state.saveMyMnemonics) {
                                                    const cypher = CryptoJS.AES.encrypt(this.state.mnemonics, this.state.mnemonicsPwd).toString();
                                                    this.props.setCypherSecret(cypher);
                                                }
                                                this.setState({ ...this.state, isRedirecting: true }, () => {
                                                    window.location.href = `${FIAT_PAYMENT_PAYPAL}?query=${this.state.query}`;
                                                })
                                            }}
                                            type="button"
                                            className="btn BtnBlack py-2 px-5">Confirm
                                        </button>
                                    </div>
                                </div>
                                <div className={ this.state.isRedirecting ? 'd-none' : this.props.cypherSecret !== null ? "" : 'd-none'}>
                                    <ContinueWithPassword callback={(mnemonics) => {
                                        this.setState({ ...this.state, mnemonics, mnemonics }, () => {
                                            this.onChangeMnemonics({ target: { name: 'mnemonics', value: mnemonics } }, true);
                                            // this.submit();

                                        });
                                    }} />
                                </div>
                            </div>
                            <div className={this.state.isRedirecting ? 'row' : 'row d-none'}>
                                <div className="col-12">
                                    <i className="fa fa-paypal text-center w-100" style={{ fontSize: '60px' }} aria-hidden="true"></i>
                                </div>
                                <div className="col-12 text-center">PayPal Loading...</div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStatetoProps = state => ({
    isLoggedIn: state.Auth.isLoggedIn,
    token: state.Auth.token,
    currentUser: state.Profile.profile,
    balance:    state.Wallet.balances,
    cypherSecret:    state.Auth.cypherSecret
})

export default connect(mapStatetoProps, { setCypherSecret })(PayPalPaymentModal);