import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { forgetPasswordValidationApi } from '../../../api/authentication/authentication';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default class Login extends Component {
    state = {
        data: {
            phone_number: null,
            email: null,
        },
        isError: false,
        message: null,
        isLoading: false,
        otpMediumValue: '',
        emailState: false,
        country_code: null,
        phoneState: false
    }
    oc = e => {
        this.setState({ ...this.state, data: { ...this.state.data, [e.target.name]: e.target.value } });
    }
    async submitPhone(e) {

        e.preventDefault();
        //phone is required
        if (this.state.data.phone_number === '' || this.state.data.phone_number === null) {

            return this.setState({ ...this.state, isError: true, message: 'Phonedd Number required!' });
        }
        this.setState({ ...this.state, isError: null, message: null, isLoading: true });
        try {
            const data = await forgetPasswordValidationApi({ auth_type: "phone", "phone_number": this.state.data.phone_number, "country_code": this.state.country_code, "forget_type": localStorage.getItem("forget_type") });
            this.setState({ ...this.state, isError: null, message: null, isLoading: false });
            // if user request for forget username we just send username and redirect to login
            if (localStorage.getItem("forget_type") === "USERNAME") {
                toast.info("We've sent username to your phone.")
                this.props.history.push("/login")
            }
            // if user request for forget password we will redirect to update password screen
            else {
                this.props.history.push("/update-password")
            }
        } catch (e) {
            this.setState({ ...this.state, isError: true, isLoading: false, message: e.response && e.response.data ? e.response.data : e.message });
        }
    }

    async submitEmail(e) {

        e.preventDefault();

        // email is required
        if (this.state.data.email === '' || this.state.data.email === null) {
            return this.setState({ ...this.state, isError: true, message: 'Email required!' });
        }
        this.setState({ ...this.state, isError: null, message: null, isLoading: true });
        try {
            await forgetPasswordValidationApi({ auth_type: "email", "email": this.state.data.email, "forget_type": localStorage.getItem("forget_type") });
            this.setState({ ...this.state, isError: null, message: null, isLoading: false });
            // if user request for forget username we just send username and redirect to login
            if (localStorage.getItem("forget_type") === "USERNAME") {
                toast.info("We've sent username to your phone.")
                this.props.history.push("/login")
            }
            // if user request for forget password we will redirect to update password screen
            else {
                this.props.history.push("/update-password")
            }

        } catch (e) {
            this.setState({ ...this.state, isError: true, isLoading: false, message: e.response && e.response.data ? e.response.data : e.message });
        }
    }

    renderHtml(htmlString) {
        let xmlString = htmlString;
        let doc = new DOMParser().parseFromString(xmlString, "text/xml");
        return doc.firstChild.innerHTML;
    }

    render() {

        return (
            <div className="container-fluid" id="SigningPage">
                <div className="row">
                    <div className="col-md-5 col-lg-4 col-xl-3 px-4 px-md-5 sidebar d-flex flex-column pt-5">
                        <Link to="/" className="back-to-home-page rounded d-flex align-items-center me-auto mb-5">
                            <svg className={'me-1'} xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/>
                                <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
                            </svg>
                            Back To Homepage
                        </Link>

                        <div className={'my-auto'}>
                            {/* ============================ADD RADIO BTN=================== */}
                            <div className={'d-flex mb-4'}>
                                <div className="custom-checkbox me-3">
                                    <input type="radio" className="custom-control-input" name="inlineRadioOptions" id="inlineRadio1" onChecked={this.state.otpMediumValue} defaultValue="option1"
                                           onChange={
                                               (e) => {
                                                   this.setState({ otpMediumValue: e.target.value })
                                                   this.setState({ emailState: true })
                                                   this.setState({ phoneState: false })
                                                   this.setState({ isError: null, message: null });
                                               }
                                           }
                                    />
                                    <label className="custom-control-label cursor-pointer" htmlFor="inlineRadio1">Email</label>
                                </div>
                                <div className="custom-checkbox">
                                    <input type="radio" className="custom-control-input" name="inlineRadioOptions" id="inlineRadio2" onChecked={this.state.otpMediumValue} defaultValue="option2"
                                           onChange={
                                               (e) => {
                                                   this.setState({ otpMediumValue: e.target.value })
                                                   this.setState({ emailState: false })
                                                   this.setState({ phoneState: true })
                                                   this.setState({ isError: null, message: null });
                                               }
                                           }
                                    />
                                    <label className="custom-control-label cursor-pointer" htmlFor="inlineRadio2">Phone</label>
                                </div>
                            </div>
                            {/* ============ Radio btn End ============ */}



                            {/* ====================== Give phone input field if user select phone ================== */}
                            {
                                this.state.phoneState && (
                                    <React.Fragment>
                                        <label className={'mb-1 text-uppercase CeraBold GreyColor'}>Select Country</label>
                                        <PhoneInput
                                            inputStyle={{
                                                width: '105px',
                                                height: '37px',
                                            }}
                                            inputProps={{
                                                required: true,
                                                disabled: true

                                            }}
                                            country={'au'}
                                            value={this.state.country_code}
                                            onChange={(country_code) => {
                                                this.setState({ country_code: country_code })

                                            }}
                                        />
                                        <div className="form-group my-3">
                                            <label className="mb-1 text-uppercase CeraBold GreyColor" for="signin_email_id">Phone Number</label>
                                            <input onChange={this.oc} className="form-control BorderDarkGrey shadow-none" id="signin_email_id" name="phone_number" placeholder="Enter your phone number" />
                                        </div>
                                        {
                                            this.state.isError ? <div className="alert alert-primary" role="alert">
                                                {this.state.message.indexOf('label') !== 0 ? ReactHtmlParser(this.state.message) : this.state.message}
                                            </div> : null
                                        }
                                        {
                                            !this.state.isError && this.state.message ? <div className="alert alert-success" role="alert">
                                                {this.state.message}
                                            </div> : null
                                        }
                                        <button disabled={this.state.isLoading} onClick={this.submitPhone.bind(this)} className="btn BtnBlack w-100 text-uppercase py-2 mb-4">SEND {this.state.isLoading && <i className="fas fa-asterisk fa-spin"></i>}</button>
                                    </React.Fragment>
                                )
                            }
                            {/* =============== End the phone input ================ */}


                            {
                                this.state.emailState && (
                                    <React.Fragment>
                                        <div className="form-group mb-1">
                                            <label className="mb-1 text-uppercase CeraBold GreyColor" for="signin_password">Email</label>
                                            <input onChange={this.oc} className="form-control BorderDarkGrey shadow-none" id="signin_password" type="email" name="email" placeholder="Enter your email" />

                                        </div>
                                        <div style={{ marginTop: '16px' }} className="help d-flex justify-content-end align-items-center">
                                            <a href="#" className="d-flex align-items-center">

                                            </a>
                                        </div>

                                        {
                                            this.state.isError ? <div className="alert alert-primary" role="alert">
                                                {this.state.message.indexOf('label') !== 0 ? ReactHtmlParser(this.state.message) : this.state.message}
                                            </div> : null
                                        }
                                        {
                                            !this.state.isError && this.state.message ? <div className="alert alert-success" role="alert">
                                                {this.state.message}
                                            </div> : null
                                        }
                                        <button disabled={this.state.isLoading} onClick={this.submitEmail.bind(this)} className="btn BtnBlack w-100 text-uppercase py-2 mb-4">SEND {this.state.isLoading && <i className="fas fa-asterisk fa-spin"></i>}</button>
                                    </React.Fragment>
                                )
                            }
                            {/* ======= End Email Value =========== */}
                        </div>
                        <div className="text-center Fsize_13 pt-5 pb-md-5">
                            Copyrights @2021, All Rights Reserved by Nucoin NFT.
                        </div>
                    </div>
                    <div className="col-md-7 col-lg-8 col-xl-9 content">
                        <div className="row">
                            <div
                                className="col-lg-7 col-md-12 mb-4 mb-xl-0 d-flex justify-content-center pt-5 px-4 px-md-5 hero">
                                <div className="hero-text d-flex flex-column justify-content-center">
                                    <div className="upper-text d-inline-block me-auto">
                                        <span>
                                            The Largest NFT Marketplace
                                        </span>
                                    </div>
                                    <h2 className="title">
                                        NFT City Beta version 2
                                    </h2>
                                    <p className="sub-text">
                                        Welcome to NFT City BETA early version, This version contains most of the major
                                        features of NFT City Exchange,
                                        Which currently is continuously being developed to add the digital notarised
                                        contract features.
                                        The purpose of BETA version is to allow user testing, with a direct feedback
                                        program.
                                        All feedback during the BETA version, will be taken onboard and adopted into the
                                        launch of full NFT City.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-12 cards d-none d-lg-block">
                                <div className="row">
                                    <div className="mb-5 col-md-6 mb-md-0">
                                        <div className="p-3 p-xl-4 mb-5 card">
                                            <img src="/images/image6.jpg" alt="" className="img-fluid"/>
                                        </div>
                                        <div className="p-3 p-xl-4 mb-5 card">
                                            <img src="/images/img1.jpeg" alt="" className="img-fluid"/>
                                        </div>
                                        <div className="p-3 p-xl-4 mb-5 card">
                                            <img src="/images/e9ee03457b929ea5dfc89380f4a2c20c.jpeg" alt=""
                                                 className="img-fluid"/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="p-3 p-xl-4 mb-5 card">
                                            <img src="/images/image5.jpg" alt="" className="img-fluid"/>
                                        </div>
                                        <div className="p-3 p-xl-4 mb-5 card">
                                            <img src="/images/user.jpg" alt="" className="img-fluid"/>
                                        </div>
                                        <div className="p-3 p-xl-4 mb-5 card">
                                            <img src="/images/imgone.jpeg" alt="" className="img-fluid"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}