import React from 'react';
import { Link } from 'react-router-dom';

export default ({title, message, redirect}) => (
    <React.Fragment>
        <button style={{display: 'none'}} id="loginConfirmationBtn" type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#loginConfirmation">
            Launch demo modal
        </button>
        <div className="modal fade DarkModal" id="loginConfirmation" tabindex="-1" aria-labelledby="loginConfirmationLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header align-items-start">
                        <h5 className="modal-title" id="loginConfirmationLabel">{title}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3">
                            {message}
                        </div>
                    </div>
                    <div className="modal-footer">
                        { redirect && <button type="button" className="btn BtnBorderBlack py-2 px-5" onClick={() => {
                            document.getElementById('loginConfirmationBtn').click();
                            setTimeout(() => document.getElementById('redirect-to-invest').click(), 0)
                        }}>Continue without Login</button> }
                        { !redirect && <button data-bs-toggle="modal" data-bs-target="#loginConfirmation" type="button" className="btn BtnBlack py-2 px-5">OK </button>}
                        <Link className="d-none" id="redirect-to-invest" to={`/invest`}/>
                        <Link className="d-none" id="redirect-to-login" to={`/login?redirect=${redirect || ''}`}/>
                        { redirect && <button onClick={() => document.getElementById('redirect-to-login').click() } data-bs-toggle="modal" data-bs-target="#loginConfirmation" type="button" className="btn BtnBlack py-2 px-5">Login </button>}
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
)