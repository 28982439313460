import React, {useEffect, useState} from 'react';
import {sendOtpApi, verifyOtpApi} from '../../../api/authentication/authentication';
import OtpInput from 'react-otp-input';
import {Link} from "react-router-dom";
import ConfirmLogin from "../../Layouts/ConfirmLogin/ConfirmLogin";
import BusinessVerificationRequired from "../../Layouts/BusinessVerificationRequired/BusinessVerificationRequired";
import KycStatus from "../../Layouts/KycStatus/KycStatus";
import ModuleUnderConstruction from "../../Layouts/ModuleUnderConstruction/ModuleUnderConstruction";

export default (props) => {

    const [isSending, setIsSending] = useState(true);
    const [sentOTP, setSentOTP] = useState(null);
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);

    const [otpValue, setOtpValue] = useState('');

    const sendOTP = async () => {

        setIsSending(true);
        setSentOTP(null)
        try {
            await sendOtpApi({authValue: props.match.params.value, authType: 'Phone'});
            setSentOTP('OTP Sent Successfull!');
            setError(null);
            setIsVerifying(false);
            setErrorWhileVerifying('');
            setIsverified(false);
            setOtpValue('');
        } catch (e) {
            setError(e.response && e.response.data && e.response.data.message ? e.response.data.message : e.message);
        }
        setIsSending(false)
    }

    useEffect(sendOTP, []);

    const [isVerifying, setIsVerifying] = useState(false);
    const [errorWhileVerifying, setErrorWhileVerifying] = useState('');
    const [isVerified, setIsverified] = useState(false);

    const verifyOtp = async () => {

        setIsVerifying(true);
        setErrorWhileVerifying(false);
        try {
            await verifyOtpApi({token: otpValue, authValue: props.match.params.value});
            setIsverified(true);
            setTimeout(() => {
                window.location.href = '/login';
            }, 2000)
        } catch (e) {
            setErrorWhileVerifying(e.response && e.response.data && e.response.data ? e.response.data : e.message);
        }
        setIsVerifying(false);
    }

    return (
        <React.Fragment>
            <main>


                <section id="CreateCollectible" className="py-5 SingleNft">
                    <div className="container">
                        <div className="row justify-content-center gx-3" id='zIndexMob'>
                            <div className="col-sm-12 col-md-10 col-lg-8 col-xl-8 Fsize_16">
                                <Link to="/" className="Fsize_16  brandnew">
                                    <svg className="mb-1 me-2" viewBox="0 0 14 12" fill="none" width="14" height="14"
                                         xlmns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                              d="M6.29436 0.292893C6.68488 -0.0976311 7.31805 -0.0976311 7.70857 0.292893C8.0991 0.683417 8.0991 1.31658 7.70857 1.70711L4.41568 5H12.9985C13.5508 5 13.9985 5.44772 13.9985 6C13.9985 6.55228 13.5508 7 12.9985 7H4.41568L7.70857 10.2929C8.0991 10.6834 8.0991 11.3166 7.70857 11.7071C7.31805 12.0976 6.68488 12.0976 6.29436 11.7071L0.587252 6L6.29436 0.292893Z"
                                              fill="currentColor"></path>
                                    </svg>
                                    Go back
                                </Link>
                                <h2 className="pt-2">OTP Verification</h2>
                                <p className="GreyColor mb-4 Fsize_16 CeraMed">We have sent you an OTP on your mobile phone, Please enter the code for
                                    verification</p>

                                <div className="row justify-content-center">
                                    <div className="col-md-10 col-lg-6 col-xl-5 col-xxl-5">
                                        <div className="mb-3">
                                            {
                                                isSending && (
                                                    <label className="text-center mb-3">
                                                        Sending OTP...
                                                        <i className="fas fa-asterisk fa-spin text-center"/>
                                                    </label>
                                                )
                                            }
                                            {
                                                error && (
                                                    <label className="text-danger text-center mb-3">{error}</label>
                                                )
                                            }
                                            {
                                                sentOTP && (
                                                    <label className="text-success text-center mb-3">{sentOTP}</label>
                                                )
                                            }
                                            {
                                                sentOTP && <React.Fragment>
                                                    <OtpInput
                                                        inputStyle={{
                                                            width: '100%',
                                                            height: '60px',
                                                            fontSize: '25px'
                                                        }}
                                                        value={otpValue}
                                                        onChange={(e) => {
                                                            setOtpValue(e);
                                                        }}
                                                        isDisabled={isVerifying || isVerified}
                                                        numInputs={6}
                                                        separator={<span>-</span>}
                                                    />
                                                    {
                                                        errorWhileVerifying !== '' && <label
                                                            className="text-center text-danger mt-3 w-100">{errorWhileVerifying}</label>
                                                    }
                                                    {
                                                        isVerified &&
                                                        <label className="text-center text-success mt-3 w-100">Successfully
                                                            verfied!</label>
                                                    }
                                                    <div className="mt-3">
                                                        <button onClick={() => verifyOtp()}
                                                                disabled={isSending || otpValue.length < 6 || isVerifying || isVerified}
                                                                className="btn BtnBlack w-100 py-2 mt-10">Verify
                                                                OTP {isVerifying && <i
                                                                className="fas fa-asterisk fa-spin text-center"/>}
                                                        </button>
                                                    </div>
                                                    <div className="mt-2">
                                                        <label disabled={isSending}
                                                               className="text-secondary cursor-pointer" onClick={() => sendOTP()}>Re-send
                                                                OTP {isSending && <i className="fas fa-asterisk fa-spin text-center"/>}
                                                        </label>
                                                    </div>
                                                </React.Fragment>
                                            }
                                        </div>
                                        {/* <div className="mb-3">
                                                    {
                                                        showMnemonicsInput && (
                                                            <input onChange={this.oc} name="mnemonics" type="text"
                                                                   className="form-control shadow-none"
                                                                   placeholder="Paste account mnemonics here..."/>
                                                        )
                                                    }
                                                </div>
                                                {
                                                    isError && message && (
                                                        <div className="alert alert-danger" role="alert">
                                                            {message}
                                                        </div>
                                                    )
                                                }
                                                {
                                                    !isError && message && (
                                                        <div className="alert alert-success" role="alert">
                                                            {message}
                                                        </div>
                                                    )
                                                }
                                                {
                                                    !isError && !message  &&<button disabled={this.state.isLoading} onClick={this.submit.bind(this)}
                                                            type="button" className="btn BtnBlack w-100 py-2">
                                                        {showMnemonicsInput ? 'Swap' : 'Next'} {this.state.isLoading &&
                                                    <i className="fas fa-asterisk fa-spin"></i>}
                                                    </button>
                                                } */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </main>
        </React.Fragment>
    )
}