import React from "react";
import SkeletonLoading from "../SkeletonLoading/SkeletonLoading";
import { Link } from "react-router-dom";
import Countdown from "react-countdown";
import { connect } from "react-redux";
import { setSocialShareDetail } from "../../../actions/shareSocial";
import { isVideo } from "../../../utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
// import './NFTItem.css';

const LISTING_URL =
  process.env.REACT_APP_LISTING_SERVICE || "http://localhost:4001";
const REACT_APP_PROFILE_SERVICE =
  process.env.REACT_APP_PROFILE_SERVICE || "http://localhost:4002";

const NFTSliderItem = ({ item, isHome = false, setSocial }) => (
  <div className="top_earning">
    <div className="main mb-3 align-items-center mb-3">
      <div className="left">
        <h6 className="HeadingEllipsis mb-0">
          {(item && item.title) || <SkeletonLoading />}{" "}
          {item && item.no_of_copies > 1 && (
            <label className="text-secondary ft-14">
              {item.available_copies}/{item.no_of_copies}
            </label>
          )}
        </h6>
        {/* <h4>Creator : Jana Base</h4> */}
      </div>
      <div className="right">
        {/* <i className="fas fa-heart"></i> */}
        ID:<span id="numbers">{(item && item.id) || "N/A"}</span>
      </div>
    </div>
    {/* <div className="time">
      <a
        href="#ShareModal"
        data-bs-toggle="modal"
        onClick={() => {
          setSocial(item);
        }}
      >
        <i className="fas fa-share-alt"></i>
      </a>
      {item?.type === "Timed Auction" && (
        <p>
          {" "}
          <Countdown date={item.bid_end_time} />{" "}
          <small className={"GreyColor"}>Left</small>{" "}
        </p>
      )}
    </div> */}
    <Link to={`/detail/${item?.id}`}>
      <div className="image">
        {item ? (
          isVideo(item.resource_type) ? (
            // <Link to={`/detail/${item.id}`}>
            <video autoPlay loop muted>
              <source
                src={`${LISTING_URL}/v1/media/video/${item.resource_url}`}
                type="video/mp4"
              />
            </video>
            // </Link>
          ) : (
            // <Link to={`/detail/${item.id}`}>
            <img src={`${LISTING_URL}/v1/media/image/${item.resource_url}`} />
            // </Link>
          )
        ) : (
          <SkeletonLoading style={{ height: "260px" }} />
        )}


        <div className="likes-container">
          <div className="d-flex align-items-center likes-div">

            <div className="time d-flex">
              {item?.type === "Timed Auction" && (
                <p>
                  {" "}
                  <Countdown date={item.bid_end_time} />{" "}
                  <small className={"GreyColor"}>Left</small>{" "}
                </p>
              )}
              <div className="likes-list">
                <FontAwesomeIcon icon={item?.reactions_count > 0 ? solid("heart") : regular("heart")}
                  size="lg"
                  style={{ width: "20px", marginTop: 2 }}
                />

                <div>
                  {
                    item?.reactions_count > 0 ? new Intl.NumberFormat("en-US", {
                      maximumFractionDigits: 1,
                      notation: "compact",
                      compactDisplay: "short",
                    }).format(item?.reactions_count) + (item?.reactions_count === 1 ? " Like" : " Likes") : ""
                  }
                </div>
              </div>
              <a
                href="#ShareModal"
                data-bs-toggle="modal"
                onClick={() => {
                  setSocial(item);
                }}
              >
                <i className="fas fa-share-alt"></i>
              </a>

            </div>
          </div>
        </div>


      </div>
    </Link>
    <div className="main">
      <div className="left">
        <h4 className="mb-0">{!item && <SkeletonLoading />}</h4>
        {item && item.type === "Fixed Price" && (
          <h4>{item && `PRICE: ${item.price}`}</h4>
        )}
        {item && item.type !== "Fixed Price" && (
          <h4>{item && `CURRENT BID: ${item.minimum_bid || 0.001}`}</h4>
        )}
        {/* <div className="my-3 likes-container">
          <FontAwesomeIcon
            icon={
              (item?.reactions_count ?? -1) > 0 ? regular("heart") : undefined
            }
            size="lg"
            style={{ width: "20px", marginTop: 2 }}
          />

          <div
            style={{
              width: "100%",
              textAlign: "start",
              marginLeft: 10,
            }}
          >
            {(item?.reactions_count ?? -1) > 0
              ? new Intl.NumberFormat("en-US", {
                  maximumFractionDigits: 1,
                  notation: "compact",
                  compactDisplay: "short",
                }).format(item?.reactions_count) +
                (item.reactions_count === 1 ? " Like" : " Likes")
              : ""}
          </div>
        </div> */}
      </div>
      <div className="right">
        <div className="tp">
          <Link to={`/profile/${item?.user_name}`}>
            <img
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Owner"
              src={
                item?.profile_image
                  ? `${REACT_APP_PROFILE_SERVICE}/v1/profile/image/${item?.user_name}`
                  : "/images/user.png"
              }
              className="img-fluid rounded-circle mb-3 ImgIcon position-relative"
            />
          </Link>
          {/* <span className="material-icons done">done</span> */}
          <h3 className="text-truncate mb-0 text-right">
            {(item && item.display_name) || (item && item.user_name) || (
              <SkeletonLoading />
            )}
          </h3>
        </div>
      </div>
    </div>
  </div>
);

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  setSocial: setSocialShareDetail,
})(NFTSliderItem);
