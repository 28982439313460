import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import $ from 'jquery';
import { connect } from 'react-redux';

const SkeletonLoader = ({ key, className = '', style = {}, theme }) => theme === 'dark' ? (
    <SkeletonTheme color="#1a1717cc" highlightColor="#667d7d" >
        <Skeleton key={key} className={className} style={style} />
    </SkeletonTheme>
) : <Skeleton key={key} className={className} style={style} />

const mapStateToProps = state => ({
    theme   :   state.Settings.theme
})

export default connect(mapStateToProps, {})(SkeletonLoader)