import React from 'react';

export default ({title, message}) => (
    <React.Fragment>
        <button style={{display: 'none'}} id="insufficientBalanceBtn" type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#insufficientBalance">
            Launch demo modal
        </button>
        <div className="modal fade DarkModal" id="insufficientBalance" tabindex="-1" aria-labelledby="loginConfirmationLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header align-items-start">
                        <h5 className="modal-title" id="loginConfirmationLabel">{title}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3">
                            {message}
                        </div>
                    </div>
                    <div className="modal-footer">
                        {/* <button type="button" className="btn BtnBorderBlack py-2 px-5" data-bs-dismiss="modal">Close</button> */}
                        <button data-bs-toggle="modal" data-bs-target="#insufficientBalance" type="button" className="btn BtnBlack py-2 px-5">OK </button>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
)