import invoke from '../../utils/invoke';

/**
 * @param {String} user_name
 * @param {String} country
 * @returns {Promise}
 */
export const getToggleStatus = (user_name = null, country = null, toggle_key = 'toggle_kyc') => {
    return invoke({
        method: 'GET',
        baseURL: `${process.env.REACT_APP_TOGGLE_END_POINT}/api`,
        route: `check_toggle_status?toggle_column=${toggle_key}&country=${country}&states&username=${user_name}`
    });
}