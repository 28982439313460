import React from 'react';
import SkeletonLoading from '../SkeletonLoading/SkeletonLoading';
import { Link } from 'react-router-dom';
import { isVideo } from '../../../utils/utils';

const LISTING_URL = process.env.REACT_APP_LISTING_SERVICE || 'http://localhost:4001';

export default ({token}) => (
    <Link to={`/detail/${token && token.id}`}>
    <div className={'ResultBox border-bottom pb-3 mb-3'}>
        <div className={'d-flex'}>
            {
                token && isVideo(token.resource_type) ? (
                    <video className="ImgResultNft border rounded p-1 me-3" autoPlay loop muted>
                        <source style={{ width: '80px', height: '80px' }} src={`${LISTING_URL}/v1/media/video/${token.resource_url}`} type="video/mp4" />
                    </video>
                ) : token ? (
                    <img style={{ width: '80px', height: '80px' }} src={`${LISTING_URL}/v1/media/image/${token.resource_url}`} className="ImgResultNft border rounded p-1 me-3" alt={token.title} />
                ) : <SkeletonLoading style={{ width: '80px', height: '80px' }} />
            }
            {/* <img className={'ImgResultNft border rounded p-1 me-3'} src="/images/imgbox-1.jpg" alt="NFT" /> */}
            <div className={'TextWhiteOnDarkMode'}>
                <h4 className="CeraBold mb-2">{token && token.title || <SkeletonLoading style={{ marginLeft: '6px' }}/> }</h4>
                <p style={{height: "40px", overflow: "hidden"}} className={'mb-0 break-all'}>{token && token.description || <SkeletonLoading style={{ marginLeft: '8px' }}/>}</p>
            </div>
        </div>
    </div>
    </Link>
)