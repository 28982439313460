import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import {
    exploreListing,
    homeListing,
    listLiveAuctionsAPI,
    listTopSellers,
} from "../../api/home/home";
import NFTItem from "../Layouts/NFTItem/NFTItem";
import NFTSliderItem from "../Layouts/NFTItem/NFTSliderItem";
import Seller from "../Layouts/Seller/Seller";
import Countdown from "react-countdown";
import { setSocialShareDetail } from "../../actions/shareSocial";
import { connect } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { setHome } from "../../actions/home";
import NewsTicker from "../NewsTicker/NewsTicker";
import { isVideo } from "../../utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import ConfirmLogin from "../Layouts/ConfirmLogin/ConfirmLogin";

const LISTING_URL =
    process.env.REACT_APP_LISTING_SERVICE || "http://localhost:4001";
const REACT_APP_PROFILE_SERVICE =
    process.env.REACT_APP_PROFILE_SERVICE || "http://localhost:4002";

class Home extends Component {
    state = {
        explore_section: {
            list: [],
            page: 0,
            loadMore: false,
            isLoading: true,
        },
        listing: {
            section_one: null,
            section_two: new Array(2).fill(null),
            section_three: [],
            section_four: [],
            live_auctions: [],
        },
        isLoading: {
            section_one: true,
            section_two: true,
            section_three: true,
            section_four: true,
            top_sellers: true,
            live_auctions: true,
        },
        filter: "all",
        top_sellers: [],
    };

    async componentDidMount() {
        try {
            const { data } = await homeListing();
            this.props.setHomeData(data);
            this.setState({
                ...this.state,
                listing: { ...this.state.listing, ...data },
                isLoading: {
                    ...this.state.isLoading,
                    section_two: false,
                    section_three: false,
                    section_four: false,
                    section_one: false,
                },
            });
        } catch (e) { }

        try {
            const { data } = await listLiveAuctionsAPI();

            this.props.setHomeData({ live_auctions: data });

            this.setState({
                ...this.state,
                listing: { ...this.state.listing, live_auctions: data },
                isLoading: { ...this.state.isLoading, live_auctions: false },
            });
        } catch (e) {
            this.setState({
                ...this.state,
                isLoading: { ...this.state.isLoading, live_auctions: false },
            });
        }

        this.exploreLoadMore();
    }

    filterExplore(param) {
        this.setState(
            {
                ...this.state,
                explore_section: {
                    list: [],
                    page: 0,
                    loadMore: false,
                    isLoading: true,
                },
                filter: param,
            },
            () => {
                this.exploreLoadMore(0);
            }
        );
    }

    exploreLoadMore(page = 0) {
        this.setState(
            {
                ...this.state,
                explore_section: { ...this.state.explore_section, isLoading: true },
            },
            () => {
                exploreListing(page, this.state.filter, this.props.token)
                    .then(({ data }) => {
                        this.setState({
                            ...this.state,
                            explore_section: {
                                list:
                                    page === 0
                                        ? data
                                        : [...this.state.explore_section.list, ...data],
                                loadMore: data.length > 19,
                                isLoading: false,
                                page: page,
                            },
                        });
                    })
                    .catch((e) => {
                        this.setState({
                            ...this.state,
                            explore_section: {
                                ...this.state.explore_section,
                                isLoading: false,
                            },
                        });
                    });
            }
        );
    }

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }
    next() {
        this.slider.slickNext();
    }
    previous() {
        this.slider.slickPrev();
    }
    render() {
        const {
            explore_section,
            isLoading,
            // listing: { section_one, section_two, section_three, section_four, live_auctions },
            top_sellers,
        } = this.state;

        const {
            section_one,
            section_two,
            section_three,
            section_four,
            live_auctions,
        } = this.props.homeListing;

        const settings = {
            dots: true,
            infinite: true,
            slidesToShow: 2,
            arrows: false,
            slidesToScroll: 1,
            dotsClass: "slick-slider-dots",
            appendDots: (dots) => (
                <div>
                    <ul className={"slick-dots"}> {dots} </ul>
                </div>
            ),
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 1,
                    },
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 575,
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ],
        };

        return (
            <React.Fragment>
                <section className="silde-container" id="zIndexMob">
                    <div id="fcol">
                        <div className="post-category">
                            <a href="#" rel="category tag">
                                The Largest NFT Marketplace
                            </a>
                        </div>
                        <h2>Discover & Collect rare NFTs</h2>
                        <p className={"mb-5 minting"}>
                            Welcome to Nugenesis NFT City. The well awaited solution for NFT
                            minting. NFTCITY.Exchange is currently in BETA, The complete
                            NFTCITY.Exchange will be released on the 1st of January 2022.
                        </p>
                        <div id="buttons">
                            <a
                                onClick={() => {
                                    document.getElementById("market-place").scrollIntoView();
                                }}
                                className="btn BtnBlack rounded-0 py-2 px-4 px-md-4"
                            >
                                Marketplace
                            </a>
                            <Link
                                to="/create"
                                className="btn BtnBorderBlack2 d-inline-block ms-2 rounded-0 py-2 px-4 px-md-4"
                            >
                                Create
                            </Link>
                            <Link id="redirect-to-invest" to="/invest"></Link>
                            {/* <Link
                                onClick={() => {
                                    if(this.props.token) document.getElementById('redirect-to-invest').click();
                                    else document.getElementById('loginConfirmationBtn').click();
                                }}
                                className="btn BtnBorderBlack2 d-inline-block ms-2 rounded-0 py-2 px-4 px-md-4">
                                Investment
                            </Link> */}
                        </div>
                    </div>
                    <div id="scol" className={"pb-3 pb-md-5"}>
                        <div className={"w-100"}>
                            {/* variable slider */}
                            <div className="py-3 pt-md-5 pb-md-4">
                                <Slider ref={(c) => (this.slider = c)} {...settings}>
                                    {
                                        //.slice(0, 2)
                                        section_two.map((item, key) => (
                                            <NFTSliderItem key={item ? item.id : key} item={item} />
                                        ))
                                    }
                                </Slider>
                                <div className={"slick-slider-nav pt-2"}>
                                    <button
                                        onClick={this.previous}
                                        className="btn p-0 border-0 bg-transparent me-2"
                                        type="button"
                                    >
                                        <i className="fas fa-chevron-circle-left"></i>
                                    </button>
                                    <button
                                        onClick={this.next}
                                        className="btn p-0 border-0 bg-transparent"
                                        type="button"
                                    >
                                        <i className="fas fa-chevron-circle-right"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <NewsTicker></NewsTicker>
                <div className={"MainContainer"}>
                    <section className="flex-container left-auction-container overflow-hidden">
                        <img src="images/Group 65.png" className="spinn-image" />
                        <div className={"VideoBox"}>
                            <video
                                className={"d-none d-lg-block"}
                                autoPlay
                                muted
                                loop
                                id="top-nft-cube"
                            >
                                <source src="images/nft-city-asset.mp4" type="video/mp4" />
                            </video>
                        </div>
                        <div className="mt-5 position-relative">
                            <div className={"container"}>
                                {/* <div className={"TopNFTAuction slider"}> */}
                                <div className={"TopNFTAuction"}>
                                    <div className={"px-3"}>
                                        <div
                                            className={
                                                "row justify-content-center align-items-center"
                                            }
                                        >
                                            <div className={"col-md-6 col-lg-5"}>
                                                <div className="top_earning me-md-5">
                                                    {/* <span className="d-block dot"></span> */}
                                                    <div
                                                        className={"d-flex justify-content-between mb-2"}
                                                    >
                                                        <div className="left">
                                                            <h3 className={"mb-0"}>
                                                                {section_one && section_one.title}
                                                            </h3>
                                                            {/* <span>Creator : Jana Base</span> */}
                                                        </div>
                                                        <div className="right">
                                                            {/* <i className="fas fa-heart align-middle me-2"></i> */}
                                                            {/* <span id="numbers">212</span> */}
                                                        </div>
                                                    </div>

                                                    <Link to={`/detail/${section_one && section_one.id}`}>
                                                        <div className="image">
                                                            {section_one ? (
                                                                isVideo(section_one.resource_type) ? (
                                                                    <video
                                                                        key="section-one-video"
                                                                        autoPlay
                                                                        loop
                                                                        muted
                                                                    >
                                                                        <source
                                                                            src={`${LISTING_URL}/v1/media/video/${section_one.resource_url}`}
                                                                            type="video/mp4"
                                                                        />
                                                                    </video>
                                                                ) : (
                                                                    <img
                                                                        key="section-one-image"
                                                                        src={`${LISTING_URL}/v1/media/image/${section_one.resource_url}`}
                                                                    />
                                                                )
                                                            ) : (
                                                                <Skeleton
                                                                    key="section-one-skeleton"
                                                                    style={{ width: "440px", height: "440px" }}
                                                                />
                                                            )}

                                                            <div className="likes-container">
                                                                <div className="d-flex align-items-center likes-div">
                                                                    <div className="time d-flex">
                                                                        {section_one &&
                                                                            section_one?.type === "Timed Auction" && (
                                                                                <p>
                                                                                    <Countdown date={section_one.bid_end_time || Date.now()} />
                                                                                    <small className={"GreyColor"}>Left</small>
                                                                                </p>
                                                                            )}


                                                                        <div className="likes-list">
                                                                            <FontAwesomeIcon
                                                                                icon={section_one?.reactions_count > 0 ? solid("heart") : regular("heart")}
                                                                                size="lg"
                                                                                style={{ width: "20px", marginTop: 2 }}
                                                                            />
                                                                            <div>
                                                                                {(section_one?.reactions_count ?? -1) > 0
                                                                                    ? new Intl.NumberFormat("en-US", {
                                                                                        maximumFractionDigits: 1,
                                                                                        notation: "compact",
                                                                                        compactDisplay: "short",
                                                                                    }).format(section_one?.reactions_count) +
                                                                                    (section_one.reactions_count === 1 ? " Like" : " Likes")
                                                                                    : ""}
                                                                            </div>
                                                                        </div>

                                                                        <a href="#ShareModal" data-bs-toggle="modal" onClick={() => {
                                                                            this.props.setSocial(section_one);
                                                                        }}
                                                                        >
                                                                            <i className="fas fa-share-alt"></i>
                                                                        </a>

                                                                    </div>


                                                                </div>
                                                            </div>


                                                        </div>
                                                    </Link>
                                                    <div
                                                        className={"d-flex justify-content-between align-items-end"}
                                                    >
                                                        <div className="left">
                                                            {section_one &&
                                                                section_one?.type === "Timed Auction" ? (
                                                                <h4>
                                                                    MIN BID:{" "}
                                                                    {(section_one && section_one.minimum_id) ||
                                                                        0.01}{" "}
                                                                    NST
                                                                </h4>
                                                            ) : section_one ? (
                                                                <h4>
                                                                    PRICE: {section_one && section_one.price} NST
                                                                </h4>
                                                            ) : (
                                                                <Skeleton />
                                                            )}
                                                        </div>
                                                        <div className="right">
                                                            <div className="UserPic position-relative">
                                                                <Link
                                                                    className={"position-relative d-block"}
                                                                    to={`/profile/${section_one && section_one?.user_name
                                                                        }`}
                                                                    tabIndex="0"
                                                                >
                                                                    <img
                                                                        className={"mb-2"}
                                                                        src={
                                                                            section_one && section_one?.profile_image
                                                                                ? `${REACT_APP_PROFILE_SERVICE}/v1/profile/image/${section_one &&
                                                                                section_one?.user_name
                                                                                }`
                                                                                : "/images/user.png"
                                                                        }
                                                                        alt=""
                                                                    />
                                                                </Link>
                                                                <h3 className={"text-truncate Fsize_13 text-right"}>
                                                                    {(section_one && section_one.display_name) ||
                                                                        (section_one && section_one.user_name)}
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"col-md-6 col-lg-5"}>
                                                <div className="TextBox">
                                                    <h2>
                                                        <img
                                                            className={"inline-block"}
                                                            src="/images/icon1.png"
                                                        />
                                                        {section_one?.type === "Fixed Price"
                                                            ? "Top NFT"
                                                            : "Top NFT Auction"}
                                                    </h2>
                                                    <h3>{section_one && section_one.title}</h3>
                                                    <Link
                                                        to={`/detail/${section_one && section_one?.id}`}
                                                        className="buttonra mb-4 rounded-pill py-2 px-4"
                                                    >
                                                        {section_one &&
                                                            section_one.type !== "Fixed Price" && (
                                                                <span>
                                                                    MINIMUM BID:{" "}
                                                                    {(section_one && section_one.minimum_id) ||
                                                                        0.01}{" "}
                                                                    NST
                                                                </span>
                                                            )}
                                                        {section_one &&
                                                            section_one.type === "Fixed Price" && (
                                                                <span>
                                                                    PRICE:{" "}
                                                                    {(section_one && section_one.price) || 0.01}{" "}
                                                                    NST
                                                                </span>
                                                            )}
                                                    </Link>
                                                    <p>{section_one && section_one?.description}</p>
                                                    <div className="tp align-items-center mb-0">
                                                        <Link
                                                            to={`/profile/${section_one && section_one?.user_name
                                                                }`}
                                                            tabIndex="0"
                                                        >
                                                            <img
                                                                className={"bg-white me-3 mb-0"}
                                                                src={
                                                                    section_one && section_one?.profile_image
                                                                        ? `${REACT_APP_PROFILE_SERVICE}/v1/profile/image/${section_one && section_one?.user_name
                                                                        }`
                                                                        : "/images/user.png"
                                                                }
                                                                alt=""
                                                            />
                                                        </Link>
                                                        {/* <i className="fas fa-check done"></i> */}
                                                        <h5 className={"mb-0"}>
                                                            {(section_one && section_one?.display_name) ||
                                                                section_one?.user_name}
                                                        </h5>
                                                    </div>
                                                    {/* <button className="btn p-0 border-0 bg-transparent me-2 TopNFTAuction_arrowLeft" type="button">
                                                        <i className="fas fa-chevron-circle-left bg-white rounded-circle"></i></button>
                                                    <button className="btn p-0 border-0 bg-transparent me-2 TopNFTAuction_arrowRight" type="button">
                                                        <i className="fas fa-chevron-circle-right bg-white rounded-circle"></i></button> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"d-none"}>
                                        <div
                                            className={
                                                "row justify-content-center align-items-center"
                                            }
                                        >
                                            <div className={"col-md-5"}>
                                                <div className="top_earning me-5">
                                                    <span className="d-block dot"></span>
                                                    <div
                                                        className={"d-flex justify-content-between mb-2"}
                                                    >
                                                        <div className="left">
                                                            <h3 className={"mb-0"}>
                                                                {section_one && section_one.title}
                                                            </h3>
                                                            <span>Creator : Jana Base</span>
                                                        </div>
                                                        <div className="right">
                                                            {/* <i className="fas fa-heart align-middle me-2"></i><span id="numbers">212</span> */}
                                                        </div>
                                                    </div>

                                                    <Link to={`/detail/${section_one && section_one.id}`}>
                                                        <div className="image">
                                                            {section_one ? (
                                                                isVideo(section_one.resource_type) ? (
                                                                    <video autoPlay loop muted>
                                                                        <source
                                                                            src={`${LISTING_URL}/v1/media/video/${section_one.resource_url}`}
                                                                            type="video/mp4"
                                                                        />
                                                                    </video>
                                                                ) : (
                                                                    <img
                                                                        src={`${LISTING_URL}/v1/media/image/${section_one.resource_url}`}
                                                                    />
                                                                )
                                                            ) : (
                                                                <img src="images/img1.jpeg" alt="" />
                                                            )}
                                                            <div className="likes-container">
                                                                <div className="d-flex align-items-center likes-div">
                                                                    <div className="time d-flex">
                                                                        <a
                                                                            href="#ShareModal"
                                                                            data-bs-toggle="modal"
                                                                            onClick={() => {
                                                                                this.props.setSocial(section_one);
                                                                            }}
                                                                        >
                                                                            <i className="fas fa-share-alt"></i>
                                                                        </a>
                                                                        {section_one &&
                                                                            section_one?.type === "Timed Auction" && (
                                                                                <p>
                                                                                    <Countdown
                                                                                        date={
                                                                                            section_one.bid_end_time || Date.now()
                                                                                        }
                                                                                    />
                                                                                    <small className={"GreyColor"}>Left</small>
                                                                                </p>
                                                                            )}
                                                                    </div>
                                                                </div>
                                                            </div>



                                                        </div>
                                                    </Link>
                                                    <div
                                                        className={
                                                            "d-flex justify-content-between align-items-end"
                                                        }
                                                    >
                                                        <div className="left">
                                                            {section_one &&
                                                                section_one?.type === "Timed Auction" ? (
                                                                <h4>
                                                                    MIN BID:{" "}
                                                                    {(section_one && section_one.minimum_id) ||
                                                                        0.01}{" "}
                                                                    NST
                                                                </h4>
                                                            ) : (
                                                                <h4>
                                                                    PRICE: {section_one && section_one.price} NST
                                                                </h4>
                                                            )}
                                                        </div>
                                                        <div className="right">
                                                            <div className="UserPic position-relative me-4">
                                                                <Link
                                                                    className={"position-relative"}
                                                                    to={`/profile/${section_one && section_one?.user_name
                                                                        }`}
                                                                    tabIndex="0"
                                                                >
                                                                    <img
                                                                        src={
                                                                            section_one && section_one?.profile_image
                                                                                ? `${REACT_APP_PROFILE_SERVICE}/v1/profile/image/${section_one &&
                                                                                section_one?.user_name
                                                                                }`
                                                                                : "/images/user.png"
                                                                        }
                                                                        alt=""
                                                                    />
                                                                    {/* <i className="fas fa-check CheckSign"></i> */}
                                                                </Link>
                                                                <h3 className={"text-truncate"}>
                                                                    {(section_one && section_one.display_name) ||
                                                                        (section_one && section_one.user_name)}
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"col-md-5"}>
                                                <div className="TextBox">
                                                    <h2>
                                                        <img
                                                            className={"inline-block"}
                                                            src="/images/icon1.png"
                                                        />{" "}
                                                        Ali Top NFT Auction
                                                    </h2>
                                                    <h3>{section_one && section_one.title}</h3>
                                                    <a
                                                        href="#"
                                                        className="buttonra rounded-pill py-3 px-4"
                                                    >
                                                        {
                                                            <span>
                                                                MINIMUM BID:{" "}
                                                                {(section_one && section_one.minimum_id) ||
                                                                    0.01}{" "}
                                                                NST
                                                            </span>
                                                        }
                                                    </a>
                                                    <p>{section_one && section_one?.description}</p>
                                                    <div className="tp">
                                                        <Link
                                                            to={`/profile/${section_one && section_one?.user_name
                                                                }`}
                                                            tabIndex="0"
                                                        >
                                                            <img
                                                                src={
                                                                    section_one && section_one?.profile_image
                                                                        ? `${REACT_APP_PROFILE_SERVICE}/v1/profile/image/${section_one && section_one?.user_name
                                                                        }`
                                                                        : "/images/user.png"
                                                                }
                                                                alt=""
                                                            />
                                                        </Link>
                                                        {/* <i className="fas fa-check done"></i> */}
                                                        <h3>
                                                            {(section_one && section_one?.display_name) ||
                                                                section_one?.user_name}
                                                        </h3>
                                                    </div>
                                                    <button
                                                        className="btn p-0 border-0 bg-transparent me-2 TopNFTAuction_arrowLeft"
                                                        type="button"
                                                    >
                                                        <i className="fas fa-chevron-circle-left"></i>
                                                    </button>
                                                    <button
                                                        className="btn p-0 border-0 bg-transparent me-2 TopNFTAuction_arrowRight"
                                                        type="button"
                                                    >
                                                        <i className="fas fa-chevron-circle-right"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className={"pt-5"}>
                        <div className={"text-center"}>
                            <p id="subhead">LIVE AUCTIONS</p>
                            <h2>Top 10 Live Auctions</h2>
                        </div>
                        <div className={"live_auction live_a"}>
                            {section_three.map((item) => (
                                <NFTItem key={item.id + item.title} item={item} />
                            ))}
                            {isLoading.section_three &&
                                new Array(20).fill(0).map((_, key) => <NFTItem key={key} />)}
                            {!isLoading.section_three && section_three.length === 0 && (
                                <h6 className="mb-4 text-center">No items to display!</h6>
                            )}
                        </div>
                    </section>
                    <section className={"pt-5"}>
                        <div className={"text-center"}>
                            <p id="subhead">CLOSING AUCTIONS</p>
                            <h2>Top 10 Closing Auctions</h2>
                        </div>
                        <div className={"live_auction closing_a"}>
                            {(section_four.length > 0 ? section_four : section_three).map(
                                (item) => (
                                    <NFTItem key={item.id + item.title} item={item} />
                                )
                            )}
                            {!isLoading.section_three &&
                                section_three.length === 0 &&
                                section_four.length === 0 && (
                                    <h6 className="mb-4 text-center">No items to display!</h6>
                                )}
                        </div>
                    </section>
                    <section style={{ display: 'none' }} className={'pt-5 live_collection d-none'}>
                        <div className={'text-center'}>
                            <p id="subhead">LIVE COLLECTIONS</p>
                            <h2>Top 10 Live Collections</h2>
                        </div>
                        <div className='container'>
                            <div className={'row'}>
                                <div className='col-xs-12 col-md-6 col-lg-3'>
                                    <div className="top_earning" style={{ marginBottom: "25px" }}>
                                        <a href='#' className='top_collection'>
                                            <div className='media mb-3 d-flex align-items-center'>
                                                <div className='media-left'>
                                                    <img src="https://listing.nftcity.exchange/v1/media/image/67a52cbf846c6e6645a5dff842225230.png" />
                                                </div>
                                                <div className="media-body">
                                                    <h5 className="mb-0">Bilal Hanif</h5>
                                                    <p id='subhead' className="mb-0">Floor</p>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-between">
                                                <div>
                                                    <h2 style={{ fontSize: "12px" }}>33.33 ETH</h2>
                                                </div>
                                                <div>
                                                    <h2 style={{ fontSize: "12px" }} class="GreyColor">$121.5k <span className='alert-success'>+5.9%</span></h2>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div className='col-xs-12 col-md-6 col-lg-3'>
                                    <div className="top_earning" style={{ marginBottom: "25px" }}>
                                        <a href='#' className='top_collection'>
                                            <div className='media mb-3 d-flex align-items-center'>
                                                <div className='media-left'>
                                                    <img src="https://listing.nftcity.exchange/v1/media/image/67a52cbf846c6e6645a5dff842225230.png" />
                                                </div>
                                                <div className="media-body">
                                                    <h5 className="mb-0">Bilal Hanif</h5>
                                                    <p id='subhead' className="mb-0">Floor</p>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-between">
                                                <div>
                                                    <h2 style={{ fontSize: "12px" }}>33.33 ETH</h2>
                                                </div>
                                                <div>
                                                    <h2 style={{ fontSize: "12px" }} class="GreyColor">$121.5k <span className='alert-success'>+5.9%</span></h2>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div className='col-xs-12 col-md-6 col-lg-3'>
                                    <div className="top_earning" style={{ marginBottom: "25px" }}>
                                        <a href='#' className='top_collection'>
                                            <div className='media mb-3 d-flex align-items-center'>
                                                <div className='media-left'>
                                                    <img src="https://listing.nftcity.exchange/v1/media/image/67a52cbf846c6e6645a5dff842225230.png" />
                                                </div>
                                                <div className="media-body">
                                                    <h5 className="mb-0">Bilal Hanif</h5>
                                                    <p id='subhead' className="mb-0">Floor</p>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-between">
                                                <div>
                                                    <h2 style={{ fontSize: "12px" }}>33.33 ETH</h2>
                                                </div>
                                                <div>
                                                    <h2 style={{ fontSize: "12px" }} class="GreyColor">$121.5k <span className='alert-success'>+5.9%</span></h2>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div className='col-xs-12 col-md-6 col-lg-3'>
                                    <div className="top_earning" style={{ marginBottom: "25px" }}>
                                        <a href='#' className='top_collection'>
                                            <div className='media mb-3 d-flex align-items-center'>
                                                <div className='media-left'>
                                                    <img src="https://listing.nftcity.exchange/v1/media/image/67a52cbf846c6e6645a5dff842225230.png" />
                                                </div>
                                                <div className="media-body">
                                                    <h5 className="mb-0">Bilal Hanif</h5>
                                                    <p id='subhead' className="mb-0">Floor</p>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-between">
                                                <div>
                                                    <h2 style={{ fontSize: "12px" }}>33.33 ETH</h2>
                                                </div>
                                                <div>
                                                    <h2 style={{ fontSize: "12px" }} class="GreyColor">$121.5k <span className='alert-success'>+5.9%</span></h2>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div className='col-xs-12 col-md-6 col-lg-3'>
                                    <div className="top_earning" style={{ marginBottom: "25px" }}>
                                        <a href='#' className='top_collection'>
                                            <div className='media mb-3 d-flex align-items-center'>
                                                <div className='media-left'>
                                                    <img src="https://listing.nftcity.exchange/v1/media/image/67a52cbf846c6e6645a5dff842225230.png" />
                                                </div>
                                                <div className="media-body">
                                                    <h5 className="mb-0">Bilal Hanif</h5>
                                                    <p id='subhead' className="mb-0">Floor</p>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-between">
                                                <div>
                                                    <h2 style={{ fontSize: "12px" }}>33.33 ETH</h2>
                                                </div>
                                                <div>
                                                    <h2 style={{ fontSize: "12px" }} class="GreyColor">$121.5k <span className='alert-success'>+5.9%</span></h2>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <section className="flex-container about py-5">
                    <div className={"VideoBox"}>
                        <video autoPlay muted loop id="DiscovernCollect-nftVideo">
                            <source src="images/nft-city-asset.mp4" type="video/mp4" />
                        </video>
                    </div>
                    <div className={"container"}>
                        <div className="row align-items-center mb-5">
                            <div className={"col-md-6"}>
                                <h2>What is NFT City?</h2>
                                <p>
                                    NFT city utilises the NuGenesis smart chain to allow digital
                                    artists and creators to secure there digital artwork, through
                                    the process of minting, Allowing the artist to give uniqueness
                                    to their art, allowing the ability to sell custom crypto
                                    assets. NFT City is both a marketplace for those assets, as
                                    well as a distributed network built on NuGenesis Smart chain
                                    network, that enables their trade without a middleman,
                                    resolving the major issues of gas fees and transaction speeds.
                                    Each NFT is unique, and unlike other NFT platforms, The
                                    NuGenesis Smart chain is close to gasless, with finality
                                    speeds at 6 seconds for all smart contract processing.
                                </p>
                            </div>
                            <div className={"col-md-6"}>
                                <img src="images/3d1.png" alt="Image" className={"img-fluid"} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <div className="BorderRight h-100 pe-4">
                                    <h3>
                                        <i className="fas fa-user me-2"></i> Accessible
                                    </h3>
                                    <p>
                                        With a single login feature, grants users easy access to NFT
                                        City, Once a user has registered for nucoins.com.au and
                                        completed the KYC, The user has access to all Nugenesis
                                        infrastructure, Allows users who have a nucoins.com.au
                                        wallet, to utilise the same login, to access all systems on
                                        the NuGenesis network.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="BorderRight h-100 pe-4">
                                    <h3>
                                        <i className="fas fa-shield-alt me-2"></i> Authentic
                                    </h3>
                                    <p>
                                        All transactions are minted on the Nugenesis smart chain,
                                        transactions are accessible via the Nugenesis smart chain
                                        explorer, allowing authentication of transaction by any
                                        user. Stage 2 of NFT city (to be released Jan 2022) will add
                                        features for artist signatures and digital notarised
                                        contracts, allowing further contractual components, that
                                        remain apart of the NFT.
                                    </p>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="BorderRight h-100 pe-4">
                                    <h3>
                                        <i className="fas fa-bolt me-2"></i>Fast
                                    </h3>
                                    <p>
                                        NuGenesis Smart chains, run at the finality rate of
                                        6seconds. The smartchain has the ability to finalise
                                        transactions instantly, although for BETA testing, NuGenesis
                                        has set finality at 6 seconds. The NuGenesis Network is able
                                        to transact at of 100,000 transactions per second on a
                                        single blockchain network. Stage 3 NFT city – will see a
                                        further load balancer system with close to instant
                                        processing speeds and unlimited speed capability.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="pe-4">
                                    <h3>
                                        <i className="fas fa-circle me-2"></i> Simplicity
                                    </h3>
                                    <p>
                                        Utilising the NuGenesis Smart Chain, allows simple
                                        processing. BETA versions will be upgraded, subject to
                                        feedback received and the focus will be usability. NuGenesis
                                        Smart chain are continuously improving to ensure simplicity
                                        of systems and usage. Please send your feedback to
                                        admin@nucoin.com.au
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="market-place" className={"pt-5"}>
                    <div className={"text-center"}>
                        <p id="subhead">EXPLORE MARKETPLACE</p>
                        <h2 className={"mb-4"}>NFT City Marketplace</h2>
                    </div>
                    <div id="btncontainer" className="mb-3">
                        <button
                            className={`btnfilter ${this.state.filter === "all" && "active"}`}
                            onClick={() => this.filterExplore("all")}
                        >
                            ALL
                        </button>
                        <button
                            className={`btnfilter ${this.state.filter === "single" && "active"
                                }`}
                            onClick={() => this.filterExplore("single")}
                        >
                            SINGLE
                        </button>
                        <button
                            className={`btnfilter ${this.state.filter === "multiple" && "active"
                                }`}
                            onClick={() => this.filterExplore("multiple")}
                        >
                            MULTIPLE
                        </button>
                        <button
                            className={`btnfilter ${this.state.filter === "business-multiple" && "active"
                                }`}
                            onClick={() => this.filterExplore("business-multiple")}
                        >
                            BUSINESS NFT
                        </button>
                    </div>
                    <div className="live_auction filter">
                        {/* <section className="live_auction live_a"> */}
                        {explore_section.list.map((item, key) => (
                            <NFTItem key={key + item.title} item={item} isHome={true} />
                        ))}
                        {explore_section.isLoading &&
                            [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((_, key) => (
                                <NFTItem key={key} isHome={true} />
                            ))}
                        {!explore_section.isLoading &&
                            explore_section.list.length === 0 && (
                                <h6 className="mb-4 text-center">No items to display!</h6>
                            )}
                    </div>
                    <div className="w-100 p-0 my-3 darkblue">
                        <center>
                            {explore_section.loadMore && (
                                <button
                                    disabled={explore_section.isLoading}
                                    onClick={() => this.exploreLoadMore(explore_section.page + 1)}
                                    style={{ width: "30%" }}
                                    className="btn BtnBlack"
                                >
                                    Load More{" "}
                                    {explore_section.isLoading && (
                                        <i className="fas fa-asterisk fa-spin" />
                                    )}
                                </button>
                            )}
                        </center>
                    </div>
                </section>
                <ConfirmLogin title="Login Required!" message="Please login to continue with investment details." redirect={"https://nftcity.exchange/invest"} />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    homeListing: state.Home.home,
    token: state.Auth.token,
});

export default connect(mapStateToProps, {
    setSocial: setSocialShareDetail,
    setHomeData: setHome,
})(Home);
