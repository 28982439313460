import invoke from '../../utils/invoke';
import { encrypt } from '../../utils/utils';

const BASE_PATH = process.env.REACT_APP_LISTING_SERVICE || 'http://localhost:4001';
const WALLET_BASE_PATH = process.env.REACT_APP_WALLET_SERVICE || 'http://localhost:4004';

export const registerNFT = (data, token = null) => {
    return invoke({
        method: 'POST',
        baseURL: BASE_PATH,
        route:  'v1/nft/register',
        data: data,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export const registerBulkNFT = (data, token = null) => {
    return invoke({
        method: 'POST',
        baseURL: BASE_PATH,
        route:  'v1/nft/bulk/register',
        data: data,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export const processBulkNFT = (token = null) => {
    return invoke({
        method: 'POST',
        baseURL: BASE_PATH,
        route:  'v1/nft/bulk/process',
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export const registerMultiNFT = (data, token = null) => {
    return invoke({
        method: 'POST',
        baseURL: BASE_PATH,
        route:  'v1/nft/register-multi',
        data: data,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export const registerSimpleMultiNFT = (data, token = null) => {
    return invoke({
        method: 'POST',
        baseURL: BASE_PATH,
        route:  'v1/nft/register-simple-multi',
        data: data,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export const placeBid = (data, token = null) => {
    data = encrypt(data);
    return invoke({
        method: 'POST',
        baseURL: WALLET_BASE_PATH,
        route:  'v1/wallet/placeBid',
        data: data,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export const mintNFT = (data, token = null) => {
    data = encrypt(data);
    return invoke({
        method: 'POST',
        baseURL: WALLET_BASE_PATH,
        route:  'v1/wallet/mintNft',
        data: data,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export const mintNFTEquity = (data, token = null) => {
    data = encrypt(data);
    return invoke({
        method: 'POST',
        baseURL: WALLET_BASE_PATH,
        route:  'v1/wallet/mintNft/equity',
        data: data,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export const approveNFT = (data, token = null) => {
    data = encrypt(data);
    return invoke({
        method: 'POST',
        baseURL: WALLET_BASE_PATH,
        route:  'v1/wallet/approveMultiTokens',
        data: data,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export const approveSingleAuctionOrder = (data, token = null) => {
    data = encrypt(data);
    return invoke({
        method: 'POST',
        baseURL: WALLET_BASE_PATH,
        route:  'v1/wallet/approveMultiOnAuction',
        data: data,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export const createOrder = (data, token = null) => {
    data = encrypt(data);
    return invoke({
        method: 'POST',
        baseURL: WALLET_BASE_PATH,
        route:  'v1/wallet/addOrder',
        data: data,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export const createAuction = (data, token = null) => {
    data = encrypt(data);
    return invoke({
        method: 'POST',
        baseURL: WALLET_BASE_PATH,
        route:  'v1/wallet/addAuction',
        data: data,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export const getNFTTokenDetailAPI = (id, token = null) => {
    return invoke({
        method: 'GET',
        baseURL: BASE_PATH,
        route:  `v1/nft/detail/${id}`,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export const purchaseNFTToken = (data = {}, token = null) => {
    data = encrypt(data);
    return invoke({
        method: 'POST',
        baseURL: WALLET_BASE_PATH,
        route:  `v1/wallet/purchaseNft`,
        data: data,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export const mintMultiNftApi = (data = {}, token = null) => {
    data = encrypt(data);
    return invoke({
        method: 'POST',
        baseURL: WALLET_BASE_PATH,
        route:  `v1/wallet/mintMultiNft`,
        data: data,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export const analyzeMintingFlow = (data = {}, token = null) => {
    data = encrypt(data);
    return invoke({
        method: 'POST',
        baseURL: WALLET_BASE_PATH,
        route:  `v1/wallet/analyize/minting`,
        data: data,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export const finalizeAuctionAPI = (data = {}, token = null) => {
    data = encrypt(data);
    return invoke({
        method: 'POST',
        baseURL: WALLET_BASE_PATH,
        route:  `v1/wallet/auction/finalize`,
        data: data,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export const getBidingStatusByTokenIdAPI = (tokenId, token) => {
    return invoke({
        method: 'GET',
        baseURL: BASE_PATH,
        route:  `v1/auction/bid/status/${tokenId}`,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export const getBidHistoriansAPI = (tokenId) => {
    return invoke({
        method: 'GET',
        baseURL: BASE_PATH,
        route:  `v1/auction/bid/historian/${tokenId}`
    });
}

export const updateNFTDetailAPI = (id, data, token = null) => {
    return invoke({
        method: 'POST',
        baseURL: BASE_PATH,
        route:  `v1/nft/update/${id}`,
        data: data,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export const updateNFTSaleDetailAPI = (id, data, token = null) => {
    return invoke({
        method: 'POST',
        baseURL: BASE_PATH,
        route:  `v1/nft/update/sale/${id}`,
        data: data,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export const cancelOrderAPI = (data = {}, token = null) => {
    data = encrypt(data);
    return invoke({
        method: 'POST',
        baseURL: WALLET_BASE_PATH,
        route:  `v1/wallet/cancelOrder`,
        data: data,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export const listBulkNFTsAPI = (token = null) => {
    return invoke({
        method: 'GET',
        baseURL: BASE_PATH,
        route:  'v1/nft/bulk/list',
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export const deleteNFTAPI = (id, token = null) => {
    return invoke({
        method: 'DELETE',
        baseURL: BASE_PATH,
        route:  `v1/nft/delete/${id}`,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export const makeReactionApi = (id, body = {}, token) => {
    return invoke({
        method: 'POST',
        baseURL: BASE_PATH,
        route:  `v1/nft/react/${id}`,
        data: body,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export const addCommentApi = (id, body = {}, token) => {
    return invoke({
        method: 'POST',
        baseURL: BASE_PATH,
        route:  `v1/nft/comment/${id}`,
        data: body,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}



export const editCommentApi = (id, body = {}, token) => {
    return invoke({
        method: 'PUT',
        baseURL: BASE_PATH,
        route:  `v1/nft/comment/${id}`,
        data: body,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export const getNFTComments = (id, token = null,limit, offset) => {
    console.log(id)
    return invoke({
        method: 'GET',
        baseURL: BASE_PATH,
        route:  `v1/nft/comments/${id}?limit=${limit}&offset=${offset}`,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}
export const getNFTReactions = (id, token = null,limit, offset) => {
    console.log(id)
    return invoke({
        method: 'GET',
        baseURL: BASE_PATH,
        route:  `v1/nft/reactions/${id}?limit=${limit}&offset=${offset}`,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}


export const deleteNFTComment = (id, token = null) => {
    return invoke({
        method: 'DELETE',
        baseURL: BASE_PATH,
        route:  `v1/nft/comment/${id}`,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}