import React, {Component} from 'react';
import {mnemonicValidate} from '@polkadot/util-crypto';
import {Keyring} from '@polkadot/api';
import {connect} from 'react-redux';
import {placeBid} from '../../../api/nft/nft';
import timeAgo from 'time-ago';

class PlaceBid extends Component {

    state = {
        bidAmount: null,
        mnemonics: null,
        public_address: null,
        isLoading: false,
        message: null,
        isError: false
    }

    oc = e => {
        this.setState({...this.state, [e.target.name]: e.target.value});
    }

    onChangeMnemonics = e => {
        e.preventDefault();

        if (mnemonicValidate(e.target.value)) {
            const keyring = new Keyring({
                type: 'sr25519',
                ss58Format: 42,
            });
            const newPair = keyring.addFromUri(e.target.value);

            this.setState({...this.state, mnemonics: e.target.value, public_address: newPair.address});
        } else this.setState({...this.state, mnemonics: e.target.value, public_address: null});
    }

    placeBid = async () => {
        this.setState({...this.state, isLoading: true});
        try {
            const {data} = await placeBid({
                secret: this.state.mnemonics,
                token_id: this.props.token.id,
                amount: this.state.bidAmount
            }, this.props.authToken);

            if (data.status === 'ExtrinsicSuccess') this.setState({
                ...this.state,
                isLoading: false,
                message: data,
                isError: false
            });
            else this.setState({...this.state, isLoading: false, message: data, isError: true});
        } catch (e) {
            this.setState({...this.state, isLoading: false, message: e.message, isError: true});
        }
    }

    render() {

        const {isLoading} = this.state;
        const {token, auctionStatus: {active_order, bids, highest_bid}} = this.props;

        return (
            <React.Fragment>
                <button style={{display: 'none'}} id="placeBidBtn" type="button" className="btn btn-primary"
                        data-bs-toggle="modal" data-bs-target="#placeBidModal">
                    Launch demo modal
                </button>
                <div className="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" id="placeBidModal"
                     tabindex="-1" aria-labelledby="exampleModalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md">
                        <div className="modal-content p-4">
                            <h3 className="mb-4 text-center">Place a bid</h3>
                            {<label style={{fontSize: '12px'}} className="text-success">Hightest
                                bid {highest_bid}</label>}
                            <div className="input-group mb-3">
                                <input style={{
                                    borderColor: this.state.bidAmount && this.state.bidAmount <= (highest_bid || token.minimum_bid) ? 'red' : ''
                                }} onChange={this.oc} name="bidAmount" type="text"
                                       className="form-control shadow-none"
                                       placeholder="Enter price for one piece"/>
                                <span className="input-group-text border-0">
                                            <div className="dropdown bootstrap-select ">
                                                <select className="SelectPicker" tabIndex="null">
                                                <option
                                                    data-content="<img className='SelectPickerImg me-2 mb-1' src='images/icon-eth.png'><span className='text-dark'>ETH</span>"> Chrome</option>
                                                <option
                                                    data-content="<img className='SelectPickerImg me-2 mb-1' src='images/icon-dai.png'><span className='text-dark'>DAI</span>"> Chrome</option>
                                                <option
                                                    data-content="<img className='SelectPickerImg me-2 mb-1' src='images/icon-usdc.png'><span className='text-dark'>USDC</span>"> Chrome</option>
                                            </select>
                                            <button type="button" tabIndex="-1"
                                                    className="btn dropdown-toggle btn-light btn-nucoin" data-bs-toggle="dropdown"
                                                    role="combobox" aria-owns="bs-select-1" aria-haspopup="listbox"
                                                    aria-expanded="false" title="DAI">
                                                <div className="filter-option">
                                                    <div className="filter-option-inner">
                                                        <div className="filter-option-inner-inner">
                                                            <img className="SelectPickerImg me-2 mb-1"
                                                                 src="/images/icon-dai.png"/>
                                                            <span className="text-dark">NST</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </button>
                                            </div>
                                        </span>
                                <p className="form-text mb-0">Minimum bid amount must not be
                                    less than or equal to {highest_bid || token?.minimum_bid} NST</p>
                            </div>

                            <div className="mb-3">
                                <label className="form-label CeraMed d-block mb-1">Account Mnemonics</label>
                                <textarea
                                    style={{borderColor: !this.state.mnemonics ? '' : mnemonicValidate(this.state.mnemonics) ? 'green' : 'red'}}
                                    onChange={this.onChangeMnemonics} name="mnemonics" rows="2"
                                    className="form-control shadow-none"
                                    placeholder="Paste your mnemonics here"></textarea>
                                <p className="form-text mb-0">We are not storing your private key, this can be
                                    use
                                    one time for this transaction.</p>
                            </div>
                            <React.Fragment>
                                {
                                    this.state.public_address && this.state.public_address === this.props.currentUser.public_address && (
                                        <React.Fragment>
                                            <strong style={{color: 'green', fontSize: '14px'}}>Looks
                                                Good!</strong> Your public address
                                            matched <strong className={'d-block break-all'}>{this.state.public_address}</strong>
                                        </React.Fragment>
                                    )
                                }
                                {
                                    this.state.public_address && this.state.public_address !== this.props.currentUser.public_address && (
                                        <React.Fragment>
                                            <strong style={{color: 'red', fontSize: '14px'}}>Something
                                                Wrong!</strong> Your profile address is not matching with the
                                            provided Mnemonics, Please add the mnemonics which you give us on
                                            sign
                                            up.
                                        </React.Fragment>
                                    )
                                }
                            </React.Fragment>
                            {
                                bids.map((_, index) => (
                                    <React.Fragment key={index}>
                                        <label className="d-block mt-2 text-center">You have made a bid for this auction of <strong>{_.bid_amount} NST</strong> at {timeAgo.ago(new Date(_.timestamp))} </label>
                                    </React.Fragment>
                                ))
                            }
                            {
                                !this.state.isError && this.state.message && (
                                    <div className="alert alert-success Fsize_13 mb-0 mt-3" role="alert">
                                        Transaction Successfull <span className={'break-all d-block'}>{this.state.message.blockHash}</span>
                                    </div>
                                )
                            }
                            <div className="col-md-auto d-flex justify-content-end my-3 mb-md-0">
                                <button
                                    id="clsbtn"
                                    data-bs-toggle="modal" data-bs-target="#placeBidModal"
                                    type="button"
                                    disabled={isLoading}
                                    className="btn BtnBorder Fsize_13 py-2 px-4 me-2">Close
                                </button>

                                {
                                    !this.state.isError && !this.state.message ? (
                                        <button onClick={this.placeBid.bind(this)}
                                                disabled={this.state.bidAmount <= (highest_bid || this.props?.token?.bid_minimum) || !mnemonicValidate(this.state.mnemonics) || this.props.currentUser.public_address !== this.state.public_address || isLoading}
                                                type="button"
                                                className="btn BtnBlack py-2 px-4 Fsize_13">Confirm {isLoading &&
                                        <div style={{width: '15px', height: '15px'}}
                                             className="spinner-border ms-2"></div>}
                                        </button>
                                    ) :  null
                                }
                            </div>
                            {
                                this.state.isError && this.state.message && (
                                    <React.Fragment>
                                        <div className="alert alert-danger Fsize_13" role="alert">
                                            {this.state.message.blockHash || this.state.message}
                                        </div>
                                        <button
                                            onClick={this.placeBid.bind(this)}
                                            disabled={this.state.bidAmount <= (highest_bid || this.props?.token?.bid_minimum) || !mnemonicValidate(this.state.mnemonics) || this.props.currentUser.public_address !== this.state.public_address || isLoading}
                                            type="button"
                                            className="btn BtnBlack py-2 px-4">Retry {isLoading &&
                                        <span className="fa fa-spinner fa-spin"/>}
                                        </button>
                                    </React.Fragment>
                                )
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    authToken: state.Auth.token,
    currentUser: state.Profile.profile
})

export default connect(mapStateToProps, {})(PlaceBid);