import React from 'react';
import { Link } from 'react-router-dom';
import timeago from 'time-ago';
import DeleteIcon from '@material-ui/icons/Delete';
import ReactHtmlParser from 'react-html-parser';

export default ({ id, title, message, is_read, category, create_time, setDeleteId, ttl }) => (
    <li style={{ cursor: 'pointer' }} className="py-3 px-0 border-bottom position-relative">
        <Link to={`/notification/detail/${id}`}>
            <div className="d-flex">
                <div className="me-3 rounded bg-white d-flex justify-content-center align-self-center" style={{ width: "72px" }}>
                    <img src="/images/notifications.png" alt="" className="avatar-md h-auto d-block py-3" style={{ width: "50px" }} />
                </div>
                <div className="align-self-center overflow-hidden me-auto NotificationList">
                    <div>
                        <h5 className="mb-1">
                            <strong>
                                { title }
                            </strong>
                        </h5>
                        <h4 className="mb-1 Fsize_14">{ ReactHtmlParser(message) }</h4>

                        <h6 className="mb-0 Fsize_12">
                            <span>{ timeago.ago(new Date(create_time)) }</span>
                        </h6>
                        { !is_read && <div className='ActiveSattus'></div> }
                        { ttl ? (
                            <h6 className="mb-0 Fsize_10 text-muted">
                                <span>{`Disappearing in ${timeago.ago(ttl)}`}</span>
                            </h6>
                        ) : <></>}
                    </div>
                </div>
            </div>
        </Link>
        <div style={{ position: 'absolute', bottom: '10px', right: '0px' }}>
            {  
                !ttl && <DeleteIcon onClick={() => {
                    setDeleteId(id);
                    document.getElementById('deleteBtn').click();
                }} style={{ color: '#808080', width: '30px', height: '30px' }}></DeleteIcon>
            }
        </div>
    </li>
)