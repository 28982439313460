import React, { useEffect, useState } from 'react';

export default ({ change = -1, callback = () => {} }) => {

    useEffect(() => {
        if(change !== -1) {
            document.getElementById('warningAlertBtn').click();
        }
    }, [ change ]);
    
    return (
        <React.Fragment>
            <button style={{display: 'none'}} id="warningAlertBtn" type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#warningAlertModal">
                Launch demo modal
            </button>
            <div className="modal fade DarkModal" data-bs-keyboard="false" id="warningAlertModal" tabindex="-1" aria-labelledby="warningAlertModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-sm">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-danger">Deleting!</h5>
                            <button onClick={() => { callback(-1); }} type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p className="mb-0">Are you sure?</p>
                        </div>
                        <div className="modal-footer justify-content-end">
                            <button onClick={() => { callback(-1); }} data-bs-toggle="modal" data-bs-target="#warningAlertModal" type="button" className="btn BtnBorderBlack py-2 px-3">Cancel</button>
                            <button onClick={() => { callback(change); }} type="button" className="btn BtnBlack py-2 px-3">Confirm</button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}