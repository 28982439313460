import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {generateCaptcha, login} from '../../../api/authentication/authentication';
import ReactHtmlParser, {processNodes, convertNodeToElement, htmlparser2} from 'react-html-parser';
import $ from 'jquery';

export default class Login extends Component {

    state = {
        data: {
            user_name: null,
            password: null
        },
        isError: false,
        message: null,
        isLoading: false,
        captcha: {
            text: null,
            image: null
        },
        captchaValue: null,
        recaptchaValue: null
    }

    oc = e => {
        this.setState({...this.state, data: {...this.state.data, [e.target.name]: e.target.value}});
    }

    async submit(e) {
        e.preventDefault();
        if (this.state.data.user_name === '' || this.state.data.user_name === null) {
            return this.setState({...this.state, isError: true, message: 'Username required!'});
        } else if (this.state.data.password === '' || this.state.data.password === null) {
            return this.setState({...this.state, isError: true, message: 'Password required!'});
        } else if (this.state.captchaValue !== this.state.captcha.text) {
            return this.setState({...this.state, isError: true, message: 'Please enter valid captcha value!'});
        }
        this.setState({...this.state, isError: null, message: null, isLoading: true});
        try {
            const {data: {token, redirectToken}} = await login(this.state.data);
            localStorage.setItem('token', token);
            localStorage.setItem('redirectToken', redirectToken);
            this.setState({...this.state, isError: null, message: null, isLoading: false});
            if(this.props.location.search === '') window.location.href = '/';
            else window.location.href = this.props.location.search.split('=')[1];
        } catch (e) {
            this.setState({
                ...this.state,
                isError: true,
                isLoading: false,
                message: e.message === 'Request failed with status code 401' ? 'Invalid Username or Password!' : e.response && e.response.data ? e.response.data : e.message
            });
        }
    }

    componentDidMount() {
        this.getCaptcha();
        if(localStorage.getItem('theme') === 'dark') {
            $(".modal").addClass("DarkModal");
            $(".dropdown-menu").addClass("dropdown-menu-dark");
        }
    }

    getCaptcha = async e => {
        e && e.preventDefault();
        try {
            const captchaResponse = await generateCaptcha();
            this.setState({ ...this.state, captcha: captchaResponse.data })
        } catch(e) {}
    }

    renderHtml(htmlString) {
        let xmlString = htmlString;
        let doc = new DOMParser().parseFromString(xmlString, "text/xml");
        return doc.firstChild.innerHTML;
    }

    render() {

        return (
            <div className="container-fluid" id="SigningPage">
                <div className="row">
                    <div className="col-md-5 col-lg-4 col-xl-3 px-4 px-md-5 sidebar d-flex flex-column pt-5">
                        <Link to="/" className="back-to-home-page rounded d-flex align-items-center me-auto mb-5">
                            <svg className={'me-1 arrow1'} xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/>
                                <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
                            </svg>
                            Back To Homepage
                        </Link>

                        <div className={'my-auto'}>
                            <form>
                                <h3 className="form-title">
                                    Sign In
                                </h3>

                                <div className="mb-4">
                                    <input tabIndex="1" autoComplete="off" onChange={this.oc} className="form-control shadow-none mb-0"
                                           id="signin_email_id" name="user_name" placeholder="Enter username"/>
                                    <p className={'text-end'}><Link onClick={() => {
                                        localStorage.setItem("forget_type", "USERNAME")
                                    }} to="/forget-credential" className="link Fsize_13">
                                        Forgot your username?
                                    </Link></p>
                                </div>
                                {/* <div className="help d-flex justify-content-end align-items-center">
                                    <a href="#" className="d-flex align-items-center">
                                        {/* <span>
                                            <svg className="help-icon" xmlns="http://www.w3.org/2000/svg" height="20px"
                                                 viewBox="0 0 24 24" width="20px" fill="#000000"><path d="M0 0h24v24H0z"
                                                                                                       fill="none"/><path
                                                d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z"/></svg>
                                        </span>
                                        <span className="link">
                                            Can't find your membership number?
                                        </span> */}
                                {/* </a>
                                </div>  */}

                                <div className="mb-4">
                                    <input tabIndex="2" autoComplete="off" onChange={this.oc} className="form-control shadow-none mb-0"
                                           id="signin_password" type="password" name="password"
                                           placeholder="Enter your password"/>
                                    <p className={'text-end'}><Link onClick={() => {
                                        localStorage.setItem("forget_type", "PASSWORD")
                                    }} to="/forget-credential" className="link Fsize_13">Forgot your password?</Link></p>
                                </div>
                                

                                <div className={`row align-items-center gx-2 mb-3 ${this.state.captcha.text === null ? ' d-none ' : ''}`}>
                                    <div className="col-12">
                                        {
                                            this.state.captcha.text && <img className="generated-captcha" src={this.state.captcha.image}/>
                                        }
                                    </div>
                                    <div className="col">
                                        <input onChange={(e) => {
                                            this.setState({ ...this.state, captchaValue: e.target.value });
                                        }}
                                               className="form-control shadow-none" autoComplete="off" id="captcha"
                                               name="captchaValue" placeholder="Enter captcha"/>
                                    </div>
                                    <div className="col-auto">
                                        <span style={{ cursor: 'pointer' }} onClick={this.getCaptcha.bind(this)} className="fas fa-redo-alt"></span>
                                    </div>
                                </div>

                                {
                                    this.state.isError ? <div className="alert alert-danger Fsize_14" role="alert">
                                        {this.state.message.indexOf('label') !== 0 ? ReactHtmlParser(this.state.message) : this.state.message}
                                    </div> : null
                                }
                                {
                                    !this.state.isError && this.state.message ?
                                        <div className="alert alert-success" role="alert">
                                            {this.state.message}
                                        </div> : null
                                }
                                <button tabIndex="3" disabled={this.state.isLoading} onClick={this.submit.bind(this)} className="btn BtnBlack text-uppercase py-2 px-5 mb-5 w-100">
                                    Sign in
                                    {this.state.isLoading && <i className="fas fa-asterisk fa-spin ms-1"></i>}
                                </button>
                                <div className="text-center Fsize_13">
                                    <p className={'mb-0'}>Don’t have an account?</p>
                                    <Link to="/signup" className="BlueColor">
                                        <u>Sign up here</u>
                                    </Link>
                                </div>
                            </form>
                        </div>
                        <div className="text-center Fsize_13 pt-5 pb-md-5">
                            Copyrights @2021, All Rights Reserved by Nucoin NFT.
                        </div>
                    </div>
                    <div className="col-md-7 col-lg-8 col-xl-9 content">
                        <div className="row">
                            <div
                                className="col-lg-7 col-md-12 mb-4 mb-xl-0 d-flex justify-content-center pt-5 px-4 px-md-5 hero">
                                <div className="hero-text d-flex flex-column justify-content-center">
                                    <div className="upper-text d-inline-block me-auto">
                                        <span>
                                            The Largest NFT Marketplace
                                        </span>
                                    </div>
                                    <h2 className="title">
                                        NFT City Beta version 2
                                    </h2>
                                    <p className="sub-text">
                                        Welcome to NFT City BETA early version, This version contains most of the major
                                        features of NFT City Exchange,
                                        Which currently is continuously being developed to add the digital notarised
                                        contract features.
                                        The purpose of BETA version is to allow user testing, with a direct feedback
                                        program.
                                        All feedback during the BETA version, will be taken onboard and adopted into the
                                        launch of full NFT City.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-12 cards d-none d-lg-block">
                                <div className="row">
                                    <div className="mb-5 col-md-6 mb-md-0">
                                        <div className="p-3 p-xl-4 mb-5 card">
                                            <img src="/images/image6.jpg" alt="" className="img-fluid"/>
                                        </div>
                                        <div className="p-3 p-xl-4 mb-5 card">
                                            <img src="/images/img1.jpeg" alt="" className="img-fluid"/>
                                        </div>
                                        <div className="p-3 p-xl-4 mb-5 card">
                                            <img src="/images/e9ee03457b929ea5dfc89380f4a2c20c.jpeg" alt=""
                                                 className="img-fluid"/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="p-3 p-xl-4 mb-5 card">
                                            <img src="/images/image5.jpg" alt="" className="img-fluid"/>
                                        </div>
                                        <div className="p-3 p-xl-4 mb-5 card">
                                            <img src="/images/user.jpg" alt="" className="img-fluid"/>
                                        </div>
                                        <div className="p-3 p-xl-4 mb-5 card">
                                            <img src="/images/imgone.jpeg" alt="" className="img-fluid"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}