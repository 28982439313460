import React, { Component } from 'react';
import { connect } from 'react-redux';
import CryptoJS from 'crypto-js';

class ContinueWithPassword extends Component {

    state = {
        mnemonicsPwd: '',
        error: null
    }

    submit() {
        try {
            const mnemonics = CryptoJS.AES.decrypt(this.props.cypherSecret, this.state.mnemonicsPwd).toString(CryptoJS.enc.Utf8);
            if(mnemonics == '') throw new Error('Wrong password entered!');
            this.props.callback(mnemonics);
            this.setState({ ...this.state, mnemonicsPwd: '', error: null });
        } catch(e) {
            this.setState({ ...this.state, error: 'Wrong password entered!' });
        }
    }

    render() {

        const { mnemonicsPwd, error } = this.state;

        return (
            <React.Fragment>
                <div className={'modal-header align-items-start'}>
                    <h5 className="modal-title">Please enter wallet password to unlock</h5>
                    <button type="button" className="btn-close m-0" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className={'modal-body'}>
                    <p className="break-all">"{this.props.currentUser.public_address}"</p>
                    <input onChange={e => this.setState({ ...this.state, mnemonicsPwd: e.target.value })} className="form-control BorderDarkGrey shadow-none mt-4" type="password" name="password" placeholder="Enter Password"/>
                    {
                        error && <div className="alert alert-danger mt-3" role="alert">
                            { error }
                        </div>
                    }
                </div>
                <div className="modal-footer">
                    <button data-bs-dismiss="modal" className="btn BtnBorderBlack py-2 px-3 px-md-5 me-3">Close </button>
                    <button
                        disabled={mnemonicsPwd.length < 6}
                        onClick={this.submit.bind(this)} 
                        type="button"
                        className="btn BtnBlack py-2 px-3 px-md-5">Confirm { this.props.isLoading && <i className="fas fa-asterisk fa-spin text-center" />}
                    </button>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    currentUser: state.Profile.profile,
    cypherSecret: state.Auth.cypherSecret
});

export default connect(mapStateToProps, {

})(ContinueWithPassword);