import invoke from '../../utils/invoke';
import { encrypt } from '../../utils/utils';

const BASE_PATH = process.env.REACT_APP_WALLET_SERVICE || 'http://localhost:4004';

export const burnNFTAPI = (data, token = null) => {
    data = encrypt(data);
    return invoke({
        method:     'POST',
        baseURL:    BASE_PATH,
        route:      'v1/wallet/burnNft',
        data:       data,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export const swapCoinsApi = (data, token = null) => {
    data = encrypt(data);
    return invoke({
        method:     'POST',
        baseURL:    BASE_PATH,
        route:      'v1/wallet/swap',
        data:       data,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export const getNUCBalanceApi = (token = null) => {
    return invoke({
        method:     'GET',
        baseURL:    BASE_PATH,
        route:      'v1/wallet/nuc/balance',
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export const getNSTBalanceApi = (token = null) => {
    return invoke({
        method:     'GET',
        baseURL:    BASE_PATH,
        route:      'v1/wallet/nst/balance',
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export const getNUCBalanceByParamApi = (public_address = null) => {
    return invoke({
        method:     'GET',
        baseURL:    BASE_PATH,
        route:      `v1/wallet/nuc/balance/${public_address}`
    });
}

export const getNSTBalanceByParamApi = (public_address = null) => {
    return invoke({
        method:     'GET',
        baseURL:    BASE_PATH,
        route:      `v1/wallet/nst/balance/${public_address}`
    });
}

export const mintBulkNftApi = (data = {}, token = null) => {
    data = encrypt(data);
    return invoke({
        method:     'POST',
        baseURL:    BASE_PATH,
        route:      `v1/wallet/mint/bulk`,
        data,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export const transferNFTApi = (data = {}, token = null) => {
    data = encrypt(data);
    return invoke({
        method:     'POST',
        baseURL:    BASE_PATH,
        route:      `v1/wallet/transferNft`,
        data,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export const generateRedeemOrderNFTApi = (data = {}, token = null) => {
    data = encrypt(data);
    return invoke({
        method:     'POST',
        baseURL:    BASE_PATH,
        route:      `v1/wallet/escrow/addOrder`,
        data,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export const redeemNFTApi = (data = {}, token = null) => {
    data = encrypt(data);
    return invoke({
        method:     'POST',
        baseURL:    BASE_PATH,
        route:      `v1/wallet/escrow/redeemToken`,
        data,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}