import React from 'react';
import { connect } from 'react-redux';
import {
    FacebookShareButton,
    LinkedinShareButton,
    TelegramShareButton,
    TwitterShareButton,
  } from "react-share";

const DOMAIN_URL = process.env.REACT_APP_DOMAIN_URL || 'http://localhost:3000';

const ShareSocial = ({shareDetail}) => (
    <div className="modal fade" id="ShareModal" tabIndex="-1" aria-labelledby="ShareModalLabel"
            aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-header align-items-center">
                    <h5 className="modal-title" id="ShareModalLabel">Share this NFT</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <ul className="list-unstyled d-flex justify-content-center text-center my-4">
                        <li className="mx-4">
                            <FacebookShareButton title={shareDetail.title} quote={shareDetail.description} hashtag="#nftcity #nft" url={`${DOMAIN_URL}/detail/${shareDetail.id}`}> 
                                <i className="fab fa-2x fa-facebook-f d-block"></i>
                            </FacebookShareButton>
                        </li>
                        <li className="mx-4">
                            <TwitterShareButton  title={shareDetail.title} hashtags={["#nftcity", "#nft"]} url={`${DOMAIN_URL}/detail/${shareDetail.id}`}> 
                                <i className="fab fa-2x fa-twitter d-block"></i>
                            </TwitterShareButton>
                        </li>
                        <li className="mx-4">
                            <LinkedinShareButton  title={shareDetail.title} summary={shareDetail.description} url={`${DOMAIN_URL}/detail/${shareDetail.id}`}> 
                                <i className="fab fa-2x fa-linkedin d-block"></i>
                            </LinkedinShareButton>
                        </li>
                        <li className="mx-4">
                        <TelegramShareButton  title={shareDetail.title} url={`${DOMAIN_URL}/detail/${shareDetail.id}`}> 
                                <i className="fab fa-2x fa-telegram d-block"></i>
                            </TelegramShareButton>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
) 

const mapStateToProps = state => ({
    shareDetail :   state.ShareSocial.shareSocial
})

export default connect(mapStateToProps, {})(ShareSocial);