import React, { useEffect } from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import BusinessVerificationRequired from '../Layouts/BusinessVerificationRequired/BusinessVerificationRequired';
import ConfirmLogin from '../Layouts/ConfirmLogin/ConfirmLogin';
import KycStatus from '../Layouts/KycStatus/KycStatus';
import ModuleUnderConstruction from '../Layouts/ModuleUnderConstruction/ModuleUnderConstruction';
import PageLoader from '../Layouts/PageLoader/PageLoader';
import PermissionRequired from '../Layouts/PermissionRequired/PermissionRequired';
import $ from 'jquery';
import { onlyAdmins } from '../../utils/utils';

const CreateNFT = (props) => {

    useEffect(() => {
        if(localStorage.getItem('theme') === 'dark') {
            $(".modal").addClass("DarkModal");
            $(".dropdown-menu").addClass("dropdown-menu-dark");
        }
    }, [])

    if(props.isLoggedIn && props.toggle.toggle_create_nft === undefined) return <PageLoader />
    if(props.isLoggedIn && !props.toggle.toggle_create_nft) return <PermissionRequired />

    return (
        <React.Fragment>
            <main>
                <section id="CreateCollectible" className="py-5 SingleNft">
                    <div className="container">
                        <div className="row justify-content-center gx-3" id='zIndexMob'>
                            <div className="col-sm-12 col-md-10 col-lg-8 col-xl-8 Fsize_16">
                                <Link to="/" className="Fsize_16  brandnew">
                                    <svg className="mb-1 me-2" viewBox="0 0 14 12" fill="none" width="14" height="14"
                                        xlmns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M6.29436 0.292893C6.68488 -0.0976311 7.31805 -0.0976311 7.70857 0.292893C8.0991 0.683417 8.0991 1.31658 7.70857 1.70711L4.41568 5H12.9985C13.5508 5 13.9985 5.44772 13.9985 6C13.9985 6.55228 13.5508 7 12.9985 7H4.41568L7.70857 10.2929C8.0991 10.6834 8.0991 11.3166 7.70857 11.7071C7.31805 12.0976 6.68488 12.0976 6.29436 11.7071L0.587252 6L6.29436 0.292893Z"
                                            fill="currentColor"></path>
                                    </svg>
                                    Go back
                                </Link>
                                <h2 className="pt-2">Create collectible</h2>
                                <p className="GreyColor mb-4 Fsize_16 CeraMed">Choose “Single” if you want your collectible
                                    to be one of a kind or “Multiple” if you want to sell one collectible multiple times</p>
                                <div className="row gx-3 mb-4">
                                    <Link className="d-none" id="redirect-to-single" to="/create-single"/>
                                    <div onClick={() => {
                                        if (!props.isLoggedIn) return document.getElementById('loginConfirmationBtn').click();
                                        if(props.toggle.toggle_kyc !== undefined && props.toggle.toggle_kyc == 1)
                                            if (props.currentUser.kycStatus == 0) return document.getElementById('kycStatusDialogBtn').click();

                                        document.getElementById('redirect-to-single').click();
                                    }} className="col-sm-6 col-lg-4 col-xl-3 col-xxl-3 mb-3 mb-xl-0">
                                        <div className="rounded border  text-center Createcard py-4 px-4">
                                            <img src="/images/img-single-create.png" alt="Image"
                                                className="img-fluid mb-3"/>
                                            <strong className="d-block CeraBold Fsize_18 CreateTitle">Single</strong>
                                        </div>
                                    </div>
                                    <Link className="d-none" id="redirect-to-multiple" to="/create-multiple"/>
                                    <div onClick={() => {
                                        if (!props.isLoggedIn) return document.getElementById('loginConfirmationBtn').click();
                                        if(props.toggle.toggle_kyc !== undefined && props.toggle.toggle_kyc == 1)
                                            if (props.currentUser.kycStatus == 0) return document.getElementById('kycStatusDialogBtn').click();
                                        
                                        document.getElementById('redirect-to-multiple').click();
                                    }} className="col-sm-6 col-lg-4 col-xl-3 col-xxl-3 mb-3 mb-xl-0">
                                        <div className="rounded border text-center Createcard py-4 px-4">
                                            <img src="/images/img-multiple-create.png" alt="Image"
                                                className="img-fluid mb-3"/>
                                            <strong className="d-block CeraBold Fsize_18 CreateTitle">Multiple</strong>
                                        </div>
                                    </div>
                                    <Link className="d-none" id="redirect-to-bulk" to="/create-bulk"/>
                                    <div onClick={() => {
                                        if (!props.isLoggedIn) return document.getElementById('loginConfirmationBtn').click();
                                        if(props.toggle.toggle_kyc !== undefined && props.toggle.toggle_kyc == 1)
                                            if (props.currentUser.kycStatus == 0) return document.getElementById('kycStatusDialogBtn').click();
                                        
                                        // return document.getElementById('underConstructionBtn').click();

                                        document.getElementById('redirect-to-bulk').click();
                                    }} className="col-sm-6 col-lg-4 col-xl-3 col-xxl-3 mb-3 mb-xl-0">
                                        <div className="rounded border  text-center Createcard py-4 px-4">
                                            <img src="/images/img-businees-multiple.png" alt="Image"
                                                className="img-fluid mb-3"/>
                                            <strong className="d-block CeraBold Fsize_18 CreateTitle">Bulk</strong>
                                        </div>
                                    </div>
                                    <Link className="d-none" id="redirect-to-b-multiple" to="/create-business-multiple"/>
                                    <div
                                        title={props.currentUser.business && props.currentUser.business.status !== 'APPROVED' ? "Business Verification is Required!" : 'Business Multiple'}
                                        onClick={() => {
                                            if (!props.isLoggedIn) return document.getElementById('loginConfirmationBtn').click();
                                            if (!props.currentUser.business) return;
                                            if (props.currentUser.business.status !== 'APPROVED') return document.getElementById('businessVerificationRequredBtn').click();
                                            if(props.toggle.toggle_kyc !== undefined && props.toggle.toggle_kyc == 1)
                                                if (props.currentUser.kycStatus == 0) return document.getElementById('kycStatusDialogBtn').click();
                                            document.getElementById('redirect-to-b-multiple').click();
                                        }} className="col-sm-6 col-lg-4 col-xl-3 col-xxl-3">
                                        <div className="rounded border  text-center Createcard py-4 px-4">
                                            <img src="/images/img-businees-multiple.png" alt="Image"
                                                className="img-fluid mb-3"/>
                                            <strong className="d-block CeraBold Fsize_18 CreateTitle">Business NFT</strong>
                                        </div>
                                    </div>
                                    <Link className="d-none" id="redirect-to-equity" to="/create-equity"/>
                                    {
                                        onlyAdmins(props.currentUser.user_name) && (
                                            <div onClick={() => {
                                                if (!props.isLoggedIn) return document.getElementById('loginConfirmationBtn').click();
                                                if(props.toggle.toggle_kyc !== undefined && props.toggle.toggle_kyc == 1)
                                                    if (props.currentUser.kycStatus == 0) return document.getElementById('kycStatusDialogBtn').click();
        
                                                document.getElementById('redirect-to-equity').click();
                                            }} className="col-sm-6 col-lg-4 col-xl-3 col-xxl-3 mb-3 mb-xl-0">
                                                <div className="rounded border  text-center Createcard py-4 px-4">
                                                    <img src="/images/img-single-create.png" alt="Image"
                                                        className="img-fluid mb-3"/>
                                                    <strong className="d-block CeraBold Fsize_18 CreateTitle">Equity</strong>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                                <p className="GreyColor Fsize_16 CeraMed">We do not own your private keys and cannot access
                                    your funds without your confirmation</p>
                            </div>
                        </div>
                        <ConfirmLogin title={`Want to create NFT?`} message={<p>Please <Link id="link-target" to="/login"/>
                            <span style={{cursor: 'pointer', color: 'blue'}} onClick={() => {
                                document.getElementById('loginConfirmationBtn').click();
                                document.getElementById('link-target').click();
                            }}
                                className="BlueColor">Login</span> to continue creating.</p>}/>
                        <BusinessVerificationRequired />
                        <KycStatus />
                        <ModuleUnderConstruction />
                    </div>
                </section>
            </main>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    isLoggedIn: state.Auth.isLoggedIn,
    currentUser: state.Profile.profile,
    toggle: state.Settings.toggle
})

export default connect(mapStateToProps, {})(CreateNFT);