import { Router, Route, Switch, BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import Header from './components/Layouts/Header/Header';
import Footer from './components/Layouts/Footer/Footer';

import Home from './components/Home/Home';
import Login from './components/Auth/Login/Login';
import Register from './components/Auth/Register/Register';

import store from './store';
import CreateNFT from './components/CreateNFT/CreateNFT';
import SingleNFT from './components/CreateNFT/SingleNFT/SingleNFT';
import Swap from './components/Swap/Swap';
import SearchResult from './components/SearchResult/SearchResult';
import MultipleNFT from './components/CreateNFT/MultipleNFT/MultipleNFT';
import BusinessMultipleNFT from './components/CreateNFT/BusinessMultipleNFT/BusinessMultipleNFT';
import MyItems from './components/MyItems/MyItems';
import ItemDetail from './components/ItemDetail/ItemDetail';
import EditProfile from './components/EditProfile/EditProfile';
import Profile from './components/Profile/Profile';
import ShareSocial from './components/Layouts/ShareSocial/ShareSocial';
import BusinessVerification from './components/BusinessVerification/BusinessVerification';
import VerifyOTP from './components/Auth/VerifyOTP/VerifyOTP';
import ForgetCredential from './components/Auth/ForgetCredentail/ForgetCredential';
import UpdatePassword from './components/Auth/UpdatePassword/Updatepassword';
import {useEffect, useState} from 'react';
import BetaVersionAlert from './components/Layouts/BetaVersionAlert/BetaVersionAlert';
import BulkNFT from './components/CreateNFT/BulkNFT/BulkNFT';
import NotificationList from './components/Notification/NotificationList/NotificationList';
import NotificationDetail from './components/Notification/NotificationDetail/NotificationDetail';
import PageNotFound from './components/Layouts/PageNotFound/404';
import { NotificationSDK } from 'notificationsdk';
import EquityNFT from './components/CreateNFT/EquityNFT/EquityNFT';
import Invest from './components/Invest/Invest';

function App() {

    /*useEffect(async () => {
        if (localStorage.getItem('beta-alert-shown') != 'true' && localStorage.getItem('token')) {
            document.getElementById('betaVersionModalBtn').click();
            localStorage.setItem('beta-alert-shown', 'true');
        }
    }, []);*/

    return (
        <Provider store={store}>
            <BrowserRouter>
                <Switch>
                    <div className="wrapper">
                        <Route exact path="*" component={Header}/>
                        <Route exact path="/" component={Home}/>
                        <Route exact path="/login" component={Login}/>
                        <Route exact path="/signup" component={Register}/>
                        <Route exact path="/create" component={CreateNFT}/>
                        <Route exact path="/create-single" component={SingleNFT}/>
                        <Route exact path="/create-multiple" component={MultipleNFT}/>
                        <Route exact path="/create-bulk" component={BulkNFT}/>
                        <Route exact path="/create-equity" component={EquityNFT}/>
                        <Route exact path="/create-business-multiple" component={BusinessMultipleNFT}/>
                        <Route exact path="/swap" component={Swap}/>
                        <Route exact path="/search" component={SearchResult}/>
                        <Route exact path="/my-items" component={MyItems}/>
                        <Route exact path="/profile/:user_name" component={Profile}/>
                        <Route exact path="/detail/:id" component={ItemDetail}/>
                        <Route exact path="/user/profile/edit" component={EditProfile}/>
                        <Route exact path="/business/verification" component={BusinessVerification}/>
                        <Route exact path="/verify/otp/:value" component={VerifyOTP}/>
                        <Route exact path="/forget-credential" component={ForgetCredential}/>
                        <Route exact path="/update-password" component={UpdatePassword}/>
                        <Route exact path="/notification/list" component={NotificationList}/>
                        <Route exact path="/notification/detail/:id" component={NotificationDetail}/>
                        <Route exact path="/invest" component={Invest}/>
                        <Route exact path="/page-not-found" component={PageNotFound}/>
                        <Route exact path="*" component={Footer}/>
                    </div>
                </Switch>
            </BrowserRouter>
            <NotificationSDK connectionURL={process.env.REACT_APP_NOTIFICATION_SOCKET} apiBasePath={process.env.REACT_APP_NOTIFICATION_API} projectKey={process.env.REACT_APP_NOTIFICATION_SUBSCRIBER_ID} authToken={localStorage.getItem('token')} toastLogo={'/images/logo.png'}/>
            <ShareSocial/>
            <BetaVersionAlert/>
        </Provider>
    );
}

export default App;
