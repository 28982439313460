import invoke from '../../utils/invoke';



const BASE_PATH = process.env.REACT_APP_LISTING_SERVICE || 'http://localhost:4001';
const NOTIFCATION_BASE_PATH = process.env.REACT_APP_NOTIFICATION_API || 'http://localhost:4001';
const NOTIFCATION_KEY = process.env.REACT_APP_NOTIFICATION_SUBSCRIBER_ID

export const homeListing = () => {
    return invoke({
        method: 'GET',
        baseURL: BASE_PATH,
        route: `v1/nft/home`
    });
}

export const listTopSellers = () => {
    return invoke({
        method: 'GET',
        baseURL: BASE_PATH,
        route: `v1/nft/sellers/top`
    });
}

export const listLiveAuctionsAPI = () => {
    return invoke({
        method: 'GET',
        baseURL: BASE_PATH,
        route: `v1/auction/list/live`
    });
}

export const exploreListing = (page = 0, filter = 'all', token = null) => {
    return invoke({
        method: 'GET',
        baseURL: BASE_PATH,
        route: `v1/nft/listing/explore?page=${page}&filter=${filter}`,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export const addSubscribe = (data) => {

    return invoke({
        method: 'POST',
        baseURL: NOTIFCATION_BASE_PATH,
        route: 'v2/news-letters/newsletter/create/',
        data: data,
        headers: {
            "platform-subscriber-id": NOTIFCATION_KEY
        }
    });
}
