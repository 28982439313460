import { SET_TOGGLE, SET_THEME_TOGGLE } from "../types/settings";

export const setToggle = payload => dispatch => {
    dispatch({
        type    :   SET_TOGGLE,
        payload :   payload
    })
}


export const setThemeToggle = payload => dispatch => {
    
    localStorage.setItem('theme', payload);

    dispatch({
        type    :   SET_THEME_TOGGLE,
        payload :   payload
    })
}
