import React, { Component } from 'react';
import $ from 'jquery';
import { getNSTBalanceApi, getNUCBalanceApi, swapCoinsApi } from '../../api/wallet/wallet';
import { connect } from 'react-redux';
import { setNUCBalance, setNSTBalance } from '../../actions/wallet';
import MessageBox from '../Layouts/MessageBox/MessageBox';
import Conversion from './Conversion/Conversion';
import { mnemonicValidate } from '@polkadot/util-crypto';
import { Keyring } from '@polkadot/api';

class Swap extends Component {

    state = {
        data: {
            from: 'NUC',
            to: 'NST',
            fromValue: null,
            toValue: null,
            mnemonics: null
        },
        balances: {
            NUC: null,
            NST: null
        },
        showMnemonicsInput: false,
        isError: false,
        message: null,
        isLoading: false
    }

    componentDidMount() {

        if(localStorage.getItem('theme') === 'dark') {
            $(".modal").addClass("DarkModal");
            $(".dropdown-menu").addClass("dropdown-menu-dark");
        }

        if (!this.props.token) return;

        getNUCBalanceApi(this.props.token).then(({data: {balance}}) => {
            this.props.setNUCBalance(balance);
            this.setState({...this.state, balances: {...this.state.balances, NUC: balance}});
        }).catch(e => {
        });

        getNSTBalanceApi(this.props.token).then(({data: {balance}}) => {
            this.props.setNSTBalance(balance);
            this.setState({...this.state, balances: {...this.state.balances, NST: balance}});
        }).catch(e => {
        });
    }

    onChangeTargets = (key, value) => {
        this.setState({
            ...this.state,
            data: {...this.state.data, [key]: value, [key === 'from' ? 'to' : 'from']: value === 'NST' ? 'NUC' : 'NST'}
        })
    }

    oc = e => {
        this.setState({...this.state, isError: null, message: null, data: {...this.state.data, [e.target.name]: e.target.value}});
    }

    submit = async e => {
        e.preventDefault();
        await new Promise(resolve => this.setState({...this.state, isError: false, message: null}, resolve));

        try {
            const fromValue = parseFloat(this.state.data.fromValue || 0);

            if (fromValue <= 0) {
                return this.setState({...this.state, isError: true, message: 'Swap value must be a positive number.'})
            }

            if (!this.state.showMnemonicsInput) return this.setState({...this.state, showMnemonicsInput: true})

            if (this.state.data.mnemonics === null || this.state.data.mnemonics === '') return this.setState({
                ...this.state,
                isError: true,
                message: 'Mnemonics are required!'
            });
            
            const keyring = new Keyring({
                type: 'sr25519',
                ss58Format: 42,
            });
            const keyPair = keyring.addFromUri(this.state.data.mnemonics);

            if (keyPair.address !== this.props.currentUser.public_address) return this.setState({
                ...this.state,
                isError: true,
                message: 'Provided mnemonics is not matching with swap address!'
            });

            await new Promise(resolve => this.setState({...this.state, isLoading: true}, resolve));

            const { data: { burn_hash, mint_hash } } = await swapCoinsApi({
                mnemonics: this.state.data.mnemonics,
                coins: this.state.data.fromValue,
                currency: this.state.data.from
            });

            this.setState({
                ...this.state, isLoading: false, isError: false,
                message: <p>Burn Hash: {burn_hash}<br/>Mint Hash: {mint_hash}<br/>Swap Successfull!</p>
            });

        } catch (e) {
            this.setState({
                ...this.state,
                isError: true,
                isLoading: false,
                message: e.response ? e.response.data : e.message
            })
        }

        document.getElementById('messageBoxBtn').click();
    }


    render() {

        const {from, to, fromValue, toValue} = this.state.data;
        const {isError, message, showMnemonicsInput} = this.state;

        return (
            <main className="MinHeight">
                {/*<section id="Swap" className="py-5 BgGrey">
                    <div className="container text-center position-relative">
                        <h1>Swap Nucoin & Nucoin Smart</h1>
                        <p className="mb-0">Trade from Nucoin to Nucoin Smartchain / Nucoin Smartchain to Nucoin</p>
                    </div>
                </section>*/}
                <section id="ConvertForm" className="py-5">
                    <div className="VideoBox d-none d-xl-block">
                        <video autoPlay muted loop id="top-nft-cube">
                            <source src="images/nft-city-asset.mp4" type="video/mp4"/>
                        </video>
                    </div>
                    <div className={'row justify-content-center'} id='zIndexMob'>
                        <div className={'col-lg-10 col-md-10 col-sm-12'}>
                            <div className={'text-center'}>
                                <h2>Swap Nucoin & Nucoin Smart</h2>
                                <p className="mb-5">Trade from Nucoin to Nucoin Smartchain / Nucoin Smartchain to Nucoin</p>
                            </div>
                            <div className="container">
                                <div className="d-md-flex">
                                    <div className="nav flex-column nav-pills flex-auto" id="v-pills-tab" role="tablist"
                                         aria-orientation="vertical">
                                        <button className="nav-link text-start Fsize_14 rounded-0 py-3 active" id="Swap-tab-1"
                                                data-bs-toggle="pill" data-bs-target="#Swap-tab-panel-1" type="button"
                                                role="tab"
                                                aria-controls="Swap-tab-panel-1" aria-selected="true"><i
                                            className="fas fa-coins me-2"></i> Coin Swap
                                        </button>
                                        <button className="nav-link text-start Fsize_14 rounded-0 py-3" id="Swap-tab-2"
                                                data-bs-toggle="pill" data-bs-target="#Swap-tab-panel-2"
                                                type="button"
                                                role="tab"
                                                aria-controls="Swap-tab-panel-2" aria-selected="false"><i
                                            className="fas fa-exchange-alt me-2"></i> Multi Wallet Swap
                                        </button>
                                        <button className="nav-link text-start Fsize_14 rounded-0 py-3 d-none" id="Swap-tab-3"
                                                data-bs-toggle="pill" data-bs-target="#Swap-tab-panel-3"
                                                type="button"
                                                role="tab"
                                                aria-controls="Swap-tab-panel-3" aria-selected="false"><i
                                            className="fas fa-history me-2"></i> Balance History
                                        </button>
                                        <button className="nav-link text-start Fsize_14 rounded-0 py-3 d-none" id="Swap-tab-4"
                                                data-bs-toggle="pill" data-bs-target="#Swap-tab-panel-4"
                                                type="button"
                                                role="tab"
                                                aria-controls="Swap-tab-panel-4" aria-selected="false"><i
                                            className="fas fa-fire-alt me-2"></i> Burning NFT
                                        </button>
                                    </div>
                                    <div className="tab-content flex-grow-1" id="v-pills-tabContent">
                                        <div className="tab-pane fade show active" id="Swap-tab-panel-1" role="tabpanel" aria-labelledby="Swap-tab-1">
                                            <div className="bgwhite p-3 p-md-4 p-lg-5">
                                                <h3 className={'mb-4'}>Coin Swap</h3>
                                                <div className="row justify-content-center">
                                                    <div className="col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                                        <div className="mb-3">
                                                            <div className="d-sm-flex justify-content-between">
                                                                <label className="form-label CeraMed d-block mb-0">From</label>
                                                                {this.state.balances[from] !== null &&
                                                                    <label className="form-label d-block mb-0">Available Balance: <span className="CeraMed ColorWhite"> {this.state.balances[from]}</span></label>
                                                                }
                                                            </div>
                                                            <div className="input-group mb-0 FullOnMob d-block d-sm-flex">
                                                                <input onChange={this.oc} value={fromValue} name="fromValue"
                                                                       type="text" className="form-control shadow-none "
                                                                       placeholder="Please enter number to swap from"/>
                                                                <span className="input-group-text">
                                                            <div className="dropdown bootstrap-select">
                                                            <select className="SelectPicker" tabIndex="null">
                                                                <option
                                                                    data-content="<img className='SelectPickerImg me-2 mb-1' src='images/icon-eth.png'><span className='text-dark'>ETH</span>"> Chrome</option>
                                                                <option
                                                                    data-content="<img className='SelectPickerImg me-2 mb-1' src='images/icon-dai.png'><span className='text-dark'>DAI</span>"> Chrome</option>
                                                                <option
                                                                    data-content="<img className='SelectPickerImg me-2 mb-1' src='images/icon-usdc.png'><span className='text-dark'>USDC</span>"> Chrome</option>
                                                            </select>
                                                            <button type="button" tabIndex="-1" className="btn dropdown-toggle btn-light"
                                                                    data-bs-toggle="dropdown" role="combobox" aria-owns="bs-select-1"
                                                                    aria-haspopup="listbox" aria-expanded="false" title={from}>
                                                                <div className="filter-option">
                                                                    <div className="filter-option-inner">
                                                                        <div className="filter-option-inner-inner">
                                                                            <img className="SelectPickerImg me-2 mb-1"
                                                                                 src={`/images/icon-${from}.png`}/>
                                                                            <span className="text-dark">{from}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </button>
                                                                <div className="dropdown-menu"
                                                                     style={{maxHeight: '210px', overflow: 'hidden', minHeight: '0px'}}>
                                                                    <div className="inner show" role="listbox" id="bs-select-1"
                                                                         tabIndex="-1" style={{
                                                                        maxHeight: '192px',
                                                                        overflow: 'hidden auto',
                                                                        minHeight: '0px'
                                                                    }} aria-activedescendant="bs-select-1-0">
                                                                        <ul className="dropdown-menu inner show" role="presentation"
                                                                            style={{marginTop: '0px', marginBottom: '0px'}}>
                                                                            <li onClick={this.onChangeTargets.bind(this, 'from', 'NUC')}
                                                                                className={from === 'NUC' ? "selected active" : ''}>
                                                                                <a role="option" className="dropdown-item"
                                                                                   id="bs-select-1-0" tabIndex="0" aria-setsize="3"
                                                                                   aria-posinset="1" aria-selected="true">
                                                                                    <span className="text"><img
                                                                                        className="SelectPickerImg me-2 mb-1"
                                                                                        src="/images/icon-NUC.png"/>
                                                                                        <span>NUC</span>
                                                                                    </span>
                                                                                </a>
                                                                            </li>
                                                                            <li onClick={this.onChangeTargets.bind(this, 'from', 'NST')}
                                                                                className={from === 'NST' ? "selected active" : ''}>
                                                                                <a role="option" className="dropdown-item"
                                                                                   id="bs-select-1-1" tabIndex="0" aria-setsize="3"
                                                                                   aria-posinset="2">
                                                                                    <span className="text"><img
                                                                                        className="SelectPickerImg me-2 mb-1"
                                                                                        src="images/icon-NST.png"/>
                                                                                        <span>NST</span>
                                                                                    </span>
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </span>
                                                            </div>
                                                            <label style={{ textAlign: 'end', fontSize: '13px' }} className="text-secondary break-all w-100">{this.props.currentUser.public_address || ''}</label>
                                                        </div>
                                                        <div className="mb-2 text-center">
                                                            <i onClick={this.onChangeTargets.bind(this, 'to', this.state.data.to === 'NUC' ? 'NST' : 'NUC')} style={{ cursor: 'pointer' }} className="fas fa-exchange-alt Rotate_180"></i>
                                                        </div>
                                                        <div className="mb-3">
                                                            <div className="d-sm-flex justify-content-between">
                                                                <label
                                                                    className="form-label CeraMed d-block mb-0">To</label>
                                                                {this.state.balances[to] !== null &&
                                                                <label className="form-label d-block mb-0">Available Balance: <span className="CeraMed ColorWhite"> {this.state.balances[to]}</span></label>
                                                                }
                                                            </div>
                                                            <div className="input-group mb-0 FullOnMob d-block d-sm-flex">
                                                                <input readOnly onChange={this.oc} value={fromValue}
                                                                       name="toValue" type="text"
                                                                       className="form-control shadow-none"
                                                                       placeholder="Receiving will show here"/>
                                                                <span className="input-group-text">
                                                            <div className="dropdown bootstrap-select">
                                                            <select className="SelectPicker" tabIndex="null">
                                                                <option
                                                                    data-content="<img className='SelectPickerImg me-2 mb-1' src='images/icon-eth.png'><span className='text-dark'>ETH</span>"> Chrome</option>
                                                                <option
                                                                    data-content="<img className='SelectPickerImg me-2 mb-1' src='images/icon-dai.png'><span className='text-dark'>DAI</span>"> Chrome</option>
                                                                <option
                                                                    data-content="<img className='SelectPickerImg me-2 mb-1' src='images/icon-usdc.png'><span className='text-dark'>USDC</span>"> Chrome</option>
                                                            </select>
                                                            <button type="button" tabIndex="-1"
                                                                    className="btn dropdown-toggle btn-light"
                                                                    data-bs-toggle="dropdown" role="combobox"
                                                                    aria-owns="bs-select-1"
                                                                    aria-haspopup="listbox" aria-expanded="false"
                                                                    title={to}>
                                                                <div className="filter-option">
                                                                    <div className="filter-option-inner">
                                                                        <div className="filter-option-inner-inner">
                                                                            <img className="SelectPickerImg me-2 mb-1"
                                                                                 src={`/images/icon-${to}.png`}/>
                                                                            <span className="text-dark">{to}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </button>
                                                                <div className="dropdown-menu">
                                                                    <div className="inner show" role="listbox"
                                                                         id="bs-select-1"
                                                                         tabIndex="-1"
                                                                         aria-activedescendant="bs-select-1-0">
                                                                        <ul className="dropdown-menu inner show"
                                                                            role="presentation"
                                                                            style={{
                                                                                marginTop: '0px',
                                                                                marginBottom: '0px'
                                                                            }}>
                                                                            <li onClick={this.onChangeTargets.bind(this, 'to', 'NUC')}
                                                                                className={to === 'NUC' ? "selected active" : ''}>
                                                                                <a role="option"
                                                                                   className="dropdown-item"
                                                                                   id="bs-select-1-0" tabIndex="0"
                                                                                   aria-setsize="3"
                                                                                   aria-posinset="1"
                                                                                   aria-selected="true">
                                                                                    <span className="text"><img
                                                                                        className="SelectPickerImg me-2 mb-1"
                                                                                        src={`/images/icon-NUC.png`}/>
                                                                                        <span>NUC</span>
                                                                                    </span>
                                                                                </a>
                                                                            </li>
                                                                            <li onClick={this.onChangeTargets.bind(this, 'to', 'NST')}
                                                                                className={to === 'NST' ? "selected active" : ''}>
                                                                                <a role="option"
                                                                                   className="dropdown-item"
                                                                                   id="bs-select-1-1" tabIndex="0"
                                                                                   aria-setsize="3"
                                                                                   aria-posinset="2">
                                                                                    <span className="text"><img
                                                                                        className="SelectPickerImg me-2 mb-1"
                                                                                        src="images/icon-NST.png"/>
                                                                                        {/* <label >NST</label> */}
                                                                                        <span>NST</span>
                                                                                    </span>
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </span>
                                                            </div>
                                                            <label style={{ textAlign: 'end', fontSize: '13px' }} className="text-secondary w-100 break-all">{this.props.currentUser.public_address || ''}</label>
                                                        </div>
                                                        <div className="mb-3">
                                                            {
                                                                showMnemonicsInput && (
                                                                    <input style={{ borderColor: !mnemonicValidate(this.state.data.mnemonics) && this.state.data.mnemonics !== null ? 'red' : '' }} onChange={this.oc} name="mnemonics" type="text"
                                                                        className="form-control shadow-none"
                                                                        placeholder={`Paste ${this.state.data.from} account mnemonics here...`}/>
                                                                )
                                                            }
                                                        </div>
                                                        {
                                                            isError && message && (
                                                                <div className="alert alert-danger" role="alert">
                                                                    {message}
                                                                </div>
                                                            )
                                                        }
                                                        {/* {
                                                            !isError && message && (
                                                                <div className="alert alert-success" role="alert">
                                                                    {message}
                                                                </div>
                                                            )
                                                        } */}
                                                        {
                                                            !isError && !message  && <button disabled={this.state.isLoading} onClick={this.submit.bind(this)}
                                                                                            type="button" className="btn BtnBlack w-100 py-2">
                                                                {showMnemonicsInput ? 'Swap' : 'Next'} {this.state.isLoading &&
                                                            <i className="fas fa-asterisk fa-spin"></i>}
                                                            </button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Conversion />
                                        <div className="tab-pane fade" id="Swap-tab-panel-3" role="tabpanel" aria-labelledby="Swap-tab-3">
                                            <h2 className="mb-3">History</h2>
                                            <div className="bg-white p-5">
                                                <p>This is some placeholder content the Settings tab's associated content.
                                                    Clicking
                                                    another tab will toggle the visibility of this one for the next. The tab
                                                    JavaScript swaps classes to control the content visibility and styling. You
                                                    can
                                                    use it with tabs, pills, and any other .nav-powered navigation.</p>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="Swap-tab-panel-4" role="tabpanel" aria-labelledby="Swap-tab-4">
                                            <h2 className="mb-3">Burning NFT</h2>
                                            <div className="bg-white p-5">
                                                <div className="row justify-content-center">
                                                    <div className="col-md-10 col-lg-6 col-xl-6 col-xxl-4">
                                                        <div className="mb-3">
                                                            <label className="form-label CeraMed d-block mb-0">Label</label>
                                                            <div className="input-group mb-0 FullOnMob d-block d-sm-flex">
                                                                <input type="text" className="form-control shadow-none"
                                                                       placeholder="Please enter 0.0004-50"/>
                                                                <span className="input-group-text">
                                                                    <select className="InitializeSelectPicker">
                                                                      <option
                                                                          data-content="<img class='SelectPickerImg me-2 mb-1' src='images/icon-eth.png'><span class='text-dark'>ETH</span>"> Chrome</option>
                                                                      <option
                                                                          data-content="<img class='SelectPickerImg me-2 mb-1' src='images/icon-dai.png'><span class='text-dark'>DAI</span>"> Chrome</option>
                                                                      <option
                                                                          data-content="<img class='SelectPickerImg me-2 mb-1' src='images/icon-usdc.png'><span class='text-dark'>USDC</span>"> Chrome</option>
                                                                    </select>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <button type="button" className="btn BtnBlack w-100 py-2">
                                                            Burn
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    { document.getElementById('Swap-tab-2') && document.getElementById('Swap-tab-2').className.indexOf('active') == -1 && <MessageBox key="swap-message-bar" title={this.state.isError ? 'Warning!' : 'Success'} message={this.state.message} isError={this.state.isError} callback={() => {
                            this.setState({
                                ...this.state,
                                data: {
                                    ...this.state.data,
                                    fromValue: '',
                                    toValue: '',
                                    mnemonics: null
                                },
                                showMnemonicsInput: false,
                                isError: false,
                                message: null,
                                isLoading: false
                            });
                            this.componentDidMount();
                        }} style={{ maxWidth: '45%' }} /> 
                    }
                </section>
            </main>
        )
    }
}

const mapStateToProps = state => ({
    token: state.Auth.token,
    currentUser:    state.Profile.profile
})

export default connect(mapStateToProps, {
    setNUCBalance,
    setNSTBalance
})(Swap);