import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import {mintMultiNftApi, registerMultiNFT, registerNFT} from '../../../api/nft/nft';
import {connect} from 'react-redux';
import {mnemonicValidate} from '@polkadot/util-crypto';
import {Keyring} from '@polkadot/api';
import CryptoJS from 'crypto-js';
import ContinueWithPassword from '../../Layouts/ContinueWithPassword/ContinueWithPassword';
import {setCypherSecret} from '../../../actions/auth';
import NotLoggedInRedirection from '../../Auth/NotLoggedInRedirection/NotLoggedInRedirection';
import MissingMnemonics from '../../Layouts/MissingMnemonics/MissingMnemonics';
import KycStatus from '../../Layouts/KycStatus/KycStatus';
import PageLoader from '../../Layouts/PageLoader/PageLoader';
import PermissionRequired from '../../Layouts/PermissionRequired/PermissionRequired';
import { isVideo } from '../../../utils/utils';

const INITIAL_STATE = {
    data: {
        image: null,
        title: '',
        description: '',
        minBid: '',
        startTime: null,
        endTime: null,
        marketType: 'FIXED PRICE',
        isAvailableForMarket: true,
        unlockOncePurchased: false,
        properties: {
            size: null,
            type: null
        },
        batch: [{id: 0, royalties: 10, noOfCopies: 1, price: null}],
        expirySalesDate: null,
        uploadType: null,
        radeemLink: null,
        alternativeText: null,
        isBusinessMultiple: true,
    },
    imgSrc: null,
    showAdvance: false,
    mnemonics: null,
    finalBatchList: [],
    isError: false,
    message: null,
    isLoading: false,
    public_address: null,
    saveMyMnemonics: false,
    mnemonicsPwd: ''
}

class BusinessMultipleNFT extends Component {

    state = {
        ...INITIAL_STATE
    }

    componentDidMount() {
        if(localStorage.getItem('theme') === 'dark') {
            $(".modal").addClass("DarkModal");
            $(".dropdown-menu").addClass("dropdown-menu-dark");
        }
    }

    onUploadFile = e => {
        const file = e.target.files[0];

        const reader = new FileReader();
        const url = reader.readAsDataURL(file);

        if ((file.size / 1024) / 1024 > 100) {
            document.getElementById('launch-modal-btn').click();
            return;
        }

        reader.onloadend = function (e) {
            this.setState({
                ...this.state,
                imgSrc: reader.result,
                data: {...this.state.data, image: file, uploadType: file.type}
            })
        }.bind(this);
    }

    oc = e => {
        if (e.target.name === 'isAvailableForMarket') this.setState({
            ...this.state,
            data: {...this.state.data, [e.target.name]: !this.state.data.isAvailableForMarket}
        });
        else if (e.target.name === 'unlockOncePurchased') this.setState({
            ...this.state,
            data: {...this.state.data, [e.target.name]: !this.state.data.unlockOncePurchased}
        });
        else if (e.target.name === 'showAdvance') this.setState({
            ...this.state,
            [e.target.name]: !this.state.showAdvance
        });
        else if (e.target.name === 'startTime' || e.target.name === 'endTime') this.setState({
            ...this.state,
            data: {...this.state.data, [e.target.name]: (new Date(e.target.value)).getTime()}
        });
        else this.setState({...this.state, data: {...this.state.data, [e.target.name]: e.target.value}});
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.toggle.toggle_create_nft !== this.props.toggle.toggle_create_nft) {
            setTimeout(() => {
                if(localStorage.getItem('theme') === 'dark') {
                    $(".modal").addClass("DarkModal");
                    $(".dropdown-menu").addClass("dropdown-menu-dark");
                }
            }, 100)
        }
    }

    onChangeMnemonics = e => {
        e.preventDefault();

        if (mnemonicValidate(e.target.value)) {
            const keyring = new Keyring({
                type: 'sr25519',
                ss58Format: 42,
            });
            const newPair = keyring.addFromUri(e.target.value);
            this.setState({...this.state, mnemonics: e.target.value, public_address: newPair.address});
        } else this.setState({...this.state, mnemonics: e.target.value, public_address: null});
    }

    async submit(e) {
        e.preventDefault();

        if(this.props.toggle.toggle_kyc !== undefined && this.props.toggle.toggle_kyc == 1 && this.props.currentUser.kycStatus === 0) {
            return document.getElementById('kycStatusDialogBtn').click();
        } else if(!this.props.currentUser.public_address) {
            return document.getElementById('confirmationMissingMnemonicsBtn').click();
        }

        const {
            title,
            description,
            image,
            marketType,
            minBid,
            startTime,
            endTime,
            batch,
            expirySalesDate
        } = this.state.data;

        if (title === null || title === '')
            return this.setState({...this.state, isError: true, message: 'Title field is required!'});
        else if (description === null || description === '')
            return this.setState({...this.state, isError: true, message: 'Description field is required!'});
        else if (image === null)
            return this.setState({...this.state, isError: true, message: 'Media file is required!'});
        // else if (expirySalesDate === null && this.state.data.isAvailableForMarket)
        //     return this.setState({...this.state, isError: true, message: 'Sales expire date is required!'});
        // else if (new Date(expirySalesDate).getTime() <= new Date().getTime() && this.state.data.isAvailableForMarket)
        //     return this.setState({
        //         ...this.state,
        //         isError: true,
        //         message: 'Sales expire date must be greater then current date or time!'
        //     });

        const finalBatchList = [];

        for (let {royalties, noOfCopies, price} of batch) {
            switch (marketType) {
                case 'FIXED PRICE': {
                    try {
                        const _price = parseFloat(price);

                        if (Number.isNaN(_price) || _price < 0) {
                            return this.setState({
                                ...this.state,
                                isError: true,
                                message: 'Price must be a positive number.'
                            });
                        }
                    } catch (e) {
                        return this.setState({
                            ...this.state,
                            isError: true,
                            message: 'Price must be a positive number.'
                        });
                    }
                    ;
                    break;
                }
                case 'Timed Auction': {
                    try {
                        const _minBid = parseFloat(minBid);

                        if (Number.isNaN(_minBid) || _minBid < 0) {
                            return this.setState({
                                ...this.state,
                                isError: true,
                                message: 'Minimum Bid must be a positive number.'
                            });
                        }

                        if (startTime === null || endTime === null) {
                            return this.setState({
                                ...this.state,
                                isError: true,
                                message: 'Auction start and end time must be provided.'
                            });
                        }

                        if (startTime > endTime) {
                            return this.setState({
                                ...this.state,
                                isError: true,
                                message: 'Auction start time cannot be greater than end time.'
                            });
                        }

                        if(new Date(startTime).getTime() < Date.now() && `${new Date(startTime).getDate()}-${new Date(startTime).getMonth()+1}-${new Date(startTime).getFullYear()}` !== `${new Date().getDate()}-${new Date().getMonth()+1}-${new Date().getFullYear()}`) {
                            return this.setState({ ...this.state, isError: true, message: 'Auction start time must be greater or equal to current date/time.' });
                        }
    
                        if(new Date(endTime).getTime() <= Date.now()) {
                            return this.setState({ ...this.state, isError: true, message: 'Auction end time must be greater then current date/time.' });
                        }
                    } catch (e) {
                        return this.setState({
                            ...this.state,
                            isError: true,
                            message: 'Minimum Bid must be a positive number.'
                        });
                    }
                    ;
                    break;
                }
            }

            try {
                const _royalities = parseInt(royalties);
                if (Number.isNaN(_royalities)) {
                    return this.setState({...this.state, isError: true, message: 'Royalities must a number!'});
                }
                if (_royalities < 0 || _royalities > 50) {
                    return this.setState({
                        ...this.state,
                        isError: true,
                        message: 'Royalities are must be 0%, 10%, 20%, 30%. Maximum is 50%'
                    });
                }
            } catch (e) {
                return this.setState({...this.state, isError: true, message: 'Royalities must a number!'});
            }

            try {
                const _noOfCopies = parseInt(noOfCopies);
                if (Number.isNaN(_noOfCopies)) {
                    return this.setState({
                        ...this.state,
                        isError: true,
                        message: 'No. of Copies must a positive integer!'
                    });
                }
                if (_noOfCopies < 0) {
                    return this.setState({
                        ...this.state,
                        isError: true,
                        message: 'No. of Copies must be a positive integer!'
                    });
                }
                if(_noOfCopies > 30) {
                    return this.setState({ ...this.state, isError: true, message: 'No. of Copies must not be greater then 30!' });
                }
            } catch (e) {
                return this.setState({...this.state, isError: true, message: 'No. of Copies must a positive integer!'});
            }

            finalBatchList.push({
                royalties: parseFloat(royalties),
                noOfCopies: parseInt(noOfCopies),
                price: parseFloat(price)
            });
        }

        this.setState({...this.state, finalBatchList: finalBatchList, isError: false, message: null});

        document.getElementById('confirmationModalBtn').click();
    }

    processAPI = async () => {

        if (this.state.saveMyMnemonics) {
            const cypher = CryptoJS.AES.encrypt(this.state.mnemonics, this.state.mnemonicsPwd).toString();
            this.props.setCypherSecret(cypher);
        }

        this.setState({...this.state, isLoading: true});

        document.getElementById('confirmationModalBtn').click();

        const dataBody = this.state.data;

        dataBody.batch = this.state.finalBatchList;

        const formData = new FormData();
        formData.append('image', this.state.data.image);
        formData.append('data', JSON.stringify(dataBody));

        try {
            const {data: {payload_hash, scheme_id}} = await registerMultiNFT(formData, this.props.token);

            this.setState({
                ...this.state,
                isLoading: true,
                message: `NFTs Registered, Requesting for minting process...`
            });

            await mintMultiNftApi({secret: this.state.mnemonics, scheme_id: scheme_id}, this.props.token);

            this.setState({
                ...this.state,
                ...INITIAL_STATE,
                isLoading: false,
                message: `We are processing NFT's in background you can check the status from 'My Items / Pending' section. <br/>Thanks`
            });
        } catch (e) {
            this.setState({
                ...this.state,
                isLoading: false,
                isError: true,
                message: e.response && e.response.data ? e.response.data : e.message
            });
        }
    }

    infoModal = () => {
        return (
            <React.Fragment>
                <button style={{display: 'none'}} id="launch-modal-btn" type="button" className="btn btn-primary"
                        data-bs-toggle="modal" data-bs-target="#exampleModal">
                    Launch demo modal
                </button>
                <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">File Size</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                File size must be equal or less then 100mb.
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    addNewBatch = e => {
        e.preventDefault();
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                batch: [...this.state.data.batch, {
                    id: this.state.data.batch.length,
                    royalties: 10,
                    noOfCopies: 1,
                    price: null
                }]
            }
        });
    }

    removeBatch = (index) => {
        const batch = this.state.data.batch.filter((_, _index) => _index !== index);
        this.setState({...this.state, data: {...this.state.data, batch: batch}});

    }

    onChangeBatch = (key, value, index) => {
        const batch = this.state.data.batch;

        batch[index][key] = value;
        this.setState({...this.state, data: {...this.state.data, batch: batch}});
    }

    confirmationModal = () => (
        <React.Fragment>
            <button style={{display: 'none'}} id="confirmationModalBtn" type="button" className="btn btn-primary"
                    data-bs-toggle="modal" data-bs-target="#confirmationModal">
                Launch demo modal
            </button>
            <div className="modal fade" id="confirmationModal" tabIndex="-1" aria-labelledby="confirmationModalLabel"
                 aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className={this.props.cypherSecret === null ? "modal-content" : "modal-content d-none"}>
                        <div className="modal-header align-items-start">
                            <h5 className="modal-title" id="confirmationModalLabel">Please enter your account mnemonics
                                to confirm transaction</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3">
                                <label className="form-label CeraMed d-block mb-1">Account Mnemonics</label>
                                <textarea
                                    style={{borderColor: !this.state.mnemonics ? '' : mnemonicValidate(this.state.mnemonics) && this.state.public_address === this.props.currentUser.public_address ? 'green' : 'red'}}
                                    onChange={this.onChangeMnemonics} name="mnemonics" rows="4"
                                    className="form-control shadow-none"
                                    placeholder="Paste your mnemonics here"></textarea>
                                <p className="form-text mb-0">We are not storing your private key, this can be use one
                                    time for this transaction.</p>
                            </div>
                            <React.Fragment>
                                {
                                    this.state.public_address && this.state.public_address === this.props.currentUser.public_address && (
                                        <React.Fragment>
                                            <strong className={'text-success d-block'}>Looks Good!</strong>
                                            Your public address matched <strong className={'break-all d-block'}>{this.state.public_address}</strong>
                                        </React.Fragment>
                                    )
                                }
                                {
                                    this.state.public_address && this.state.public_address !== this.props.currentUser.public_address && (
                                        <React.Fragment>
                                            <strong className={'text-danger d-block'}> Something Wrong! </strong> Your profile address is not matching with the provided
                                            Mnemonics, Please add the mnemonics which you give us on sign up.
                                        </React.Fragment>
                                    )
                                }
                            </React.Fragment>
                            <div className="form-check pt-3">
                                <input className="form-check-input shadow-none" type="checkbox" value="" id="EncryptCheckBoxBusiness" checked={this.state.saveMyMnemonics} onClick={() => this.setState({ ...this.state, saveMyMnemonics: !this.state.saveMyMnemonics })}/>
                                <label className="form-check-label" htmlFor="EncryptCheckBoxBusiness">
                                    Encrypt and save my mnemonics on browser for future transactions!
                                </label>
                            </div>
                            <div className="row">
                                {
                                    this.state.saveMyMnemonics && (
                                        <input
                                            onChange={e => this.setState({...this.state, mnemonicsPwd: e.target.value})}
                                            className="form-control BorderDarkGrey shadow-none mt-4" type="password"
                                            name="password" placeholder="Enter Password"/>
                                    )
                                }
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn BtnBorderBlack py-2 px-5"
                                    data-bs-dismiss="modal">Close
                            </button>
                            <button
                                disabled={!mnemonicValidate(this.state.mnemonics) || this.state.public_address !== this.props.currentUser.public_address || (this.state.saveMyMnemonics ? this.state.mnemonicsPwd.length < 6 : false)}
                                onClick={this.processAPI.bind(this)} type="button"
                                className="btn BtnBlack py-2 px-5">Confirm
                            </button>
                        </div>
                    </div>
                    <div className={this.props.cypherSecret !== null ? "modal-content" : "modal-content d-none"}>
                        <div className="modal-body">
                            <ContinueWithPassword callback={(mnemonics) => {
                                this.setState({...this.state, mnemonics, mnemonics}, () => {
                                    this.processAPI();
                                });
                            }}/>
                        </div>
                    </div>
                </div>
            </div>

            {/*<div className="modal fade" id="confirmationModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div style={{ maxWidth: '800px' }}  className="modal-dialog">
            <div className="modal-content">
            <main className="py-4">
                <div className="container">
                    <h3 className="mb-4 text-center">Please enter your account mnemonics to confirm transaction</h3>
                    <div className="mb-3">
                        <label className="form-label CeraMed d-block mb-1">Account Mnemonics</label>
                        <textarea  style={{ borderColor: !this.state.mnemonics ? '' : mnemonicValidate(this.state.mnemonics) ? 'green' : 'red' }} onChange={this.onChangeMnemonics} name="mnemonics" rows="4" className="form-control shadow-none"
                                placeholder="Paste your mnemonics here"></textarea>
                        <p className="form-text mb-0">We are not storing your private key, this can be use one time for this transaction.</p>
                    </div>
                    <React.Fragment>
                        {
                            this.state.public_address && this.state.public_address === this.props.currentUser.public_address && (
                                <React.Fragment>
                                    <strong style={{ color: 'green', fontSize: '14px' }}>Looks Good!</strong> Your public address matched <strong>{this.state.public_address}</strong>
                                </React.Fragment>
                            )
                        }
                        {
                            this.state.public_address && this.state.public_address !== this.props.currentUser.public_address && (
                                <React.Fragment>
                                    {this.state.public_address}
                                    <strong style={{ color: 'red', fontSize: '14px' }}>Something Wrong!</strong> Your profile address is not matching with the provided Mnemonics, Please add the mnemonics which you give us on sign up.
                                </React.Fragment>
                            )
                        }
                    </React.Fragment>
                    <div className="col-md-auto mb-3 mb-md-0">
                        <button style={{ width: '100%', marginTop: '20px' }} disabled={!mnemonicValidate(this.state.mnemonics)} onClick={this.processAPI.bind(this)} type="button" className="btn BtnBlack py-2 px-5">Confirm</button>
                    </div>
                </div>
                </main>
            </div>
            </div>
        </div>*/}
        </React.Fragment>
    )

    render() {
        const {isAvailableForMarket, unlockOncePurchased, marketType, title, description, batch} = this.state.data;

        if(this.props.toggle.toggle_create_nft === undefined) return <PageLoader />
        if(!this.props.toggle.toggle_create_nft) return <PermissionRequired />

        return (
            <main>
                <section id="CreateCollectible" className={'py-5'}>
                    <div className="container">
                        <div className="row justify-content-center gx-3" id='zIndexMob'>
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-9 col-xxl-9 Fsize_16">
                                <Link to="/create" className="Fsize_16">
                                    <svg className="mb-1 me-2" viewBox="0 0 14 12" fill="none" width="14" height="14"
                                         xlmns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                              d="M6.29436 0.292893C6.68488 -0.0976311 7.31805 -0.0976311 7.70857 0.292893C8.0991 0.683417 8.0991 1.31658 7.70857 1.70711L4.41568 5H12.9985C13.5508 5 13.9985 5.44772 13.9985 6C13.9985 6.55228 13.5508 7 12.9985 7H4.41568L7.70857 10.2929C8.0991 10.6834 8.0991 11.3166 7.70857 11.7071C7.31805 12.0976 6.68488 12.0976 6.29436 11.7071L0.587252 6L6.29436 0.292893Z"
                                              fill="currentColor"></path>
                                    </svg>
                                    Manage collectible type
                                </Link>
                                <h2 className="mb-4 pt-2">Create business multiple collectible</h2>
                                <div className="row mb-4">
                                    <div className="col-lg-8 mb-3 mb-sm-0">
                                        <div className="mb-3">
                                            <label className="form-label CeraMed">Upload file</label>
                                            <div className="UploadFileAreaSmall Height GreyColor rounded mb-4">
                                                <div id="fileuploader">
                                                    <div className="ajax-upload-dragdrop"
                                                         style={{verticalAlign: 'top', width: '100%'}}>
                                                        <div className="ajax-file-upload" style={{
                                                            position: 'relative',
                                                            overflow: 'hidden',
                                                            cursor: 'default'
                                                        }}>
                                                            <p className="mb-2 CeraBold">PNG, GIF, WEBP, MP4 or MP3. Max
                                                                100mb.</p>
                                                            <form style={{margin: '0px', padding: '0px'}}>
                                                                <input onChange={this.onUploadFile} type="file"
                                                                       accept="*" style={{
                                                                    position: 'absolute',
                                                                    cursor: 'pointer',
                                                                    top: '0px',
                                                                    width: '100%',
                                                                    height: '100%',
                                                                    left: '0px',
                                                                    zIndex: '100',
                                                                    opacity: '0'
                                                                }}/>
                                                            </form>
                                                        </div>
                                                        <button type="button"
                                                                className="mb-0 btn BtnBlack rounded-pill">Choose File
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="d-flex justify-content-between mb-3">
                                                <div className="">
                                                    <label className="form-label CeraMed d-block mb-0">Put on
                                                        marketplace</label>
                                                    <small>Enter price to allow users instantly purchase your
                                                        NFT</small>
                                                </div>
                                                <div className="form-check form-switch">
                                                    <input onChange={this.oc} name="isAvailableForMarket"
                                                           className="form-check-input shadow-none cursor-pointer"
                                                           type="checkbox"
                                                           id="putOnMarket" checked={isAvailableForMarket}/>
                                                </div>
                                            </div>
                                            {
                                                isAvailableForMarket && (
                                                    <React.Fragment>
                                                        <div className="row gx-3 mb-4">

                                                            <div className="col-sm-12 mb-3 mb-sm-0">
                                                                <div onClick={e => {
                                                                    e.target.name = 'marketType';
                                                                    e.target.value = 'FIXED PRICE';
                                                                    this.oc(e);
                                                                }}
                                                                     className={`rounded border border-grey-color text-center Createcard ${marketType === 'FIXED PRICE' ? 'active' : ''} py-4 px-4`}>
                                                                    <svg className="mb-3 GreySvgFill" width="41"
                                                                         height="40" viewBox="0 0 41 40" fill="none"
                                                                         xmlns="http://www.w3.org/2000/svg">
                                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                                              d="M9.82263 10C9.82263 9.72386 10.0465 9.5 10.3226 9.5H18.4033C18.5359 9.5 18.6631 9.55268 18.7568 9.64645L29.8478 20.7374C30.043 20.9326 30.043 21.2492 29.8478 21.4445L21.7671 29.5251C21.5718 29.7204 21.2552 29.7204 21.06 29.5251L9.96908 18.4342C9.87531 18.3404 9.82263 18.2133 9.82263 18.0807V10ZM10.3226 6.5C8.38964 6.5 6.82263 8.067 6.82263 10V18.0807C6.82263 19.0089 7.19138 19.8992 7.84776 20.5555L18.9387 31.6464C20.3055 33.0133 22.5216 33.0133 23.8884 31.6464L31.9691 23.5658C33.3359 22.1989 33.3359 19.9829 31.9691 18.616L20.8782 7.52512C20.2218 6.86875 19.3316 6.5 18.4033 6.5H10.3226ZM14.8226 16C15.6511 16 16.3226 15.3284 16.3226 14.5C16.3226 13.6716 15.6511 13 14.8226 13C13.9942 13 13.3226 13.6716 13.3226 14.5C13.3226 15.3284 13.9942 16 14.8226 16Z"
                                                                              fill="black"/>
                                                                    </svg>
                                                                    <strong className="d-block CeraBold LineHeight-1">Fixed
                                                                        price</strong>
                                                                </div>
                                                            </div>
                                                            {/* <div className="col-sm-4 mb-3 mb-sm-0">
                                                    <div onClick={e => { e.target.name = 'marketType'; e.target.value = 'Timed Auction'; this.oc(e); }} className={`rounded border border-grey-color text-center Createcard ${marketType === 'Timed Auction' ? 'active' : ''} py-4 px-4`}>
                                                        <svg className="mb-3" width="41" height="40" viewBox="0 0 41 40" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                                d="M20.3226 9.5C14.5236 9.5 9.82263 14.201 9.82263 20C9.82263 25.799 14.5236 30.5 20.3226 30.5C26.1216 30.5 30.8226 25.799 30.8226 20C30.8226 14.201 26.1216 9.5 20.3226 9.5ZM6.82263 20C6.82263 12.5442 12.8668 6.5 20.3226 6.5C27.7785 6.5 33.8226 12.5442 33.8226 20C33.8226 27.4558 27.7785 33.5 20.3226 33.5C12.8668 33.5 6.82263 27.4558 6.82263 20Z"
                                                                fill="black"/>
                                                            <path opacity="0.4"
                                                                d="M12.3226 20C12.3226 15.5817 15.9044 12 20.3226 12V20H12.3226Z"
                                                                fill="black"/>
                                                        </svg>
                                                        <strong className="d-block CeraBold LineHeight-1">Timed auction</strong>
                                                    </div>
                                                </div> */}
                                                            {/* <div className="col-sm-6">
                                                    <div onClick={e => { e.target.name = 'marketType'; e.target.value = 'Open For Bids'; this.oc(e); }} className={`rounded border border-grey-color text-center ${marketType === 'Open For Bids' ? 'active' : ''} Createcard py-4 px-4`}>
                                                        <svg className="mb-3" width="41" height="40" viewBox="0 0 41 40" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                                d="M33.8433 14.6655C31.0225 12.0107 26.6226 12.0107 23.8018 14.6655L20.3226 17.9401L16.8433 14.6655C14.0225 12.0107 9.62258 12.0107 6.80186 14.6655C3.72867 17.5579 3.72867 22.4421 6.80186 25.3345C9.62258 27.9893 14.0226 27.9893 16.8433 25.3345L20.3226 22.0599L23.8018 25.3345C26.6226 27.9893 31.0225 27.9893 33.8433 25.3345C36.9164 22.4421 36.9164 17.5579 33.8433 14.6655ZM18.1339 20L18.1339 20L14.7872 16.8501C13.1216 15.2825 10.5235 15.2825 8.85795 16.8501C7.0433 18.558 7.0433 21.442 8.85795 23.1499C10.5235 24.7175 13.1216 24.7175 14.7872 23.1499L18.1339 20ZM22.5112 20L25.8579 23.1499C27.5235 24.7175 30.1216 24.7175 31.7872 23.1499C33.6018 21.442 33.6018 18.558 31.7872 16.8501C30.1216 15.2825 27.5235 15.2825 25.8579 16.8501L22.5112 20L22.5112 20Z"
                                                                fill="black"/>
                                                        </svg>
                                                        <strong className="d-block CeraBold LineHeight-1">Open for bids</strong>
                                                    </div>
                                                </div> */}
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            }
                                        </div>
                                        <div class="mb-3">
                                            <div className="d-flex justify-content-between mb-2">
                                                <div className="">
                                                    <label className="form-label CeraMed d-block mb-0">Unlock once
                                                        purchased</label>
                                                    <small>Content will be unlocked after successful transaction</small>
                                                </div>
                                                <div className="form-check form-switch">
                                                    <input onChange={this.oc} name="unlockOncePurchased"
                                                           className="form-check-input shadow-none cursor-pointer"
                                                           type="checkbox" checked={unlockOncePurchased}/>
                                                </div>
                                            </div>
                                            {
                                                unlockOncePurchased &&
                                                <input onChange={this.oc} name="radeemLink" type="text"
                                                       className="form-control shadow-none"
                                                       placeholder="Digital key, code to redeem or link to a file..."/>
                                            }
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label CeraMed d-block mb-1">Title</label>
                                            <input onChange={this.oc} value={title} name="title" type="text"
                                                   className="form-control shadow-none mb-3"
                                                   placeholder="e.g Redeemable T-shirt with logo"/>
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label CeraMed d-block mb-1">Description</label>
                                            <textarea onChange={this.oc} value={description} name="description" rows="4"
                                                      className="form-control shadow-none"
                                                      placeholder="e.g. After purchasing you’ll be able to get the real T-Shirt"></textarea>
                                            <p className="form-text mb-0">With preserved line-breaks</p>
                                        </div>
                                        {
                                            marketType === 'FIXED PRICE' ? (
                                                <React.Fragment>
                                                    {
                                                        batch.map(({id, royalties, noOfCopies, price}, index) => (
                                                            <div key={id} className="mb-3">
                                                                <h3 className="CeraBold">Batch {index + 1}</h3>
                                                                <div className="row gx-2">
                                                                    <div className="col-xl-4">
                                                                        <label
                                                                            className="form-label CeraMed d-block mb-1">Royalties</label>
                                                                        <div className="input-group">
                                                                            <input
                                                                                onChange={e => this.onChangeBatch(e.target.name, e.target.value, index)}
                                                                                name="royalties" value={royalties}
                                                                                type="text"
                                                                                className="form-control shadow-none"/>
                                                                            <span className="input-group-text border-0"
                                                                                  id="basic-addon1">%</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-xl-4">
                                                                        <label
                                                                            className="form-label CeraMed d-block mb-1">No.
                                                                            of copies</label>
                                                                        <input
                                                                            onChange={e => this.onChangeBatch(e.target.name, e.target.value, index)}
                                                                            value={noOfCopies} name="noOfCopies" maxLength="2" max="30"
                                                                            type="text"
                                                                            className="form-control shadow-none"
                                                                            placeholder="E. g. 10"/>
                                                                        <p style={{ fontSize: '12px' }} className="form-text">Amount of tokens (Between 1 - 30)</p>

                                                                    </div>
                                                                    <div className="col-xl-4">
                                                                        <label
                                                                            className="form-label CeraMed d-block mb-1">Price
                                                                            per copy</label>
                                                                        <div className="mb-3 position-relative">
                                                                            <input
                                                                                onChange={e => this.onChangeBatch(e.target.name, e.target.value, index)}
                                                                                value={price} name="price" type="text"
                                                                                className="form-control shadow-none"
                                                                                placeholder="Enter price for one piece"/>
                                                                            {
                                                                                index !== 0 && (
                                                                                    <a onClick={this.removeBatch.bind(this, index)}
                                                                                       className="PlusMinus"
                                                                                       href="javascript:void(0);">
                                                                                        <img src="/images/minus.png"
                                                                                             className="img-fluid"/>
                                                                                    </a>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                    <div className="mb-3">
                                                        <button onClick={this.addNewBatch.bind(this)} name="showAdvance"
                                                                type="button" className="btn BtnBlack w-100 py-2"> + Add
                                                            New Batch
                                                        </button>
                                                    </div>
                                                </React.Fragment>

                                            ) : null
                                        }
                                        <div className="mb-3 position-relative d-none">
                                            <label className="form-label CeraMed d-block mb-1">NFT expiry sales
                                                date</label>
                                            <input onChange={this.oc} name="expirySalesDate" type="date"
                                                   className="form-control shadow-none mb-3 DateTimePicker"
                                                   placeholder="Select date"/>
                                        </div>
                                        <div className="mb-3">
                                            <button onClick={this.oc} name="showAdvance" type="button"
                                                    className="btn BtnBorder w-100 py-2">{!this.state.showAdvance ? 'Show Advanced Settings' : 'Hide Advanced Settings'}
                                            </button>
                                        </div>
                                        <div className="mb-3">
                                            {
                                                this.state.showAdvance && (
                                                    <React.Fragment>
                                                        <label
                                                            className="form-label CeraMed d-block mb-1">Properties <small
                                                            className="GreyColor">(Optional)</small></label>
                                                        <div className="row gx-2">
                                                            <div className="col-xl-6">
                                                                <input onChange={(e) => {
                                                                    this.setState({
                                                                        ...this.state,
                                                                        data: {
                                                                            ...this.state,
                                                                            properties: {
                                                                                ...this.state.data.properties,
                                                                                size: e.target.value
                                                                            }
                                                                        }
                                                                    })
                                                                }} name="size" type="text"
                                                                       className="form-control shadow-none mb-3"
                                                                       placeholder="e.g. Size"/>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <input onChange={(e) => {
                                                                    this.setState({
                                                                        ...this.state,
                                                                        data: {
                                                                            ...this.state,
                                                                            properties: {
                                                                                ...this.state.data.properties,
                                                                                type: e.target.value
                                                                            }
                                                                        }
                                                                    })
                                                                }} name="type" type="text"
                                                                       className="form-control shadow-none mb-3"
                                                                       placeholder="e.g. M"/>
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label CeraMed d-block mb-1">Alternative
                                                                text for
                                                                NFT<small
                                                                    className="GreyColor"> (Optional)</small></label>
                                                            <textarea onChange={this.oc} name="alternativeText" rows="4"
                                                                      className="form-control shadow-none"
                                                                      placeholder="Image description in details (do not start with word “image”)"></textarea>
                                                            <p className="form-text mb-0">Text that will be used in
                                                                VoiceOver for people
                                                                with disabilities</p>
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            }
                                            {
                                                this.state.isError && this.state.message && (
                                                    <div className="alert alert-danger" role="alert">
                                                        {this.state.message}
                                                    </div>
                                                )
                                            }
                                            {
                                                !this.state.isError && this.state.message && (
                                                    <div className="alert alert-success" role="alert">
                                                        {this.state.message}
                                                    </div>
                                                )
                                            }
                                            <div className="row justify-content-between align-items-center">
                                                <div className="col-md-auto mb-3 mb-md-0">
                                                    <button disabled={this.state.isLoading}
                                                            onClick={this.submit.bind(this)} type="button"
                                                            className="btn BtnBlack py-2 px-5">Create
                                                        item {this.state.isLoading &&
                                                        <i className="fas fa-asterisk fa-spin"></i>}</button>
                                                </div>
                                                <div className="col-md-auto">
                                                    <a href="javascript:void(0);" className="GreyColor"> </a>
                                                    <button type="button" className="btn bg-transparent border-0 p-0">
                                                        Unsaved changes
                                                        <i data-bs-toggle="tooltip" data-bs-placement="top"
                                                           title="Attach file or add title to save your changes"
                                                           className="fas fa-question-circle ms-2"></i>
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="PreviewBoxSticky">
                                            <label className="form-label CeraMed">Preview</label>
                                            <div
                                                className="PreviewBox text-center rounded p-3 CeraBold GreyColor d-flex justify-content-center align-items-center">
                                                {
                                                    !this.state.imgSrc ? 'Upload file to preview your brand new NFT' : !isVideo(this.state.data.uploadType) ? (
                                                        <img className={'w-100 h-auto rounded'} src={this.state.imgSrc}/>
                                                    ) : (
                                                        <video autoPlay className="w-100 rounded" loop muted>
                                                            <source src={this.state.imgSrc} type="video/mp4"/>
                                                        </video>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {this.infoModal()}
                {this.confirmationModal()}
                <NotLoggedInRedirection/>
                <KycStatus />
                <MissingMnemonics callback={(e) => this.submit(e)} />
            </main>
        )
    }
}

const mapStateToProps = state => ({
    token: state.Auth.token,
    currentUser: state.Profile.profile,
    cypherSecret: state.Auth.cypherSecret,
    toggle: state.Settings.toggle
})

export default connect(mapStateToProps, {setCypherSecret})(BusinessMultipleNFT);