import { SET_CYPHER_SECRET } from "../types/auth";

const INITIAL_STATE = {
    isLoggedIn: localStorage.getItem('token') != null,
    token: localStorage.getItem('token'),
    cypherSecret: localStorage.getItem('cypher-secret')
}

function authReducer(state = INITIAL_STATE, action) {
    switch(action.type) {
        case SET_CYPHER_SECRET: {
            return { ...state, cypherSecret: action.payload }
        }
        default: {
            return state;
        }
    }
}

export default authReducer;