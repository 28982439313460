import { combineReducers } from 'redux';
import authReducer from './auth';
import profileReducer from './profile';
import shareReducer from './share';
import walletReducer from './wallet';
import homeReducer from './home';
import settingsReducer from './settings';
import { notificationReducer } from 'notificationsdk';

const reducer = combineReducers({
    Auth        :   authReducer,
    Profile     :   profileReducer,
    ShareSocial :   shareReducer,
    Wallet      :   walletReducer,
    Home        :   homeReducer,
    Settings    :   settingsReducer,
    Notification: notificationReducer
});

export default reducer;