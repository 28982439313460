import axios from 'axios';

axios.interceptors.response.use(response => {
    return response;
 }, error => {
   if (error.response && error.response.status === 401) {
       localStorage.clear();
       window.location.href = "/";
   }
   throw error; 
});

export default function({ method, baseURL, route, data, headers = { Accept: 'application/json' },signal }) {
    console.log("NU:",route)
    return axios({
        method,
        url: `${baseURL}/${route}`,
        data,
        headers,
        signal
    })
}