import React from "react";
import { Link } from "react-router-dom";

export default ({ onUploadFile }) => (
    <main>
        <section id="CreateCollectible" className="py-5">
            <div className="container">
                <div className="row justify-content-center gx-3" id='zIndexMob'>
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-9 col-xxl-9 Fsize_16">
                        <Link className="Fsize_16 BlackColor" to="/create">
                            <svg className="mb-1 me-2" viewBox="0 0 14 12" fill="none" width="14" height="14"
                                xlmns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M6.29436 0.292893C6.68488 -0.0976311 7.31805 -0.0976311 7.70857 0.292893C8.0991 0.683417 8.0991 1.31658 7.70857 1.70711L4.41568 5H12.9985C13.5508 5 13.9985 5.44772 13.9985 6C13.9985 6.55228 13.5508 7 12.9985 7H4.41568L7.70857 10.2929C8.0991 10.6834 8.0991 11.3166 7.70857 11.7071C7.31805 12.0976 6.68488 12.0976 6.29436 11.7071L0.587252 6L6.29436 0.292893Z"
                                    fill="currentColor"></path>
                            </svg>
                            Manage collectible type
                        </Link>
                        <h2 className="mb-4 pt-3">Create bulk collectible</h2>
                        <div className="row mb-4">
                            <div className="col-sm-12 mb-3 mb-sm-0">
                            <div className="mb-3">
                                <label className="form-label CeraMed">Upload file</label> <label className="text-secondary">select upto 20</label>
                                <div className="UploadFileAreaSmall Height GreyColor rounded mb-4">
                                    <div id="fileuploader">
                                        <div className="ajax-upload-dragdrop"
                                                style={{verticalAlign: 'top', width: '100%'}}>
                                            <div className="ajax-file-upload" style={{
                                                position: 'relative',
                                                overflow: 'hidden',
                                                cursor: 'default'
                                            }}>
                                                <p className="mb-2 CeraBold">PNG, GIF, WEBP or MP4 Max
                                                    100mb.</p>
                                                <form style={{margin: '0px', padding: '0px'}}>
                                                    <input multiple="true" onChange={onUploadFile} type="file"
                                                            accept="image/png, image/gif, image/jpeg, video/mp4"
                                                            style={{
                                                                position: 'absolute',
                                                                cursor: 'pointer',
                                                                top: '0px',
                                                                width: '100%',
                                                                height: '100%',
                                                                left: '0px',
                                                                zIndex: '100',
                                                                opacity: '0'
                                                            }}/>
                                                </form>
                                            </div>
                                            <button type="button"
                                                    className="mb-0 btn BtnBlack rounded-pill">Choose File
                                            </button>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
)