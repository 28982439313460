import React, { useEffect, useState } from "react";
import { getPayPalPaymentStatus } from "../../../api/wallet/wallet";

export default ({ query }) => {

    let [ timer, setTimerValue ] = useState(70);
    
    const startTimer = () => {
        timer -= 1;
        setTimerValue(timer);
        if(timer > 1) setTimeout(startTimer, 1300); else {
            window.location.href = window.location.pathname;
        }
    }
    
    useEffect(() => {
        if(query.payment_paypal === 'success') {
            document.getElementById('paypalPaymentSuccessBtn').click();
            startTimer();
        }

    }, [ query.payment_paypal ]);
    
    // useEffect(async () => {
    //     if(!query.paymentId) {
    //         getPayPalPaymentStatus(query.paymentId);
    //     }
    // }, [ query.paymentId ]);

    return (
        <React.Fragment>
        <button style={{ display: 'none' }} id="paypalPaymentSuccessBtn" type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#paypalPaymentSuccess">
            Launch demo modal
        </button>

        <div className="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" id="paypalPaymentSuccess" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-md">
                <div className="modal-content p-4">
                    <div className="d-flex align-items-center">
                        <i className="fas fa-check-circle text-success Fsize_24 me-4"></i>
                        <div>
                            <h4>Payment Successfull!</h4>
                            <p className={'mb-0'}>Please wait we are redirecting in {timer}s...</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
    )
}