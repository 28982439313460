import React, { Component } from 'react';
import SkeletonLoading from '../Layouts/SkeletonLoading/SkeletonLoading';
import { searchByKeywords } from '../../api/search/search';
import NFTBar from '../Layouts/SearchResultTile/NFTBar';
import UserBar from '../Layouts/SearchResultTile/UserBar';

class SearchResult extends Component {

    state = {
        filter: 0,
        search: '',
        results: {
            tokens: [],
            people: [],
            others: [],
        },
        isSearching: true
    }

    componentDidMount() {
        this.searchKeywordsApi(this.props.location.search);
    }

    componentDidUpdate(props) {
        if (props.location.search !== this.props.location.search) {
            this.searchKeywordsApi(this.props.location.search);
        }
    }

    searchKeywordsApi(keyword) {
        searchByKeywords(keyword).then(({ data }) => {
            this.setState({ ...this.state, search: keyword, results: data, isSearching: false });
        }).catch(e => { this.setState({ ...this.state, isSearching: false }) });
    }

    filterDisplay(param) {
        if (this.state.filter === 0) return '';
        if (param !== this.state.filter) return 'd-none';
    }

    render() {

        const { tokens, people, others } = this.state.results;
        const { isSearching, filter, search } = this.state;

        return (
            <main className="MinHeight">
                <section id="SearchResult">
                    <div className="container">
                        <div id="filters" className="text-center d-md-flex justify-content-center align-items-center pb-4 pt-5">
                            <button className={`btn BtnBorder rounded shadow-none px-3 me-2 mb-2 mb-sm-0 ${filter === 0 && 'active'}`} onClick={() => this.setState({ ...this.state, filter: 0 })} data-filter="*">All </button>
                            <button className={`btn BtnBorder rounded shadow-none px-3 me-2 mb-2 mb-sm-0 ${filter === 1 && 'active'}`} onClick={() => this.setState({ ...this.state, filter: 1 })} data-filter=".catnft">NFT </button>
                            <button className={`btn BtnBorder rounded shadow-none px-3 me-2 mb-2 mb-sm-0 ${filter === 2 && 'active'}`} onClick={() => this.setState({ ...this.state, filter: 2 })} data-filter=".catpeople">People </button>
                            <button className={`btn BtnBorder rounded shadow-none px-3 me-2 mb-2 mb-sm-0 ${filter === 3 && 'active'}`} onClick={() => this.setState({ ...this.state, filter: 3 })} data-filter=".catpost">Description </button>
                        </div>
                        <div className={'row justify-content-center'} id='zIndexMob'>
                            <h3 className="CeraBold mb-5">Search keywords "{search.replace('?q=', '')}"</h3>
                            <div className={'col-md-9'}>
                                <div id="isotopBox" className="row row-cols-12">
                                    <div className={`item catnft ${this.filterDisplay(1)}`}>
                                        <h2 className="CeraBold mb-3">NFT</h2>
                                        <div className={'row row-cols-xxl-4 row-cols-xl-4 row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1 flex-wrap'}>
                                            {
                                                tokens.map(token => <NFTBar key={token.id} token={token} />)
                                            }
                                            {
                                                !isSearching && tokens.length === 0 && <label className="text-secondary text-center mb-4">No Items Found!</label>
                                            }
                                            {
                                                isSearching && new Array(10).fill(null).map(_ => <NFTBar />) //<SkeletonLoading style={{ height: '109px' }} className="ResultBox border-bottom pb-3 mb-3 w-100" />
                                            }
                                        </div>
                                    </div>
                                    <div className={`item catpeople ${this.filterDisplay(2)}`}>
                                        <h2 className="CeraBold mb-3">People</h2>
                                        <div className={'row row-cols-xxl-4 row-cols-xl-4 row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1 flex-wrap'}>
                                            {
                                                people.map(user => <UserBar key={user.user_id} user={user} />)
                                            }
                                            {
                                                !isSearching && people.length === 0 && <label className="text-secondary text-center mb-4">No Items Found!</label>
                                            }
                                            {
                                                isSearching && new Array(10).fill(null).map((_, index) => <UserBar key={index} />)//<SkeletonLoading style={{ height: '109px' }} className="ResultBox border-bottom pb-3 mb-3" />
                                            }
                                            {/* <div className={'text-center'}>
                                                <a className={'btn BtnBlack rounded shadow-none px-3 me-2'} href="#">See all Post Result</a>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className={`item catpost ${this.filterDisplay(3)}`}>
                                        <h2 className="CeraBold mb-3">Descriptions</h2>
                                        <div className={'row row-cols-xxl-4 row-cols-xl-4 row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1 flex-wrap'}>
                                            {
                                                others.map(token => <NFTBar key={token.id} token={token} />)
                                            }
                                            {
                                                !isSearching && others.length === 0 && <label className="text-secondary text-center mb-4">No Items Found!</label>
                                            }
                                            {
                                                isSearching && new Array(10).fill(null).map(_ => <NFTBar />) //<SkeletonLoading style={{ height: '109px' }} className="ResultBox border-bottom pb-3 mb-3 w-100" />
                                                // isSearching && <SkeletonLoading style={{ height: '109px' }} className="ResultBox border-bottom pb-3 mb-3" />
                                            }
                                            {/* <div className={'text-center'}>
                                                <a className={'btn BtnBlack rounded shadow-none px-3 me-2'} href="#">See all Post Result</a>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="item catuser d-none">
                                        <h2 className="CeraBold mb-3">User</h2>
                                        <div className={'BgGrey p-4 rounded mb-4'}>
                                            <div className={'ResultBox border-bottom pb-3 mb-3'}>
                                                <div className={'d-flex'}>
                                                    <img className={'ImgResultNft border rounded p-1 me-3'} src="/images/imgbox-1.jpg" alt="NFT" />
                                                    <div className={''}>
                                                        <h4 className="CeraBold mb-2">NFT Name</h4>
                                                        <p className={'mb-0'}>Some Description of NFT comes here...</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'ResultBox border-bottom pb-3 mb-3'}>
                                                <div className={'d-flex'}>
                                                    <img className={'ImgResultNft border rounded p-1 me-3'} src="/images/imgbox-1.jpg" alt="NFT" />
                                                    <div className={''}>
                                                        <h4 className="CeraBold mb-2">NFT Name</h4>
                                                        <p className={'mb-0'}>Some Description of NFT comes here...</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'ResultBox border-bottom pb-3 mb-3'}>
                                                <div className={'d-flex'}>
                                                    <img className={'ImgResultNft border rounded p-1 me-3'} src="/images/imgbox-1.jpg" alt="NFT" />
                                                    <div className={''}>
                                                        <h4 className="CeraBold mb-2">NFT Name</h4>
                                                        <p className={'mb-0'}>Some Description of NFT comes here...</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'text-center'}>
                                                <a className={'btn BtnBlack rounded shadow-none px-3 me-2'} href="#">See all User Result</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        )
    }
}

export default SearchResult;
