import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Keyring } from '@polkadot/api';
import { mnemonicValidate, validateAddress } from "@polkadot/util-crypto";
import QRCode from "react-qr-code";
import $ from 'jquery';
import { validatedNumber } from '../../../utils/utils';
import { getNSTBalanceApi, getNSTBalanceByParamApi, getNUCBalanceByParamApi, swapCoinsApi } from '../../../api/wallet/wallet';
import { setNSTBalance } from '../../../actions/wallet';

class TopUpBalance extends Component {

    state = {
        data: {
            from: 'NUC',
            to: 'NST',
            fromAddress: null,
            toAddress: null,
            fromValue: null,
            toValue: null,
            mnemonics: null
        },
        balances: {
            NUC: null,
            NST: null
        },
        balanceLoading: {
            NUC: false,
            NST: false
        },
        isError: false,
        message: null,
        isLoading: false,
        isSuccess: false
    }


    componentDidMount() {
        if(localStorage.getItem('theme') === 'dark') {
            $(".modal").addClass("DarkModal");
            $(".dropdown-menu").addClass("dropdown-menu-dark");
        }
        this.updateSenderBalance(this.props.profile.public_address, 'NST')
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.profile.public_address !== this.props.profile.public_address) {
            this.updateSenderBalance(this.props.profile.public_address, 'NST');
        }
    }

    submit = async (e) => {
        e.preventDefault();
        await new Promise(resolve => this.setState({...this.state, isError: false, message: null}, resolve));

        try {
            const fromValue = parseFloat(this.state.data.fromValue || 0);

            if (fromValue <= 0) {
                return this.setState({...this.state, isError: true, message: 'Swap value must be a positive number.'})
            }

            if (!this.state.showMnemonicsInput) return this.setState({...this.state, showMnemonicsInput: true})

            if (this.state.data.mnemonics === null || this.state.data.mnemonics === '') return this.setState({
                ...this.state,
                isError: true,
                message: 'Mnemonics are required!'
            });
            
            await new Promise(resolve => this.setState({...this.state, isLoading: true}, resolve));

            const { data: { burn_hash, mint_hash } } = await swapCoinsApi({
                mnemonics: this.state.data.mnemonics,
                coins: this.state.data.fromValue,
                currency: this.state.data.from,
                toAddress: this.props.profile.public_address
            });

            this.setState({
                ...this.state, isLoading: false, isError: false, isSuccess: true,
                message: <p>Burn Hash: <a href={`https://explorer.nftcity.exchange/block/${burn_hash}`} target="_blank">{burn_hash}</a><br/>Mint Hash: <a href={`https://explorer.nftcity.exchange/block/${mint_hash}`} target="_blank">{mint_hash}</a><br/>Swap Successfull!</p>
            });

            getNSTBalanceApi(this.props.token).then(({ data: { balance } }) => {
                this.props.setNSTBalance(balance);
            }).catch(e => {
            });

            this.updateSenderBalance(this.state.data.fromAddress);

        } catch (e) {
            this.setState({
                ...this.state,
                isError: true,
                isLoading: false,
                message: e.response ? e.response.data : e.message
            })
        }
    }

    oc = e => {
        this.setState({...this.state, isError: null, balanceLoading: { ...this.state.balanceLoading, [this.state.data.to]: e.target.name === 'toAddress' && this.isValidAddress(e.target.value) }, message: null, data: {...this.state.data, [e.target.name]: e.target.value}});
    }

    async updateSenderBalance(address, type = 'NUC') {
        if(type === 'NUC') {
            getNUCBalanceByParamApi(address).then(res => {
                this.setState({ ...this.state, balanceLoading: { ...this.state.balanceLoading, [this.state.data.from]: false }, balances: { ...this.state.balances, [this.state.data.from]: res.data.balance } });
            }).catch(_ => {});
        } else {
            getNSTBalanceByParamApi(address).then(res => {
                this.setState({ ...this.state, balanceLoading: { ...this.state.balanceLoading, [this.state.data.from]: false }, balances: { ...this.state.balances, [this.state.data.from]: res.data.balance } });
            }).catch(_ => {});
        }
    }

    onChangeMnemonics = e => {
        e.preventDefault();

        if (mnemonicValidate(e.target.value)) {
            const keyring = new Keyring({
                type: 'sr25519',
                ss58Format: 42,
            });
            const newPair = keyring.addFromUri(e.target.value);
            
            this.setState({ ...this.state, balanceLoading: { ...this.state.balanceLoading, [this.state.data.from]: true }, data: { ...this.state.data, mnemonics: e.target.value, fromAddress: newPair.address } });

            this.updateSenderBalance(newPair.address);

        } else {
            this.setState({ ...this.state, data: { ...this.state.data, mnemonics: e.target.value, fromAddress: null } });
        }
    }

    render() {

        const { mnemonics, from, to, fromValue, fromAddress } = this.state.data;
        const { isError, message, isSuccess } = this.state;

        return (
            <React.Fragment>
                <a id="topUpModalBtn" href="#topUpModal" data-bs-toggle="modal" type="button" className="btn BtnBlack w-100 mt-4 py-2 d-none">
                    Test
                </a>
                <div className="modal fade" id="topUpModal" tabIndex="-1" aria-labelledby="ConversionModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content">
                            <div className="modal-header align-items-center">
                                <h5 className="modal-title" id="ConversionModalLabel">Top-up Account</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="bgwhite p-3">
                                    <h5 className={'mb-4'}>Enter Mnemonics of your nucoin account to Top Up NST balance.</h5>
                                    <div className="row justify-content-center">
                                        <div className="col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                            <div className="mb-1">
                                                <div className="d-sm-flex justify-content-between">
                                                    <label className="form-label CeraMed d-block mb-2">Enter Mnemonics</label>
                                                    {this.state.balances[from] !== null &&
                                                        <label className="form-label d-block mb-0">Available Balance: <span className="CeraMed ColorWhite"> {this.state.balances[from]} NUC</span></label>
                                                    }
                                                </div>
                                                <div className="input-group mb-0 FullOnMob d-block d-sm-flex">
                                                    <input onChange={this.onChangeMnemonics} style={{ borderColor: mnemonicValidate(mnemonics) ? '' : mnemonics === null ? '' : 'red' }} value={mnemonics} name="mnemonics" type="text" className="form-control shadow-none" placeholder={`Please enter ${from} account mnemonics`}/>
                                                    <span className="input-group-text">
                                                        <div className="dropdown bootstrap-select">
                                                            <select className="SelectPicker" tabIndex="null">
                                                                <option
                                                                    data-content="<img className='SelectPickerImg me-2 mb-1' src='/images/icon-eth.png'><span className='text-dark'>ETH</span>"> Chrome</option>
                                                                <option
                                                                    data-content="<img className='SelectPickerImg me-2 mb-1' src='/images/icon-dai.png'><span className='text-dark'>DAI</span>"> Chrome</option>
                                                                <option
                                                                    data-content="<img className='SelectPickerImg me-2 mb-1' src='/images/icon-usdc.png'><span className='text-dark'>USDC</span>"> Chrome</option>
                                                            </select>
                                                            <button type="button" tabIndex="-1" className="btn dropdown-toggle btn-light"
                                                                data-bs-toggle="dropdown" role="combobox" aria-owns="bs-select-1"
                                                                aria-haspopup="listbox" aria-expanded="false">
                                                                <div className="filter-option">
                                                                    <div className="filter-option-inner">
                                                                        <div className="filter-option-inner-inner">
                                                                            <img className="SelectPickerImg me-2 mb-1" src="/images/icon-NUC.png" />
                                                                            <span className="text-dark">NUC</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </button>
                                                            <div className="dropdown-menu d-none"
                                                                style={{ maxHeight: '210px', overflow: 'hidden', minHeight: '0px' }}>
                                                                <div className="inner show" role="listbox" id="bs-select-1"
                                                                    tabIndex="-1" style={{
                                                                        maxHeight: '192px',
                                                                        overflow: 'hidden auto',
                                                                        minHeight: '0px'
                                                                    }} aria-activedescendant="bs-select-1-0">
                                                                    <ul className="dropdown-menu inner show" role="presentation"
                                                                        style={{ marginTop: '0px', marginBottom: '0px' }}>
                                                                        <li>
                                                                            <a role="option" className="dropdown-item"
                                                                                id="bs-select-1-0" tabIndex="0" aria-setsize="3"
                                                                                aria-posinset="1" aria-selected="true">
                                                                                <span className="text"><img
                                                                                    className="SelectPickerImg me-2 mb-1"
                                                                                    src="/images/icon-NUC.png" />
                                                                                    <span>NUC</span>
                                                                                </span>
                                                                            </a>
                                                                        </li>
                                                                        <li>
                                                                            <a role="option" className="dropdown-item"
                                                                                id="bs-select-1-1" tabIndex="0" aria-setsize="3"
                                                                                aria-posinset="2">
                                                                                <span className="text"><img
                                                                                    className="SelectPickerImg me-2 mb-1"
                                                                                    src="/images/icon-NST.png" />
                                                                                    <span>NST</span>
                                                                                </span>
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </span>
                                                </div>
                                                <label style={{ textAlign: 'end', fontSize: '13px' }} className="text-secondary w-100">
                                                    { fromAddress }
                                                </label>
                                            </div>
                                            <div className="mb-3 text-center">
                                                <div className="row">
                                                    <div className="col-8">
                                                        <div className="input-group mb-0">
                                                            <input style={{ borderColor: validatedNumber(fromValue) ? '' : fromValue === null ? '' : 'red' }} onChange={this.oc} value={fromValue} name="fromValue" type="text" className="form-control shadow-none" placeholder={`Enter amount to convert in ${to}`}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-4">
                                                        <div className="mt-2 text-center">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <label className="form-label d-block mb-0"><span className="CeraMed ColorWhite">Your balance is {this.props.balances.NST} NST</span></label>
                                                        <label className="text-secondary">{this.props.profile.public_address}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-3 d-none">
                                                <div className="row">
                                                    <div className="col-8">
                                                        Scan address to send direct amount  <QRCode style={{ marginTop: '10px' }} value={this.props.profile.public_address || ''} />
                                                    </div>
                                                    <div className="col-4">
                                                        <div className="mt-2 text-center">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                {
                                                    isError && message && (
                                                        <div className="alert alert-danger" role="alert">
                                                            {message}
                                                        </div>
                                                    )
                                                }
                                                {
                                                    !isError && message && (
                                                        <div className="alert alert-success" role="alert">
                                                            {message}
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            <div className="mb-3">
                                                {
                                                    !isSuccess && <button 
                                                        disabled={this.state.isLoading || !( mnemonicValidate(mnemonics) && validatedNumber(fromValue) )}
                                                        onClick={(e) => this.submit(e)}
                                                        type="button" className="btn BtnBlack w-100 py-2">Top Up { this.state.isLoading && <i className="fas fa-asterisk fa-spin"></i> }
                                                    </button>
                                                }
                                                {
                                                    isSuccess && (
                                                        <React.Fragment>
                                                            <div className="row">
                                                            <button 
                                                                onClick={() => {
                                                                    document.getElementById('topUpModalBtn').click();
                                                                    this.setState({        
                                                                        data: {
                                                                        from: 'NUC',
                                                                        to: 'NST',
                                                                        fromAddress: null,
                                                                        toAddress: null,
                                                                        fromValue: null,
                                                                        toValue: null,
                                                                        mnemonics: null
                                                                    },
                                                                    balances: {
                                                                        NUC: null,
                                                                        NST: null
                                                                    },
                                                                    balanceLoading: {
                                                                        NUC: false,
                                                                        NST: false
                                                                    },
                                                                    isError: false,
                                                                    message: null,
                                                                    isLoading: false,
                                                                    isSuccess: false
                                                                });
                                                                    setTimeout(() => {
                                                                        document.getElementById('confirmationModalBtn').click();
                                                                    }, 300)
                                                                }}
                                                                type="button" className="btn BtnBlack w-100 py-2"> Continue with purchasing
                                                            </button>
                                                            </div>
                                                            <div className="row"></div>
                                                        </React.Fragment>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateTopProps = state => ({
    profile :   state.Profile.profile,
    balances :   state.Wallet.balances,
    currentUser:    state.Profile.profile,
    token:  state.Auth.token
})

export default connect(mapStateTopProps, { setNSTBalance })(TopUpBalance);