import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getNFTComments, getNFTReactions } from "../../api/nft/nft";
const REACT_APP_PROFILE_SERVICE =
  process.env.REACT_APP_PROFILE_SERVICE || "http://localhost:4002";

export default (props) => {

  const [state, setState] = useState({
    reactionsList: [],
    limit: 10,
    offset: 0,
    hasMoreReactions: true,
    loadingReactions: false,
  });

  useEffect(() => {
    setState({ ...state, reactionsList: props.reactions, offset: props.reactions.length });
  }, [ props.nftID, props.reactions.length ]);

  const syncReactions = async (offset, limit) => {
    if (props.nftID) {
      setState({ ...state, loadingReactions: true });

      getNFTReactions( props.nftID, props.userToken, limit, offset ).then(({ data }) => {
        if (data?.data) 
          setState({ ...state, reactionsList: [ ...(state.reactionsList ?? []), ...(data?.data ?? []) ], loadingReactions: false, hasMoreReactions: data?.data?.length === 0 ? false : true });
      });
    }
  };

  const loadMoreReactions = () => {
    setState({
      ...state,
      offset: state.reactionsList?.length ?? 0,
      limit: 10,
    });
    syncReactions(state.reactionsList?.length ?? 0, 10);
  };

  return (
    <React.Fragment>
      <button style={{display: 'none'}} id="likerListModalBtn" type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target={`#likerListModal`}>
          Launch demo modal
      </button>
      <div className="modal fade DarkModal" data-bs-keyboard="false" id="likerListModal" tabindex="-1" aria-labelledby="messageBoxModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-md">
              <div className="modal-content">
                  <div className="modal-header align-items-start">
                      <h5 className="modal-title" id="messageBoxModalLabel">Reactions</h5>
                      {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                  </div>
                  <div className="modal-body">
                      <div className="mb-3">
                      <ul className={"overflow-scroll mx-0 px-0"}>
                        {
                          state?.reactionsList?.map((item, index) => {
                            return (
                              <li key={item.id || index} className={"list-group-numbered "}>
                                <ItemLiker item={item} />
                              </li>
                            );
                          })
                        }
                        {
                          state.reactionsList.length === 0 && <label className="text-secondary w-100 text-center">No reactions found!</label>
                        }
                        </ul>
                        {
                          props.totalReactions > state.reactionsList?.length && state.reactionsList?.length != 0 && state.hasMoreReactions && (
                            <div
                              className="Fsize_14 cursor-pointer  link-primary"
                              onClick={loadMoreReactions}
                            >
                              View more reactions
                            </div>
                          )}
                          {
                            state.loadingReactions && (
                            <div className="d-flex align-content-cente justify-content-center w-100">
                              <Spinner
                                animation="border"
                                style={{ width: 16, height: 16 }}
                                className={"mx-2"}
                              />
                            </div>
                          )
                        }
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </React.Fragment>
  );
};
const ItemLiker = (props) => {
  return (
    <React.Fragment>
      <Link id={`link-${props.item.user_name}`} style={{ display: 'none' }} to={`/profile/${props?.item?.user_name}`} className="d-flex align-items-center cursor-pointer"></Link>
      <div style={{ cursor: 'pointer' }} onClick={() => {
        document.getElementById('likerListModalBtn').click();
        setTimeout(() => {
          document.getElementById(`link-${props.item.user_name}`).click();
        }, 150)
      }} className={`d-flex py-2 align-items-center border-bottom`}>
          <img src={ props?.item?.profile_image ? `${REACT_APP_PROFILE_SERVICE}/v1/profile/image/${props?.item?.user_name}` : "/images/user.png" } alt="Image" className="img-fluid ImgProfile me-2 " style={{ width: 35, height: 35 }}/>
        <div className="Fsize_14 mb-1 flex-grow-1 ">
          { props?.item?.display_name ?? props?.item?.user_name }
        </div>
      </div>
    </React.Fragment>
  );
};
