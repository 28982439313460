import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setPublicKey } from '../../../api/profile/profile';
import { mnemonicGenerate, mnemonicValidate } from '@polkadot/util-crypto';
import { Keyring } from '@polkadot/api';
import { setProfile } from '../../../actions/profile';

class MissingMnemonics extends Component {

    state = {
        mnemonics: null,
        public_address: null,
        isLoading: false,
        isError: null

    }
    onChangeMnemonics = e => {
        e.preventDefault && e.preventDefault();

        if (mnemonicValidate(e.target.value)) {
            const keyring = new Keyring({
                type: 'sr25519',
                ss58Format: 42,
            });
            const newPair = keyring.addFromUri(e.target.value);

            this.setState({ ...this.state, mnemonics: e.target.value, public_address: newPair.address });
        } else this.setState({ ...this.state, mnemonics: e.target.value, public_address: null });
    }

    async submit() {
        this.setState({ ...this.state, isLoading: true });
        try {
            await setPublicKey({ public_address: this.state.public_address }, this.props.token);
            this.setState({ ...this.state, isError: null, isLoading: false }, () => {
                this.props.setProfile({ ...this.props.currentUser, public_address: this.state.public_address });
                // window.location.reload();
                document.getElementById('confirmationMissingMnemonicsBtn').click();
                this.props.callback();
            });
        } catch(e) {
            this.setState({ ...this.state, isError: e.response && e.response.data ? e.response.data : e.message, isLoading: false });
        }
    }

    render() {
        return (
            <React.Fragment>
                <button style={{ display: 'none' }} id="confirmationMissingMnemonicsBtn" type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#confirmationMissingMnemonicsModal">
                    Launch demo modal
                </button>
                <div className="modal fade" id="confirmationMissingMnemonicsModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md">
                        <div className="modal-content">
                            <div className="modal-header align-items-start">
                                <h5 className="modal-title">We have not confirmed your Public Address yet!</h5>
                                <button type="button" className="btn-close m-0" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <p className="mb-4">Please enter the mnemonics to associate the public key with your account for future use.</p>
                                <div className="mb-3">
                                    <label className="form-label CeraMed d-block mb-1">Account Mnemonics</label>
                                    <textarea
                                        value={this.state.mnemonics}
                                        style={{ borderColor: !this.state.mnemonics ? '' : mnemonicValidate(this.state.mnemonics) ? 'green' : 'red' }}
                                        onChange={this.onChangeMnemonics} name="mnemonics" rows="4"
                                        className="form-control shadow-none"
                                        placeholder="Paste your mnemonics here"></textarea>
                                    <p className="form-text mb-0">We are not storing your private key, this can be use
                                        one time for this transaction.</p>
                                </div>
                                <button className="btn BtnBorderBlack w-100 mb-3" onClick={() => this.onChangeMnemonics({ target: { value: mnemonicGenerate() } })}>Generate New Account Mnemonics</button>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn BtnBorderBlack py-2 px-3 px-md-5" data-bs-dismiss="modal">Close</button>
                                <button style={{color: this.state.isError ? '' : '' }}
                                        disabled={!mnemonicValidate(this.state.mnemonics) || this.state.isLoading}
                                        onClick={this.submit.bind(this)} type="button"
                                        className="btn BtnBlack py-2 px-3 px-md-5">{this.state.isError ? this.state.isError : 'Confirm'} {this.state.isLoading && <i
                                    className="fas fa-asterisk fa-spin text-center" />}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
        </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    public_address  : state.Profile.profile.public_address,
    currentUser  : state.Profile.profile,
    token   :  state.Auth.token
})

export default connect(mapStateToProps, {
    setProfile,
})(MissingMnemonics);