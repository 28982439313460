import { SET_HOME } from "../types/home";

const INITIAL_STATE = {
    home: {
        section_one: [],
        section_two: new Array(2).fill(null),
        section_three: [],
        section_four:   [],
        live_auctions: []
    }
}

function homeReducer(state = INITIAL_STATE, action) {
    switch(action.type) {
        case SET_HOME: {
            return { ...state, home: { ...state.home, ...action.payload } }
        }
        default: {
            return state;
        }
    }
}

export default homeReducer;