// import { Modal } from "bootstrap";
import React from 'react';
import Modal from 'react-bootstrap/Modal'

export default class MessageModal extends React.Component {
    state = {
      
        disable: false
    }

    closeModal = (value) => {
        this.props.onCloseYesOrNoModal(value)
    }


    render() {
        return (
            <Modal dialogClassName="ErrorAlert text-center modalZIndexTop"
                show={this.props.showModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdropClassName={"modal-backdrop "}
                contentClassName={"bg-light"}
            >
                <Modal.Header>
                    <Modal.Title style={{ color: 'black', fontSize:14 }} id="contained-modal-title-vcenter">
                        {this?.props?.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{
                    wordWrap: 'break-word',
                    paddingLeft: '2px',
                    paddingRight: '2px',
                    zIndex:100000000
                }}>

                    <p>{this?.props?.msg}</p>
                    {this.props.showCancel &&  <button disabled={this.state.disable} type="button" className="btn btn-secondary Submit mx-3" onClick={() => { this.closeModal(false) }} style={{ width: 80 }}>Cancel</button>}
                   
                    <button disabled={this.state.disable} type="button" className="btn btn-primary Cancel mx-3" onClick={(event) => this.closeModal(true)} style={{ width: 80 }}>OK</button>

                </Modal.Body>
            </Modal >
        )
    }


}