import React from 'react';
import NotificationsIcon from '@material-ui/icons/Notifications';
import timeago from 'time-ago';

export default ({ title, message, is_read = false, category, create_time}) => (
    <div className='row py-2 align-items-top'>
        <div className="avatar-xs col-2">
            <span className={`avatar-title bg-primary rounded-circle`}>
                <NotificationsIcon className="font-16" />
            </span>
        </div>
        <div className="col-10">
            <h4>{ title }</h4>
            <div className="font-12 text-muted">
                <h6 className="mb-0">{message}</h6>
            </div>
            <p className="mb-0">
                <span key="t-min-ago">{ timeago.ago(new Date(create_time)) }</span>
            </p>
            { !is_read && category !== 'announcement' && <div className='ActiveSattus'></div> }
        </div>
    </div>
)