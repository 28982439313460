import React, { Component, useRef, useState } from 'react';
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import Skeleton from "react-loading-skeleton";
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import "@webscopeio/react-textarea-autocomplete/style.css";
import { searchUserProfileWithStartKey } from "../../../../api/profile/profile";
import { Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';

const REACT_APP_PROFILE_SERVICE = process.env.REACT_APP_PROFILE_SERVICE || "http://localhost:4002";

const InputIntellisense = (props) => {

    const { onChangeListener, onKeyPressedListener, textValue, onTagItemSelected, inputComponentRef, inputFieldRef } = props;
    const [ showTagDropDown, setShowTagDropDown ] = useState(false);
    const [ state, setState ] = useState({ commentToPost: undefined, isSearching: false, searchedUsersToTag: [], stringTags: [] });
    const abortControler = useRef();
    const dropDownPinRef = useRef();

    const searchUserToTag = async (searchQuery) => {

        if (!props.token) return;
        if (state.isSearching) return;
    
        if (abortControler?.current !== null) {
          abortControler?.current?.abort();
        }
    
        abortControler.current = new AbortController();
    
        setShowTagDropDown(true);
        try {
          setState((prev) => ({ ...prev, isSearching: true, searchedUsersToTag: [] }));
          const data = await searchUserProfileWithStartKey( searchQuery, props.token, abortControler.current );
    
          setState((prev) => ({ ...prev, searchedUsersToTag: data?.data?.data, isSearching: false }));
          return data?.data?.data ?? [];
        } catch (e) {
          setState((prev) => ({ ...prev, searchedUsersToTag: [], isSearching: false }));
          return [];
        }
    };
    return (
        <React.Fragment>
            <ReactTextareaAutocomplete
                className="form-control mx-1 w-100 "
                containerClassName="mx-2 w-100"
                style={{ height: 43, fontSize: 14 }}
                loadingComponent={() => (
                    <div style={{ width: 40, height: 40, backgroundColor: "#ccc", borderRadius: 5, justifyContent: "center", alignItems: "center", display: "flex" }} >
                        <Spinner animation="border" style={{ width: 20, height: 20, borderWidth: 2 }}/>
                    </div>
                )}
                containerStyle={{ width: "100%" }}
                type="text"
                placeholder={"Write a comment..."}
                onChange={onChangeListener}
                onKeyDown={onKeyPressedListener}
                value={textValue}
                onItemSelected={onTagItemSelected}
                trigger={{
                "@" : {
                    dataProvider: (token) => {
                        return searchUserToTag(token);
                    },
                    component: ItemUser,
                    output: (item, trigger) => item.user_name,
                },
                }}
          />

          <div id="temp-ref-for-tooltip" ref={dropDownPinRef} style={{ width: 10, height: 1, position: "absolute", top: 10, left: 20, start: 10 }}/>
          <Overlay target={dropDownPinRef} placement="top-start" containerPadding={20}>
            <Popover id="popover-contained">
              <Popover.Body>
                <div>
                  {state.isSearching ? <Skeleton height={50} count={2} width={230} className={"bg-comment-box my-2"} />
                  : (
                    state.searchedUsersToTag?.length > 0 && (
                      <ul className={"overflow-scroll mx-0 px-0"}>
                        {state.searchedUsersToTag?.map((item) => {
                          return (
                            <li key={item.user_name} className={"list-group-numbered "}>
                              <ItemUser item={item} />
                            </li>
                          );
                        })}
                      </ul>
                    )
                  )}
                </div>
              </Popover.Body>
            </Popover>
          </Overlay>
        </React.Fragment>
    )
}

const ItemUser = (props) => {
    const item = props?.entity;
    return (
      <div
        className="d-flex py-2 border-bottom align-items-center" style={{ width: 250 }}>
        <img src={item?.profile_image ? `${REACT_APP_PROFILE_SERVICE}/v1/profile/image/${item?.user_name}` : "/images/user.png"} alt="Image" className="img-fluid ImgProfile me-2 " style={{ width: 35, height: 35 }}/>
        <div className="Fsize_14 mb-1 flex-grow-1 text-dark">
          { item?.display_name ?? item?.user_name}<br/>
          <label className="text-secondary">{ item.user_name }</label>
        </div>
      </div>
    );
};

const mapStatetoProps = state => ({
    token: state.Auth.token
})

export default connect(mapStatetoProps, {})(InputIntellisense);