import React, { Component } from 'react';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import CampaignIcon from '@material-ui/icons/RecordVoiceOver';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import ArchiveIcon from '@material-ui/icons/Archive';
import { Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import NotificationTile from '../../Layouts/NotificationTile/NotificationTile'
import ReactHtmlParser from 'react-html-parser';

function NotificationDrop(isShowing) {
    
    if (isShowing) {
        document.getElementById('zIndexMob').classList.add("position-relative")
    }
     else {
        document.getElementById('zIndexMob').classList.remove("position-relative")
    }
}
function NotificationDropHide() {
    document.getElementById('HideDrop').classList.remove("show")
}

class NotificationDropDown extends Component { 

    render() {
        return (
            <>
                <div className='me-2'>
                    <Dropdown id="Notification" 
                    onToggle={NotificationDrop}
                        size="lg" className="User">
                        <Dropdown.Toggle id="dropdown-basic" className='NotificationDrop'>
                            <span className="material-icons user-icon">
                                <NotificationsIcon /> { this.props.notifications.unread.unread_count ? <span className='Counter'>{this.props.notifications.unread.unread_count || ""}</span> : '' }
                            </span>
                        </Dropdown.Toggle>
                        <Tabs className="TabsList">
                            <Dropdown.Menu className="dropdown-menu-lg" id="HideDrop">
                                <div class="p-3 Notifi_Head">
                                    <div class="row align-items-center">
                                        <div class="col">
                                            <h6 class="m-0"> Notifications </h6>
                                        </div>
                                        <div class="col-auto">
                                            <Link to="/notification/list" class="small" onClick={NotificationDropHide}>View All</Link>
                                        </div>
                                    </div>
                                </div>
                                <TabList className="px-3 border-0">
                                    <Tab title={"Announcement"}><CampaignIcon></CampaignIcon></Tab>
                                    <Tab title={"All Notifiication"}><NotificationsActiveIcon></NotificationsActiveIcon></Tab>
                                    <Tab title={"Unread Notification"}><NotificationImportantIcon></NotificationImportantIcon></Tab>
                                </TabList>
                                <TabPanel>
                                    <div className="DropDownScroll">
                                        {/* <NotificationTile key={'id'} title={'title'} message={'message'} create_time={new Date()} />) */}
                                        {
                                            this.props.announcements.data.map(({ id, title, message, userpreferences, category, create_time }) => (
                                                <React.Fragment>
                                                    <Dropdown.Item key={id} onClick={(e) => {
                                                        e.preventDefault();
                                                        document.getElementById(`redirect-to-${id}`).click();
                                                    }} className="row text-reset notification-item">
                                                        <NotificationTile title={title} message={ReactHtmlParser(message)} is_read={userpreferences.is_read} category={category} create_time={create_time} />
                                                    </Dropdown.Item>
                                                    <Link id={`redirect-to-${id}`} to={`/notification/detail/${id}`}/>
                                                </React.Fragment>
                                                )
                                            )
                                        }
                                        {
                                            this.props.announcements.data.length === 0 && (
                                                <Dropdown.Item className="row text-reset notification-item">
                                                    <label className="text-secondary mb-2 text-center">No announcements found!</label>
                                                </Dropdown.Item>
                                            )
                                        }
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className="DropDownScroll">
                                        {
                                            this.props.notifications.all.data.map(({ id, title, message, userpreferences, category, create_time }) => (
                                                <React.Fragment>
                                                    <Dropdown.Item key={id} onClick={(e) => {
                                                        e.preventDefault();
                                                        document.getElementById(`redirect-to-${id}`).click();
                                                    }} className="row text-reset notification-item">
                                                        <NotificationTile title={title} message={ReactHtmlParser(message)} is_read={userpreferences.is_read} category={category} create_time={create_time} />
                                                    </Dropdown.Item>
                                                    <Link id={`redirect-to-${id}`} to={`/notification/detail/${id}`}/>
                                                </React.Fragment>
                                                )
                                            )
                                        }
                                        {
                                            this.props.notifications.all.data.length === 0 && (
                                                <Dropdown.Item className="row text-reset notification-item">
                                                    <label className="text-secondary mb-2 text-center">No notifications found!</label>
                                                </Dropdown.Item>
                                            )
                                        }
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className="DropDownScroll">
                                        {
                                            this.props.notifications.unread.data.map(({ id, title, message, userpreferences, category, create_time }) => (
                                                <React.Fragment>
                                                    <Dropdown.Item key={id} onClick={(e) => {
                                                        e.preventDefault();
                                                        document.getElementById(`redirect-to-${id}`).click();
                                                    }} className="row text-reset notification-item">
                                                        <NotificationTile key={id} title={title} message={ReactHtmlParser(message)} is_read={userpreferences.is_read} category={category} create_time={create_time} />
                                                    </Dropdown.Item>
                                                    <Link id={`redirect-to-${id}`} to={`/notification/detail/${id}`}/>
                                                </React.Fragment>
                                                )
                                            )
                                        }
                                        {
                                            this.props.notifications.unread.data.length === 0 && (
                                                <Dropdown.Item className="row text-reset notification-item">
                                                    <label className="text-secondary mb-2 text-center">No notifications found!</label>
                                                </Dropdown.Item>
                                            )
                                        }
                                    </div>
                                </TabPanel>
                            </Dropdown.Menu>
                        </Tabs>
                    </Dropdown>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
    notifications: state.Notification.notifications,
    announcements: state.Notification.announcements,
})

export default connect(mapStateToProps, {})(NotificationDropDown);