import React from "react"

const PageLoader = () => {
    return (
        <div style={{ textAlign: 'center', padding: '150px' }}>
            <article style={{ display: 'block', textAlign: 'left', width: '650px', margin: '0 auto'  }}>
                <h1 className="text-center" style={{ font: '20px Helvetica, sans-serif', color: '#333' }}><b>Loading... Please wait!</b></h1>
            </article>
        </div>
    )
}
export default PageLoader;