import React from 'react';
import {encryptFiatPaymentData} from '../../../utils/utils';

export default () => {

    return (
        <React.Fragment>
            <button style={{display: 'none'}} id="paymentMethodSelectionBtn" type="button" className="btn btn-primary"
                    data-bs-toggle="modal" data-bs-target="#paymentMethodSelectionModal">
                Launch demo modal
            </button>
            <div className="modal fade DarkModal" data-bs-keyboard="false" id="paymentMethodSelectionModal" tabindex="-1" aria-labelledby="warningAlertModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md">
                    <div className="modal-content">
                        <div className="modal-header align-items-center">
                            <h5 className="modal-title">Select payment method</h5>
                            <button type="button" className="btn-close m-0" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className={'d-flex'}>
                                <button data-bs-toggle="modal"
                                        data-bs-target="#paymentMethodSelectionModal" onClick={() => {
                                    document.getElementById('confirmationModalBtn').click();
                                }} className="btn BtnBlack py-2 px-4 w-50 me-3"><i className="fas fa-wallet"></i> Crypto - NST
                                </button>
                                <button onClick={() => {
                                    // fiatPaymentCallbackQuery()
                                    document.getElementById('payPalPaymentModalBtn').click()
                                }} data-bs-toggle="modal" data-bs-target="#paymentMethodSelectionModal"
                                        className="btn BtnBlack py-2 px-4 w-50"><i
                                    className="fab fa-paypal"></i> PayPal
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}