import React from "react"

const PermissionRequired = () => {
    return (
        <div style={{ textAlign: 'center', padding: '150px' }}>
            <article style={{ display: 'block', textAlign: 'left', width: '650px', margin: '0 auto'  }}>
                <h1 style={{ font: '20px Helvetica, sans-serif', color: '#333' }}><b>Temporary Suspension</b></h1>
                <div>
                    <p>Sorry for the inconvenience, NFT creation functionality is suspended for now, please try again later. If you have any query <a style={{ color: '#dc8100', textDecoration: 'none' }} href="mailto:admin@nucoin.com.au">contact us</a>.</p>
                    <p>&mdash; The NuGenesis Team</p>
                </div>
            </article>
        </div>
    )
}
export default PermissionRequired;