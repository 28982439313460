import React, { useEffect, useState } from 'react';
import { isAuthRoute, isItemDetailSection } from '../../../utils/utils';
import { addSubscribe } from "../../../api/home/home";
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';


export default () => {
    const [email, setEmail] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        $("#footer_fixed_button_show").click(function () {
            $(".footer_fixed_button").css("bottom", "300px");
            $("#footer_fixed_button_show").fadeOut();
            $(".f_hide").fadeIn();
            $(".f_show").fadeOut();
            $(".footer_fixed").fadeIn();

        });
        $("#footer_fixed_button_hide").click(function () {
            $(".footer_fixed_button").css("bottom", "10px");
            $("#footer_fixed_button_hide").fadeIn();
            $("#footer_fixed_button_show").fadeIn();
            $(".f_hide").fadeOut();
            $(".f_show").fadeIn();
            $(".footer_fixed").fadeOut();
        });
    }, [!isAuthRoute(window.location.pathname) && !isItemDetailSection(window.location.pathname)]);

    const [searchText, setSearchText] = useState('');

    const onChangeSearchText = e => {
        e.preventDefault();

        setSearchText(e.target.value);
    }

    return !isAuthRoute(window.location.pathname) && !isItemDetailSection(window.location.pathname) ? (
        <React.Fragment>
            <footer className={'px-sm-5 pt-5'}>
                <div className="container">
                    <Link id="search-redirect-footer" to={`/search?q=${searchText}`}></Link>
                    <div className={'row'}>
                        <div id="left" className={'col-12 col-lg-4'}>
                            <div id="title">
                                Get the latest updates
                            </div>
                            <div className="form-group footer_subscribe">
                                <input onChange={e => setEmail(e.target.value)} value={email} type="email" className="form-control Fsize_14 mb-2"
                                    placeholder="Subscribe from email for updates" />
                                <button type="submit" className="btn BtnBlack rounded-0 py-2 px-4"
                                    onClick={async () => {
                                        let emailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
                                        if (emailValid === null) {
                                            toast.error("Please provide a valid email address")
                                        }
                                        else {

                                            try {
                                                setIsLoading(true)
                                                const addSub = await addSubscribe({ email: email })
                                                setIsLoading(false)
                                                setEmail("")
                                                toast.success("Thanks for subscribing!")
                                            }
                                            catch (e) {
                                                setIsLoading(false)
                                                toast.error("Currently, we are not adding subscribers. Please try again later.")
                                            }

                                        }
                                    }}

                                >
                                    {isLoading === true ? <i className="fas fa-asterisk fa-spin ms-1"></i> : null}

                                    {" "}Subscribe</button>
                            </div>
                        </div>
                        <div className={'col-md'}>
                            <div className={'text-white mb-3'} id="title">NFT</div>
                            <ul className={'list-unstyled'}>
                                <li className={'pb-1'}><a href="#">Explore</a></li>
                                <li className={'pb-1'}><a href="#">How it works</a></li>
                                <li className={'pb-1'}><a href="#">Support</a></li>
                                <li className={'pb-1'}><a href="#">Become a partner</a></li>
                            </ul>
                        </div>
                        <div className={'col-md'}>
                            <div className={'text-white mb-3'} id="title">Community</div>
                            <ul className={'list-unstyled'}>
                                <li className={'pb-1'}><a href="#">Token</a></li>
                                <li className={'pb-1'}><a href="#">Discussion</a></li>
                                <li className={'pb-1'}><a href="#">Voting</a></li>
                                <li className={'pb-1'}><a href="#">Suggest feature</a></li>
                            </ul>
                        </div>
                        <div className={'col-md'}>
                            <div className={'text-white mb-3'} id="title">Follow us</div>
                            <ul className={'list-unstyled'}>
                                <li className={'pb-1'}><a href="https://www.facebook.com/nutechcity" target="_blank">Facebook</a></li>
                                <li className={'pb-1'}><a href="https://instagram.com/nugenesis.ou" target="_blank">Instagram</a></li>
                                <li className={'pb-1'}><a href="https://twitter.com/nugenesisou" target="_blank">Twitter</a></li>
                                <li className={'pb-1'}><a href="#">Medium</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
            <div id="footer_bottom" className={'d-none d-xl-block py-4 px-sm-5'}>
                <div id="footer_fixed_button_hide" className="footer_fixed_button f_hide cursor-pointer">
                    <p className={'mb-0'}>Hide</p>
                    <i className="fas fa-chevron-down ms-2"></i>
                </div>
                <div id="footer_fixed_button_show" className="footer_fixed_button f_show cursor-pointer">
                    <p className={'mb-0'}>Discover</p>
                    <i className="fas fa-chevron-up ms-2"></i>
                </div>
                <div id="copyright">
                    <p>© NFT 2021 All Rights Reserved</p>
                    <ul>
                        <li><a href="#">Terms</a></li>
                        <li><a href="#">Privacy Policy</a></li>
                    </ul>
                </div>
                <div id="social_media">
                    <a href="https://www.facebook.com/nutechcity" target="_blank" className="fa fa-facebook"></a>
                    <a href="https://twitter.com/nugenesisou" target="_blank" className="fa fa-twitter"></a>
                    <a href="https://www.youtube.com/channel/UCUcwdvfmOUB9axXVaKKW4lA" target="_blank" className="fa fa-youtube"></a>
                    <a href="https://instagram.com/nugenesis.ou" target="_blank" className="fa fa-instagram"></a>
                </div>
            </div>
            <div id="footer-blue-bar" className={'d-none d-xl-block'}></div>
            <div className="footer_fixed">
                <div id="detail">
                    <h3>DISCOVER</h3>
                    <p>Facilisi cras fermentum odio eu feugiat. Aliquet nibh praesent tristique magna sit amet.
                        Hendrerit
                        gravida rutrum quisque non tellus orci ac auctor. Posuere ac ut consequat semper.</p>
                    <div class="fg--search">
                        <div className={'position-relative'}>
                            <input type="text" value={searchText} onKeyPress={e => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    document.getElementById('search-redirect-footer').click();
                                    setSearchText('');
                                    document.getElementById('footer_fixed_button_hide').click();
                                }
                            }} onChange={onChangeSearchText} className="input" placeholder="Search nft users.." />
                            <button className={'p-0 border-0 bg-transparent Fsize_18 z-index-2'}
                                onClick={() => {
                                    document.getElementById('search-redirect-footer').click();
                                    document.getElementById('footer_fixed_button_hide').click();
                                }} type="submit">
                                <i className="fas fa-search"></i>
                            </button>
                        </div>
                        <a href="#" class="buttonra">MINE</a>
                        <a href="#" class="buttonra">COMMUNITY</a>
                    </div>

                </div>
            </div>
        </React.Fragment>
    ) : null;
}
// export default () => !isAuthRoute(window.location.pathname) && !isItemDetailSection(window.location.pathname) ? (
//     <footer className="pt-5">
//         <div className="container">
//             <div className="row justify-content-between">
//                 <div className="col-md-3 mb-4">
//                     <h3 className="mb-4">Get the latest updates</h3>
//                     <form className="position-relative order-2 order-lg-1">
//                         <input type="text" placeholder="Subscribe from email for updates" id="Subscriber" className="form-control SubscribeInput shadow-none rounded mb-1"/>
//                         <button type="submit" className="btn BtnBlack shadow-none py-2 px-3 rounded">Subscribe</button>
//                     </form>
//                 </div>
//                 <div className="col-md-3 col-sm-6 mb-4 ps-5">
//                     <h3 className="mb-4">NFT</h3>
//                     <ul className="list-unstyled">
//                         <li className="mb-2"><a href="#">Explore</a></li>
//                         <li className="mb-2"><a href="#">How it works</a></li>
//                         <li className="mb-2"><a href="#">Support</a></li>
//                         <li><a href="#">Become a partner</a></li>
//                     </ul>
//                 </div>
//                 <div className="col-md-3 col-sm-6 mb-4">
//                     <h3 className="mb-4">Community</h3>
//                     <ul className="list-unstyled">
//                         <li className="mb-2"><a href="#">Token</a></li>
//                         <li className="mb-2"><a href="#">Discussion</a></li>
//                         <li className="mb-2"><a href="#">Voting</a></li>
//                         <li><a href="#">Suggest feature</a></li>
//                     </ul>
//                 </div>
//                 <div className="col-md-3 col-sm-6 mb-4">
//                     <h3 className="mb-4">Follow us</h3>
//                     <ul className="list-unstyled">
//                         <li className="mb-2"><a href="#">Facebook</a></li>
//                         <li className="mb-2"><a href="#">Instagram</a></li>
//                         <li className="mb-2"><a href="#">Twitter</a></li>
//                         <li><a href="#">Medium</a></li>
//                     </ul>
//                 </div>
//             </div>
//         </div>
//         <div className={'CopyRight p-3 d-flex align-items-center'}>
//             <p className="small me-5 mb-0">© NFT 2021 All Rights Reserved</p>
//             <ul className={'d-flex align-items-center me-auto list-unstyled mb-0'}>
//                 <li className="me-3"><a href="#">Terms</a></li>
//                 <li><a href="#">Privacy policy</a></li>
//             </ul>
//             <ul className={'d-flex align-items-center list-unstyled Links mb-0'}>
//                 <li className="me-3"><a href="#"><i className="fab fa-facebook-f"></i></a></li>
//                 <li className="me-3"><a href="#"><i className="fab fa-twitter"></i></a></li>
//                 <li className="me-3"><a href="#"><i className="fab fa-instagram"></i></a></li>
//                 <li className="me-3"><a href="#"><i className="fab fa-discord"></i></a></li>
//                 <li className="me-3"><a href="#"><i className="fab fa-youtube"></i></a></li>
//                 <li><a href="#"><i className="fab fa-medium-m"></i></a></li>
//             </ul>
//         </div>
//     </footer>
// ) : null;