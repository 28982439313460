import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {registerBulkNFT, listBulkNFTsAPI, deleteNFTAPI, updateNFTDetailAPI, processBulkNFT} from '../../../api/nft/nft';
import {connect} from 'react-redux';
import {mnemonicValidate} from '@polkadot/util-crypto';
import {Keyring} from '@polkadot/api';
import {getNSTBalanceApi, mintBulkNftApi} from '../../../api/wallet/wallet';
import KycStatus from '../../Layouts/KycStatus/KycStatus';
import MissingMnemonics from '../../Layouts/MissingMnemonics/MissingMnemonics';
import ContinueWithPassword from '../../Layouts/ContinueWithPassword/ContinueWithPassword';
import CryptoJS from 'crypto-js';
import {setCypherSecret} from '../../../actions/auth';
import NotLoggedInRedirection from '../../Auth/NotLoggedInRedirection/NotLoggedInRedirection';
import PreviewItem from './PreviewItem/PreviewItem';
import MessageBox from '../../Layouts/MessageBox/MessageBox';
import PageLoader from '../../Layouts/PageLoader/PageLoader';
import PermissionRequired from '../../Layouts/PermissionRequired/PermissionRequired';
import EmptyScreen from './EmptyScreen/EmptyScreen';
import WarningAlert from '../../Layouts/WarningAlert/WarningAlert';
import $ from 'jquery';

const LISTING_URL = process.env.REACT_APP_LISTING_SERVICE || 'http://localhost:4001';

class BulkNFT extends Component {

    model = {
        image: null,
        title: null,
        description: null,
        price: null,
        noOfCopies: 1,
        minBid: null,
        startTime: null,
        endTime: null,
        royalties: 10,
        marketType: 'FIXED PRICE',
        isAvailableForMarket: true,
        unlockOncePurchased: false,
        properties: {
            size: null,
            type: null
        },
        uploadType: null,
        radeemLink: null,
        alternativeText: null,
        is_bulk: 1,
        showAdvance: false
    }

    state = {
        data: {
            image: null,
            title: null,
            description: null,
            price: null,
            noOfCopies: 1,
            minBid: null,
            startTime: null,
            endTime: null,
            royalties: 10,
            marketType: 'FIXED PRICE',
            isAvailableForMarket: true,
            unlockOncePurchased: false,
            properties: {
                size: null,
                type: null
            },
            uploadType: null,
            radeemLink: null,
            alternativeText: null,
            is_bulk: 1,
        },
        imgSrc: null,
        showAdvance: false,
        isError: false,
        message: null,
        isLoading: false,
        mnemonics: null,
        step: 1,
        message_list: [],
        blockHash: null,
        public_address: null,
        balance: null,
        saveMyMnemonics: false,
        mnemonicsPwd: '',
        listNfts: [],
        listNftsApiLoading: true,
        selectedIndex: 0,
        deletingIndex: -1
    }

    componentDidMount() {
        
        if(localStorage.getItem('theme') === 'dark') {
            $(".modal").addClass("DarkModal");
            $(".dropdown-menu").addClass("dropdown-menu-dark");
        }

        if (!this.props.token) return;

        getNSTBalanceApi(this.props.token).then(({data: {balance}}) => {
            this.setState({...this.state, balance: balance});
        }).catch(e => {
        });

        listBulkNFTsAPI(this.props.token).then(({data}) => {
            this.setState({
                ...this.state, listNfts: data.map(_ => {
                    _.marketType = _.type.toUpperCase();
                    _.isAvailableForMarket = true;
                    return _;
                }), listNftsApiLoading: false
            });
        }).catch(_ => {
            this.setState({...this.state, listNftsApiLoading: false});
        })
    }

    onUploadFile = e => {

        if (this.state.listNfts.length + e.target.files.length > 20) {
            this.setState({...this.state, isError: true, message: 'Images limit must be less or equal to 20'}, () => {
                document.getElementById('messageBoxBtn1').click();
            });
            return;
        }

        for (const file of e.target.files) {

            const reader = new FileReader();
            const url = reader.readAsDataURL(file);

            if ((file.size / 1024) / 1024 > 100) {
                document.getElementById('launch-modal-btn').click();
                return;
            }

            reader.onloadend = function (e) {
                this.setState({
                    ...this.state,
                    listNfts: [...this.state.listNfts, {
                        ...this.model,
                        image: file, uploadType: file.type, imgSrc: reader.result
                    }], selectedIndex: 0
                });
            }.bind(this);
        }
    }

    oc = e => {

        const {listNfts, selectedIndex} = this.state;
        
        const data = listNfts[selectedIndex];

        if (e.target.name === 'isAvailableForMarket') {
            data[e.target.name] = !data[e.target.name];
        } else if (e.target.name === 'unlockOncePurchased') {
            data[e.target.name] = !data[e.target.name];
        } else if (e.target.name === 'showAdvance') {
            data[e.target.name] = !data[e.target.name];
        } else if (e.target.name === 'startTime' || e.target.name === 'endTime') {
            data[e.target.name] = e.target.value;
        } else data[e.target.name] = e.target.value;

        data.unsaved = true;

        listNfts[selectedIndex] = data;

        this.setState({...this.state, listNfts});
    }

    onChangeMnemonics = e => {
        e.preventDefault();
        if (mnemonicValidate(e.target.value)) {
            const keyring = new Keyring({
                type: 'sr25519',
                ss58Format: 42,
            });
            const newPair = keyring.addFromUri(e.target.value);
            this.setState({...this.state, mnemonics: e.target.value, public_address: newPair.address});
        } else this.setState({...this.state, mnemonics: e.target.value, public_address: null});
    }

    async submit(e) {
        e && e.preventDefault();

        if (this.state.listNfts.length === 0) {
            return this.setState({...this.state, isError: true, message: 'Please upload Media files!'})
        }

        if (this.props.toggle.toggle_kyc !== undefined && this.props.toggle.toggle_kyc == 1 && this.props.currentUser.kycStatus === 0) {
            return document.getElementById('kycStatusDialogBtn').click();
        } else if (!this.props.currentUser.public_address) {
            return document.getElementById('confirmationMissingMnemonicsBtn').click();
        }

        const {
            title,
            description,
            royalties,
            image,
            marketType,
            price,
            minBid,
            startTime,
            endTime
        } = this.state.listNfts[this.state.selectedIndex];

        if (title === null || title === '')
            return this.setState({...this.state, isError: true, message: 'Title field is required!'});
        else if (description === null || description === '')
            return this.setState({...this.state, isError: true, message: 'Description field is required!'});
        else if (image === null)
            return this.setState({...this.state, isError: true, message: 'Media file is required!'});

        switch (marketType) {
            case 'FIXED PRICE': {
                try {
                    const _price = parseFloat(price);

                    if (Number.isNaN(_price) || _price < 0) {
                        return this.setState({
                            ...this.state,
                            isError: true,
                            message: 'Price must be a positive number.'
                        });
                    }
                } catch (e) {
                    return this.setState({...this.state, isError: true, message: 'Price must be a positive number.'});
                }
                ;
                break;
            }
            case 'Timed Auction': {
                try {
                    const _minBid = parseFloat(minBid);

                    if (Number.isNaN(_minBid) || _minBid < 0) {
                        return this.setState({
                            ...this.state,
                            isError: true,
                            message: 'Minimum Bid must be a positive number.'
                        });
                    }

                    if (startTime === null || endTime === null) {
                        return this.setState({
                            ...this.state,
                            isError: true,
                            message: 'Auction start and end time must be provided.'
                        });
                    }

                    if (new Date(startTime).getTime() > new Date(endTime).getTime()) {
                        return this.setState({
                            ...this.state,
                            isError: true,
                            message: 'Auction start time cannot be greater than end time.'
                        });
                    }

                    if(new Date(startTime).getTime() < Date.now() && `${new Date(startTime).getDate()}-${new Date(startTime).getMonth()+1}-${new Date(startTime).getFullYear()}` !== `${new Date().getDate()}-${new Date().getMonth()+1}-${new Date().getFullYear()}`) {
                        return this.setState({ ...this.state, isError: true, message: 'Auction start time must be greater or equal to current date/time.' });
                    }

                    if(new Date(endTime).getTime() <= Date.now()) {
                        return this.setState({ ...this.state, isError: true, message: 'Auction end time must be greater then current date/time.' });
                    }
                } catch (e) {
                    return this.setState({
                        ...this.state,
                        isError: true,
                        message: 'Minimum Bid must be a positive number.'
                    });
                }
                ;
                break;
            }
        }

        try {
            const _royalties = parseInt(royalties);
            if (Number.isNaN(_royalties)) {
                return this.setState({...this.state, isError: true, message: 'Royalities must a number!'});
            }
            if (_royalties < 0 || _royalties > 50) {
                return this.setState({
                    ...this.state,
                    isError: true,
                    message: 'Royalities are must be 0%, 10%, 20%, 30%. Maximum is 50%'
                });
            }
        } catch (e) {
            return this.setState({...this.state, isError: true, message: 'Royalities must a number!'});
        }

        try {

            this.setState({...this.state, isLoading: true});

            const dataBody = this.state.listNfts[this.state.selectedIndex];

            const formData = new FormData();
            const _d = { ...dataBody };
            delete _d.imgSrc;
            
            formData.append('image', image);
            formData.append('data', JSON.stringify(_d));

            const {data: {payload_hash, token_id}} = await registerBulkNFT(formData, this.props.token);

            const stateNFT = this.state.listNfts[this.state.selectedIndex];

            stateNFT.id = token_id;
            stateNFT.unsaved = false;

            this.state.listNfts[this.state.selectedIndex] = stateNFT;

            this.setState({
                ...this.state,
                isLoading: false,
                isError: false,
                message: null,
                selectedIndex: this.state.listNfts.length - 1 > this.state.selectedIndex ? this.state.selectedIndex + 1 : this.state.selectedIndex,
                listNfts: this.state.listNfts
            });

            // document.getElementById('BulkNftModalBtn').click();

        } catch (e) {
            return this.setState({
                ...this.state,
                isLoading: false,
                isError: true,
                message: e.response && e.response.data ? e.response.data : e.message
            });
        }
        // document.getElementById('confirmationModalBtn').click();
    }

    async updateNftDetail() {
        try {

            if (this.state.listNfts.length === 0) {
                return this.setState({...this.state, isError: true, message: 'Please upload Media files!'})
            }

            if (this.props.toggle.toggle_kyc !== undefined && this.props.toggle.toggle_kyc == 1 && this.props.currentUser.kycStatus === 0) {
                return document.getElementById('kycStatusDialogBtn').click();
            } else if (!this.props.currentUser.public_address) {
                return document.getElementById('confirmationMissingMnemonicsBtn').click();
            }

            const {
                title,
                description,
                royalties,
                image,
                marketType,
                price,
                minBid,
                startTime,
                endTime
            } = this.state.listNfts[this.state.selectedIndex];

            if (title === null || title === '')
                return this.setState({...this.state, isError: true, message: 'Title field is required!'});
            else if (description === null || description === '')
                return this.setState({...this.state, isError: true, message: 'Description field is required!'});
            else if (image === null)
                return this.setState({...this.state, isError: true, message: 'Media file is required!'});

            switch (marketType) {
                case 'FIXED PRICE': {
                    try {
                        const _price = parseFloat(price);

                        if (Number.isNaN(_price) || _price < 0) {
                            return this.setState({
                                ...this.state,
                                isError: true,
                                message: 'Price must be a positive number.'
                            });
                        }
                    } catch (e) {
                        return this.setState({
                            ...this.state,
                            isError: true,
                            message: 'Price must be a positive number.'
                        });
                    }
                    ;
                    break;
                }
                case 'Timed Auction': {
                    try {
                        const _minBid = parseFloat(minBid);

                        if (Number.isNaN(_minBid) || _minBid < 0) {
                            return this.setState({
                                ...this.state,
                                isError: true,
                                message: 'Minimum Bid must be a positive number.'
                            });
                        }

                        if (startTime === null || endTime === null) {
                            return this.setState({
                                ...this.state,
                                isError: true,
                                message: 'Auction start and end time must be provided.'
                            });
                        }

                        if (new Date(startTime).getTime() > new Date(endTime).getTime()) {
                            return this.setState({
                                ...this.state,
                                isError: true,
                                message: 'Auction start time cannot be greater than end time.'
                            });
                        }
                    } catch (e) {
                        return this.setState({
                            ...this.state,
                            isError: true,
                            message: 'Minimum Bid must be a positive number.'
                        });
                    }
                    ;
                    break;
                }
            }

            try {
                const _royalties = parseInt(royalties);
                if (Number.isNaN(_royalties)) {
                    return this.setState({...this.state, isError: true, message: 'Royalities must a number!'});
                }
                if (_royalties < 0 || _royalties > 50) {
                    return this.setState({
                        ...this.state,
                        isError: true,
                        message: 'Royalities are must be 0%, 10%, 20%, 30%. Maximum is 50%'
                    });
                }
            } catch (e) {
                return this.setState({...this.state, isError: true, message: 'Royalities must a number!'});
            }

            this.setState({...this.state, isError: null, message: null, isLoading: true});

            await updateNFTDetailAPI(this.state.listNfts[this.state.selectedIndex].id, this.state.listNfts[this.state.selectedIndex], this.props.token);

            const nftStateDetail = this.state.listNfts[this.state.selectedIndex];
            nftStateDetail.unsaved = false;
            this.state.listNfts[this.state.selectedIndex] = nftStateDetail;
            this.setState({
                ...this.state,
                isError: null,
                message: null,
                listNfts: this.state.listNfts,
                isLoading: false
            });

        } catch (e) {
            this.setState({...this.state, isError: null, message: null, isLoading: false});
        }
    }

    processAPI = async () => {

        if (this.state.saveMyMnemonics) {
            const cypher = CryptoJS.AES.encrypt(this.state.mnemonics, this.state.mnemonicsPwd).toString();
            this.props.setCypherSecret(cypher);
        }

        document.getElementById('confirmationModalBtn').click();

        this.setState({
            ...this.state,
            isLoading: true,
            isError: false,
            message: 'Please wait we are processing you request!'
        }, () => {
            document.getElementById('confirmationModalBtn').click();
        });

        try {
            await processBulkNFT(this.props.token);
            await mintBulkNftApi({secret: this.state.mnemonics}, this.props.token);

            this.setState({
                ...this.state,
                listNfts: [...this.state.listNfts.map(d => {
                    d.is_baking = 1;
                    return d;
                })],
                message:<React.Fragment>Request has been successfully submitted! We are processing your NFT's in background it will take some while to complete the flow. <br></br> Thanks</React.Fragment> ,
                // message: `Request has been successfully submitted! We are processing your NFT's in background it will take some while to complete the flow. \n Thanks`,
                isLoading: false
            });

            document.getElementById('messageBoxBtn1').click();

        } catch (e) {
            this.setState({
                ...this.state,
                isLoading: false,
                isError: true,
                message: e.response && e.response.data ? e.response.data : e.message
            });
            document.getElementById('messageBoxBtn1').click();
        }
    }

    infoModal = () => {
        return (
            <React.Fragment>
                <button style={{display: 'none'}} id="launch-modal-btn" type="button" className="btn btn-primary"
                        data-bs-toggle="modal" data-bs-target="#exampleModal">
                    Launch demo modal
                </button>
                <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">File Size</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                File size must be equal or less then 100mb.
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    confirmationModal = () => (
        <React.Fragment>
            <button style={{display: 'none'}} id="confirmationModalBtn" type="button" className="btn btn-primary"
                    data-bs-toggle="modal" data-bs-target="#confirmationModal">
                Launch demo modal
            </button>
            <div className="modal fade" id="confirmationModal" tabIndex="-1" aria-labelledby="confirmationModalLabel"
                 aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className={this.props.cypherSecret === null ? "modal-content" : "modal-content d-none"}>
                        <div className="modal-header align-items-start">
                            <h5 className="modal-title" id="confirmationModalLabel">Please enter your account mnemonics
                                to confirm transaction</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3">
                                <label className="form-label CeraMed d-block mb-1">Account Mnemonics</label>
                                <textarea
                                    style={{borderColor: !this.state.mnemonics ? '' : mnemonicValidate(this.state.mnemonics) && this.state.public_address && this.state.public_address === this.props.currentUser.public_address ? 'green' : 'red'}}
                                    onChange={this.onChangeMnemonics} name="mnemonics" rows="4"
                                    className="form-control shadow-none"
                                    placeholder="Paste your mnemonics here"></textarea>
                                <p className="form-text mb-0">We are not storing your private key, this can be use one
                                    time for this transaction.</p>
                            </div>
                            <React.Fragment>
                                {
                                    this.state.public_address && this.state.public_address === this.props.currentUser.public_address && (
                                        <React.Fragment>
                                            <strong className={'text-success d-block'}>Looks Good!</strong>
                                            Your public address matched <strong className={'break-all d-block'}>{this.state.public_address}</strong>
                                        </React.Fragment>
                                    )
                                }
                                {
                                    this.state.public_address && this.state.public_address !== this.props.currentUser.public_address && (
                                        <React.Fragment>
                                            <strong className={'text-danger d-block'}> Something Wrong! </strong> Your profile address is not matching with the provided
                                            Mnemonics, Please add the mnemonics which you give us on sign up.
                                        </React.Fragment>
                                    )
                                }
                            </React.Fragment>
                            <div className="form-check pt-3">
                                <input className="form-check-input shadow-none" type="checkbox" value="" id="EncryptCheckBoxBulk" checked={this.state.saveMyMnemonics} onClick={() => this.setState({ ...this.state, saveMyMnemonics: !this.state.saveMyMnemonics })}/>
                                <label className="form-check-label" htmlFor="EncryptCheckBoxBulk">
                                    Encrypt and save my mnemonics on browser for future transactions!
                                </label>
                            </div>
                            <div className="row">
                                {
                                    this.state.saveMyMnemonics && (
                                        <input
                                            onChange={e => this.setState({...this.state, mnemonicsPwd: e.target.value})}
                                            className="form-control BorderDarkGrey shadow-none mt-4" type="password"
                                            name="password" placeholder="Enter Password"/>
                                    )
                                }
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn BtnBorderBlack py-2 px-5"
                                    data-bs-dismiss="modal">Close
                            </button>
                            <button
                                disabled={!mnemonicValidate(this.state.mnemonics) || this.state.public_address && this.state.public_address !== this.props.currentUser.public_address || (this.state.saveMyMnemonics ? this.state.mnemonicsPwd.length < 6 : false)}
                                onClick={this.processAPI.bind(this)} type="button"
                                className="btn BtnBlack py-2 px-5">Confirm
                            </button>
                        </div>
                    </div>
                    <div className={this.props.cypherSecret !== null ? "modal-content" : "modal-content d-none"}>
                        <div className="modal-body">
                            <ContinueWithPassword callback={(mnemonics) => {
                                this.setState({...this.state, mnemonics, mnemonics}, () => {
                                    this.processAPI();
                                });
                            }}/>
                        </div>
                    </div>
                </div>
            </div>


            {/*<div className="modal fade" id="confirmationModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div style={{ maxWidth: '800px' }}  className="modal-dialog">
            <div className="modal-content">
            <main className="py-4">
                <div className="container">
                    <h3 className="mb-4 text-center">Please enter your account mnemonics to confirm transaction</h3>
                    <div className="mb-3">
                        <label className="form-label CeraMed d-block mb-1">Account Mnemonics</label>
                        <textarea  style={{ borderColor: !this.state.mnemonics ? '' : mnemonicValidate(this.state.mnemonics) ? 'green' : 'red' }} onChange={this.onChangeMnemonics} name="mnemonics" rows="4" className="form-control shadow-none"
                                placeholder="Paste your mnemonics here"></textarea>
                        <p className="form-text mb-0">We are not storing your private key, this can be use one time for this transaction.</p>
                    </div>
                    <React.Fragment>
                        {
                            this.state.public_address && this.state.public_address === this.props.currentUser.public_address && (
                                <React.Fragment>
                                    <strong style={{ color: 'green', fontSize: '14px' }}>Looks Good!</strong> Your public address matched <strong>{this.state.public_address}</strong>
                                </React.Fragment>
                            )
                        }
                        {
                            this.state.public_address && this.state.public_address !== this.props.currentUser.public_address && (
                                <React.Fragment>
                                    {this.state.public_address}
                                    <strong style={{ color: 'red', fontSize: '14px' }}>Something Wrong!</strong> Your profile address is not matching with the provided Mnemonics, Please add the mnemonics which you give us on sign up.
                                </React.Fragment>
                            )
                        }
                    </React.Fragment>
                    <div className="col-md-auto mb-3 mb-md-0">
                        <button style={{ width: '100%', marginTop: '20px' }} disabled={!mnemonicValidate(this.state.mnemonics)} onClick={this.processAPI.bind(this)} type="button" className="btn BtnBlack py-2 px-5">Confirm</button>
                    </div>
                </div>
                </main>
            </div>
            </div>
        </div>*/}
        </React.Fragment>
    )

    render() {
        const {listNfts, selectedIndex} = this.state;

        if (this.props.toggle.toggle_create_nft === undefined) return <PageLoader/>
        if (!this.props.toggle.toggle_create_nft) return <PermissionRequired/>

        if (listNfts.length === 0) return (
            <React.Fragment>
                <EmptyScreen onUploadFile={this.onUploadFile}/>
                <MessageBox callback={() => {}} key="messageBoxBtn1" id="messageBoxBtn1" title={this.state.isError ? 'Information' : 'Success'} message={this.state.message}
                            isError={this.state.isError}/>
            </React.Fragment>
        )

        const {
            id,
            isAvailableForMarket,
            unlockOncePurchased,
            price,
            marketType,
            title,
            description,
            royalties,
            showAdvance,
            minBid,
            startTime,
            endTime,
            unsaved
        } = listNfts[selectedIndex];

        console.log('tester ', marketType)

        return (
            <main>
                <section id="CreateCollectible" className="py-5">
                    <div className="container">
                        <div className="row justify-content-center gx-3" id='zIndexMob'>
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-9 col-xxl-9 Fsize_16">

                                <div className="row mb-4">
                                    <div className="col-lg-8 mb-3 mb-sm-0">
                                        <Link className="Fsize_16 brandnew" to="/create">
                                            <svg className="mb-1 me-2" viewBox="0 0 14 12" fill="none" width="14" height="14"
                                                 xlmns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                      d="M6.29436 0.292893C6.68488 -0.0976311 7.31805 -0.0976311 7.70857 0.292893C8.0991 0.683417 8.0991 1.31658 7.70857 1.70711L4.41568 5H12.9985C13.5508 5 13.9985 5.44772 13.9985 6C13.9985 6.55228 13.5508 7 12.9985 7H4.41568L7.70857 10.2929C8.0991 10.6834 8.0991 11.3166 7.70857 11.7071C7.31805 12.0976 6.68488 12.0976 6.29436 11.7071L0.587252 6L6.29436 0.292893Z"
                                                      fill="currentColor"></path>
                                            </svg>
                                            Manage collectible type
                                        </Link>
                                        <h2 className="mb-4 pt-3">Create bulk collectible</h2>

                                        <div className="mb-3 d-none">
                                            <label className="form-label CeraMed">Upload file</label>
                                            <div className="UploadFileAreaSmall Height GreyColor rounded mb-4">
                                                <div id="fileuploader">
                                                    <div className="ajax-upload-dragdrop">
                                                        <div className="ajax-file-upload">
                                                            <p className="mb-2 CeraBold">PNG, GIF, WEBP or MP4 Max
                                                                100mb.</p>
                                                            <form style={{margin: '0px', padding: '0px'}}>
                                                                <input disabled={this.state.listNfts.length > 19}
                                                                       multiple="true" onChange={this.onUploadFile}
                                                                       type="file"
                                                                       accept="image/png, image/gif, image/jpeg, video/mp4"
                                                                       style={{
                                                                           position: 'absolute',
                                                                           cursor: 'pointer',
                                                                           top: '0px',
                                                                           width: '100%',
                                                                           height: '100%',
                                                                           left: '0px',
                                                                           zIndex: '100',
                                                                           opacity: '0'
                                                                       }}/>
                                                            </form>
                                                        </div>
                                                        <button disabled={this.state.listNfts.length > 19} type="button"
                                                                className="mb-0 btn BtnBlack rounded-pill">Choose File
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-content PanelTwoTabs">
                                            <div aria-labelledby="NFT-tab-1" className="tab-pane fade h-100 show active"
                                                 id="Tabing_PanelTwo_1" role="tabpanel">
                                                <div className="d-flex justify-content-between mb-3">
                                                    <div className="">
                                                        <label className="form-label CeraMed d-block mb-0">Put on
                                                            marketplace</label>
                                                        <small>Enter price to allow users instantly purchase your
                                                            NFT</small>
                                                    </div>
                                                    <div className="form-check form-switch">
                                                        <input onChange={this.oc} name="isAvailableForMarket"
                                                               className="form-check-input shadow-none cursor-pointer"
                                                               type="checkbox"
                                                               id="putOnMarket" checked={isAvailableForMarket}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            isAvailableForMarket && (
                                                <React.Fragment>
                                                    <div className="row gx-3 mb-4">

                                                        <div className="col-sm-4 mb-3 mb-sm-0">
                                                            <div onClick={e => {
                                                                e.target.name = 'marketType';
                                                                e.target.value = 'Fixed Price';
                                                                this.oc(e);
                                                            }}
                                                                 className={`rounded border border-grey-color text-center Createcard ${marketType.toLowerCase() === 'Fixed Price'.toLowerCase() ? 'active' : ''} py-4 px-4`}>
                                                                <svg className="mb-3 GreySvgFill" width="41"
                                                                     height="40" viewBox="0 0 41 40" fill="none"
                                                                     xmlns="http://www.w3.org/2000/svg">
                                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                                          d="M9.82263 10C9.82263 9.72386 10.0465 9.5 10.3226 9.5H18.4033C18.5359 9.5 18.6631 9.55268 18.7568 9.64645L29.8478 20.7374C30.043 20.9326 30.043 21.2492 29.8478 21.4445L21.7671 29.5251C21.5718 29.7204 21.2552 29.7204 21.06 29.5251L9.96908 18.4342C9.87531 18.3404 9.82263 18.2133 9.82263 18.0807V10ZM10.3226 6.5C8.38964 6.5 6.82263 8.067 6.82263 10V18.0807C6.82263 19.0089 7.19138 19.8992 7.84776 20.5555L18.9387 31.6464C20.3055 33.0133 22.5216 33.0133 23.8884 31.6464L31.9691 23.5658C33.3359 22.1989 33.3359 19.9829 31.9691 18.616L20.8782 7.52512C20.2218 6.86875 19.3316 6.5 18.4033 6.5H10.3226ZM14.8226 16C15.6511 16 16.3226 15.3284 16.3226 14.5C16.3226 13.6716 15.6511 13 14.8226 13C13.9942 13 13.3226 13.6716 13.3226 14.5C13.3226 15.3284 13.9942 16 14.8226 16Z"
                                                                          fill="black"/>
                                                                </svg>
                                                                <strong className="d-block CeraBold LineHeight-1">Fixed
                                                                    price</strong>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4 mb-3 mb-sm-0">
                                                            <div onClick={e => {
                                                                e.target.name = 'marketType';
                                                                e.target.value = 'Timed Auction';
                                                                this.oc(e);
                                                            }}
                                                                 className={`rounded border border-grey-color text-center Createcard ${marketType.toLowerCase() === 'Timed Auction'.toLowerCase() ? 'active' : ''} py-4 px-4`}>
                                                                <svg className="mb-3 GreySvgFill" width="41"
                                                                     height="40" viewBox="0 0 41 40" fill="none"
                                                                     xmlns="http://www.w3.org/2000/svg">
                                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                                          d="M20.3226 9.5C14.5236 9.5 9.82263 14.201 9.82263 20C9.82263 25.799 14.5236 30.5 20.3226 30.5C26.1216 30.5 30.8226 25.799 30.8226 20C30.8226 14.201 26.1216 9.5 20.3226 9.5ZM6.82263 20C6.82263 12.5442 12.8668 6.5 20.3226 6.5C27.7785 6.5 33.8226 12.5442 33.8226 20C33.8226 27.4558 27.7785 33.5 20.3226 33.5C12.8668 33.5 6.82263 27.4558 6.82263 20Z"
                                                                          fill="black"/>
                                                                    <path opacity="0.4"
                                                                          d="M12.3226 20C12.3226 15.5817 15.9044 12 20.3226 12V20H12.3226Z"
                                                                          fill="black"/>
                                                                </svg>
                                                                <strong className="d-block CeraBold LineHeight-1">Timed
                                                                    auction</strong>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div onClick={e => {
                                                                e.target.name = 'marketType';
                                                                e.target.value = 'Open For Bids';
                                                                this.oc(e);
                                                            }}
                                                                 className={`rounded border border-grey-color text-center ${marketType.toLowerCase() === 'Open For Bids'.toLowerCase() ? 'active' : ''} Createcard py-4 px-4`}>
                                                                <svg className="mb-3 GreySvgFill" width="41"
                                                                     height="40" viewBox="0 0 41 40" fill="none"
                                                                     xmlns="http://www.w3.org/2000/svg">
                                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                                          d="M33.8433 14.6655C31.0225 12.0107 26.6226 12.0107 23.8018 14.6655L20.3226 17.9401L16.8433 14.6655C14.0225 12.0107 9.62258 12.0107 6.80186 14.6655C3.72867 17.5579 3.72867 22.4421 6.80186 25.3345C9.62258 27.9893 14.0226 27.9893 16.8433 25.3345L20.3226 22.0599L23.8018 25.3345C26.6226 27.9893 31.0225 27.9893 33.8433 25.3345C36.9164 22.4421 36.9164 17.5579 33.8433 14.6655ZM18.1339 20L18.1339 20L14.7872 16.8501C13.1216 15.2825 10.5235 15.2825 8.85795 16.8501C7.0433 18.558 7.0433 21.442 8.85795 23.1499C10.5235 24.7175 13.1216 24.7175 14.7872 23.1499L18.1339 20ZM22.5112 20L25.8579 23.1499C27.5235 24.7175 30.1216 24.7175 31.7872 23.1499C33.6018 21.442 33.6018 18.558 31.7872 16.8501C30.1216 15.2825 27.5235 15.2825 25.8579 16.8501L22.5112 20L22.5112 20Z"
                                                                          fill="black"/>
                                                                </svg>
                                                                <strong className="d-block CeraBold LineHeight-1">Open
                                                                    for bids</strong>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        marketType.toLowerCase() === 'FIXED PRICE'.toLowerCase() ? (
                                                            <React.Fragment>
                                                                <label
                                                                    className="form-label CeraMed d-block mb-0">Price</label>
                                                                <div className="input-group mb-3">
                                                                    <input onChange={this.oc} name="price"
                                                                           type="text"
                                                                           value={price || ''}
                                                                           className="form-control shadow-none"
                                                                           placeholder="Enter price for one piece"/>
                                                                    <span className="input-group-text border-0">
                                                                    <div
                                                                        className="dropdown bootstrap-select "> {/**dropup */}
                                                                        <select className="SelectPicker"
                                                                                tabIndex="null">
                                                                        <option
                                                                            data-content="<img className='SelectPickerImg me-2 mb-1' src='images/icon-eth.png'><span className='text-dark'>ETH</span>"> Chrome</option>
                                                                        <option
                                                                            data-content="<img className='SelectPickerImg me-2 mb-1' src='images/icon-dai.png'><span className='text-dark'>DAI</span>"> Chrome</option>
                                                                        <option
                                                                            data-content="<img className='SelectPickerImg me-2 mb-1' src='images/icon-usdc.png'><span className='text-dark'>USDC</span>"> Chrome</option>
                                                                    </select>
                                                                    <button type="button" tabIndex="-1"
                                                                            className="btn dropdown-toggle btn-light"
                                                                            data-bs-toggle="dropdown" role="combobox"
                                                                            aria-owns="bs-select-1"
                                                                            aria-haspopup="listbox"
                                                                            aria-expanded="false" title="NST">
                                                                        <div className="filter-option">
                                                                            <div className="filter-option-inner">
                                                                                <div
                                                                                    className="filter-option-inner-inner">
                                                                                    <img
                                                                                        className="SelectPickerImg me-2 mb-1"
                                                                                        src="images/icon-dai.png"/>
                                                                                    <span
                                                                                        className="text-dark">NST</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </button>
                                                                    </div>
                                                                </span>
                                                                </div>
                                                                <label
                                                                    className="form-label CeraMed d-block GreyColor mb-1">Service
                                                                    fee <span
                                                                        className="BlackColor CeraBold">0%</span></label>
                                                                <label
                                                                    className="form-label CeraMed d-block GreyColor mb-0">You
                                                                    will receive <span
                                                                        className="BlackColor CeraBold">{parseFloat(price || 0) - (parseFloat(price || 0) * 0)} NST </span></label>
                                                            </React.Fragment>
                                                        ) : marketType.toLowerCase() === 'Timed Auction'.toLowerCase() ? (
                                                            <React.Fragment>
                                                                <div className="mb-3">
                                                                    <label
                                                                        className="form-label CeraMed d-block mb-1">Minimum
                                                                        bid</label>
                                                                    <div className="input-group">
                                                                        <input name="minBid" onChange={this.oc}
                                                                               value={minBid || ''}
                                                                               type="text"
                                                                               className="form-control shadow-none"
                                                                               placeholder="Enter Minimum bid"/>
                                                                        <span className="input-group-text border-0">
                                                                        <div
                                                                            className="dropdown bootstrap-select "> {/**dropup */}
                                                                            <select className="SelectPicker"
                                                                                    tabIndex="null">
                                                                            <option
                                                                                data-content="<img className='SelectPickerImg me-2 mb-1' src='images/icon-eth.png'><span className='text-dark'>ETH</span>"> Chrome</option>
                                                                            <option
                                                                                data-content="<img className='SelectPickerImg me-2 mb-1' src='images/icon-dai.png'><span className='text-dark'>DAI</span>"> Chrome</option>
                                                                            <option
                                                                                data-content="<img className='SelectPickerImg me-2 mb-1' src='images/icon-usdc.png'><span className='text-dark'>USDC</span>"> Chrome</option>
                                                                        </select>
                                                                        <button type="button" tabIndex="-1"
                                                                                className="btn dropdown-toggle btn-light"
                                                                                data-bs-toggle="dropdown"
                                                                                role="combobox" aria-owns="bs-select-1"
                                                                                aria-haspopup="listbox"
                                                                                aria-expanded="false" title="DAI">
                                                                            <div className="filter-option">
                                                                                <div className="filter-option-inner">
                                                                                    <div
                                                                                        className="filter-option-inner-inner">
                                                                                        <img
                                                                                            className="SelectPickerImg me-2 mb-1"
                                                                                            src="images/icon-dai.png"/>
                                                                                        <span
                                                                                            className="text-dark">NST</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </button>
                                                                        </div>
                                                                    </span>
                                                                    </div>
                                                                    <p className="form-text">Bids below this amount
                                                                        won’t be allowed.</p>
                                                                </div>
                                                                <div className="mb-3">
                                                                    <div className="row gx-2">
                                                                        <div className="col-xl-6">
                                                                            <label
                                                                                className="form-label CeraMed d-block mb-1">Starting
                                                                                Date</label>
                                                                            <input onChange={this.oc}
                                                                                   value={startTime || ''}
                                                                                   name="startTime" type="date"
                                                                                   className="form-control shadow-none DateTimePicker"/>
                                                                        </div>
                                                                        <div className="col-xl-6">
                                                                            <label
                                                                                className="form-label CeraMed d-block mb-1">Expiration
                                                                                Date</label>
                                                                            <input onChange={this.oc} name="endTime"
                                                                                   type="date"
                                                                                   value={endTime || ''}
                                                                                   className="form-control shadow-none DateTimePicker"/>
                                                                        </div>
                                                                        <div className="col-12">
                                                                            <p className="form-text mb-3">Any bid
                                                                                placed in the last 10 minutes
                                                                                extends the auction by 10 minutes.
                                                                                <a href="#">Learn more how timed
                                                                                    auctions work</a></p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </React.Fragment>
                                                        ) : null
                                                    }
                                                </React.Fragment>
                                            )
                                        }
                                        <div className="tab-content" id="NFTTabContent">
                                            <div aria-labelledby="NFT-tab-1" className="tab-pane fade show active"
                                                 id="NFT-tabing-1" role="tabpanel">
                                                <div className="mb-3">
                                                    <label className="form-label CeraMed d-block mb-1">Title</label>
                                                    <input onChange={this.oc} value={title || ''} name="title"
                                                           type="text"
                                                           className="form-control shadow-none mb-3"
                                                           placeholder="e.g Redeemable T-shirt with logo"/>
                                                </div>
                                                <div className="mb-3">
                                                    <label
                                                        className="form-label CeraMed d-block mb-1">Description</label>
                                                    <textarea onChange={this.oc} value={description || ''}
                                                              name="description" rows="4"
                                                              className="form-control shadow-none"
                                                              placeholder="e.g. After purchasing you’ll be able to get the real T-Shirt"></textarea>
                                                    <p className="form-text mb-0">With preserved line-breaks</p>
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label CeraMed d-block mb-1">Royalties</label>
                                                    <div className="input-group mb-3">
                                                        <input onChange={this.oc} value={royalties || ''}
                                                               name='royalties'
                                                               type="text" className="form-control shadow-none"
                                                               placeholder="10"/>
                                                        <span className="input-group-text border-0"
                                                              id="basic-addon1">%</span>
                                                    </div>
                                                    <p className="form-text">Suggested: 0%, 10%, 20%, 30%. Maximum is
                                                        50%</p>
                                                </div>
                                                <div className="mb-3">
                                                    <button onClick={this.oc} name="showAdvance" type="button"
                                                            className="btn BtnBorder w-100 py-2">{!showAdvance ? 'Show Advanced Settings' : 'Hide Advanced Settings'}
                                                    </button>
                                                </div>
                                                <div className="mb-3">
                                                    {
                                                        showAdvance && (
                                                            <React.Fragment>
                                                                <label
                                                                    className="form-label CeraMed d-block mb-1">Properties <small
                                                                    className="GreyColor">(Optional)</small></label>
                                                                <div className="row gx-2">
                                                                    <div className="col-xl-6">
                                                                        <input onChange={(e) => {
                                                                            const data = listNfts[selectedIndex];
                                                                            data.properties = {
                                                                                ...data.properties,
                                                                                size: e.target.value
                                                                            };
                                                                            listNfts[selectedIndex] = data;
                                                                            this.setState({...this.state, listNfts});
                                                                        }} name="size" type="text"
                                                                               className="form-control shadow-none mb-3"
                                                                               placeholder="e.g. Size"/>
                                                                    </div>
                                                                    <div className="col-xl-6">
                                                                        <input onChange={(e) => {

                                                                            const data = listNfts[selectedIndex];
                                                                            data.properties = {
                                                                                ...data.properties,
                                                                                type: e.target.value
                                                                            };
                                                                            listNfts[selectedIndex] = data;
                                                                            this.setState({...this.state, listNfts});

                                                                        }} name="type" type="text"
                                                                               className="form-control shadow-none mb-3"
                                                                               placeholder="e.g. M"/>
                                                                    </div>
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label className="form-label CeraMed d-block mb-1">Alternative
                                                                        text for
                                                                        NFT<small
                                                                            className="GreyColor"> (Optional)</small></label>
                                                                    <textarea onChange={this.oc} name="alternativeText"
                                                                              rows="4"
                                                                              className="form-control shadow-none"
                                                                              placeholder="Image description in details (do not start with word “image”)"></textarea>
                                                                    <p className="form-text mb-0">Text that will be used
                                                                        in
                                                                        VoiceOver for people
                                                                        with disabilities</p>
                                                                </div>
                                                            </React.Fragment>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            {
                                                this.state.isError && this.state.message && (
                                                    <div className="alert alert-danger" role="alert">
                                                        {this.state.message}
                                                    </div>
                                                )
                                            }
                                            {
                                                !this.state.isError && this.state.message && (
                                                    <div className="alert alert-success" role="alert">
                                                        {this.state.message}
                                                    </div>
                                                )
                                            }
                                            <div className="row justify-content-between align-items-center">
                                                <div className="col-md-auto mb-3 mb-md-0">
                                                    {
                                                        id === undefined && (
                                                            <button disabled={this.state.isLoading}
                                                                    onClick={this.submit.bind(this)} type="button"
                                                                    className={"btn BtnBlack py-2 px-5"}>Save &
                                                                Next {this.state.isLoading &&
                                                                <i className="fas fa-asterisk fa-spin"/>}
                                                            </button>
                                                        )
                                                    }
                                                    {
                                                        id !== undefined && unsaved && (
                                                            <button disabled={this.state.isLoading}
                                                                    onClick={this.updateNftDetail.bind(this)}
                                                                    type="button"
                                                                    className={"btn BtnBlack py-2 px-5"}>Update {this.state.isLoading &&
                                                            <i className="fas fa-asterisk fa-spin"/>}
                                                            </button>
                                                        )
                                                    }
                                                </div>
                                                <div className="col-md-auto mb-3 mb-md-0 d-none">
                                                    <button disabled={this.state.isLoading}
                                                            onClick={this.submit.bind(this)} type="button"
                                                            className="btn BtnBlack py-2 px-5">Create item
                                                    </button>
                                                </div>
                                                <div className="col-md-auto">
                                                    <a href="javascript:void(0);" className="GreyColor"> </a>
                                                    {id && !unsaved && <i style={{color: 'green'}} title="Changes Saved"
                                                                          className="far fa-check-circle"> changes
                                                        saved!</i>}
                                                    {id && unsaved && <i style={{color: '#'}} title="Not Saved"
                                                                         className="fas fa-exclamation"> Unsaved
                                                        changes</i>}
                                                    {!id && (
                                                        <button type="button"
                                                                className="btn bg-transparent border-0 p-0">
                                                            Unsaved changes
                                                            <i data-bs-toggle="tooltip" data-bs-placement="top"
                                                               title="Attach file or add title to save your changes"
                                                               className="fas fa-question-circle ms-2"></i>
                                                        </button>
                                                    )}
                                                </div>


                                                <div className="col-12 mt-2">
                                                    {
                                                        this.state.listNfts.filter(_ => _.id !== undefined).length > 0 && (
                                                            <button disabled={this.state.isLoading}
                                                                    onClick={() => {
                                                                        document.getElementById('confirmationModalBtn').click();
                                                                    }} type="button"
                                                                    className={"btn BtnBlack py-2 px-5"}>Submit
                                                                Bulk {this.state.isLoading &&
                                                                <i className="fas fa-asterisk fa-spin"/>}
                                                            </button>
                                                        )
                                                    }
                                                </div>
                                                {this.state.balance !== null && this.state.balance < 0.1 &&
                                                <label className="BlueColor d-none">Balance is too low to make
                                                    transaction.
                                                    Balance: {this.state.balance}</label>}
                                            </div>
                                            <div className="row justify-content-between align-items-center d-none">
                                                <div className="col-md-auto mb-3 mb-md-0">
                                                    <button type="button" className="btn BtnBlue py-2 px-5">Create
                                                        item
                                                    </button>
                                                </div>
                                                <div className="col-md-auto">
                                                    <a href="javascript:void(0);" className="GreyColor"> </a>
                                                    <button type="button" className="btn bg-transparent border-0 p-0">
                                                        Unsaved changes
                                                        <i data-bs-toggle="tooltip" data-bs-placement="top"
                                                           title="Attach file or add title to save your changes"
                                                           className="fas fa-question-circle"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">

                                        <label className="form-label CeraMed">Upload file</label>
                                        <div className="UploadFileAreaSmall Height GreyColor rounded mb-4">
                                            <div id="fileuploader">
                                                <div className="ajax-upload-dragdrop"
                                                     style={{verticalAlign: 'top', width: '100%'}}>
                                                    <div className="ajax-file-upload" style={{
                                                        position: 'relative',
                                                        overflow: 'hidden',
                                                        cursor: 'default'
                                                    }}>
                                                        <p className="mb-2 Fsize_12 CeraBold">PNG, GIF, WEBP or MP4 Max
                                                            100mb.</p>
                                                        <form style={{margin: '0px', padding: '0px'}}>
                                                            <input multiple="true" onChange={this.onUploadFile}
                                                                   type="file"
                                                                   accept="image/png, image/gif, image/jpeg, video/mp4"
                                                                   style={{
                                                                       position: 'absolute',
                                                                       cursor: 'pointer',
                                                                       top: '0px',
                                                                       width: '100%',
                                                                       height: '100%',
                                                                       left: '0px',
                                                                       zIndex: '100',
                                                                       opacity: '0'
                                                                   }}/>
                                                        </form>
                                                    </div>
                                                    <button disabled={this.state.listNfts.length > 19} type="button"
                                                            className="mb-0 btn BtnBlack rounded-pill">Choose File
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="PreviewBoxSticky">
                                            <label className="form-label CeraMed">Preview </label><label
                                            style={{fontSize: '13px'}}
                                            className="text-secondary">({this.state.listNfts.length}/20)</label>

                                            <div className="BulkImages d-flex flex-wrap">
                                                {
                                                    this.state.listNfts.map((item, i) => <PreviewItem key={item.id} id={item.id}
                                                                                                      unsaved={item.unsaved}
                                                                                                      isSelectedIndex={this.state.selectedIndex === i}
                                                                                                      title={item.title}
                                                                                                      resource_url={item.resource_url ? item.resource_type && item.resource_type !== 'mp4' || this.state.data.uploadType !== 'video/mp4' ? `${LISTING_URL}/v1/media/image/${item.resource_url}` : `${LISTING_URL}/v1/media/video/${item.resource_url}` : item.imgSrc}
                                                                                                      resource_type={item.uploadType}
                                                                                                      price={item.price}
                                                                                                      minimum_bid={item.minimum_bid}
                                                                                                      type={item.type || item.marketType}
                                                                                                      is_baking={item.is_baking}
                                                                                                      callback={(isSelect) => {
                                                                                                          if (isSelect) this.setState({
                                                                                                              ...this.state,
                                                                                                              selectedIndex: i
                                                                                                          });
                                                                                                          else this.setState({
                                                                                                              ...this.state,
                                                                                                              deletingIndex: i
                                                                                                          });// this.setState({ ...this.state, listNfts: this.state.listNfts.filter((_, index) => index !== i ) });
                                                                                                      }}/>)
                                                }
                                                {
                                                    this.state.listNfts.length === 0 &&
                                                    <label className="text-secondary">No preview available</label>
                                                }
                                            </div>
                                        </div>
                                        {/*<img
                                            className="SelectPickerImg me-2 mb-1"
                                            src="images/icon-dai.png"/>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <KycStatus/>
                {this.infoModal()}
                {this.confirmationModal()}
                <MissingMnemonics callback={(e) => this.submit(e)}/>
                <NotLoggedInRedirection/>
                <MessageBox callback={() => {}} key="messageBoxBtn1" id="messageBoxBtn1" title={this.state.isError ? 'Information' : 'Success'} message={this.state.message}
                            isError={this.state.isError}/>
                <WarningAlert change={this.state.deletingIndex} callback={async (i) => {
                    try {
                        if(i === -1)  return this.setState({  ...this.state, deletingIndex: i });
                        if (this.state.listNfts[i].id) {
                            await deleteNFTAPI(this.state.listNfts[i].id, this.props.token);
                        }
                        document.getElementById('warningAlertBtn').click();

                        this.setState({
                            ...this.state,
                            deletingIndex: -1,
                            selectedIndex: i === this.state.selectedIndex ? this.state.selectedIndex === 0 ? 0 : this.state.selectedIndex - 1 : this.state.selectedIndex,
                            listNfts: this.state.listNfts.filter((_, index) => index !== i)
                        });
                    } catch (e) {
                        console.log(e)
                    }
                }}/>
            </main>
        );
    }
}

const mapStateToProps = state => ({
    token: state.Auth.token,
    currentUser: state.Profile.profile,
    cypherSecret: state.Auth.cypherSecret,
    toggle: state.Settings.toggle
})

export default connect(mapStateToProps, {setCypherSecret})(BulkNFT);