import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateNFTDetailAPI } from '../../../api/nft/nft';

const LISTING_URL = process.env.REACT_APP_LISTING_SERVICE || 'http://localhost:4001';
const REACT_APP_PROFILE_SERVICE = process.env.REACT_APP_PROFILE_SERVICE || 'http://localhost:4002';

class EditNFT extends Component {


    state = {
        data: this.props.data,
        isLoading: false,
        error: null
    }

    oc = e => { 
        if(e.target.name === 'isAvailableForMarket') this.setState({ ...this.state, data: { ...this.state.data, [e.target.name]: !this.state.data.isAvailableForMarket  } });
        else if(e.target.name === 'unlockOncePurchased') this.setState({ ...this.state, data: { ...this.state.data, [e.target.name]: !this.state.data.unlockOncePurchased  } });
        else if(e.target.name === 'showAdvance') this.setState({ ...this.state, [e.target.name]: !this.state.showAdvance });
        else if(e.target.name === 'startTime' || e.target.name === 'endTime') this.setState({ ...this.state, data: { ...this.state.data, [e.target.name]: e.target.value } });
        else this.setState({ ...this.state, data: { ...this.state.data, [e.target.name]: e.target.value }});
    }

    submit = async e => {
        this.setState({ ...this.state, error: null, isLoading: true });
        try {
            
            const { title, description } = this.state.data;

            if(title.length < 3) {
                return this.setState({ ...this.state, error: 'Minimum 4 characters are required for title.' });
            }

            if(description.length < 9) {
                return this.setState({ ...this.state, error: 'Minimum 10 characters are required for description.' });
            }

            await updateNFTDetailAPI(this.state.data.id, { title, description }, this.props.token);
            this.props.callback(title, description);
            this.setState({ ...this.state, error: null, isLoading: false });
            document.getElementById('saveBtn').click();
        } catch(e) {
            this.setState({ ...this.state, error: e.message, isLoading: false });
        }
    }

    render() {
        
        const { id, user_id, user_name, display_name, title, description, minimum_bid, bid_end_time, royalties, price, resource_url, resource_type, on_sale, no_of_copies, available_copies, historian, type, nft_minted_hash, nft_order_hash, timestamp, is_burned, order } = this.state.data;
        const { isLoading, error } = this.state;

        return (
            <React.Fragment>
                <button id="editNFTBtn" type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#editNFT">
                    Launch demo modal
                </button>
                <div className="modal fade DarkModal" id="editNFT" tabindex="-1" aria-labelledby="loginConfirmationLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md">
                        <div className="modal-content">
                            <div className="modal-header align-items-center">
                                <h5 className="modal-title" id="loginConfirmationLabel">Edit NFT</h5>
                                <button type="button" className="btn-close m-0" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="mb-3">
                                    <label className="form-label CeraMed d-block mb-1">Title</label>
                                    <input onChange={this.oc} value={title} name="title" type="text" className="form-control shadow-none mb-3"
                                           placeholder="e.g Redeemable T-shirt with logo"/>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label CeraMed d-block mb-1">Description</label>
                                    <textarea onChange={this.oc} value={description} name="description" rows="4" className="form-control shadow-none"
                                              placeholder="e.g. After purchasing you’ll be able to get the real T-Shirt"></textarea>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label CeraMed d-block mb-1 text-danger">{error}</label>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn BtnBorderBlack py-2 px-3 px-md-5" data-bs-dismiss="modal">Close</button>
                                <button id="saveBtn" data-bs-toggle="modal" data-bs-target="#editNFT" type="button" className="btn BtnBlack py-2 px-3 px-md-5 d-none">Save { isLoading && <i className="fas fa-asterisk fa-spin"></i> }</button>
                                <button disabled={isLoading} onClick={this.submit.bind(this)} type="button" className="btn BtnBlack py-2 px-3 px-md-5">Save { isLoading && <i className="fas fa-asterisk fa-spin"></i> }</button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStatetoProps = state => ({
    isLoggedIn: state.Auth.isLoggedIn,
    token: state.Auth.token,
    currentUser: state.Profile.profile,
    balance:    state.Wallet.balances
})

export default connect(mapStatetoProps, {})(EditNFT);