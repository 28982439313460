import React, { useState } from 'react';
import {mnemonicValidate} from "@polkadot/util-crypto";
import { Keyring } from '@polkadot/api';
import { connect } from 'react-redux';
import { setCypherSecret } from '../../../actions/auth';
import { setPublicKey } from '../../../api/profile/profile';

const ChangeWallet = (props) => {

    const [ public_address, setPublicAddress ] = useState(null);
    const [ mnemonics, setMnemonics ] = useState(null);

    const onChangeMnemonics = e => {
        e.preventDefault();

        if (mnemonicValidate(e.target.value)) {
            const keyring = new Keyring({
                type: 'sr25519',
                ss58Format: 42,
            });
            const newPair = keyring.addFromUri(e.target.value);
            
            setMnemonics(e.target.value);
            setPublicAddress(newPair.address);
        } else {
            setMnemonics(e.target.value);
            setPublicAddress(null);
        }
    }

    const [ isLoading, setIsLoading ] = useState(false);
    const [ success, setSuccess ] = useState(null);
    const [ error, setError ] = useState(null);

    const submit = async () => {
        setIsLoading(true);
        try {
            await setPublicKey({ public_address: public_address }, props.token);
            window.location.reload();
        } catch(e) {
            setError(e.response && e.response.data ? e.response.data : e.message);
        }
        setIsLoading(false);
    }

    return (
        <React.Fragment>
            <button style={{ display: 'none' }} id="changeWalletModalBtn" type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#changeWalletModal">
                Launch demo modal
            </button>
            <div className="modal fade" id="changeWalletModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content p-4">
                        <h3 className="mb-4 text-center">Change your wallet address will effect to not showing your previous wallet NFT's under my items section</h3>
                        <div className={ props.cypherSecret === null ? "mb-3" : "mb-3 d-none"}>
                            <label className="form-label CeraMed d-block mb-1">Enter your account mnemonics to confirm</label>
                            <textarea
                                style={{ borderColor: !mnemonics ? '' : mnemonicValidate(mnemonics) ? 'green' : 'red' }}
                                onChange={onChangeMnemonics} name="mnemonics" rows="4"
                                className="form-control shadow-none"
                                placeholder="Paste your mnemonics here"></textarea>
                            <p className="form-text mb-0">We are not storing your private key, this can be use
                                one time for confirming your account public address.</p>
                        </div>
                        <React.Fragment>
                            {
                                public_address && public_address !== props.currentUser.public_address && (
                                    <React.Fragment>
                                        <strong style={{ color: 'green', fontSize: '14px' }}>Looks
                                            Good!</strong> Your public address <br/>
                                        <strong style={{ fontSize: '12px' }}>{public_address}</strong>
                                    </React.Fragment>
                                )
                            }
                        </React.Fragment>
                        {
                            success && (
                                <div style={{ marginTop: '10px' }} className="alert alert-success" role="alert">
                                    {success}
                                </div>
                            )
                        }
                        {
                            error && (
                                <div style={{ marginTop: '10px' }} className="alert alert-danger" role="alert">
                                    {error}
                                </div>
                            )
                        }
                        <div className="col-md-auto mb-3 mb-md-0">
                            {/* onClick={this.processAPI.bind(this)}  */}
                            {
                                !success ? (
                                    props.cypherSecret === null && <button style={{ width: '100%', marginTop: '10px' }}
                                                                           disabled={!mnemonicValidate(mnemonics) || props.currentUser.public_address === public_address || isLoading}
                                                                           onClick={submit}
                                                                           type="button"
                                                                           className="btn BtnBlack py-2 px-5">Confirm { isLoading && <i className="fas fa-asterisk fa-spin text-center" />}
                                    </button>
                                ) : (
                                    <button style={{ width: '100%', marginTop: '10px' }}
                                            onClick={() => window.location.reload()}
                                            type="button"
                                            className="btn BtnBlack py-2 px-5">Refresh
                                    </button>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    token: state.Auth.token,
    currentUser: state.Profile.profile,
    cypherSecret:    state.Auth.cypherSecret
})

export default connect(mapStateToProps, { setCypherSecret })(ChangeWallet);