import invoke from '../../utils/invoke';
import { encrypt } from '../../utils/utils';

const BASE_PATH = process.env.REACT_APP_AUTH_SERVICE || 'http://localhost:2001';
//const BASE_PATH = 'http://localhost:2001';
export const signup = (data) => {
    data = encrypt(data);
    return invoke({
        method: 'POST',
        baseURL: BASE_PATH,
        route: 'v1/auth/register',
        data: data
    });
}


export const generateCaptcha = (data) => {
    data = encrypt(data);
    return invoke({
        method: 'GET',
        baseURL: BASE_PATH,
        route: 'v1/auth/captcha',
        data: data
    });
}


export const login = (data) => {
    data = encrypt(data);
    return invoke({
        method: 'POST',
        baseURL: BASE_PATH,
        route: 'v1/auth/login',
        data: data
    });
}

export const sendOtpApi = (data) => {
    data = encrypt(data);
    return invoke({
        method: 'POST',
        baseURL: BASE_PATH,
        route: 'v1/auth/send/otp',
        data: data
    });
}

export const verifyOtpApi = (data) => {
    data = encrypt(data);
    return invoke({
        method: 'POST',
        baseURL: BASE_PATH,
        route: 'v1/auth/verify/otp',
        data: data
    });
}

export const forgetPasswordValidationApi = (data) => {
    data = encrypt(data);
    return invoke({
        method: 'POST',
        baseURL: BASE_PATH,
        route: 'v1/auth/forgot-pass',
        data: data
    });
}

export const updatePassword = (data) => {
    data = encrypt(data);
    return invoke({
        method: 'POST',
        baseURL: BASE_PATH,
        route: 'v1/auth/update-pass',
        data: data
    });
}