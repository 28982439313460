


import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Modal, Button, Spinner } from 'react-bootstrap';
import MessageModal from "./MessageModal";



import { Document, Page,pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { getOwnProfile, updateProfileEquityFlag } from "../../../api/profile/profile";
import { useDispatch, useSelector } from "react-redux";
import { setProfile } from "../../../actions/profile";

export default ({callBack, isShow, isLoggedIn})=>{

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const [state, setState] = useState({isSubmitting:false , isRead:false})
    const token = useSelector((state) => state.Auth.token);
    const disptach = useDispatch()
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
  
    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
    }

    const submitEquityUpdate = async () =>{
        setState((state)=>({...state,isSubmitting:true}))

        try {

            const formData = new FormData();
            formData.append('data', JSON.stringify({nft_equity_acceptance:1}));

            if(isLoggedIn) {
                await updateProfileEquityFlag(formData, token);
            
                const { data } = await getOwnProfile(token);
                disptach(setProfile(data))
            }
       
            setState((state)=>({...state,isSubmitting:false}))
            // setState((state)=>({...state,showConfirmation:true, modalMsg:  "Email Sent, The Team will contact you soon!",modalTitle:"Email Sent", modalType:"ACCEPTED"}))
            console.log('tester 001 ', callBack)
            callBack?.termsAccepted()
        } catch(e) {
            console.error("err",e)
            setState((state)=>({...state,isSubmitting:false}))
        }
    }

    const onRejectClick = ()=>{
        setState((state)=>({...state,showConfirmation:true, modalMsg:"Your expression of interest will not be submitted.",modalTitle:"", modalType:"REJECTED", showCancelModalBtn:true}))

       
    }

    const acceptTerms = ()=>{
        if(state.isRead)
        {
            submitEquityUpdate()
        }
        else{
            setState((state)=>({...state,showConfirmation:true, modalMsg:"",modalTitle:"", modalType:"NOT_READ"}))

        }
      
    }

    const setPreviousPage= ()=>{
    if(pageNumber > 1 )
        setPageNumber((last)=>(last-1))
    }
    const setNextPAge = ()=>{
    if(pageNumber >= 9 ){
        setState((state)=>({...state,isRead:true}))

    }
    if(pageNumber < 10 )
        setPageNumber((last)=>(last+1))

    }

    useEffect(()=>{
  
        setPageNumber(1);
        setState((state)=>({...state,isRead:false}))
    },[isShow])
  
    return (<>
    
            <Modal
                    show={isShow}
                    // onHide={onHide}
                  
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    dialogClassName="pdf-modal"
                    fullscreen={true}
                    >
                        <Modal.Body className="p-2 disable-overflo"> 
                
                            <div className="d-flex flex-column ">
                                <h5 className=" f-size-15 mb-4 mt-4 text-center ">Please read all pages to accept your expression of interest</h5>
                                <div className="d-flex justify-content-center "  style={{width:'100%', height:"78vh", background:'#ccc' , border:2, borderRadius:2,overflow: 'scroll'}}>
                                    <div className="mb-2 rounded-5 p-1 Example__container__document ">
                                    
                                    

                                        <Document file="/pdf/NuGen_NFTs_Investor.pdf" onLoadSuccess={onDocumentLoadSuccess}  onLoadError ={(err)=>{
                                            console.error(err)
                                        }}  className="lightbox-pdf" >
                                            <Page  pageNumber={pageNumber} width={1000}/>
                                            {/* {Array.from(new Array(numPages), (el, index) => (
                                                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                                                ))} */}
                                        </Document>


                                        
                                    </div>
                                </div>

                                <div className="d-flex  mt-2 footer bg-light pt-3 pb-3">
                                    <div className="d-flex align-items-center justify-content-center  col-3"> 
                                        
                                    </div>
                                                
                                    <div className="d-flex align-items-center justify-content-center col-6"> 
                                        <Button disabled={pageNumber === 1 || numPages == null} className="btn btn-success  w-md radius-10 mx-5" onClick={setPreviousPage} style={{minWidth:120, height:40}}>
                                            Previous
                                        </Button>
                                            Page {pageNumber} of {numPages ?? 0}
                                        <Button disabled={pageNumber === 10 || numPages == null} className="btn btn-success w-md radius-10 mx-5" style={{minWidth:120, height:40}} onClick={setNextPAge}>
                                            Next
                                        </Button>  
                                    </div>


                                    <div className="d-flex align-items-center justify-content-end col-3"> 
                                        <Button className="btn btn-danger white-back w-md radius-10 =" onClick={onRejectClick} style={{minWidth:150, height:40}}>
                                            Reject
                                        </Button>
                                        <Button disabled={!state.isRead} className="btn btn-success w-md radius-10 mx-3" style={{minWidth:150, height:40}}  onClick={acceptTerms}>
                                            {state.isSubmitting ? <Spinner animation="border" size="sm" className="m-1"/> : "Accept" }
                                        </Button> 
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
            </Modal>
        
            <MessageModal  showCancel = {state.showCancelModalBtn} msg={state.modalMsg ?? ""} title={state.modalTitle ?? ""} showModal={state.showConfirmation ?? false}  onCloseYesOrNoModal={(value) => {
                    if (value === true) {
                        if(state.modalType == "REJECTED")
                        {
                            callBack?.termsRejected()
                        }
                        else if(state.modalType == "ACCEPTED")
                        {
                       
                        }
                        
                        setState((state)=>({...state, showConfirmation: false, modalMsg:null, modalTitle:null, modalType:null, showCancelModalBtn:false }))
                    } else {
                        setState((state)=>({...state, showConfirmation: false, modalMsg:null, modalTitle:null, modalType:null, showCancelModalBtn:false }))
                    }
                }}/>
            </>)
}
