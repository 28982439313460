import { SET_THEME_TOGGLE, SET_TOGGLE } from "../types/settings";

const INITIAL_STATE = {
    toggle: {
        toggle_create_nft   :   undefined
    },
    theme: localStorage.getItem('theme') || 'light'
}

function settingsReducer(state = INITIAL_STATE, action) {
    switch(action.type) {
        case SET_TOGGLE: {
            return { ...state, toggle: { ...state.toggle, [action.payload.key]: action.payload.value } }
        }
        case SET_THEME_TOGGLE: {
            return { ...state, theme: action.payload }
        }
        default: {
            return state;
        }
    }
}

export default settingsReducer;