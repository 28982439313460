import React, { Component } from 'react';
import { mnemonicValidate } from '@polkadot/util-crypto';
import { Keyring } from '@polkadot/api';
import { connect } from 'react-redux';
import { approveSingleAuctionOrder, createAuction, createOrder, updateNFTSaleDetailAPI } from '../../../api/nft/nft';

class TurnOnSale extends Component {

    state = {
        data: {
            minBid: null,
            startTime: null,
            endTime: null,
            marketType: 'FIXED PRICE',
            price: null
        },
        mnemonics: null,
        public_address: null,
        isError: false,
        message: null,
        isLoading: false
    }

    oc = e => { 
        if(e.target.name === 'isAvailableForMarket') this.setState({ ...this.state, data: { ...this.state.data, [e.target.name]: !this.state.data.isAvailableForMarket  } });
        else if(e.target.name === 'unlockOncePurchased') this.setState({ ...this.state, data: { ...this.state.data, [e.target.name]: !this.state.data.unlockOncePurchased  } });
        else if(e.target.name === 'showAdvance') this.setState({ ...this.state, [e.target.name]: !this.state.showAdvance });
        else if(e.target.name === 'startTime' || e.target.name === 'endTime') this.setState({ ...this.state, data: { ...this.state.data, [e.target.name]: e.target.value } });
        else this.setState({ ...this.state, data: { ...this.state.data, [e.target.name]: e.target.value } });
    }

    onChangeMnemonics = e => {
        e.preventDefault();

        if (mnemonicValidate(e.target.value)) {
            const keyring = new Keyring({
                type: 'sr25519',
                ss58Format: 42,
            });
            const newPair = keyring.addFromUri(e.target.value);

            this.setState({ ...this.state, mnemonics: e.target.value , public_address: newPair.address });
        } else this.setState({ ...this.state, mnemonics: e.target.value, public_address: null });
    }

    async submit(e) {
        e.preventDefault();

        const { price, minBid, startTime, endTime, marketType } = this.state.data;

        switch(marketType) {
            case 'FIXED PRICE': {
                try {
                    const _price = parseFloat(price);

                    if(Number.isNaN(_price) || _price < 0) {
                        return this.setState({ ...this.state, isError: true, message: 'Price must be a positive number.' });
                    }
                } catch(e) {
                    return this.setState({ ...this.state, isError: true, message: 'Price must be a positive number.' });
                };
                break;
            }
            case 'Timed Auction': {
                try {
                    const _minBid = parseFloat(minBid);

                    if(Number.isNaN(_minBid) || _minBid < 0) {
                        return this.setState({ ...this.state, isError: true, message: 'Minimum Bid must be a positive number.' });
                    }

                    if(startTime === null || endTime === null) {
                        return this.setState({ ...this.state, isError: true, message: 'Auction start and end time must be provided.' });
                    }

                    if(new Date(startTime).getTime() > new Date(endTime).getTime())  {
                        return this.setState({ ...this.state, isError: true, message: 'Auction start time cannot be greater than end time.' });
                    }
                } catch(e) {
                    return this.setState({ ...this.state, isError: true, message: 'Minimum Bid must be a positive number.' });
                };
                break;
            }
        }

        this.setState({ ...this.state, isError: null, message: null, isLoading: true });

        try {

            await updateNFTSaleDetailAPI(this.props.tokenId, this.state.data, this.props.token);
            if(marketType === 'FIXED PRICE') {
                const response = await createOrder({ token_id: this.props.tokenId, secret: this.state.mnemonics }, this.props.token);

                if(response.data.status !== 'ExtrinsicSuccess') {
                    throw new Error(`Transaction reverted by EVM, While creating NFT Order`);
                }

                this.setState({  ...this.state, isLoading: false, isError: null, message: <p>Successfully created! Hash <br/><a href={`https://explorer.nftcity.exchange/block/${response.data.blockHash}`} target="_blank">{response.data.blockHash}</a></p> });

            } else {
                await approveSingleAuctionOrder({ secret: this.state.mnemonics }, this.props.token);
                const response = await createAuction({ secret: this.state.mnemonics, token_id: this.props.tokenId }, this.props.token);

                if(response.data.status !== 'ExtrinsicSuccess') {
                    throw new Error(`Transaction reverted by EVM, While creating NFT Order`);
                }

                this.setState({  ...this.state, isLoading: false, isError: null, message: <p>Successfully created! Hash <br/><a href={`https://explorer.nftcity.exchange/block/${response.data.blockHash}`} target="_blank">{response.data.blockHash}</a></p> });
            }
        } catch(e) {
            this.setState({  ...this.state, isLoading: false, isError: true, message: e.response && e.response.data ? e.response.data : e.message });
        }
    }

    render() {
        
        const { marketType } = this.state.data;

        return(
            <React.Fragment>
                <button style={{ display: 'none' }} id="turnOnSaleModalBtn" type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#turnOnSaleModal">
                    Launch demo modal
                </button>
                <div className="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" id="turnOnSaleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md">
                        <div className="modal-content">
                            <div className={'modal-body'}>
                                <div className="row gx-3 mb-4">
                                    <div className="col-sm-4 mb-3 mb-sm-0">
                                        <div onClick={e => {
                                            e.target.name = 'marketType';
                                            e.target.value = 'FIXED PRICE';
                                            this.oc(e);
                                        }}
                                             className={`rounded border border-grey-color text-center Createcard ${marketType === 'FIXED PRICE' ? 'active' : ''} py-4 px-4`}>
                                            <svg className="mb-3 GreySvgFill" width="41" height="40" viewBox="0 0 41 40"
                                                 fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                      d="M9.82263 10C9.82263 9.72386 10.0465 9.5 10.3226 9.5H18.4033C18.5359 9.5 18.6631 9.55268 18.7568 9.64645L29.8478 20.7374C30.043 20.9326 30.043 21.2492 29.8478 21.4445L21.7671 29.5251C21.5718 29.7204 21.2552 29.7204 21.06 29.5251L9.96908 18.4342C9.87531 18.3404 9.82263 18.2133 9.82263 18.0807V10ZM10.3226 6.5C8.38964 6.5 6.82263 8.067 6.82263 10V18.0807C6.82263 19.0089 7.19138 19.8992 7.84776 20.5555L18.9387 31.6464C20.3055 33.0133 22.5216 33.0133 23.8884 31.6464L31.9691 23.5658C33.3359 22.1989 33.3359 19.9829 31.9691 18.616L20.8782 7.52512C20.2218 6.86875 19.3316 6.5 18.4033 6.5H10.3226ZM14.8226 16C15.6511 16 16.3226 15.3284 16.3226 14.5C16.3226 13.6716 15.6511 13 14.8226 13C13.9942 13 13.3226 13.6716 13.3226 14.5C13.3226 15.3284 13.9942 16 14.8226 16Z"
                                                      fill="black"/>
                                            </svg>
                                            <strong className="d-block CeraBold LineHeight-1">Fixed <br/> price</strong>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 mb-3 mb-sm-0">
                                        <div onClick={e => {
                                            e.target.name = 'marketType';
                                            e.target.value = 'Timed Auction';
                                            this.oc(e);
                                        }}
                                             className={`rounded border border-grey-color text-center Createcard ${marketType === 'Timed Auction' ? 'active' : ''} py-4 px-4`}>
                                            <svg className="mb-3 GreySvgFill" width="41" height="40" viewBox="0 0 41 40"
                                                 fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                      d="M20.3226 9.5C14.5236 9.5 9.82263 14.201 9.82263 20C9.82263 25.799 14.5236 30.5 20.3226 30.5C26.1216 30.5 30.8226 25.799 30.8226 20C30.8226 14.201 26.1216 9.5 20.3226 9.5ZM6.82263 20C6.82263 12.5442 12.8668 6.5 20.3226 6.5C27.7785 6.5 33.8226 12.5442 33.8226 20C33.8226 27.4558 27.7785 33.5 20.3226 33.5C12.8668 33.5 6.82263 27.4558 6.82263 20Z"
                                                      fill="black"/>
                                                <path opacity="0.4"
                                                      d="M12.3226 20C12.3226 15.5817 15.9044 12 20.3226 12V20H12.3226Z"
                                                      fill="black"/>
                                            </svg>
                                            <strong className="d-block CeraBold LineHeight-1">Timed auction</strong>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div onClick={e => {
                                            e.target.name = 'marketType';
                                            e.target.value = 'Open For Bids';
                                            this.oc(e);
                                        }}
                                             className={`rounded border border-grey-color text-center ${marketType === 'Open For Bids' ? 'active' : ''} Createcard py-4 px-4`}>
                                            <svg className="mb-3 GreySvgFill" width="41" height="40" viewBox="0 0 41 40"
                                                 fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                      d="M33.8433 14.6655C31.0225 12.0107 26.6226 12.0107 23.8018 14.6655L20.3226 17.9401L16.8433 14.6655C14.0225 12.0107 9.62258 12.0107 6.80186 14.6655C3.72867 17.5579 3.72867 22.4421 6.80186 25.3345C9.62258 27.9893 14.0226 27.9893 16.8433 25.3345L20.3226 22.0599L23.8018 25.3345C26.6226 27.9893 31.0225 27.9893 33.8433 25.3345C36.9164 22.4421 36.9164 17.5579 33.8433 14.6655ZM18.1339 20L18.1339 20L14.7872 16.8501C13.1216 15.2825 10.5235 15.2825 8.85795 16.8501C7.0433 18.558 7.0433 21.442 8.85795 23.1499C10.5235 24.7175 13.1216 24.7175 14.7872 23.1499L18.1339 20ZM22.5112 20L25.8579 23.1499C27.5235 24.7175 30.1216 24.7175 31.7872 23.1499C33.6018 21.442 33.6018 18.558 31.7872 16.8501C30.1216 15.2825 27.5235 15.2825 25.8579 16.8501L22.5112 20L22.5112 20Z"
                                                      fill="black"/>
                                            </svg>
                                            <strong className="d-block CeraBold LineHeight-1">Open for bids</strong>
                                        </div>
                                    </div>
                                </div>
                                {
                                    marketType === 'FIXED PRICE' ? (
                                        <React.Fragment>
                                            <label className="form-label CeraMed d-block mb-0">Price</label>
                                            <div className="input-group mb-3">
                                                <input onChange={this.oc} name="price" type="text"
                                                       className="form-control shadow-none"
                                                       placeholder="Enter price for one piece"/>
                                                <span className="input-group-text border-0">
                                                    <div className="dropdown bootstrap-select "> {/**dropup */}
                                                        <select className="SelectPicker" tabIndex="null">
                                                        <option
                                                            data-content="<img className='SelectPickerImg me-2 mb-1' src='images/icon-eth.png'><span className='text-dark'>ETH</span>"> Chrome</option>
                                                        <option
                                                            data-content="<img className='SelectPickerImg me-2 mb-1' src='images/icon-dai.png'><span className='text-dark'>DAI</span>"> Chrome</option>
                                                        <option
                                                            data-content="<img className='SelectPickerImg me-2 mb-1' src='images/icon-usdc.png'><span className='text-dark'>USDC</span>"> Chrome</option>
                                                    </select>
                                                    <button type="button" tabIndex="-1"
                                                            className="btn dropdown-toggle btn-light"
                                                            data-bs-toggle="dropdown" role="combobox"
                                                            aria-owns="bs-select-1" aria-haspopup="listbox"
                                                            aria-expanded="false" title="DAI">
                                                        <div className="filter-option">
                                                            <div className="filter-option-inner">
                                                                <div className="filter-option-inner-inner">
                                                                    <img className="SelectPickerImg me-2 mb-1"
                                                                         src="/images/icon-dai.png"/>
                                                                    <span className="text-dark">NST</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </button>
                                                    </div>
                                                </span>
                                            </div>
                                            <label className="form-label CeraMed d-block GreyColor mb-1">Service fee <span
                                                className="BlackColor CeraBold">0%</span></label>
                                            <label className="form-label CeraMed d-block GreyColor mb-0">You will
                                                receive <span
                                                    className="BlackColor CeraBold">{parseFloat(this.state.data.price || 0) - (parseFloat(this.state.data.price || 0) * 0)} NST </span></label>
                                        </React.Fragment>
                                    ) : marketType === 'Timed Auction' ? (
                                        <React.Fragment>
                                            <div className="mb-3">
                                                <label className="form-label CeraMed d-block mb-1">Minimum bid</label>
                                                <div className="input-group">
                                                    <input name="minBid" onChange={this.oc} type="text"
                                                           className="form-control shadow-none"
                                                           placeholder="Enter Minimum bid"/>
                                                    <span className="input-group-text border-0">
                                                        <div className="dropdown bootstrap-select "> {/**dropup */}
                                                            <select className="SelectPicker" tabIndex="null">
                                                            <option
                                                                data-content="<img className='SelectPickerImg me-2 mb-1' src='images/icon-eth.png'><span className='text-dark'>ETH</span>"> Chrome</option>
                                                            <option
                                                                data-content="<img className='SelectPickerImg me-2 mb-1' src='images/icon-dai.png'><span className='text-dark'>DAI</span>"> Chrome</option>
                                                            <option
                                                                data-content="<img className='SelectPickerImg me-2 mb-1' src='images/icon-usdc.png'><span className='text-dark'>USDC</span>"> Chrome</option>
                                                        </select>
                                                        <button type="button" tabIndex="-1"
                                                                className="btn dropdown-toggle btn-light"
                                                                data-bs-toggle="dropdown" role="combobox"
                                                                aria-owns="bs-select-1" aria-haspopup="listbox"
                                                                aria-expanded="false" title="DAI">
                                                            <div className="filter-option">
                                                                <div className="filter-option-inner">
                                                                    <div className="filter-option-inner-inner">
                                                                        <img className="SelectPickerImg me-2 mb-1"
                                                                             src="/images/icon-dai.png"/>
                                                                        <span className="text-dark">NST</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </button>
                                                        </div>
                                                    </span>
                                                </div>
                                                <p className="form-text">Bids below this amount won’t be allowed.</p>
                                            </div>
                                            <div className="mb-3">
                                                <div className="row gx-2">
                                                    <div className="col-xl-6">
                                                        <label className="form-label CeraMed d-block mb-1">Starting
                                                            Date</label>
                                                        <input onChange={this.oc} name="startTime" type="date"
                                                               className="form-control shadow-none DateTimePicker"/>
                                                    </div>
                                                    <div className="col-xl-6">
                                                        <label className="form-label CeraMed d-block mb-1">Expiration
                                                            Date</label>
                                                        <input onChange={this.oc} name="endTime" type="date"
                                                               className="form-control shadow-none DateTimePicker"/>
                                                    </div>
                                                    <div className="col-12">
                                                        <p className="form-text mb-3">Any bid placed in the last 10 minutes
                                                            extends the auction by 10 minutes.
                                                            <a href="#">Learn more how timed auctions work</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    ) : null
                                }
                                <div className="mb-3">
                                    <label className="form-label CeraMed d-block mb-1">Please enter your account mnemonics
                                        to confirm
                                        transaction</label>
                                    <textarea
                                        style={{borderColor: !this.state.mnemonics ? '' : mnemonicValidate(this.state.mnemonics) ? 'green' : 'red'}}
                                        onChange={this.onChangeMnemonics} name="mnemonics" rows="4"
                                        className="form-control shadow-none"
                                        placeholder="Paste your mnemonics here"></textarea>
                                    <p className="form-text mb-0">We are not storing your private key, this can be use
                                        one time for this transaction.</p>
                                </div>
                                <React.Fragment>
                                    {
                                        this.state.public_address && this.state.public_address === this.props.currentUser.public_address && (
                                            <React.Fragment>
                                                <strong className={'d-block text-success'}>Looks
                                                    Good!</strong> Your public address
                                                matched <strong className={'d-block break-all'}>{this.state.public_address}</strong>
                                            </React.Fragment>
                                        )
                                    }
                                    {
                                        this.state.public_address && this.state.public_address !== this.props.currentUser.public_address && (
                                            <React.Fragment>
                                                <strong style={{color: 'red', fontSize: '14px'}}>Something
                                                    Wrong!</strong> Your profile address is not matching with the
                                                provided Mnemonics, Please add the mnemonics which you give us on sign
                                                up.
                                            </React.Fragment>
                                        )
                                    }
                                </React.Fragment>
                                {
                                    this.state.isError && this.state.message && (
                                        <div className="alert alert-danger mt-3 break-all"
                                             role="alert">
                                            {this.state.message}
                                        </div>
                                    )
                                }
                                {
                                    !this.state.isError && this.state.message && (
                                        <div className="alert alert-success mt-3 break-all"
                                             role="alert">
                                            {this.state.message}
                                        </div>
                                    )
                                }
                            </div>
                            <div className="modal-footer">
                                {
                                    !this.state.isError && this.state.message ? (
                                        <button
                                                disabled={this.state.isLoading}
                                                type="button"
                                                onClick={() => this.props.callback && this.props.callback()}
                                                data-bs-toggle="modal" data-bs-target="#turnOnSaleModal"
                                                className="btn BtnBorderBlack py-2 px-3 px-md-5">Ok
                                        </button>
                                    ) : (
                                        <button  disabled={this.state.isLoading}
                                                type="button"
                                                data-bs-toggle="modal" data-bs-target="#turnOnSaleModal"
                                                className="btn BtnBorderBlack py-2 px-3 px-md-5">Close
                                        </button>
                                    )
                                }
                                {
                                    !this.state.isError && !this.state.message && (
                                        <button
                                            disabled={!mnemonicValidate(this.state.mnemonics) || this.props.currentUser.public_address !== this.state.public_address || this.state.isLoading}
                                            onClick={this.submit.bind(this)} type="button"
                                            className="btn BtnBlack py-2 px-3 px-md-5">Confirm {this.state.isLoading && <i
                                            className="fas fa-asterisk fa-spin text-center"/>}
                                        </button>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    token   :   state.Auth.token,
    currentUser :   state.Profile.profile
})

export default connect(mapStateToProps)(TurnOnSale);