import invoke from '../../utils/invoke';

const BASE_PATH = process.env.REACT_APP_LISTING_SERVICE || 'http://localhost:4001';

export const getInvesterInfoApi = (data, token = null) => {
    return invoke({
        method: 'GET',
        baseURL: BASE_PATH,
        route:  'v1/invest/info'
    });
}

export const bookInvesterSlotApi = (data, token = null) => {
    return invoke({
        method: 'POST',
        baseURL: BASE_PATH,
        route:  'v1/invest/book',
        data: data,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export const bookInvesterSlotWithoutAuthApi = (data, token = null) => {
    return invoke({
        method: 'POST',
        baseURL: BASE_PATH,
        route:  'v1/invest/book/visitor',
        data: data,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}