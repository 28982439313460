import React from 'react';

export default () => (
    <React.Fragment>
        <button style={{display: 'none'}} id="betaVersionModalBtn" type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#betaVersionModal">
            Launch demo modal
        </button>
        <div className="modal fade DarkModal" data-bs-backdrop="static" data-bs-keyboard="false" id="betaVersionModal" tabindex="-1" aria-labelledby="messageBoxModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '40%' }}>
                <div className="modal-content">
                    <div className="modal-header align-items-start">
                        <h5 className="modal-title" id="messageBoxModalLabel">Beta Version</h5>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3">
                            <div className="row">
                                <div className="col-4">
                                    <img src="/images/beta-logo.png"/>
                                </div>
                                <div className="col-8">
                                    <label className="text-secondary">
                                        Welcome to NFT City BETA ( early version). This version contains most of the major features of  NFT City.Exchange, 
                                        but is continually being innovated to  add the digital notarised contract (smart contract) features. 
                                        The purpose of BETA version is to allow user testing, with a direct feedback program. All feedback during the BETA version, 
                                        will be taken onboard and adopted into the launch of full NFT City.exchange version.  
                                    </label>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button data-bs-toggle="modal" data-bs-target="#betaVersionModal" type="button" className="btn BtnBlack py-2 px-5">OK</button>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
)