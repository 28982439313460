import React, { Component } from "react";
import Select from 'react-select';
import { searchByUserKeywords } from "../../../api/search/search";
import { Keyring } from '@polkadot/api';
import { mnemonicValidate } from '@polkadot/util-crypto';
import { connect } from "react-redux";
import CryptoJS from 'crypto-js';
import { transferNFTApi } from "../../../api/wallet/wallet";
import { setCypherSecret } from "../../../actions/auth";

class TransferNFT extends Component {

    state = {
        options: [],
        selectedOption: {
            user_name: null, public_address: null
        },
        value: 1,
        mnemonicsPwd: null,
        mnemonics: null,
        public_address: null,
        isLoading: false,
        message: null,
        isError: false,
        success: null,
        saveMyMnemonics: false
    }

    searchUserByUsernameKeywords(q) {
        try {
            searchByUserKeywords(q).then(({ data }) => {
                const users = data.map(({ user_name, public_address }) => ({ value: `${user_name}:${public_address}`, label: `@${user_name}` }));
                this.setState({ ...this.state, options: users });
            }).catch(e => {})
        } catch(e) {}
    }

    submit = async () => {

        await new Promise(resolve => this.setState({ ...this.state, isLoading: true }, resolve));

        if(this.state.saveMyMnemonics) {
            const cypher = CryptoJS.AES.encrypt(this.state.mnemonics, this.state.mnemonicsPwd).toString();
            this.props.setCypherSecret(cypher);
        }

        if(this.props.cypherSecret !== null) {
            try {
                const mnemonics = CryptoJS.AES.decrypt(this.props.cypherSecret, this.state.mnemonicsPwd).toString(CryptoJS.enc.Utf8);
                if(mnemonics == '') throw new Error('Wrong password entered!');
                this.setState({ ...this.state, mnemonics: mnemonics, mnemonicsPwd: '', message: null, isError: false });
            } catch(e) {
                this.setState({ ...this.state, isLoading: false, isError: true, message: 'Wrong password entered!' });
                return;
            }
        }


        try {

            const { token_id, isEquity, availableCopies } = this.props;

            try {
                
                const _noOfCopies = parseInt(availableCopies);
                if(Number.isNaN(_noOfCopies)) {
                    return this.setState({ ...this.state, isLoading: false, isError: true, message: `Value must be a positive integer in between 1 - ${availableCopies}` });
                }
                if(_noOfCopies < 1) {
                    return this.setState({ ...this.state, isLoading: false, isError: true, message: `Value must be a positive integer in between 1 - ${availableCopies}` });
                }
                if(parseInt(this.state.value) > availableCopies) {
                    return this.setState({ ...this.state, isLoading: false, isError: true, message: `Value must be a positive integer in between 1 - ${availableCopies}` });
                }
        
            } catch(e) {
                return this.setState({ ...this.state, isLoading: false, isError: true, message: `Value must be a positive integer in between 1 - ${availableCopies}` });
            }

            const { data } = await transferNFTApi({ token_id: token_id, secret: this.state.mnemonics, beneficiary: this.state.selectedOption.public_address, isEquity: isEquity, value: this.state.value }, this.props.token);

            if (data.status === 'ExtrinsicSuccess') {
                this.setState({
                    ...this.state,
                    isLoading: false,
                    isError: false,
                    message: <p>Transfer successfull!<br/><a className={'break-all'} style={{ color: '#0f0f53b8' }} target="_blank" href={`${process.env.REACT_APP_NFT_EXPLORER}/block/${data.blockHash}`}>{data.blockHash}</a></p>
                });

                if(this.props.callback) this.props.callback();
            }
            else return this.setState({
                ...this.state,
                isLoading: false,
                isError: true,
                message: <p>Transfer unsuccessfull!<br/><a className={'break-all'} style={{ color: '#0f0f53b8' }} target="_blank" href={`${process.env.REACT_APP_NFT_EXPLORER}/block/${data.blockHash}`}>{data.blockHash}</a></p>
            });
        } catch(e) {
            this.setState({
                ...this.state,
                isLoading: false,
                isError: true,
                message: `Unable to transfer NFT`
            });
        }

    }

    onChangeMnemonics = e => {
        e.preventDefault();

        if (mnemonicValidate(e.target.value)) {
            const keyring = new Keyring({
                type: 'sr25519',
                ss58Format: 42,
            });
            const newPair = keyring.addFromUri(e.target.value);

            this.setState({ ...this.state, mnemonics: e.target.value, public_address: newPair.address });
        } else this.setState({ ...this.state, mnemonics: e.target.value, public_address: null });
    }

    render() {

        const { message, isError, isLoading } = this.state;
        const { isEquity } = this.props;

        return (
            <React.Fragment>
                <div className="modal fade" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" id="TransferModal" tabIndex="-1" aria-labelledby="TransferModalModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md">
                        <div className="modal-content">
                            <div className="modal-header align-items-center">
                                <h5 className="modal-title" id="TransferModalModalLabel">Transfer</h5>
                                {/* <button type="button" className="btn-close m-0" data-bs-dismiss="modal" aria-label="Close"></button> */}
                            </div>
                            <div className="modal-body">
                                <div className="mb-3">
                                    <label className="mb-1">Transfer to</label>
                                    <Select
                                        onInputChange={(e) => {
                                            this.searchUserByUsernameKeywords(`?q=${e}`)
                                        }}

                                        onChange={e => {
                                            const [ user_name, public_address ] = e.value.split(':');
                                            this.setState({ ...this.state, selectedOption: { user_name, public_address } })
                                        }}

                                        placeholder="Enter username"
                                        options={this.state.options}
                                        
                                        theme={theme => this.props.theme === 'dark' ? ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                              ...theme.colors,
                                              color: 'white',
                                              primary25: '#607979',
                                              primary50: '#607979',
                                              primary75: '#607979',
                                              neutral70: '#607979',
                                              neutral80: 'white',
                                              neutral90: '#607979',
                                              primary: 'white',
                                              neutral0: '#121212',
                                              neutral5: '#121212',
                                              neutral10: '#121212',
                                              neutral20: '#121212',
                                            },
                                          }) : ({
                                            ...theme,
                                            borderRadius: 0,
                                          }) 
                                        }
                                    />
                                    <label className="pt-1 break-all">{this.state.selectedOption.public_address || ''}</label>
                                    {/* <input type="text" placeholder="Enter Username" className="form-control shadow-none" /> */}
                                </div>
                                <div className="mb-3">
                                    <label className="form-label CeraMed d-block mb-1">Enter value</label>
                                    <input value={this.state.value} className="form-control shadow-none" onChange={e => {
                                        this.setState({ ...this.state, value: e.target.value });
                                    }} placeholder={`Value must be less then ${this.props.availableCopies + 1}`}/>
                                </div>
                                <div className={ this.props.cypherSecret === null ? "" : 'd-none'}>
                                    <h4 className="mb-4 text-center d-none">Please enter your account mnemonics to confirm transaction</h4>
                                    <div className="mb-3">
                                        <label className="form-label CeraMed d-block mb-1">Enter account mnemonics to confirm transaction</label>
                                        <textarea
                                            style={{ borderColor: !this.state.mnemonics ? '' : mnemonicValidate(this.state.mnemonics) ? 'green' : 'red' }}
                                            onChange={this.onChangeMnemonics} name="mnemonics" rows="4"
                                            className="form-control shadow-none"
                                            value={this.state.mnemonics}
                                            placeholder="Paste your mnemonics here"></textarea>
                                        <p className="form-text mb-0">We are not storing your private key, this can be use
                                            one time for this transaction.</p>
                                    </div>
                                    <React.Fragment>
                                        <div className="mb-3">
                                        {
                                            this.state.public_address && this.state.public_address === this.props.currentUser.public_address && (
                                                <React.Fragment>
                                                    <strong className={'text-success d-block'}>Looks Good!</strong> Your public address matched <strong className={'break-all d-block'}>{this.state.public_address}</strong>
                                                </React.Fragment>
                                            )
                                        }
                                        {
                                            this.state.public_address && this.state.public_address !== this.props.currentUser.public_address && (
                                                <React.Fragment>
                                                    <strong className={'text-danger d-block'}>Something Wrong!</strong> Your profile address is not matching with the
                                                    provided Mnemonics, <br/>Please add the mnemonics which you give us on sign up.
                                                </React.Fragment>
                                            )
                                        }
                                        </div>
                                    </React.Fragment>
                                    <div className="form-check pt-3">
                                        <input className="form-check-input shadow-none" type="checkbox" value="" id="TransferNFTCheckBox" checked={this.state.saveMyMnemonics} onClick={() => this.setState({ ...this.state, saveMyMnemonics: !this.state.saveMyMnemonics })} />
                                        <label className="form-check-label" htmlFor="TransferNFTCheckBox">Encrypt and save my mnemonics on browser for future transactions!</label>
                                    </div>
                                    <div className="px-0">
                                        {
                                            this.state.saveMyMnemonics && (
                                                <input onChange={e => this.setState({ ...this.state, mnemonicsPwd: e.target.value })} className="form-control BorderDarkGrey shadow-none mt-4" type="password" name="password" placeholder="Enter Password"/>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className={ this.props.cypherSecret !== null ? "" : 'd-none'}>
                                    <label className="w-100">Enter password to unlock</label>
                                    <label className="w-100 break-all">"{this.props.currentUser.public_address}"</label>
                                    <input onChange={e => this.setState({ ...this.state, mnemonicsPwd: e.target.value })} className="form-control BorderDarkGrey shadow-none mt-4" type="password" name="password" placeholder="Enter Password"/>
                                </div>
                                {
                                    message && (
                                        <div className="mb-3">
                                            {
                                                !isError ? (
                                                    <div className="alert alert-success w-100 Fsize_12 mt-2" role="alert">
                                                        { message }
                                                    </div>
                                                ) : (
                                                    <div className="alert alert-danger Fsize_12 mt-2" role="alert">
                                                        { message }
                                                    </div>
                                                )
                                            }
                                        </div>
                                    )
                                }
                            </div>
                            <div className="modal-footer">
                                <button onClick={() => {
                                    this.setState({        
                                        options: [],
                                        selectedOption: {
                                            user_name: '', public_address: ''
                                        },
                                        mnemonicsPwd: null,
                                        mnemonics: '',
                                        public_address: null,
                                        isLoading: false,
                                        message: null,
                                        isError: false,
                                        success: null,
                                        saveMyMnemonics: false });
                                }} disabled={isLoading} type="button" className="btn BtnBorderBlack py-2 px-3 px-md-5" data-bs-dismiss="modal">Close </button>
                                {
                                    this.props.cypherSecret !== null ? (
                                        <button onClick={this.submit.bind(this)} type="button" disabled={ message && !isError || isLoading || !this.state.selectedOption.public_address} className="btn BtnBlack py-2 px-3 px-md-5">Transfer { isLoading && <i className="fas fa-asterisk fa-spin"/> }</button>

                                    ) : (
                                        <button onClick={this.submit.bind(this)} type="button" disabled={ message && !isError || isLoading || this.state.public_address && this.state.public_address !== this.props.currentUser.public_address || !mnemonicValidate(this.state.mnemonics) || !this.state.selectedOption.public_address} className="btn BtnBlack py-2 px-3 px-md-5">Transfer { isLoading && <i className="fas fa-asterisk fa-spin"/> }</button>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStatetoProps = state => ({
    isLoggedIn: state.Auth.isLoggedIn,
    token: state.Auth.token,
    currentUser: state.Profile.profile,
    balance:    state.Wallet.balances,
    cypherSecret:    state.Auth.cypherSecret,
    theme:  state.Settings.theme
})

export default connect(mapStatetoProps, { setCypherSecret })(TransferNFT);