import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {signup, generateCaptcha} from '../../../api/authentication/authentication';
import {mnemonicValidate, mnemonicGenerate} from '@polkadot/util-crypto';
import {Keyring} from '@polkadot/api';
import PhoneInput from 'react-phone-input-2';
import moment from "moment"
import 'react-phone-input-2/lib/style.css';
import $ from 'jquery';

export default class Register extends Component {
    state = {
        data: {
            fullname: null,
            email: null,
            username: null,
            phone: null,
            country_name: 'Australia',
            country_code: null,
            password: null,
            gender: null,
            date_of_birth: null,
            confirm_password: null,
            isChecked: false
        },
        captcha: {
            text: null,
            image: null
        },
        captchaValue: null,
        message: null,
        isError: false,
        isLoading: false,
        mnemonics: null,
        public_address: null,
        validatedPhoneNumberOnOtp: null
    }
    
    async componentDidMount() {
        this.getCaptcha();
        if(localStorage.getItem('theme') === 'dark') {
            $(".modal").addClass("DarkModal");
            $(".dropdown-menu").addClass("dropdown-menu-dark");
        }
    }

    getCaptcha = async e => {
        e && e.preventDefault();
        try {
            const captchaResponse = await generateCaptcha();
            this.setState({ ...this.state, captcha: captchaResponse.data })
        } catch(e) {}
    }

    oc = e => {
        
        if (e.target.name === 'isChecked') this.setState({
            ...this.state,
            data: {...this.state.data, [e.target.name]: !this.state.data.isChecked}
        }, () => console.log(this.state.data));
        else this.setState({
            ...this.state,
            data: {...this.state.data, [e.target.name]: e.target.value}
        }, () => console.log(this.state.data));
    }

    onChangeMnemonics = e => {

        e.preventDefault && e.preventDefault();
        if (mnemonicValidate(e.target.value)) {
            const keyring = new Keyring({
                type: 'sr25519',
                ss58Format: 42,
            });
            const newPair = keyring.addFromUri(e.target.value);
            this.setState({...this.state, mnemonics: e.target.value, public_address: newPair.address});
        } else this.setState({...this.state, mnemonics: e.target.value});
    }

    submit(e) {
        e.preventDefault();

        const dateRes = moment().diff(moment(this.state.data.date_of_birth, 'YYYYMMDD'), 'years');

        if (this.state.data.fullname === '' || this.state.data.fullname === null) {
            return this.setState({...this.state, isError: true, message: 'Full Name is required!'});
        } else if (this.state.data.email === '' || this.state.data.email === null) {
            return this.setState({...this.state, isError: true, message: 'Email is required!'});
        } else if (this.state.data.phone === '' || this.state.data.phone === null) {
            return this.setState({...this.state, isError: true, message: 'Phone is required!'});
        } else if (this.state.data.username === '' || this.state.data.username === null) {
            return this.setState({...this.state, isError: true, message: 'Username is required!'});
        } else if (!this.state.data.date_of_birth) {
            return this.setState({...this.state, isError: true, message: 'Date of birth is required!'});
        } else if (this.state.captchaValue !== this.state.captcha.text) {
            return this.setState({...this.state, isError: true, message: 'Please enter valid captcha value!'});
        }
        // age should be greater then 18
        else if (this.state.data.gender === '' || this.state.data.gender === null) {
            return this.setState({...this.state, isError: true, message: 'Gender is required!'});
        } else if (this.state.data.password === '' || this.state.data.password === null) {
            return this.setState({...this.state, isError: true, message: 'Password is required!'});
        } else if (this.state.data.password !== this.state.data.confirm_password) {
            return this.setState({...this.state, isError: true, message: 'Password Mismatched!'});
        } else if (!this.state.data.isChecked) {
            return this.setState({
                ...this.state,
                isError: true,
                message: 'Please accept the aggrement before proceed!'
            });
        } else if (dateRes < 18) {
            return this.setState({
                ...this.state,
                isError: true,
                message: 'We’re not able to complete your registration at this time. To sign up for a nftcity exchange account, you must meet certain age requirements which is 18 years and over.'
            });
        }

        this.setState({...this.state, isError: null, message: null});
        document.getElementById('confirmationModalBtn').click();
    }

    process = async () => {

        document.getElementById('confirmationModalBtn').click();
        try {

            const response = await signup({
                ...this.state.data,
                public_address: this.state.public_address,
                isLoading: true
            });
           
            console.log("response", response.data.phoneNumber)
            this.setState({
                ...this.state,
                isLoading: false,
                isError: false,
                message: 'Sign Up Successfull!',
                validatedPhoneNumberOnOtp: response.data.phoneNumber
            });
            document.getElementById('route-to-verification').click();
        } catch (e) {
            this.setState({
                ...this.state,
                isLoading: false,
                isError: true,
                message: e.response && e.response.data ? e.response.data : e.message
            });
        }
    }

    confirmationModal = () => (
        <React.Fragment>
            <button style={{display: 'none'}} id="confirmationModalBtn" type="button" className="btn btn-primary"
                    data-bs-toggle="modal" data-bs-target="#confirmationModal">
                Launch demo modal
            </button>
            <div className="modal fade DarkModal" id="confirmationModal" tabIndex="-1" aria-labelledby="confirmationModalLabel"
                 aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content p-4">
                        {/*<div className="modal-header align-items-start">
                            <h5 className="modal-title" id="confirmationModalLabel">Please enter your account mnemonics
                                to confirm public address which will bind with your profile.</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        </div>*/}
                        <div className="modal-body">
                            <h3 className="mb-4 text-center">Please enter your account mnemonics
                                to confirm public address which will bind with your profile.</h3>
                            <div className="mb-3">
                                <label className="form-label CeraMed d-block mb-1">Account Mnemonics</label>
                                <textarea value={this.state.mnemonics}
                                          style={{borderColor: !this.state.mnemonics ? '' : mnemonicValidate(this.state.mnemonics) ? 'green' : 'red'}}
                                          onChange={this.onChangeMnemonics} name="mnemonics" rows="4"
                                          className="form-control shadow-none"
                                          placeholder="Paste your mnemonics here"></textarea>
                                <p className="form-text mb-0">We are not storing your private key, We are only ensuring
                                    that the public address which will bind with your profile is belongs to you.</p>
                            </div>
                            <div className="mb-3">
                                <button className="btn BtnBorderBlack w-100"
                                        onClick={() => this.onChangeMnemonics({target: {value: mnemonicGenerate()}})}>Generate
                                    New Account Mnemonics
                                </button>
                            </div>
                            <React.Fragment>
                                {
                                    this.state.public_address && (
                                        <React.Fragment>
                                            <strong className={'d-block'} style={{color: 'green', fontSize: '14px'}}>Looks
                                                Great!</strong> Your public address is <strong
                                            className={'break-all d-block'}>{this.state.public_address}</strong>
                                        </React.Fragment>
                                    )
                                }
                            </React.Fragment>
                        </div>
                        <div className="modal-footer border-0">
                            <button type="button" className="btn BtnBorderBlack py-2 px-5"
                                    data-bs-dismiss="modal">Close
                            </button>
                            <button disabled={!mnemonicValidate(this.state.mnemonics)} onClick={this.process.bind(this)}
                                    type="button" className="btn BtnBlack py-2 px-5">Confirm
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )

    render() {
        return (


            <div className="container-fluid" id="SigningPage">
                <div className="row">
                    <div className="col-md-5 col-lg-4 col-xl-3 px-4 px-md-5 sidebar d-flex flex-column py-5 pb-md-0">
                        <Link to="/" className="back-to-home-page rounded d-flex align-items-center me-auto mb-5">
                            <svg className={'me-1'} xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/>
                                <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
                            </svg>
                            Back To Homepage
                        </Link>
                        <div className={'my-auto'}>
                            <h3 className="mb-4">
                                Join NFT City
                            </h3>
                            <p className="Fsize_14">
                                Welcome to NFT City BETA early version, This version contains most of the major features
                                of NFT City Exchange,
                                Which currently is continuously being developed to add the digital notarised contract
                                features.
                                The purpose of BETA version is to allow user testing, with a direct feedback program.
                                All feedback during the BETA version,
                                will be taken onboard and adopted into the launch of full NFT City.
                            </p>
                        </div>
                        <div className="text-center Fsize_13 pt-5 pb-md-5">
                            Copyrights @2021, All Rights Reserved by Nucoin NFT.
                        </div>
                    </div>
                    <div className="col-md-7 col-lg-8 col-xl-9 content">
                        <div className="pt-5 px-4 px-md-5">
                            <div
                                className="border rounded-lg shadow d-flex align-items-center justify-content-between mb-5 p-4 header">
                                <div className="header-left-text">
                                    Already a NFT City member?
                                </div>
                                <div className="header-left-text">
                                    <Link to="/login" className="d-flex align-items-center">
                                        <span className="ms-3">
                                            Log In
                                        </span>
                                        <i className="fas fa-chevron-right login-icon ms-3"></i>
                                    </Link>
                                </div>
                            </div>
                            <div>
                                <h3 className="mb-4">
                                    Let's start with your details
                                </h3>
                            </div>
                            <div>
                                <form id="SignUp" method="post" action="">
                                    <div className="row location mb-4">
                                        <div className="col-md-6 col-lg-6 col-xl-3 mb-4 mb-md-0">
                                            <h4 className="label">
                                                Country
                                            </h4>
                                            <PhoneInput
                                                inputProps={{
                                                    required: true,
                                                    disabled: true
                                                }}
                                                inputClass={'w-100'}
                                                country={'au'}

                                                value={this.state.data.country_code}
                                                onChange={(country_code, {name}) => this.setState({
                                                    ...this.state,
                                                    data: {...this.state.data, country_code, country_name: name}
                                                })}
                                            />
                                        </div>
                                        <div className="col-md-6 col-lg-6 col-xl-3">
                                            <h4 className="label">
                                                Phone
                                            </h4>
                                            <input onChange={this.oc}
                                                   className="form-control shadow-none"
                                                   id="phone" name="phone" placeholder="Enter phone number"/>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <h4 className="label">
                                            Personal Details
                                        </h4>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 col-xl-8 mb-3">
                                            <input onChange={this.oc}
                                                   className="form-control form-control-lg shadow-none" id="fullname"
                                                   name="fullname" placeholder="Enter full name"/>
                                            {/*<input type="text" className="form-control form-control-lg" placeholder="Full Name *">*/}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-lg-6 col-xl-4 mb-3">
                                            {/*<input type="text" className="form-control form-control-lg" placeholder="Email Address *">*/}
                                            <input onChange={this.oc} type="email"
                                                   className="form-control form-control-lg shadow-none"
                                                   id="signin_email_id" name="email" placeholder="Enter your email"/>
                                        </div>
                                        <div className="col-md-6 col-lg-6 col-xl-4 mb-3">
                                            {/*<input type="text" className="form-control form-control-lg" placeholder="User Name *">*/}
                                            <input onChange={this.oc}
                                                   className="form-control form-control-lg shadow-none" autoComplete="off" id="username"
                                                   name="username" placeholder="Enter user name"/>
                                        </div>
                                    </div>

                                    <div className="row align-items-center">
                                        <div className="col-md-6 col-lg-6 col-xl-4 mb-3">
                                            {/*<input type="text" className="form-control form-control-lg" placeholder="Email Address *">*/}
                                            <input onChange={this.oc} type="date"
                                                   className=" form-control form-control-lg shadow-none"//DatePickerField
                                                   id="signin_email_id" name="date_of_birth"
                                                   placeholder="Enter your date of birth"/>
                                        </div>
                                        <div className="col-md-6 col-lg-6 col-xl-4 mb-3">

                                            <div className="custom-checkbox mb-1 me-3">
                                                <input type="radio" className="custom-control-input" name="gender"
                                                       id="inlineRadio1" onChecked={this.state.gender}
                                                       defaultValue="Male"
                                                       onChange={this.oc}
                                                />
                                                <label className="custom-control-label cursor-pointer"
                                                       htmlFor="inlineRadio1">Male</label>
                                            </div>
                                            <div className="custom-checkbox">
                                                <input type="radio" className="custom-control-input" name="gender"
                                                       id="inlineRadio2" onChecked={this.state.gender}
                                                       defaultValue="Female"
                                                       onChange={this.oc}
                                                />
                                                <label className="custom-control-label cursor-pointer"
                                                       htmlFor="inlineRadio2">Female</label>
                                            </div>


                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="col-md-6 col-lg-6 col-xl-4 mb-3">
                                            {/*<input type="text" className="form-control form-control-lg" placeholder="Password *">*/}
                                            <input onChange={this.oc}
                                                   className="form-control form-control-lg shadow-none"
                                                   id="signin_password" type="password" name="password"
                                                   placeholder="Enter your password"/>
                                        </div>
                                        <div className="col-md-6 col-lg-6 col-xl-4 mb-3">
                                            {/*<input type="text" className="form-control form-control-lg" placeholder="Confirm Password *">*/}
                                            <input onChange={this.oc}
                                                   className="form-control form-control-lg shadow-none"
                                                   id="confirm_password" type="password" name="confirm_password"
                                                   placeholder="Enter confirm password"/>
                                        </div>
                                    </div>
                                    <div className="row checkbox">
                                        <div className="col-md-12 col-lg-8 col-xl-8">

                                            <div className="d-flex mb-2">
                                                <label className="form-check-label me-1">
                                                    <input onChange={this.oc} name="isChecked"
                                                           className="form-check-input shadow-none f-14" id="agree"
                                                           type="checkbox"
                                                           checked={this.state.data.isChecked} id="agree"/>
                                                </label>
                                                <label className="form-check-label Fsize_14" htmlFor="agree">
                                                    By clicking Sign Up, I hereby acknowledge that I agree to the
                                                    Crypto.com NFT
                                                    Terms and Conditions and I've read the Privacy Notice..
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        this.state.isError ? <div className="alert alert-danger" role="alert">
                                            {this.state.message}
                                        </div> : null
                                    }
                                    {
                                        !this.state.isError && this.state.message ?
                                            <div className="alert alert-success" role="alert">
                                                {this.state.message}
                                            </div> : null
                                    }
                                    <div className={`row align-items-center gx-2 mb-3 ${this.state.captcha.text === null ? ' d-none ' : ''}`}>
                                        <div className={`col-12`}>
                                            {
                                                this.state.captcha.text && <img className="generated-captcha" src={this.state.captcha.image}/>
                                            }
                                        </div>
                                        <div className="col col-sm-6 col-md-8 col-lg-5">
                                            <input onChange={(e) => {
                                                this.setState({ ...this.state, captchaValue: e.target.value });
                                            }}
                                                   className="form-control shadow-none" autoComplete="off" id="captcha"
                                                   name="captchaValue" placeholder="Enter captcha"/>
                                        </div>
                                        <div className="col-auto">
                                            <span style={{ cursor: 'pointer' }} onClick={this.getCaptcha.bind(this)} className="fas fa-redo-alt"></span>
                                        </div>
                                    </div>
                                    {!(!this.state.isError && this.state.message) &&
                                    <button disabled={this.state.isLoading} onClick={this.submit.bind(this)}
                                            className="btn BtnBlack text-uppercase py-3 px-5 mb-4">Sign
                                        Up {this.state.isLoading &&
                                        <i className="fas fa-asterisk fa-spin"></i>}</button>}
                                </form>
                                
                            </div>
                        </div>
                    </div>
                    {this.confirmationModal()}
                    <Link id="route-to-verification"
                          to={`/verify/otp/${this.state.validatedPhoneNumberOnOtp}`}/>
                </div>
            </div>


        )
    }
}