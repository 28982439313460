import { SET_PROFILE } from "../types/profile";

const INITIAL_STATE = {
    profile: {
        user_name: null, 
        public_address: null
    }
}

function profileReducer(state = INITIAL_STATE, action) {
    switch(action.type) {
        case SET_PROFILE: {
            return { ...state, profile: action.payload }
        }
        default: {
            return state;
        }
    }
}

export default profileReducer;