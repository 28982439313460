export default function PageNotFound() {
    return (
        <>
            <section className='py-5 BgGrey'>
                <div className='container'>
                    <div className="row mb-40">
                        <div className="col-12">
                            <div className="text-center mb-5">
                                <h1 className="display-2 fw-medium text-color d-flex justify-content-center align-items-center">4
                                    {/* <Circle className="Circle" /> */}
                                    <div class="circle-wrapper">
                                        <div class="circle-zero"></div>
                                        <div class="circle-one"></div>
                                        <div class="circle-two"></div>
                                        <div class="circle-three"></div>
                                        <div class="circle-shadow"></div>
                                    </div>
                                    4
                                </h1>
                                <h4 className="text-uppercase text-color">Sorry, page not found</h4>
                                <div className="mt-5 text-center">
                                    <a className="btn btn-primary waves-effect waves-light" href="/">Back to Dashboard</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}