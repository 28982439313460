import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

class NewsTicker extends Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }
    next() {
        this.slider.slickNext();
    }
    previous() {
        this.slider.slickPrev();
    }
    render() {

        const settings = {
            dots: false,
            infinite: true,
            slidesToShow: 1,
            arrows: false,
            slidesToScroll: 1,
            autoplay: true,
            autoplayspeed: 2000,
        };

        return (
            <>
                <section className={`NewsTicker ${this.props.headerNews.length === 0 ? 'd-none' : ''}`}>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-10 offset-1'>
                                <div className={'position-relative w-100 TopStories my-3'}>
                                    <div className='Title'>
                                        <h2 style={{ background: 'linear-gradient(242deg, #69efb7 0%, #11cff3 100%) 0% 0% no-repeat padding-box' }}>Top Stories</h2>
                                    </div>
                                    <Slider ref={c => (this.slider = c)} {...settings}>
                                        {
                                            this.props.headerNews.map(({ id, message }) => (
                                                <div key={id} className='NewsTitle'><h3 className='m-0'>{ReactHtmlParser(message.replaceAll('p>', 'div>'))}</h3></div>
                                            ))
                                        }
                                    </Slider>
                                    <div className={'slick-slider-arrows'}>
                                        <button onClick={this.previous} className="btn p-0 border-0 bg-transparent me-2" type="button"><i className="fas fa-chevron-circle-left"></i></button>
                                        <button onClick={this.next} className="btn p-0 border-0 bg-transparent" type="button"><i className="fas fa-chevron-circle-right"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

const mapStateTopProps = state => ({
    headerNews: state.Notification.header.data
})

export default connect(mapStateTopProps, {})(NewsTicker);