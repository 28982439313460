import { SET_CYPHER_SECRET } from "../types/auth"

export const setCypherSecret = cypher => dispatch => {
    
    localStorage.setItem('cypher-secret', cypher);

    dispatch({
        type    :   SET_CYPHER_SECRET,
        payload :   cypher
    })
}