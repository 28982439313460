import React, { useState } from "react";
// import './delete-modal.css';

export default ({ callback }) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <React.Fragment>
      <button
        id="deleteBtn"
        type="button"
        className="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#deleteModal"
      >
        Launch demo modal
      </button>
      <div
        className="modal fade DarkModal"
        id="deleteModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
            
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Delete Notficiation?
              </h5>
            </div>
            <div className="modal-body">Are you sure you want to delete?</div>
            <div className="modal-footer">
              <button
                disabled={isLoading}
                data-bs-toggle="modal"
                data-bs-target="#deleteModal"
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                disabled={isLoading}
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  setIsLoading(true);
                  callback().then(() => {
                    setIsLoading(false);
                    document.getElementById("deleteBtn").click();
                  });
                }}
              >
                Confirm{" "}
                {isLoading && <span className="fa fa-spinner fa-spin" />}
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
