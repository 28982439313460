import React from "react";
import { Link } from "react-router-dom";

export default () => {

    return (
        <React.Fragment>
        <button style={{display: 'none'}} id="businessVerificationRequredBtn" type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#businessVerificationRequredModal">
            Launch demo modal
        </button>
        <div className="modal fade DarkModal" id="businessVerificationRequredModal" tabindex="-1" aria-labelledby="messageBoxModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-md">
                <div className="modal-content">
                    <div className="modal-header align-items-start">
                        <h5 className="modal-title" id="messageBoxModalLabel">Business Verification - Required</h5>
                    </div>
                    <div className="modal-body">
                    <Link id="redirection" to="/business/verification"/>
                        <div className="mb-3">
                            Business verification on profile is required. Please visit <a href={'javascript:void(0);'} style={{ color: 'blue' }} onClick={() => {
                                document.getElementById('businessVerificationRequredBtn').click();
                                document.getElementById('redirection').click();
                            }}>here</a> to get your business verified!
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button onClick={() => { 
                            document.getElementById('businessVerificationRequredBtn').click(); 
                        }} data-bs-toggle="modal" data-bs-target="#businessVerificationRequredModal" type="button" className="btn BtnBlack py-2 px-5">OK </button>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
    )
}