import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateProfile } from '../../api/profile/profile';
import { setProfile } from '../../actions/profile';
import { AvatarGenerator } from 'random-avatar-generator';
import { Link } from 'react-router-dom';
import { cleanObject, validateEmail, validateWebsite } from '../../utils/utils';
import NotLoggedInRedirection from '../Auth/NotLoggedInRedirection/NotLoggedInRedirection';
import ChangeWallet from '../Layouts/ChangeWallet/ChangeWallet';
import $ from 'jquery';

const REACT_APP_PROFILE_SERVICE = process.env.REACT_APP_PROFILE_SERVICE || 'http://localhost:4002';

class EditProfile extends Component {

    generator = new AvatarGenerator();

    state = {
        profile: this.props.profile,
        imgSrc: null,
        image: null,
        isLoading: false,
        message: null,
        isError: false,
        isDisabled: true
    }

    componentWillReceiveProps(nextProps) {
        if(this.state.profile.user_name !== nextProps.profile.user_name) {
            this.setState({ ...this.state, profile: nextProps.profile });
        }
    }

    componentDidMount() {
        if(localStorage.getItem('theme') === 'dark') {
            $(".modal").addClass("DarkModal");
        }
    }

    oc = e => {
        e.preventDefault();
        this.setState({ ...this.state, isDisabled: false, isError: false, message: null, profile: { ...this.state.profile, [e.target.name]: e.target.value } });
    }

    onUploadFile = e => {
        const file = e.target.files[0];

        const reader = new FileReader();
        const url = reader.readAsDataURL(file);
        
        if((file.size / 1024) / 1024 > 100) {
            document.getElementById('launch-modal-btn').click();
            return;
        }

        reader.onloadend = function (e) {
            this.setState({ ...this.state, isDisabled: false, imgSrc: reader.result, image: file })
        }.bind(this);
    }

    infoModal = () => {
        return (
            <React.Fragment>
                  <button style={{ display: 'none' }} id="launch-modal-btn" type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    Launch demo modal
                </button>
                <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">File Size</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            File size must be equal or less then 100mb.
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Ok</button>
                        </div>
                    </div>
                </div>
            </div>
            </React.Fragment>
        )
    }

    async submit() {
        this.setState({ ...this.state, isLoading: true });
        try {

            if(this.state.profile.email && this.state.profile.email !== '' && !validateEmail(this.state.profile.email)) {
                return this.setState({ ...this.state, isDisabled: false, isError: true, message: 'Please enter the valid Email address!', isLoading: false });
            }
            
            if(this.state.profile.custom_url && this.state.profile.custom_url !== '' && !validateWebsite(this.state.profile.custom_url)) {
                return this.setState({ ...this.state, isDisabled: false, isError: true, message: 'Please enter the valid Custom URL', isLoading: false });
            }

            if(this.state.profile.custom_url && this.state.profile.custom_url !== '' && !validateWebsite(this.state.profile.custom_url)) {
                return this.setState({ ...this.state, isDisabled: false, isError: true, message: 'Please enter the valid Custom URL', isLoading: false });
            }

            if(this.state.profile.site_or_portfolio && this.state.profile.site_or_portfolio !== '' && !validateWebsite(this.state.profile.site_or_portfolio)) {
                return this.setState({ ...this.state, isDisabled: false, isError: true, message: 'Please enter the valid Site Portfolio URL', isLoading: false });
            }

            const profile = cleanObject(this.state.profile);

            const formData = new FormData();
            formData.append('data', JSON.stringify(profile));
            if(this.state.image) formData.append('img', this.state.image);

            await updateProfile(formData, this.props.token);
            this.props.setProfile(this.state.profile);
            this.setState({ ...this.state, isDisabled: true, isError: false, message: 'Changes applied!', isLoading: false });
        } catch(e) {
            this.setState({ ...this.state, isDisabled: false, isError: true, message: 'Unable to apply changes!', isLoading: false });
        }
    }

    render() {
        const { user_name, display_name, custom_url, twitter_username, site_or_portfolio, profile_image, email, business, public_address } = this.state.profile;
        
        return (
            <main>
                <section id="CreateCollectible" className="py-5">
                    <div className="container">
                        <div className="row justify-content-center gx-3" id='zIndexMob'>
                            <div className="col-sm-12 col-md-10 col-lg-8 col-xl-8 Fsize_16">
                                <h2 className="mb-4">Edit profile</h2>
                                <p>You can set preferred display name, create your branded profile URL and manage other personal
                                    settings</p>
                                <div className="row">
                                    <div className="col-sm-8 mb-3 mb-sm-0">
                                        <div className="mb-3">
                                            <label className="form-label CeraMed d-block mb-1">Display name</label>
                                            <input type="text" onChange={this.oc} name="display_name" value={display_name || ''} className="form-control shadow-none"
                                                   placeholder="Enter your display name" />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label CeraMed d-block mb-1">Custom URL</label>
                                            <div className="input-group">
                                                <input style={{ borderColor: custom_url && validateWebsite(custom_url) ? '' : !custom_url || custom_url == '' ? '' : 'red' }} onChange={this.oc} name="custom_url" value={custom_url || ''} type="text" className="form-control shadow-none"
                                                       placeholder="Enter your custom URL" />
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label CeraMed d-block mb-1">Twitter Username</label>
                                            <div className="input-group">
                                                <input onChange={this.oc} name="twitter_username" value={twitter_username || ''} type="text" className="form-control shadow-none" placeholder="@" />
                                                <span className="input-group-text"><a href="#">Link</a></span>
                                            </div>
                                            <p className="form-text mb-0">Link your Twitter account to gain more trust on the
                                                marketplace</p>
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label CeraMed d-block mb-1">Personal site or portfolio</label>
                                            <input style={{ borderColor: site_or_portfolio && validateWebsite(site_or_portfolio) ? '' : !site_or_portfolio || site_or_portfolio === '' ? '' : 'red' }} onChange={this.oc} type="text" name="site_or_portfolio" value={site_or_portfolio || ''} className="form-control shadow-none" placeholder="https://" />
                                        </div>
                                        <div className="mb-3 d-none">
                                            <label className="form-label CeraMed d-block mb-1">Email</label>
                                            <input style={{ borderColor: email && validateEmail(email) ? '' : email && 'red' }} onChange={this.oc} name="email" value={email || ''} type="email" className="form-control shadow-none" placeholder="Email"
                                                />
                                            <p className="form-text mb-2">Your email for marketplace notifications</p>
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label CeraMed d-block mb-1">Change my Wallet</label>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <input onChange={this.oc} name="public_address" value={public_address || ''} type="text" className="form-control me-2 mb-0 shadow-none" placeholder="Public Address"
                                                    readOnly />
                                                <a className="btn BtnBorder flex-auto" onClick={() => {
                                                    document.getElementById('changeWalletModalBtn').click();
                                                }}>Change</a>
                                            </div>
                                            <p className="form-text mb-2">Caution! Change your wallet may cause your NFT's lost.</p>
                                        </div>
                                        <div className="mb-3">
                                            {
                                                business && business.status === null ? (
                                                    <React.Fragment>
                                                        <label className="form-label CeraMed d-block mb-1">Verification</label>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <p className="form-text me-2 mb-0">Proceed with verification process to get more
                                                                visibility and gain trust on NUCOIN Marketplace. Please allow up to several
                                                                weeks for the process.</p>
                                                            <Link to="/business/verification" className="btn BtnBorder flex-auto">Get verified</Link>
                                                        </div>
                                                    </React.Fragment>
                                                ) : business && business.status === 'PENDING' ? (
                                                    <React.Fragment>
                                                        <label className="form-label CeraMed d-block mb-1">{this.props.profile.business.name}</label>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <p className="form-text me-2 mb-0"><strong>Registration Number:</strong> {this.props.profile.business.registration_number}</p>
                                                            <button className="btn BtnBorder flex-auto text-dark" disabled>PENDING</button>
                                                        </div>
                                                    </React.Fragment>
                                                ) : business && business.status === 'APPROVED' ? (
                                                    <React.Fragment>
                                                        <label className="form-label CeraMed d-block mb-1">{this.props.profile.business.name}</label>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <p className="form-text me-2 mb-0"><strong>Registration Number:</strong> {this.props.profile.business.registration_number}</p>
                                                            <button className="btn BtnBorder flex-auto text-success" disabled>APPROVED</button>
                                                        </div>
                                                    </React.Fragment>
                                                ) : business && business.status === 'REJECTED' ? (
                                                    <React.Fragment>
                                                        <label className="form-label CeraMed d-block mb-1">{this.props.profile.business.name}</label>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <p className="form-text me-2 mb-0"><strong>Registration Number:</strong> {this.props.profile.business.registration_number}</p>
                                                            <Link to="/business/verification" className="btn BtnBorder flex-auto text-danger" style={{ backgroundColor: 'white' }}>Rejected, Click to request again!</Link>
                                                        </div>
                                                    </React.Fragment>
                                                ) : null
                                            }

                                        </div>
                                        <div className="mb-3">
                                            <button style={{ backgroundColor: this.state.isError ? 'red' : this.state.message ? '#418641' : '' }} disabled={this.state.isLoading || this.state.isDisabled} onClick={this.submit.bind(this)} className="btn BtnBlack py-2 px-4">{ this.state.isError ? this.state.message : this.state.message ? 'Changes Applied!': 'Update Profile' } {this.state.isLoading && <i className="fas fa-asterisk fa-spin" />}</button>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="PreviewBoxSticky">
                                            {
                                                !this.state.imgSrc && <img style={{ width: '100px', height: '100px' }}  src={ user_name && profile_image ? `${REACT_APP_PROFILE_SERVICE}/v1/profile/image/${user_name}` : this.generator.generateRandomAvatar(user_name)} alt={user_name}
                                                 className="ProfileImg rounded-circle border mb-2"/>
                                            }
                                            {
                                                this.state.imgSrc && <img style={{ width: '100px', height: '100px' }} src={this.state.imgSrc} alt={user_name}
                                                 className="ProfileImg rounded-circle border mb-2"/>
                                            }
                                                <input id="input-file" onChange={this.onUploadFile} type="file"  accept="image/png, image/gif, image/jpeg" style={{ display: 'none' }}/>
                                            <label className="form-label CeraMed">We recommend an image of at least 400x400. Gifs
                                                work too.</label>
                                            <button onClick={() => {
                                                document.getElementById('input-file').click();
                                            }} type="submit" className="btn BtnBlack py-2 px-4">Choose file</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </section>
            { this.infoModal() }
            <NotLoggedInRedirection />
            <ChangeWallet />
        </main>
        )
    }
}

const mapStateToProps = state => ({
    profile :   state.Profile.profile,
    token   :   state.Auth.token
})

export default connect(mapStateToProps, {
    setProfile
})(EditProfile);