import React from 'react';
import SkeletonLoading from '../SkeletonLoading/SkeletonLoading';
import { Link } from 'react-router-dom';

const REACT_APP_PROFILE_SERVICE = process.env.REACT_APP_PROFILE_SERVICE || 'http://localhost:4002';

export default ({user}) => (
    <Link to={`/profile/${user && user?.user_name}`}>
    <div className={'ResultBox border-bottom pb-3 mb-3'}>
        <div className={'d-flex'}>
            { user && user.user_name && user.profile_image && <img style={{ width: '80px', height: '80px' }} src={`${REACT_APP_PROFILE_SERVICE}/v1/profile/image/${user.user_name}`} alt="Profile Image" className="
            ImgResultNft border rounded p-1 me-3"/> }
            { user && user.user_name && !user.profile_image && <img style={{ width: '80px', height: '80px' }} src="/images/user.png" alt="Profile Image" className="ImgResultNft border rounded p-1 me-3"/> }
            { !user && <SkeletonLoading style={{ width: '80px', height: '80px' }} className="ImgResultNft border rounded p-1 me-3"/> }
            {/* <img className={'ImgResultNft border rounded p-1 me-3'} src="/images/imgbox-1.jpg" alt="NFT" /> */}
            <div className={'w-100 TextWhiteOnDarkMode overflow-hidden'}>
                <h4 className="CeraBold mb-2">{user && user.display_name || user && user.user_name || <SkeletonLoading style={{ marginLeft: '6px' }}/>}</h4>
                { user && <p className={'mb-0'}>Username: <span className="text-truncate">{user.user_name}</span></p>}
                { !user && <p className={'mb-0'}><SkeletonLoading style={{ marginLeft: '6px', width: '100%' }}/></p>}
            </div>
        </div>
    </div>
    </Link>
)