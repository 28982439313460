import React, { useState } from 'react';
import {mnemonicValidate} from "@polkadot/util-crypto";
import { Keyring } from '@polkadot/api';
import { connect } from 'react-redux';
import { burnNFTAPI } from '../../../api/wallet/wallet';
import { setCypherSecret } from '../../../actions/auth';
import ContinueWithPassword from '../ContinueWithPassword/ContinueWithPassword';

const BurnNFT = (props) => {

    const [ public_address, setPublicAddress ] = useState(null);
    const [ mnemonics, setMnemonics ] = useState(null);

    const onChangeMnemonics = e => {
        e.preventDefault();

        if (mnemonicValidate(e.target.value)) {
            const keyring = new Keyring({
                type: 'sr25519',
                ss58Format: 42,
            });
            const newPair = keyring.addFromUri(e.target.value);
            
            setMnemonics(e.target.value);
            setPublicAddress(newPair.address);
        } else {
            setMnemonics(e.target.value);
            setPublicAddress(null);
        }
    }

    const [ isLoading, setIsLoading ] = useState(false);
    const [ success, setSuccess ] = useState(null);
    const [ error, setError ] = useState(null);

    const submit = async (_mnemonics) => {
        setIsLoading(true);
        try {
            const { data } = await burnNFTAPI({ secret: mnemonics || _mnemonics, token_id: props.tokenId }, props.token);
            setSuccess(<p>Block hash <a href={`https://explorer.nftcity.exchange/block/${data.blockHash}`} target="_blank">{data.blockHash}</a></p>);
        } catch(e) {
            console.log('tester ', e)
            setError(e.response && e.response.data ? e.response.data : e.message);
        }
        setIsLoading(false);
    }

    return (
        <React.Fragment>
            <button id="burnNFTModalBtn" type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#burnNFTModal">
                Launch demo modal
            </button>
            <div className="modal fade" tabindex="-1"  data-bs-backdrop="static" data-bs-keyboard="false" id="burnNFTModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md">
                    <div className="modal-content">
                        <div className="modal-header align-items-start">
                            <h5 className="modal-title">Are you sure you want to burn '{props.title}' NFT with token id '{props.tokenId}'?</h5>
                            { props.cypherSecret === null && <button onClick={() => {
                                setMnemonics('');
                                setPublicAddress('');
                            }} type="button" className="btn-close m-0" data-bs-dismiss="modal" aria-label="Close"></button> }
                        </div>
                        <div className="modal-body">
                            <div className={ props.cypherSecret === null ? "mb-3" : "mb-3 d-none"}>
                                <label className="form-label CeraMed d-block mb-1">Enter your account mnemonics to confirm</label>
                                <textarea
                                    style={{ borderColor: !mnemonics ? '' : mnemonicValidate(mnemonics) ? 'green' : 'red' }}
                                    onChange={onChangeMnemonics} name="mnemonics" rows="4"
                                    className="form-control shadow-none"
                                    value={mnemonics}
                                    placeholder="Paste your mnemonics here"></textarea>
                                <p className="form-text mb-0">We are not storing your private key, this can be use
                                    one time for this transaction.</p>
                            </div>
                            <div className={props.cypherSecret === null ? 'mb-3 d-none' : 'mb-3'}>
                                <ContinueWithPassword isLoading={isLoading} callback={(mnemonics) => {
                                    setMnemonics(mnemonics);
                                    submit(mnemonics)
                                }} />
                            </div>
                            <React.Fragment>
                                {
                                    public_address && public_address === props.currentUser.public_address && (
                                        <React.Fragment>
                                            <strong style={{ color: 'green', fontSize: '14px' }}>Looks
                                                Good!</strong> Your public address
                                            matched <strong className={'break-all d-block'}>{public_address}</strong>
                                        </React.Fragment>
                                    )
                                }
                                {
                                    public_address && public_address !== props.currentUser.public_address && (
                                        <React.Fragment>
                                            <strong style={{ color: 'red', fontSize: '14px' }}>Something
                                                Wrong!</strong> Your profile address is not matching with the
                                            provided Mnemonics, Please add the mnemonics which you give us on sign
                                            up.
                                        </React.Fragment>
                                    )
                                }
                            </React.Fragment>
                            {
                                success && (
                                    <div  className="alert alert-success mt-3 break-all" role="alert">
                                        {success}
                                    </div>
                                )
                            }
                            {
                                error && (
                                    <div className="alert alert-danger mt-3 break-all" role="alert">
                                        {error}
                                    </div>
                                )
                            }
                        </div>
                        <div className={props.cypherSecret !== null && success === null ? " modal-footer d-none" : "modal-footer"}>
                            { props.cypherSecret === null && <button onClick={() => {
                                setMnemonics('');
                                setPublicAddress('');
                            }} type="button" className="btn BtnBorderBlack py-2 px-3 px-md-5" data-bs-dismiss="modal">Close</button> }
                            {/* onClick={this.processAPI.bind(this)}  */}
                            {
                                !success ? (
                                    props.cypherSecret === null && <button  disabled={!mnemonicValidate(mnemonics) || props.currentUser.public_address !== public_address || isLoading}
                                                                           onClick={submit}
                                                                           type="button"
                                                                           className="btn BtnBlack py-2 px-3 px-md-5">Confirm { isLoading && <i className="fas fa-asterisk fa-spin text-center" />}
                                    </button>
                                ) : (
                                    <button
                                            onClick={() => window.location.reload()}
                                            type="button"
                                            className="btn BtnBlack py-2 px-3 px-md-5">Refresh
                                    </button>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    token: state.Auth.token,
    currentUser: state.Profile.profile,
    cypherSecret:    state.Auth.cypherSecret
})

export default connect(mapStateToProps, { setCypherSecret })(BurnNFT);