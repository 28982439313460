import React, { Component } from 'react';
import 'react-tabs/style/react-tabs.css';
import DeleteIcon from '@material-ui/icons/Delete';
import { getNotificationById, markNotificationAsRemoveById } from 'notificationsdk';
import ReactHtmlParser from 'react-html-parser';
import timeAgo from 'time-ago';
import DeleteModal from '../../Layouts/DeleteModal/DeleteModal';
import { Redirect } from 'react-router';
import SkeletonLoading from '../../Layouts/SkeletonLoading/SkeletonLoading';

class NotificationList extends Component {

    state = {
        data: {},
        isLoading: true,
        isRedirect: false
    }

    async componentDidMount(id = undefined) {
        try {

            const data = await getNotificationById( id ? id : this.props.match.params.id );
            this.setState({ ...this.state, data, isLoading: false });
        } catch(e) {
            console.log('tester ', e)

            this.setState({ ...this.state, isLoading: false, isError: e.message })
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.match.params.id !== this.props.match.params.id) {
            this.setState({ ...this.state, isLoading: true });
            this.componentDidMount(nextProps.match.params.id);
        }
    }

    deleteNotification = async () => {
        return new Promise(resolve => {
            markNotificationAsRemoveById(this.props.match.params.id).then(() => {
                resolve();
                setTimeout(() => {
                    this.setState({ ...this.state, isRedirect: true });
                }, 500); 
            } ).catch(resolve);
        })
    }

    render() {
        const { title, message, category, create_time, ttl } = this.state.data;
        const { isLoading, isRedirect } = this.state;

        return (
            <>
            <DeleteModal callback={this.deleteNotification} />
                <section className='py-5 BgGrey'>
                    <div className='container'>
                        <div className="row mb-40" id='zIndexMob'>
                            <div className="col-md-8 offset-md-2 col-sm-12">
                                <div className="NotificationList">
                                    <div className="card Box top_earning">
                                        <div className="card-header bg-transparent border-bottom pl-0">
                                            <div className="d-flex flex-wrap align-items-start">
                                                <div className="me-2">
                                                    <h3 className="card-title mt-1 mb-0"><strong>Notification Detail { isLoading && <span className="fa fa-spinner fa-spin"/> } </strong></h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body p-0">
                                            <ul className="list-group list-group-flush p-0 list-inline">
                                                <li style={{ cursor: 'pointer' }} className="py-3 px-0 border-bottom position-relative">
                                                    <div className="d-flex">
                                                        <div className="me-3 rounded bg-white d-flex justify-content-center align-self-center" style={{ width: "72px" }}>
                                                            <img src="/images/notifications.png" alt="" className="avatar-md h-auto d-block py-3" style={{ width: "50px" }} />
                                                        </div>
                                                        <div className="align-self-center overflow-hidden me-auto NotificationList">
                                                            <div>
                                                                <h5 className="mb-1">
                                                                    <strong>
                                                                        { title || <SkeletonLoading style={{ width: '500px' }} /> }
                                                                    </strong>
                                                                </h5>
                                                                <h4 className="mb-1 Fsize_14">{ message ? ReactHtmlParser(message) : <SkeletonLoading style={{ width: '100%' }} /> }</h4>
                                                                <h6 className="mb-0 Fsize_12">
                                                                    <span>{ create_time ? timeAgo.ago(new Date(create_time)) : <SkeletonLoading style={{ width: '300px' }} /> }</span>
                                                                </h6>
                                                                {
                                                                    ttl ? (
                                                                        <h6 className="mb-0 Fsize_10 text-muted">
                                                                            <span>{`Disappearing in ${timeAgo.ago(ttl)}`}</span>
                                                                        </h6>
                                                                    ) : <></>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{ position: 'absolute', bottom: '10px', right: '0px' }}>
                                                        { 
                                                            !ttl && message && <DeleteIcon onClick={() => {
                                                                document.getElementById('deleteBtn').click();
                                                            }} style={{ color: '#808080', width: '30px', height: '30px' }}></DeleteIcon>
                                                        }
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    { isRedirect && <Redirect to="/" /> }
                </section>
            </>
        )
    }

}
export default NotificationList;