import { SET_NST_BALANCE, SET_NUC_BALANCE } from "../types/wallet";

const INITIAL_STATE = {
    balances: {
        NUC: null,
        NST: null
    }
}

function walletReducer(state = INITIAL_STATE, action) {
    switch(action.type) {
        case SET_NST_BALANCE:
            return { ...state, balances: { ...state.balances, NST: action.payload } }
        case SET_NUC_BALANCE: 
            return { ...state, balances: { ...state.balances, NUC: action.payload } }
        default: {
            return state;
        }
    }
}

export default walletReducer;