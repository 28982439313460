import { SET_NUC_BALANCE, SET_NST_BALANCE } from "../types/wallet"

export const setNUCBalance = balance => dispatch => {
    dispatch({
        type    :   SET_NUC_BALANCE,
        payload :   balance
    })
}

export const setNSTBalance = balance => dispatch => {
    dispatch({
        type    :   SET_NST_BALANCE,
        payload :   balance
    })
}
