import React, { Component } from "react";
import {
  analyzeMintingFlow,
  cancelOrderAPI,
  createOrder,
  finalizeAuctionAPI,
  getBidHistoriansAPI,
  getBidingStatusByTokenIdAPI,
  getNFTTokenDetailAPI,
  makeReactionApi,
  purchaseNFTToken,
} from "../../api/nft/nft";
import { updateHeartLikeAction } from "../../actions/home";
import { mnemonicValidate } from "@polkadot/util-crypto";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ConfirmLogin from "../Layouts/ConfirmLogin/ConfirmLogin";
import { Keyring } from "@polkadot/api";
import moment from "moment";
import Countdown from "react-countdown";
import PlaceBid from "../Layouts/PlaceBid/PlaceBid";
import BidHistorianItem from "../Layouts/BidHistorianItem/BidHistorianItem";
import MessageBox from "../Layouts/MessageBox/MessageBox";
import BurnNFT from "../Layouts/BurnNFT/BurnNFT";
import { setSocialShareDetail } from "../../actions/shareSocial";
import InsufficientBalance from "../Layouts/InsufficientBalance/InsufficientBalance";
import EditNFT from "../Layouts/EditNFT/EditNFT";
import KycStatus from "../Layouts/KycStatus/KycStatus";
import TurnOnSale from "../Layouts/TurnOnSale/TurnOnSale";
import MissingMnemonics from "../Layouts/MissingMnemonics/MissingMnemonics";
import CryptoJS from "crypto-js";
import { setCypherSecret } from "../../actions/auth";
import ContinueWithPassword from "../Layouts/ContinueWithPassword/ContinueWithPassword";
import PaymentMethodSelection from "../Layouts/PaymentMethodSelection/PaymentMethodSelection";
import PayPalPaymentModal from "../Layouts/PayPalPaymentModal/PayPalPaymentModal";
import TransferNFT from "../Layouts/TransferNFT/TransferNFT";
import { isVideo, onlyAdmins, parseQueryParamsToObject } from "../../utils/utils";
import GeneratePromoCode from "../Layouts/GeneratePromoCode/GeneratePromoCode";
import RedeemNFT from "../Layouts/RedeemNFT/RedeemNFT";
import FiatPaymentResponse from "../Layouts/FiatPaymentResponse/FiatPaymentResponse";
import $ from "jquery";
import SkeletonLoading from "../Layouts/SkeletonLoading/SkeletonLoading";
import TopUpBalance from "../Layouts/TopUpBalance/TopUpBalance";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import CommentSection from "./CommentSection/CommentSection";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import NftLikerListModal from "./NftLikerListModal";
import CommentInput from "./CommentSection/CommentInput/CommentInput";
import Heart from 'react-heart';

const LISTING_URL = process.env.REACT_APP_LISTING_SERVICE || "http://localhost:4001";
const REACT_APP_PROFILE_SERVICE = process.env.REACT_APP_PROFILE_SERVICE || "http://localhost:4002";

class ItemDetail extends Component {
  commentSectionRef = React.createRef();
  state = {
    data: {},
    reactions: [],
    total_count: 0,
    is_liked: false,
    current_user_reactions: [],
    bid_historians: {
      isLoading: false,
      list: [],
    },
    auctionStatus: { activeOrder: null, bids: [], highestBid: null },
    mnemonics: null,
    isLoading: true,
    isPurchasing: false,
    message: null,
    isError: false,
    success: null,
    public_address: null,
    isForAnalysing: false,
    isForOpenSale: false,
    selectedTab: 0,
    isOpeningPlaceBidModal: false,
    isForAuctionFinalize: false,
    cancelOrder: false,
    isConfirmed: true,
    saveMyMnemonics: false,
    mnemonicsPwd: "",
    fiatPaymentQuery: null,
    blockHash: null,
    showLikerListModal: false,
  };

  setSelectedTab = async (param) => {
    switch (param) {
      case 0: {
        break;
      }
      case 1: {
        break;
      }
      case 2: {
        this.setState({
          ...this.state,
          bid_historians: { ...this.state.bid_historians, isLoading: true },
        });
        const { data } = await getBidHistoriansAPI(this.state.data.id);
        this.setState({
          ...this.state,
          bid_historians: { isLoading: false, list: data },
        });
        break;
      }
    }
    this.setState({ ...this.state, selectedTab: param });
  };

  makeReaction = async () => {

    if (!this.state.data.id) return;

    if (!this.props.isLoggedIn) return;

    try {
      await makeReactionApi(this.state.data.id, { reaction: "HEART" }, this.props.token);
      const reactions = !this.state.is_liked ? [...this.state.reactions, { user_name: this.props.currentUser.user_name }] : this.state.reactions.filter(({ user_name }) => user_name !== this.props.currentUser.user_name);
      this.setState({ ...this.state, reactions: reactions, total_count: !this.state.is_liked ? this.state.total_count + 1 : this.state.total_count - 1, is_liked: !this.state.is_liked });
      // this.props.updateHeartLikeAction(this.state.data.id, true);
    } catch (e) {
      console.log(e);
    }
  };

  async componentDidMount() {
    try {
      if (localStorage.getItem("theme") === "dark") {
        $(".modal").addClass("DarkModal");
        $(".dropdown-menu").addClass("dropdown-menu-dark");
      }
      const { id } = this.props.match.params;

      const { data } = await getNFTTokenDetailAPI(id, this.props.token);

      this.setState({
        data: data,
        reactions: data.reactions.reactions,
        total_count: data.reactions.total_count || 0,
        current_user_reactions: data.current_user_reactions || [],
        is_liked: (data.current_user_reactions || []).length > 0,
        bid_historians: {
          isLoading: false,
          list: [],
        },
        auctionStatus: { activeOrder: null, bids: [], highestBid: null },
        mnemonics: null,
        isLoading: false,
        isPurchasing: false,
        message: null,
        isError: false,
        success: null,
        public_address: null,
        isForAnalysing: false,
        isForOpenSale: false,
        selectedTab: 0,
        isOpeningPlaceBidModal: false,
        isForAuctionFinalize: false,
        cancelOrder: false,
        isConfirmed: true,
        saveMyMnemonics: false,
        mnemonicsPwd: "",
        fiatPaymentQuery: null,
        blockHash: null,
      });
    } catch (e) { }
  }

  showLikerList = () => {
    this.setState((state) => ({ ...state, showLikerListModal: true }));
  };

  onChangeMnemonics = (e) => {
    e.preventDefault();

    if (mnemonicValidate(e.target.value)) {
      const keyring = new Keyring({
        type: "sr25519",
        ss58Format: 42,
      });
      const newPair = keyring.addFromUri(e.target.value);

      this.setState({
        ...this.state,
        mnemonics: e.target.value,
        public_address: newPair.address,
      });
    } else
      this.setState({
        ...this.state,
        mnemonics: e.target.value,
        public_address: null,
      });
  };

  confirmMnemonics = (isForAnalysing = false, isForOpenSale = false, isForAuctionFinalize = false, cancelOrder = false) => {
    if (!this.props.isLoggedIn) {
      document.getElementById("loginConfirmationBtn").click();
    } else if (!this.props.currentUser.public_address) {
      document.getElementById("confirmationMissingMnemonicsBtn").click();
    } else if (this.props.currentUser.kycStatus === 0 && false) {
      document.getElementById("kycStatusDialogBtn").click();
    } else if (isForOpenSale === true) {
      document.getElementById("turnOnSaleModalBtn").click();
    } else {
      if (
        this.props.currentUser.purchase_using_paypal === 0 &&
        this.state.data.type === "Fixed Price" &&
        parseFloat(this.state.data.price) > 0.1 &&
        !isForAnalysing &&
        !isForOpenSale &&
        !isForAuctionFinalize &&
        !cancelOrder
      ) {
        // if( this.props.currentUser.purchase_using_paypal === 1 && this.state.data.type === 'Fixed Price' && parseFloat(this.state.data.price) > 0.1 && !isForAnalysing && !isForOpenSale && !isForAuctionFinalize && !cancelOrder) {
        document.getElementById("paymentMethodSelectionBtn").click();
      } else {
        if (parseFloat(this.props.balance.NST) >= 0.001) {
          document.getElementById("confirmationModalBtn").click();
        } else {
          document.getElementById("insufficientBalanceBtn").click();
        }
      }
    }
    this.setState({ ...this.state, isForAnalysing: isForAnalysing, isForOpenSale: isForOpenSale, isForAuctionFinalize: isForAuctionFinalize, cancelOrder });
  };

  confirmationModal = () => (
    <React.Fragment>
      <button
        id="confirmationModalBtn"
        type="button"
        className="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#confirmationModal"
      >
        Launch demo modal
      </button>
      <div
        className="modal fade"
        id="confirmationModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className={this.props.cypherSecret !== null ? "" : "d-none"}>
              <ContinueWithPassword
                callback={(mnemonics) => {
                  this.setState({ ...this.state, mnemonics, mnemonics }, () => {
                    this.submit();
                  });
                }}
              />
            </div>
            <div
              className={
                this.props.cypherSecret === null
                  ? "modal-header align-items-start"
                  : "modal-header align-items-start d-none"
              }
            >
              <h5 className="modal-title">
                Please enter your account mnemonics to confirm transaction
              </h5>
              <button
                type="button"
                className="btn-close m-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div
              className={
                this.props.cypherSecret === null
                  ? "modal-body"
                  : "modal-body d-none"
              }
            >
              <div className="mb-3">
                <label className="form-label CeraMed d-block mb-1">
                  Account Mnemonics
                </label>
                <textarea
                  style={{
                    borderColor: !this.state.mnemonics
                      ? ""
                      : mnemonicValidate(this.state.mnemonics)
                        ? "green"
                        : "red",
                  }}
                  onChange={this.onChangeMnemonics}
                  name="mnemonics"
                  rows="4"
                  className="form-control shadow-none"
                  placeholder="Paste your mnemonics here"
                ></textarea>
                <p className="form-text mb-0">
                  We are not storing your private key, this can be use one time
                  for this transaction.
                </p>
              </div>
              {this.state.isForAuctionFinalize && (
                <React.Fragment>
                  <div className="row">
                    <fieldset id="group2">
                      <input
                        type="radio"
                        checked={this.state.isConfirmed}
                        style={{ marginRight: "5px", cursor: "pointer" }}
                        onClick={() =>
                          this.setState({ ...this.state, isConfirmed: true })
                        }
                      />
                      <label
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          this.setState({ ...this.state, isConfirmed: true })
                        }
                      >
                        {" "}
                        Confirm
                      </label>
                      <input
                        checked={!this.state.isConfirmed}
                        style={{
                          marginLeft: "10px",
                          marginRight: "5px",
                          cursor: "pointer",
                        }}
                        type="radio"
                        onClick={() =>
                          this.setState({ ...this.state, isConfirmed: false })
                        }
                      />
                      <label
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          this.setState({ ...this.state, isConfirmed: false })
                        }
                      >
                        {" "}
                        Ignore
                      </label>
                    </fieldset>
                  </div>
                </React.Fragment>
              )}
              <React.Fragment>
                <div className="w-100 mb-3">
                  {this.state.public_address &&
                    this.state.public_address ===
                    this.props.currentUser.public_address && (
                      <React.Fragment>
                        <strong className={"text-success d-block"}>
                          Looks Good!{" "}
                        </strong>
                        Your public address matched{" "}
                        <strong className={"break-all d-block"}>
                          {this.state.public_address}
                        </strong>
                      </React.Fragment>
                    )}
                  {this.state.public_address &&
                    this.state.public_address !==
                    this.props.currentUser.public_address && (
                      <React.Fragment>
                        <strong className={"text-danger d-block"}>
                          Something Wrong!
                        </strong>{" "}
                        Your profile address is not matching with the provided
                        Mnemonics, Please add the mnemonics which you give us on
                        sign up.
                      </React.Fragment>
                    )}
                </div>
              </React.Fragment>
              <div className="form-check pt-3">
                <input
                  className="form-check-input shadow-none"
                  type="checkbox"
                  value=""
                  id="EncryptCheckBox"
                  checked={this.state.saveMyMnemonics}
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      saveMyMnemonics: !this.state.saveMyMnemonics,
                    })
                  }
                />
                <label className="form-check-label" htmlFor="EncryptCheckBox">
                  Encrypt and save my mnemonics on browser for future
                  transactions!
                </label>
              </div>
              <div className="">
                {this.state.saveMyMnemonics && (
                  <input
                    onChange={(e) =>
                      this.setState({
                        ...this.state,
                        mnemonicsPwd: e.target.value,
                      })
                    }
                    className="form-control BorderDarkGrey shadow-none mt-4"
                    type="password"
                    name="password"
                    placeholder="Enter Password"
                  />
                )}
              </div>
            </div>
            <div
              className={
                this.props.cypherSecret === null
                  ? "modal-footer"
                  : "modal-footer d-none"
              }
            >
              <button
                type="button"
                className="btn BtnBorderBlack py-2 px-3 px-md-5 me-3"
                data-bs-dismiss="modal"
              >
                {" "}
                Close{" "}
              </button>
              <button
                disabled={
                  !mnemonicValidate(this.state.mnemonics) ||
                  this.props.currentUser.public_address !==
                  this.state.public_address ||
                  (this.state.saveMyMnemonics
                    ? this.state.mnemonicsPwd.length < 6
                    : false)
                }
                onClick={this.submit.bind(this)}
                type="button"
                className="btn BtnBlack py-2 px-3 px-md-5"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );

  submit = async () => {
    if (this.state.saveMyMnemonics) {
      const cypher = CryptoJS.AES.encrypt(
        this.state.mnemonics,
        this.state.mnemonicsPwd
      ).toString();
      this.props.setCypherSecret(cypher);
    }

    document.getElementById("confirmationModalBtn").click();
    document.getElementById("messageBoxBtn").click();
    this.setState({
      ...this.state,
      isPurchasing: true,
      isLoading: true,
      isError: false,
      message: null,
    });
    try {
      if (this.state.isForAnalysing === true) {
        const { data } = await analyzeMintingFlow(
          {
            secret: this.state.mnemonics,
            token_id: this.state.data.id,
          },
          this.props.token
        );

        this.setState({
          ...this.state,
          success: data,
          isPurchasing: false,
          message:
            "Analyzing request has been submitted we are evaluating your request in background it will take few minutes. <br/>Thanks",
          isError: false,
        });
        return;
      } else if (this.state.isForOpenSale === true) {
        const { data } = await createOrder(
          {
            secret: this.state.mnemonics,
            token_id: this.state.data.id,
          },
          this.props.token
        );
        if (data.status === "ExtrinsicSuccess")
          return this.setState({
            ...this.state,
            isPurchasing: false,
            isLoading: false,
            isError: false,
            blockHash: data.blockHash,
            message: <p>Opened for sale! </p>,
          });
        else
          return this.setState({
            ...this.state,
            isPurchasing: false,
            isLoading: false,
            isError: true,
            blockHash: data.blockHash,
            message: (
              <p>
                Unable to make NFT open for sale!{" "}
                <a
                  className={"break-all BlackColor"}
                  target="_blank"
                  href={`${process.env.REACT_APP_NFT_EXPLORER}/block/${data.blockHash}`}
                >
                  {data.blockHash}
                </a>
              </p>
            ),
          });
      } else if (this.state.isForAuctionFinalize === true) {
        const { data } = await finalizeAuctionAPI(
          {
            secret: this.state.mnemonics,
            token_id: this.state.data.id,
            is_confirm: this.state.isConfirmed,
          },
          this.props.token
        );

        if (data.status === "ExtrinsicSuccess")
          return this.setState({
            ...this.state,
            isPurchasing: false,
            isLoading: false,
            isError: false,
            blockHash: data.blockHash,
            message: (
              <p>
                <span className={"d-block"}>Auction has been finalized</span>{" "}
                <a
                  className={"break-all BlackColor"}
                  target="_blank"
                  href={`${process.env.REACT_APP_NFT_EXPLORER}/block/${data.blockHash}`}
                >
                  {data.blockHash}
                </a>{" "}
              </p>
            ),
          });
        else
          return this.setState({
            ...this.state,
            isPurchasing: false,
            isLoading: false,
            isError: true,
            blockHash: data.blockHash,
            message: (
              <p>
                <span className={"d-block"}>
                  Unable to finalize the auction
                </span>{" "}
                {data.blockHash && (
                  <a
                    className={"break-all BlackColor"}
                    target="_blank"
                    href={`${process.env.REACT_APP_NFT_EXPLORER}/block/${data.blockHash}`}
                  >
                    {data.blockHash}
                  </a>
                )}
              </p>
            ),
          });
      } else if (this.state.cancelOrder === true) {
        const { data } = await cancelOrderAPI(
          {
            secret: this.state.mnemonics,
            token_id: this.state.data.id,
          },
          this.props.token
        );

        if (data.status === "ExtrinsicSuccess")
          return this.setState({
            ...this.state,
            isPurchasing: false,
            isLoading: false,
            isError: false,
            blockHash: data.blockHash,
            message: (
              <p>
                <strong className={"d-block text-success"}>
                  Order has been canceled
                </strong>{" "}
                <a
                  className={"WhiteForDarkMode BlackColor "}
                  target="_blank"
                  href={`${process.env.REACT_APP_NFT_EXPLORER}/block/${data.blockHash}`}
                >
                  {data.blockHash}
                </a>
              </p>
            ),
          });
        else
          return this.setState({
            ...this.state,
            isPurchasing: false,
            isLoading: false,
            isError: true,
            blockHash: data.blockHash,
            message: (
              <p>
                <span className={"d-block"}>Unable to cancel the order</span>{" "}
                {data.blockHash && (
                  <a
                    className={"break-all BlackColor"}
                    target="_blank"
                    href={`${process.env.REACT_APP_NFT_EXPLORER}/block/${data.blockHash}`}
                  >
                    {data.blockHash}
                  </a>
                )}
              </p>
            ),
          });
      }

      const { data } = await purchaseNFTToken(
        {
          secret: this.state.mnemonics,
          token_id: this.state.data.id,
          user_id: this.state.data.user_id,
          amount: `${this.state.data.price}`,
        },
        this.props.token
      );

      this.setState({
        ...this.state,
        success: data,
        isPurchasing: false,
        message: (
          <div>
            <h4>Purchased Successfull!</h4>
            <strong className={"d-block"}>Block Hash</strong>{" "}
            <a
              className={"break-all BlackColor"}
              target="_blank"
              href={`${process.env.REACT_APP_NFT_EXPLORER}/block/${data.blockHash}`}
            >
              {data.blockHash}
            </a>
          </div>
        ),
        isError: false,
      });
    } catch (e) {
      this.setState({
        ...this.state,
        isPurchasing: false,
        isLoading: false,
        message: e.response && e.response.data ? e.response.data : e.message,
        isError: true,
      });
    }
  };

  updateBidingStatus = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await getBidingStatusByTokenIdAPI(
          this.state.data.id,
          this.props.token
        );
        this.setState({ ...this.state, auctionStatus: data });
      } catch (e) { }
      resolve();
    });
  };

  openPlaceBidder = async () => {
    if (!this.props.isLoggedIn) {
      document.getElementById("loginConfirmationBtn").click();
    } else {
      this.setState({ ...this.state, isOpeningPlaceBidModal: true });
      await this.updateBidingStatus();
      document.getElementById("placeBidBtn").click();
      this.setState({ ...this.state, isOpeningPlaceBidModal: false });
    }
  };

  render() {
    const {
      id,
      user_id,
      user_name,
      display_name,
      title,
      description,
      minimum_bid,
      bid_end_time,
      royalties,
      price,
      resource_url,
      resource_type,
      on_sale,
      no_of_copies,
      batch_no,
      available_copies,
      historian,
      type,
      nft_minted_hash,
      profile_image,
      total_count,
      nft_order_hash,
      timestamp,
      is_burned,
      order,
      is_business_multiple,
      is_equity
    } = this.state.data;

    // console.log("------>>", this.props.currentUser);
    const { isPurchasing, isError, message, fiatPaymentQuery } = this.state;

    const [creator] = historian
      ? historian.filter(({ holder_type }) => holder_type === "CREATOR")
      : [];

    return (
      <main>
        <section id="ItemDetail" className={"pt-4 pt-lg-0"}>
          <div className="container-fluid ">
            <div
              className="row justify-content-lg-between justify-content-center gx-0"
              id="zIndexMob"
            >
              <div className="col-xxl-8 col-lg-4 col-md-6 mx-lg-auto mb-4 mb-lg-0 ItemBox d-flex justify-content-center align-items-center">
                {isVideo(resource_type) ? (
                  <video
                    autoPlay
                    className="rounded Video loading Detail"
                    loop
                    muted
                  >
                    <source
                      src={`${LISTING_URL}/v1/media/video/full/${resource_url}`}
                      type="video/mp4"
                    />
                  </video>
                ) : resource_url ? (
                  <img
                    loading="lazy"
                    src={`${LISTING_URL}/v1/media/image/full/${resource_url}`}
                    alt={title}
                    className="img-fluid rounded loading Detail"
                  />
                ) : (
                  <SkeletonLoading className="img-fluid rounded Detail" />
                )}
              </div>
              <div className="col-xxl-4 col-lg-5 d-flex flex-column ItemBox">
                <div className="TabArea mb-auto pt-4 pe-4">
                  <div className="d-flex justify-content-between">
                    <h2
                      title={title}
                      className={"HeadingEllipsis"}
                      style={{ maxWidth: "80%" }}
                    >
                      {title || <SkeletonLoading />}
                    </h2>
                    <div className={"dropdown"}>
                      <button
                        title="Options"
                        className="btndoat"
                        type="button"
                        id="ProfileDropDownButton"
                        style={{ fontSize: "20px" }}
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        ...
                      </button>
                      <div
                        className="dropdown-menu dropdown-menu-end pt-2"
                        aria-labelledby="ProfileDropDownButton"
                      >
                        <ul className="list-unstyled mb-0">
                          {user_name === this.props.currentUser.user_name &&
                            is_burned == false && (
                              <React.Fragment>
                                {
                                  !is_equity && (
                                      <li>
                                        <a
                                          onClick={() => {
                                            document
                                              .getElementById("editNFTBtn")
                                              .click();
                                          }}
                                          style={{ cursor: "pointer" }}
                                          className="dropdown-item"
                                        >
                                          Edit
                                        </a>
                                      </li>
                                    )
                                  }
                                {is_business_multiple === 1 &&
                                  (type !== "Redemption" || on_sale !== 1) &&
                                  historian &&
                                  historian.length === 1 && (
                                    <li>
                                      <a
                                        onClick={() => {
                                          document
                                            .getElementById(
                                              "GeneratePromoCodeModalModalBtn"
                                            )
                                            .click();
                                        }}
                                        style={{ cursor: "pointer" }}
                                        className="dropdown-item"
                                      >
                                        Generate Promo Code
                                      </a>
                                    </li>
                                  )}
                                {
                                  !is_equity && (
                                    <li>
                                      <a
                                        onClick={() => {
                                          document
                                            .getElementById("burnNFTModalBtn")
                                            .click();
                                        }}
                                        style={{ cursor: "pointer" }}
                                        className="dropdown-item"
                                      >
                                        Burn NFT
                                      </a>
                                    </li>
                                  )
                                }
                                {
                                  !is_equity && (
                                    <li>
                                      <a href={"#TransferModal"} data-bs-toggle="modal" className="dropdown-item">Transfer</a>
                                    </li>
                                  )
                                }
                                {
                                  is_equity && onlyAdmins(this.props.currentUser.user_name) && (
                                    <li>
                                      <a href={"#TransferModal"} data-bs-toggle="modal" className="dropdown-item">Transfer</a>
                                    </li>
                                  )
                                }
                                
                                {on_sale === 1 && !is_equity && (
                                  <li>
                                    <a
                                      onClick={() =>
                                        this.confirmMnemonics(
                                          false,
                                          false,
                                          false,
                                          true
                                        )
                                      }
                                      style={{ cursor: "pointer" }}
                                      className="dropdown-item"
                                    >
                                      Cancel Order
                                    </a>
                                  </li>
                                )}
                              </React.Fragment>
                            )}
                          <li>
                            <a
                              onClick={(e) => {
                                this.props.setSocialShareDetail(
                                  this.state.data
                                );
                              }}
                              className="dropdown-item"
                              data-bs-toggle="modal"
                              href="#ShareModal"
                            >
                              Share
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  {
                  is_equity ? (
                    <p className="sale">
                      Total equity ownership share value <strong>${ price ? available_copies * price :  <SkeletonLoading style={{ width: "20%" }} /> }</strong>
                      {/* · Highest bid <span className="BlueColor">0.02 wETH</span> */}
                    </p>
                  ) : type === "Fixed Price" ? (
                    <p className="sale">
                      On sale for{" "}
                      <span className="color-red">
                        {price || <SkeletonLoading style={{ width: "20%" }} />}{" "}
                        NST
                      </span>{" "}
                      token id is <span className="color-red">{id}</span>
                      {/* · Highest bid <span className="BlueColor">0.02 wETH</span> */}
                    </p>
                  ) : type === "Timed Auction" ? (
                    <p className="sale">
                      {type}{" "}
                      <span className="color-red">{minimum_bid} NST</span> token
                      id is {id}
                    </p>
                  ) : (
                    <p className="sale">
                      {type}{" "}
                      <span className="color-red">
                        {minimum_bid || price || 0.001 || (
                          <SkeletonLoading style={{ width: "20%" }} />
                        )}{" "}
                        NST
                      </span>{" "}
                      token id is {id}
                    </p>
                  )}
                  <p>
                    {batch_no !== null && is_business_multiple == 0 ? (
                      <React.Fragment>
                        <span
                          title="Multiple"
                          className="fas fa-align-center color-maroon"
                        ></span>
                        <b style={{ marginLeft: "5px" }}>
                          {available_copies} copy available of {no_of_copies}
                        </b>
                      </React.Fragment>
                    ) : is_business_multiple == 1 ? (
                      <React.Fragment>
                        <span
                          title="Business NFT"
                          className="fas fa-align-justify color-green"
                        ></span>
                        <b style={{ marginLeft: "5px" }}>
                          {available_copies} copy available of {no_of_copies}
                        </b>
                      </React.Fragment>
                    ) : is_equity === 0 ? (
                      <React.Fragment>
                        <span
                          title="Single"
                          className="fas fa-dice-one color-grey"
                        ></span>
                        <i class="fas fa-sharp fa-solid fa-sparkles"></i>
                      </React.Fragment>
                    ) : (
                        <React.Fragment>
                          <span color="#e6c45a" title="Equity" className="fas fa-star"></span>
                          <b style={{ marginLeft: "5px" }}>
                            {available_copies} equity ownership shares allocated out of {no_of_copies}
                          </b>
                        </React.Fragment>
                    )}
                  </p>
                  <p className="sale">{description || <SkeletonLoading />}</p>
                  {/* <label className="form-label CeraBold d-block">Sell Type <span className="GreyColor">{type || <SkeletonLoading style={{ width: '10%' }} />}</span></label> */}
                  <div className="row">
                    <div className="col-sm-6 mb-4">
                      <label className="form-label CeraBold d-block">
                        Creator{" "}
                        <small className="GreyColor">
                          {royalties || 0}% royality
                        </small>
                      </label>
                      <Link
                        to={`/profile/${creator?.user_name}`}
                        className="d-flex align-items-center"
                      >
                        <img
                          src={
                            creator?.profile_image
                              ? `${REACT_APP_PROFILE_SERVICE}/v1/profile/image/${creator?.user_name}`
                              : "/images/user.png"
                          }
                          alt={creator?.user_name}
                          className="img-fluid ImgProfile me-2"
                          style={{ width: 40, height: 40 }}
                        />
                        <a
                          href="#"
                          className="form-label CeraBold BlackColor text-uppercase break-all Fsize_16"
                        >
                          {creator?.display_name || creator?.user_name || (
                            <SkeletonLoading />
                          )}
                        </a>
                      </Link>
                    </div>
                    <div className="col-sm-6 mb-4">
                      <label className="form-label CeraBold d-block">
                        Owner
                      </label>
                      <Link
                        to={`/profile/${user_name}`}
                        className="d-flex align-items-center"
                      >
                        <img
                          src={
                            profile_image
                              ? `${REACT_APP_PROFILE_SERVICE}/v1/profile/image/${user_name}`
                              : "/images/user.png"
                          }
                          alt={user_name}
                          className="img-fluid ImgProfile me-2"
                          style={{ width: 40, height: 40 }}
                        />
                        <a
                          href="#"
                          className="form-label CeraBold BlackColor text-uppercase break-all Fsize_16"
                        >
                          {display_name || user_name}
                        </a>
                      </Link>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-2 ">
                    {/* fill: !this.state.is_liked && this.props.theme === 'dark'  ? 'white' : '' */}
                    <Heart animationDuration={0.20} animationTrigger="both" style={{ marginLeft: '2px', cursor: this.props.isLoggedIn ? 'pointer' : '', width: '24px', stroke: !this.state.is_liked && this.props.theme === 'dark' ? 'white' : 'black' }} isActive={this.state.is_liked} onClick={() => this.makeReaction()} />
                    {/* <FontAwesomeIcon
                      onClick={this.makeReaction.bind(this)}
                      style={this.props.isLoggedIn ? { cursor: "pointer" } : {}}
                      icon={
                        (this.state?.current_user_reactions?.findIndex( ({ user_name, reaction }) => this.props.currentUser?.user_name === user_name && reaction === "HEART" ) ?? -1) >= 0 ? solid("heart") : regular("heart")
                      }
                    />{" "} */}
                    <OverlayTrigger
                      placement="bottom"
                      overlay={(props) => (
                        <Tooltip {...props}>
                          {this.state.reactions?.map((item) => (
                            <div className="text-start Fsize_11 WhiteColor">
                              {item.user_name}
                            </div>
                          ))}

                          {this.state.total_count > 10 && (
                            <div className="text-start Fsize_11 WhiteColor">
                              and{" "}
                              {this.state.total_count -
                                this.state.reactions?.length}{" "}
                              more ...
                            </div>
                          )}
                        </Tooltip>
                      )}
                    >
                      <div
                        className="mx-1 likes-nft" onClick={() => { document.getElementById('likerListModalBtn').click(); }}>
                        {(this.state.total_count ?? -1) > 0 ? new Intl.NumberFormat("en-US", { maximumFractionDigits: 1, notation: "compact", compactDisplay: "short", }).format(this.state.total_count) + (this.state.total_count === 1 ? " Like" : " Likes") : ""}
                      </div>
                    </OverlayTrigger>
                  </div>
                  <div className="w-100 ">
                    <ul
                      className="nav nav-tabs BorderBottom mb-4"
                      id="myTab"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <button
                          className={`nav-link active`}
                          onClick={() => this.setSelectedTab(0)}
                          id="Comments-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#Reviews"
                          type="button"
                          role="tab"
                          aria-controls="Reviews"
                          aria-selected="true"
                        >
                          Reviews
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className={`nav-link`}
                          onClick={() => this.setSelectedTab(1)}
                          id="Details-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#Details"
                          type="button"
                          role="tab"
                          aria-controls="Details"
                          aria-selected="false"
                        >
                          Owners
                        </button>
                      </li>
                      <li className={`nav-item ${is_equity ? 'd-none' : ''}`} role="presentation">
                        <button
                          className={`nav-link`}
                          onClick={() => this.setSelectedTab(2)}
                          id="Bids-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#Bids"
                          type="button"
                          role="tab"
                          aria-controls="Bids"
                          aria-selected="false"
                        >
                          Bids{" "}
                          {this.state.bid_historians.isLoading && (
                            <i className="fas fa-asterisk fa-spin text-center" />
                          )}
                        </button>
                      </li>
                    </ul>
                    <div className="tab-content px-3 " id="myTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="Reviews"
                        role="tabpanel"
                        aria-labelledby="Comment-tab"
                      >
                        <CommentSection
                          ref={this.commentSectionRef}
                          nftID={this.state.data.id ?? null}
                          userToken={this.props.token}
                        />
                      </div>

                      <div
                        className="tab-pane fade show"
                        id="Details"
                        role="tabpanel"
                        aria-labelledby="Details-tab"
                      >
                        <div className="row">
                          {historian &&
                            historian.map((holder) => (
                              <Link
                                to={`/profile/${holder.user_name}`}
                                className="d-flex align-items-center mb-3"
                              >
                                <img
                                  src={
                                    holder.profile_image
                                      ? `${REACT_APP_PROFILE_SERVICE}/v1/profile/image/${holder.user_name}`
                                      : "/images/user.png"
                                  }
                                  alt="Image"
                                  className="img-fluid ImgProfile me-2"
                                />
                                <div className="">
                                  <a
                                    href="#"
                                    className="form-label CeraBold BlackColor text-uppercase Fsize_16"
                                  >
                                    {holder.display_name || holder.user_name}
                                    <strong>
                                      {" - "}
                                      {holder.holder_type}
                                    </strong>
                                  </a>
                                  <span className="d-block WhiteForDarkMode">
                                    {new Date(
                                      holder.create_time
                                    ).toDateString()}
                                  </span>
                                </div>
                              </Link>
                            ))}
                        </div>
                      </div>

                      <div
                        className="tab-pane fade"
                        id="Bids"
                        role="tabpanel"
                        aria-labelledby="Bids-tab"
                      >
                        {this.state.bid_historians.list.map((prop) => (
                          <BidHistorianItem prop={prop} />
                        ))}
                        {!this.state.bid_historians.isLoading &&
                          this.state.bid_historians.list.length === 0 && (
                            <label className="text-secondary text-center w-100">
                              No recent bids found!
                            </label>
                          )}
                      </div>
                      <div
                        className="tab-pane fade"
                        id="History"
                        role="tabpanel"
                        aria-labelledby="History-tab"
                      >
                        <div className="d-flex align-items-center mb-3">
                          <img
                            src="images/img-nft-detail.png"
                            alt="Image"
                            className="img-fluid ImgProfile me-2"
                          />
                          <div className="">
                            <a
                              href="#"
                              className="form-label CeraBold BlackColor text-uppercase Fsize_16"
                            >
                              {" "}
                              0.02 wETH by Crypto Sonson
                            </a>
                            <span className="d-block">8/12/2021, 10:42 AM</span>
                          </div>
                        </div>
                        <div className="d-flex align-items-center mb-3">
                          <img
                            src="images/img-nft-detail.png"
                            alt="Image"
                            className="img-fluid ImgProfile me-2"
                          />
                          <div className="">
                            <a
                              href="#"
                              className="form-label CeraBold BlackColor text-uppercase Fsize_16"
                            >
                              {" "}
                              0.01 wETH by AEQEA
                            </a>
                            <span className="d-block">8/8/2021, 9:38 AM</span>
                          </div>
                        </div>
                        <div className="d-flex align-items-center mb-3">
                          <img
                            src="images/img-nft-detail.png"
                            alt="Image"
                            className="img-fluid ImgProfile me-2"
                          />
                          <div className="">
                            <a
                              href="#"
                              className="form-label CeraBold BlackColor text-uppercase Fsize_16"
                            >
                              {" "}
                              0.01 wETH by AEQEA
                            </a>
                            <span className="d-block">8/8/2021, 9:38 AM</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="positoin-relative sticky-bottom">
                  {this.state.selectedTab === 0 && (
                    <CommentInput
                      userName={this.props.currentUser.user_name}
                      nftID={this.state.data.id ?? null}
                      userToken={this.props.token}
                      commentAddCallback={(commntAdded) => this.commentSectionRef.current.addNewlyAddedComment(commntAdded)}
                    />
                  )}

                  <div className="ItemButtons positoin-relative p-4">
                    <div className="row align-items-center">
                      <div
                        className={`col-6 text-center ${is_burned === 1 && is_burned !== undefined
                            ? "d-none"
                            : ""
                          }`}
                      >
                        {user_name === this.props.currentUser.user_name ? (
                          <React.Fragment>
                            {!on_sale &&
                              available_copies !== 0 &&
                              is_business_multiple === 0 ? (
                              <button
                                style={{ width: "100%" }}
                                className="btn BtnBlack py-2 px-4 mb-2 mb-sm-0"
                                onClick={() =>
                                  this.confirmMnemonics(false, true)
                                }
                              >
                                Turn on for Sale
                              </button>
                            ) : (
                              user_name !==
                              this.props.currentUser.user_name && (
                                <label className="text-center ft-14 w-100">
                                  You can Radeem this NFT in future. The feature
                                  is under contruction.{" "}
                                </label>
                              )
                            )}
                          </React.Fragment>
                        ) : (
                          ""
                        )}
                        {on_sale === 1 &&
                          user_name !== this.props.currentUser.user_name &&
                          type === "Fixed Price" &&
                          nft_minted_hash !== null ? (
                          <button
                            style={{
                              display: this.state.success ? "none" : "",
                            }}
                            disabled={price === undefined || isPurchasing}
                            type="button"
                            onClick={() => this.confirmMnemonics()}
                            // document.getElementById('paymentMethodSelectionBtn').click()}//
                            className="btn BtnBlack py-2 px-4 mb-2 mb-sm-0 w-100"
                          >
                            {!isPurchasing ? (
                              <React.Fragment>
                                Buy for{" "}
                                {price || (
                                  <SkeletonLoading style={{ width: "10%" }} />
                                )}{" "}
                                NST
                              </React.Fragment>
                            ) : (
                              !isError && (
                                <React.Fragment>
                                  Purchasing{" "}
                                  {<span className="fas fa-asterisk fa-spin" />}{" "}
                                </React.Fragment>
                              )
                            )}
                          </button>
                        ) : type === "Redemption" &&
                          on_sale === 1 &&
                          user_name !== this.props.currentUser.user_name ? (
                          <React.Fragment>
                            <button
                              onClick={() => {
                                document
                                  .getElementById(
                                    "redeemPromoCodeModalModalBtn"
                                  )
                                  .click();
                              }}
                              disabled={this.state.isLoading}
                              className="btn BtnBlack py-2 px-4 mb-2 mb-sm-0 w-100"
                              style={{ fontSize: "13px !important" }}
                            >
                              Redeem{" "}
                            </button>
                          </React.Fragment>
                        ) : type != "Fixed Price" &&
                          type != "Redemption" &&
                          on_sale == 1 &&
                          new Date().getTime() >
                          new Date(bid_end_time || 0).getTime() ? (
                          <React.Fragment>
                            {type != "Open For Bids" &&
                              user_name !==
                              this.props.currentUser.user_name && (
                                <label>This auction has been ended!</label>
                              )}
                            {user_name === this.props.currentUser.user_name &&
                              this.state.message === null ? (
                              <button
                                onClick={() =>
                                  this.confirmMnemonics(false, false, true)
                                }
                                disabled={this.state.isLoading}
                                className="btn BtnBlack py-2 px-4 mb-2 mb-sm-0 w-100"
                                style={{ fontSize: "13px !important" }}
                              >
                                Finalize Auction{" "}
                                {this.state.isLoading && (
                                  <i className="fas fa-asterisk fa-spin" />
                                )}{" "}
                              </button>
                            ) : (
                              (type === "Open For Bids" ||
                                new Date().getTime() <
                                new Date(bid_end_time || 0).getTime()) && (
                                <button
                                  onClick={this.openPlaceBidder}
                                  className="btn BtnBlack py-2 px-4 mb-2 mb-sm-0 w-100"
                                  disabled={isPurchasing}
                                >
                                  Place a bid{" "}
                                  {this.state.isOpeningPlaceBidModal && (
                                    <i className="fas fa-asterisk fa-spin" />
                                  )}
                                </button>
                              )
                            )}
                          </React.Fragment>
                        ) : (
                          !this.state.isLoading &&
                          (user_name !== this.props.currentUser.user_name ? (
                            <button
                              onClick={this.openPlaceBidder}
                              className="btn BtnBlack py-2 px-2 px-sm-4 mb-2 mb-sm-0 w-100"
                              disabled={isPurchasing || on_sale === 0}
                            >
                              {on_sale === 0 ? "Not for sale" : "Place a bid"}{" "}
                              {this.state.isOpeningPlaceBidModal && (
                                <i className="fas fa-asterisk fa-spin" />
                              )}
                            </button>
                          ) : (
                            user_name === this.props.currentUser.user_name &&
                            on_sale !== 0 && (
                              <label
                                style={{ fontSize: "15px", marginTop: "8px" }}
                                className="form-label CeraBold d-block"
                              >
                                You owned this NFT
                              </label>
                            )
                          ))
                        )}
                        {user_name === this.props.currentUser.user_name && !is_equity &&
                          (nft_minted_hash === null ||
                            order === null ||
                            (order && order.order_hash === null)) &&
                          moment(timestamp).diff(Date.now(), "hours") * -1 <=
                          1 && (
                            <React.Fragment>
                              <label className="form-label CeraBold d-block">{`"${title}" is minting in background it will take around few minutes to complete. Please wait...`}</label>
                            </React.Fragment>
                          )}
                        {user_name === this.props.currentUser.user_name && !is_equity &&
                          (nft_minted_hash === null ||
                            order === null ||
                            (order && order.order_hash === null)) &&
                          moment(timestamp).diff(Date.now(), "hours") * -1 >
                          1 && !is_equity && (
                            <React.Fragment>
                              <label className="form-label CeraBold d-block">
                                {`"${title}" is taking too long, You can retry the minting process by `}
                                <span
                                  style={{ cursor: "pointer", color: "blue" }}
                                  className="BlueColor"
                                  onClick={() => this.confirmMnemonics(true)}
                                >
                                  Clicking Here
                                </span>
                              </label>
                            </React.Fragment>
                          )}
                        {/* {this.state.success &&
                                                <h5 style={{ color: 'green' }}>You have purchased the NFT successfully! Block
                                                    Hash {this.state.success?.blockHash}</h5>}
                                            {this.state.isForAnalysing === true && this.state.message &&
                                                <h5 style={{ color: this.state.isError ? 'red' : 'green' }}>{this.state.message}</h5>}
                                            {this.state.isForOpenSale === true && this.state.message &&
                                                <h5 style={{ color: this.state.isError ? 'red' : 'green' }}>{this.state.message}</h5>}
                                            {this.state.isLoading && this.state.isForAnalysing === true &&
                                                <span className="BlueColor">Please wait... <i
                                                    className="fas fa-asterisk fa-spin"></i></span>}
                                            {this.state.isLoading && this.state.isForOpenSale === true &&
                                                <span className="BlueColor">We are on it, Please wait...<i
                                                    className="fas fa-asterisk fa-spin"></i></span>} */}
                      </div>
                      <div
                        className={`col-6 text-center mt-2 ${is_burned !== 0 && is_burned !== undefined
                            ? ""
                            : "d-none"
                          }`}
                      >
                        <label className="form-label CeraBold d-block">
                          BURNED
                        </label>
                      </div>
                      <div className="col-6 text-center">
                        {type === "Timed Auction" &&
                          new Date(bid_end_time).getTime() >
                          new Date().getTime() ? (
                          <React.Fragment>
                            <label
                              style={{ fontSize: "15px" }}
                              className="text-secondary w-100 text-center"
                            >
                              Auction will end in
                            </label>
                            <label
                              style={{ fontSize: "16px" }}
                              className="text-secondary w-100 text-center"
                            >
                              <strong>
                                <Countdown date={new Date(bid_end_time)} />
                              </strong>
                            </label>
                          </React.Fragment>
                        ) : (
                          <label
                            style={{ fontSize: "15px", marginTop: "8px" }}
                            className="form-label CeraBold d-block"
                          >
                            Sell Type:{" "}
                            <span className="GreyColor">
                              {type != null ? (
                                type
                              ) : (
                                <SkeletonLoading style={{ width: "10%" }} />
                              )}
                            </span>
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ConfirmLogin
          title={`Want to purchase "${title}" ?`}
          message={
            <p>
              Please <Link id="link-target" to="/login" />{" "}
              <span
                style={{ cursor: "pointer", color: "#2929ff" }}
                onClick={() => {
                  document.getElementById("loginConfirmationBtn").click();
                  document.getElementById("link-target").click();
                }}
                className="BlueColor"
              >
                Login
              </span>{" "}
              to continue complete purchasing.
            </p>
          }
        />

        <InsufficientBalance
          title={`Insufficient Balance ${this.props.balance.NST} NST`}
          message={
            <p>
              Please <Link id="link-target1" to="/swap" />{" "}
              <span
                style={{ cursor: "pointer", color: "#2929ff" }}
                onClick={() => {
                  document.getElementById("insufficientBalanceBtn").click();
                  document.getElementById("link-target1").click();
                }}
                className="BlueColor"
              >
                Swap
              </span>{" "}
              with NuCoin (NUC) to continue complete purchasing.
            </p>
          }
        />
        {this.confirmationModal()}
        <PlaceBid token={this.state.data} auctionStatus={this.state.auctionStatus} />
        <MessageBox title={isError ? "Information" : "Transaction"} message={message} isError={isError} callback={() => this.componentDidMount()} isProcessing={isPurchasing} />
        <BurnNFT tokenId={id} title={title} />
        <KycStatus />
        <GeneratePromoCode token_id={id} user_id={user_id} callback={this.componentDidMount.bind(this)} />
        <TurnOnSale tokenId={id} callback={this.componentDidMount.bind(this)} />
        <RedeemNFT token_id={id} user_id={user_id} callback={this.componentDidMount.bind(this)} />
        <PaymentMethodSelection />
        <PayPalPaymentModal id={id} title={title} price={price} user_id={this.props.currentUser.user_id} user_name={this.props.currentUser.user_name} query={fiatPaymentQuery} />
        <MissingMnemonics
          callback={() => {
            this.confirmMnemonics(this.state.isForAnalysing, this.state.isForOpenSale, this.state.isForAuctionFinalize, this.state.cancelOrder);
          }}
        />
        {id && user_name === this.props.currentUser?.user_name && (
          <EditNFT data={this.state.data}
            callback={(title, description) => {
              this.setState({
                ...this.state,
                data: { ...this.state.data, title, description },
              });
            }}
          />
        )}
        <FiatPaymentResponse query={parseQueryParamsToObject(this.props.location.search)}
        />
        <TransferNFT
          callback={this.componentDidMount.bind(this)}
          token_id={id}
          isEquity={is_equity}
          availableCopies={available_copies}
        />
        <TopUpBalance />
        <NftLikerListModal
          show={this.state.showLikerListModal === true}
          nftID={this.state.data.id ?? null}
          totalReactions={this.state.total_count ?? 0}
          userToken={this.props.token}
          reactions={this.state.reactions}
          onCancelPress={() => {
            this.setState((state) => ({ ...state, showLikerListModal: false }));
          }}
        // onConfirmPress={doRemoveCommentCall}
        />
      </main>
    );
  }
}

const mapStatetoProps = (state) => ({
  isLoggedIn: state.Auth.isLoggedIn,
  token: state.Auth.token,
  currentUser: state.Profile.profile,
  balance: state.Wallet.balances,
  cypherSecret: state.Auth.cypherSecret,
  theme: state.Settings.theme,

});

export default connect(mapStatetoProps, {
  setSocialShareDetail,
  setCypherSecret,
  updateHeartLikeAction,
})(ItemDetail);
