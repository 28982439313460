import React from 'react';
import { connect } from 'react-redux';

const REACT_APP_DOMAIN_URL = process.env.REACT_APP_DOMAIN_URL;
const REACT_APP_KYC_REDIRECTION = process.env.REACT_APP_KYC_REDIRECTION;

const KycStatus = (props) => (
    <React.Fragment>
        <button style={{ display: 'none' }} id="kycStatusDialogBtn" type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#kycStatusDialogModal">
            Launch demo modal
        </button>
        <div className="modal fade DarkModal" data-bs-backdrop="static" data-bs-keyboard="false" id="kycStatusDialogModal" tabindex="-1" aria-labelledby="messageBoxModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '530px' }}>
                <div className="modal-content">
                    <div className="modal-header align-items-start">
                        <h5 className="modal-title" id="messageBoxModalLabel">KYC Status Pending!</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3">
                            <label className="text-secondary">
                                Dear {props.profile.display_name || 'User'}!<br/><br/>
                                Your KYC status is not complete to move further please complete your KYC by <a href={`${REACT_APP_KYC_REDIRECTION}?redirect=${window.location}&authentication=${localStorage.getItem('redirectToken')}`}>clicking here</a>.
                                <br/>
                                Thanks,<br/>
                                NuGenesis Team
                            </label>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button data-bs-toggle="modal" data-bs-target="#kycStatusDialogModal" type="button" className="btn BtnBlack py-2 px-5">OK</button>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
)

const mapStateToProps = state => ({
    profile: state.Profile.profile
});

export default connect(mapStateToProps, {})(KycStatus)