import { SET_HOME } from "../types/home";

export const setHome = (payload) => (dispatch) => {
  dispatch({
    type: SET_HOME,
    payload: payload,
  });
};

export const updateHeartLikeAction = (id, isLiked) => (dispatch, getState) => {
  const homeData = getState().Home.home;
  const dataToUpdate = {
    ...homeData,
  };

  for (let index = 0; index < dataToUpdate.section_one?.length ?? 0; index++) {
    if (dataToUpdate.section_one?.[index].id == id) {
      dataToUpdate.section_one[index].reactions_count =
        isLiked === true
          ? dataToUpdate.section_one[index].reactions_count + 1
          : dataToUpdate.section_one[index].reactions_count - 1;
      break;
    }
  }
  for (let index = 0; index < dataToUpdate.section_two?.length ?? 0; index++) {
    if (dataToUpdate.section_two?.[index].id == id) {
      dataToUpdate.section_two[index].reactions_count =
        isLiked === true
          ? dataToUpdate.section_two[index].reactions_count + 1
          : dataToUpdate.section_two[index].reactions_count - 1;

      break;
    }
  }
  for (
    let index = 0;
    index < dataToUpdate.section_three?.length ?? 0;
    index++
  ) {
    if (dataToUpdate.section_three?.[index].id == id) {
      dataToUpdate.section_three[index].reactions_count =
        isLiked === true
          ? dataToUpdate.section_three[index].reactions_count + 1
          : dataToUpdate.section_three[index].reactions_count - 1;

      break;
    }
  }
  for (let index = 0; index < dataToUpdate.section_four?.length ?? 0; index++) {
    if (dataToUpdate.section_four?.[index].id == id) {
      dataToUpdate.section_four[index].reactions_count =
        isLiked === true
          ? dataToUpdate.section_four[index].reactions_count + 1
          : dataToUpdate.section_four[index].reactions_count - 1;

      break;
    }
  }
  dispatch({
    type: SET_HOME,
    payload: dataToUpdate,
  });
};
