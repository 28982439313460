import React, {Component} from "react";
import {noCase} from 'change-case';
import {businessVerificationRequest} from "../../api/business";
import {connect} from "react-redux";
import {validateEmail, validatePhoneNumber, validateWebsite} from "../../utils/utils";
import NotLoggedInRedirection from "../Auth/NotLoggedInRedirection/NotLoggedInRedirection";

class BusinessVerification extends Component {

    state = {
        data: {
            name: '',
            registration_number: '',
            mobile: '',
            telephone: '',
            category: 'Information Technology',
            address: '',
            registered_location: '',
            registration_date: '',
            email: '',
            business_nature: '',
            website_url: '',
            applicant_designation: '',
            applicant_name: '',
            applicant_mobile: '',
            avidence_document: null,
            declaration: false
        },
        message: null,
        isError: false,
        isLoading: false
    }

    oc = e => {
        e.preventDefault();
        this.setState({...this.state, data: {...this.state.data, [e.target.name]: e.target.value}})
    }

    submit = async (e) => {

        e.preventDefault();

        try {

            for (const key of Object.keys(this.state.data)) {
                if (this.state.data[key] === '' || this.state.data[key] === false || this.state.data[key] === null) {
                    return this.setState({...this.state, isError: true, message: `${noCase(key)} is required!`})
                }

                if (key === 'email' && !validateEmail(this.state.data[key].toLowerCase())) {
                    return this.setState({...this.state, isError: true, message: `${noCase(key)} is not valid!`})
                }

                if (key === 'mobile' && !validatePhoneNumber(this.state.data[key].toLowerCase())) {
                    return this.setState({...this.state, isError: true, message: `${noCase(key)} is not valid!`})
                }

                if (key === 'telephone' && !validatePhoneNumber(this.state.data[key].toLowerCase())) {
                    return this.setState({...this.state, isError: true, message: `${noCase(key)} is not valid!`})
                }

                if (key === 'applicant_mobile' && !validatePhoneNumber(this.state.data[key].toLowerCase())) {
                    return this.setState({...this.state, isError: true, message: `${noCase(key)} is not valid!`})
                }

                if (key === 'website_url' && !validateWebsite(this.state.data[key].toLowerCase())) {
                    return this.setState({...this.state, isError: true, message: `${noCase(key)} is not valid!`})
                }
            }

            await new Promise(resolve => this.setState({
                ...this.state,
                isError: false,
                message: null,
                isLoading: true
            }, () => resolve()));

            const formData = new FormData();

            formData.append('document', this.state.data.avidence_document);
            formData.append('data', JSON.stringify({
                ...this.state.data,
                avidence_document: this.state.data.avidence_document.name
            }));

            await businessVerificationRequest(formData, this.props.token);
            this.setState({...this.state, message: 'Successfully Requested!', isError: false, isLoading: false});
        } catch (e) {
            this.setState({...this.state, isLoading: false, isError: true, message: e.message});
        }
    }

    render() {
        const {business} = this.props.profile;

        if (business && business.status === 'PENDING')
            return (
                <main>
                    <section id="CreateCollectible" className={'py-5'}>
                        <div className="container position-relative">
                            <div className="col-sm-12 col-md-10 col-lg-8 col-xl-8 Fsize_16 ">
                                <h2 className="mb-4">Your Request for Business Verification has been Received</h2>
                                <p>After reviewing your business information our team will let you know about the status
                                    it will approximately took 48 hours depends on the verifications load. Thank you for
                                    your patience.</p>
                            </div>
                        </div>
                    </section>
                </main>
    )

        if (business && business.status === 'APPROVED')
            return (
                <main>
                    <section id="CreateCollectible" className={'py-5'}>
                        <div className="container position-relative">
                            <div className="row justify-content-center gx-3">
                                <div className="col-sm-12 col-md-10 col-lg-8 col-xl-8 Fsize_16">
                                    <h1 className="mb-4">Congratulations! Your Request for business account has been
                                        approved!</h1>
                                    <p>Your profile has been successully verified as business profile! Now you are
                                        elligible to take advantages for business multiple functionalities in NFT City
                                        Marketplace. Thanks for your patience.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            )

        const {
            data: {
                name,
                registration_number,
                mobile,
                telephone,
                category,
                address,
                registered_location,
                registration_date,
                email,
                business_nature,
                website_url,
                applicant_designation,
                applicant_name,
                applicant_mobile,
                avidence_document,
                declaration
            }, message, isError, isLoading
        } = this.state;
        return (
            <main>
                <section id="CreateCollectible" className={'py-5'}>
                    <div className="container">
                        <div className="row justify-content-center gx-3" id="zIndexMob">
                            <div className="col-sm-12 col-md-10 col-lg-12 col-xl-8 Fsize_16">
                                <h2 className="mb-4">Business Verification</h2>
                                <p className={'mb-5'}>Proceed with verification process to get more
                                    visibility and gain trust on NUCOIN Marketplace.
                                    <br/>Please allow up to several
                                    weeks for the process</p>


                                <div className={'row'}>
                                    <div className="col-md-6 col-sm-6 mb-3">
                                        <label className="form-label CeraMed d-block mb-1">Business Name</label>
                                        <input type="text" maxLength="50" name="name" value={name}
                                               onChange={this.oc} className="form-control shadow-none"
                                               placeholder="Enter business name"/>
                                    </div>
                                    <div className="col-md-6 col-sm-6 mb-3">
                                        <label className="form-label CeraMed d-block mb-1">Registration
                                            Number</label>
                                        <input maxLength="70" name="registration_number"
                                               value={registration_number} onChange={this.oc} type="text"
                                               className="form-control shadow-none"
                                               placeholder="Enter registration number"/>
                                    </div>
                                    <div className="col-xl-3 col-md-4 col-sm-4 mb-3">
                                        <label className="form-label CeraMed d-block mb-1">Business
                                            Mobile</label>
                                        <input maxLength="20"
                                               style={{borderColor: validatePhoneNumber(mobile) ? '' : mobile !== '' && 'red'}}
                                               name="mobile" value={mobile} onChange={this.oc} type="text"
                                               className="form-control shadow-none"
                                               placeholder="Enter mobile number"/>
                                    </div>
                                    <div className="col-xl-3 col-md-4 col-sm-4 mb-3">
                                        <label className="form-label CeraMed d-block mb-1">Business Telephone</label>
                                        <input maxLength="20"
                                               style={{borderColor: validatePhoneNumber(telephone) ? '' : telephone !== '' && 'red'}}
                                               name="telephone" value={telephone} onChange={this.oc} type="text"
                                               className="form-control shadow-none"
                                               placeholder="Enter telephone number"/>
                                    </div>
                                    <div className="col-xl-6 col-md-4 col-sm-4 mb-3">
                                        <label className="form-label CeraMed d-block mb-1">Business
                                            Category</label>
                                        <select name="category" value={category} onChange={this.oc}
                                                className="form-control shadow-none">
                                            <option>Information Technology</option>
                                            <option>Health</option>
                                            <option>Law & Order</option>
                                            <option>Education</option>
                                            <option>Agriculture / Livestock / Food</option>
                                            <option>Transport</option>
                                            <option>Citizen Centric Services</option>
                                            <option>Support Services</option>
                                        </select>
                                    </div>
                                    <div className="col-12 mb-3">
                                        <label className="form-label CeraMed d-block mb-1">Business Address -
                                            Head Office <span
                                                className="text-secondary">{address.length}/100</span></label>
                                        <input maxLength="100" name="address" value={address} onChange={this.oc}
                                               type="text" className="form-control shadow-none"
                                               placeholder="Enter address - Head Office"/>
                                    </div>
                                    <div className="col-xl-6 col-lg-4 col-md-6 col-sm-12 mb-3">
                                        <label className="form-label CeraMed d-block mb-1">Where's the business
                                            registered?</label>
                                        <input maxLength="100" name="registered_location"
                                               value={registered_location} onChange={this.oc} type="text"
                                               className="form-control shadow-none"
                                               placeholder="Enter registered location"/>
                                    </div>
                                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                                        <label className="form-label CeraMed d-block mb-1">Date of Business
                                            Reg.</label>
                                        <input name="registration_date" value={registration_date}
                                               onChange={this.oc} type="date"
                                               className="form-control shadow-none"/>
                                    </div>
                                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                                        <label className="form-label CeraMed d-block mb-1">Business Email</label>
                                        <input maxLength="60" name="email" value={email} onChange={this.oc}
                                               style={{borderColor: validateEmail(email) ? '' : email !== '' && 'red'}}
                                               type="text" className="form-control shadow-none"
                                               placeholder="Enter business email"/>
                                    </div>

                                    <div className="col-12 mb-3">
                                        <label className="form-label CeraMed d-block mb-1">What is the nature of
                                            the business? <span
                                                className="text-secondary">{business_nature.length}/150</span></label>
                                        <textarea maxLength="150" name="business_nature" value={business_nature}
                                                  onChange={this.oc} type="text"
                                                  className="form-control shadow-none"
                                                  placeholder="Enter business nature"/>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-3">
                                        <label className="form-label CeraMed d-block mb-1">Applicant Phone
                                            Number</label>
                                        <input maxLength="20"
                                               style={{borderColor: validatePhoneNumber(applicant_mobile) ? '' : applicant_mobile !== '' && 'red'}}
                                               name="applicant_mobile" value={applicant_mobile}
                                               onChange={this.oc} type="text"
                                               className="form-control shadow-none"
                                               placeholder="Enter applicant mobile"/>
                                    </div>
                                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                                        <label className="form-label CeraMed d-block mb-1">Business
                                            Website</label>
                                        <input maxLength="30"
                                               style={{borderColor: validateWebsite(website_url) ? '' : website_url !== '' && 'red'}}
                                               name="website_url" value={website_url} onChange={this.oc}
                                               type="text" className="form-control shadow-none"
                                               placeholder="https://nftcity.com.au"/>
                                    </div>
                                    <div className="col-xl-3 col-md-6 col-sm-6 mb-3">
                                        <label className="form-label CeraMed d-block mb-1">Applicant
                                            Name</label>
                                        <input maxLength="40" name="applicant_name" value={applicant_name}
                                               onChange={this.oc} type="text"
                                               className="form-control shadow-none"
                                               placeholder="Enter name of the applicant"/>
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-sm-12 mb-3">
                                        <label className="form-label CeraMed d-block mb-1">What is your
                                            designation in the business?</label>
                                        <input maxLength="40" name="applicant_designation"
                                               value={applicant_designation} onChange={this.oc} type="text"
                                               className="form-control shadow-none"
                                               placeholder="Director / CEO"/>
                                    </div>

                                    <div className={'row my-4'}>
                                        <div className={'col-lg-8 col-md-12'}>
                                            <label className="form-label CeraMed d-block mb-1">Upload Business
                                                Registration Certificate</label>
                                            <div className="d-sm-flex justify-content-between align-items-center">
                                                <input className="d-none" id="input-file" type="file"
                                                       accept="image/png, image/gif, image/jpeg, pdf, docx"
                                                       onChange={e => {
                                                           this.setState({
                                                               ...this.state,
                                                               data: {
                                                                   ...this.state.data,
                                                                   avidence_document: e.target.files[0]
                                                               }
                                                           })
                                                       }}/>
                                                {/* <label className="form-label CeraMed">Upload business identity document PDF, DOCX file</label> */}
                                                <p className="form-text me-2 mb-md-0 mb-2">Provided informations will remain
                                                    private and confidentially between user and nucoin.</p>
                                                <button onClick={() => {
                                                    document.getElementById('input-file').click();
                                                }} type="submit"
                                                        className="btn BtnBlack py-2 px-4 white-space-nowrap">{avidence_document ? avidence_document.name : 'Choose file'}</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <div className="row">
                                            <div className="col-12">
                                                <input checked={declaration} onClick={() => {
                                                    this.setState({
                                                        ...this.state,
                                                        data: {...this.state.data, declaration: !declaration}
                                                    })
                                                }} type="checkbox" style={{marginRight: '5px', cursor: 'pointer'}}/>
                                                <span style={{cursor: 'pointer'}} onClick={() => {
                                                    this.setState({
                                                        ...this.state,
                                                        data: {...this.state.data, declaration: !declaration}
                                                    })
                                                }}>I declare that all the information I have given on this form is accurate.</span>
                                            </div>
                                        </div>
                                        {
                                            isError && (<div className="alert alert-danger mt-4">
                                                {message}
                                            </div>)
                                        }
                                        {
                                            !isError && message && (<div className="alert alert-success mt-4">
                                                {message}
                                            </div>)
                                        }
                                        <button disabled={isLoading} onClick={this.submit.bind(this)}
                                                className="btn BtnBlack py-2 px-4 text-uppercase mt-3">Submit {isLoading &&
                                        <i className="fas fa-asterisk fa-spin"></i>} </button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <NotLoggedInRedirection/>
            </main>
        )
    }
}

const mapStateToProps = state => ({
    profile: state.Profile.profile,
    token: state.Auth.token
})

export default connect(mapStateToProps, {})(BusinessVerification);