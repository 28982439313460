import moment from "moment";
import React, { Component, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import timeAgo from "time-ago";
import { UrlMatcher, HashtagMatcher } from "interweave-autolink";
import { addCommentApi, deleteNFTComment, editCommentApi, getNFTComments } from "../../../api/nft/nft";
import { Interweave } from "interweave";
import FadeIn from "react-fade-in";
import { toast } from "react-toastify";
import ConfirmationModal from "../../Layouts/Modals/ConfirmationModal";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import InputIntellisense from "./InputIntellisense/InputIntellisense";
import SkeletonLoading from "../../Layouts/SkeletonLoading/SkeletonLoading";

const REACT_APP_PROFILE_SERVICE = process.env.REACT_APP_PROFILE_SERVICE || "http://localhost:4002";

const CommentSection = React.forwardRef((props, ref) => {

  const lastItemRef = useRef();

  const [ state, setState ] = useState({ comments: [], mode: "SUBMIT", limit: 2, offset: 0, hasMoreComments: true, loadingComments: false });

  const [ deleteCommentData, setDeleteCommentData ] = useState({ show: false, comment_id: undefined });

  const curentUserName = useSelector((state) => state.Profile?.profile?.user_name);

  useImperativeHandle(ref, () => ({
    addNewlyAddedComment: (comment) => {
      console.log('tester ', comment)
      if (comment) {
        setState({
          ...state,
          comments: [ comment, ...state.comments ]
        });
      }

      lastItemRef?.current?.scrollIntoView({ behavior: "smooth" });
    },
  }));

  useEffect(() => {
    syncComemnts();
  }, [props.nftID, state.offset]);

  const syncComemnts = async () => {
    if (props.nftID) {
      
      setState({ ...state, loadingComments: true });
      
      getNFTComments(props.nftID, props.userToken, state.limit, state.offset).then(({ data }) => {
        if (data?.data) setState({ ...state, comments: [...(state.comments ?? []), ...(data?.data ?? [])], loadingComments: false, hasMoreComments: data?.data?.length === 0 ? false : true });
      });
    }
  };

  const submitEditComment = (commentID, commentUpdate) => {

    const commentIndex = state.comments.findIndex(_ => _ == commentID);

    if(commentIndex > -1) {
      state.comments[commentIndex] = commentUpdate;
    }
  };

  const doRemoveCommentCall = () => {
    const id = deleteCommentData.comment_id;
    if (id) {
      deleteNFTComment(id, props.userToken)
        .then(() => {})
        .catch(() => {
          toast.error(
            "Failed to remove your comment. Please check your internet connection!"
          );
        });

      new Promise(function (resolve) {
        var filtered = state.comments?.filter(function (item, index, arr) {
          return item.id !== id;
        });
        resolve(filtered);
      }).then(function (result) {
        if (result)
          setState({
            ...state,
            commentToPost: "",
            isSubmiting: false,
            comments: result,
          });
      });
    }
    setDeleteCommentData({ show: false, comment_id: undefined });
  };

  const removeComment = (id) => {
    if (id) setDeleteCommentData({ show: true, comment_id: id });
  };

  const loadMoreComments = () => setState({ ...state, offset: state.comments?.length ?? 0, limit: 10 });

  return (
    <div className="row">
      {state.comments?.length != 0 &&
        state.hasMoreComments &&
        state.comments?.length > 1 && (
          <div
            className="Fsize_14 cursor-pointer py-2 link-primary"
            onClick={loadMoreComments}
          >
            View more comments
            {state.loadingComments && (
              <Spinner
                animation="border"
                style={{ width: 16, height: 16 }}
                className={"mx-2"}
              />
            )}
          </div>
        )}

      {
        state.comments?.length === 0 && state.hasMoreComments == false && (
          <div className="Fsize_13 cursor-pointer py-2 text-secondary text-center">
            <FadeIn delay={0}>
              No reviews found!
            </FadeIn>
          </div>
        )
      }
      {state.loadingComments && state.comments?.length == 0 && (
        <FadeIn delay={0}>
          { 
            [1, 2, 3].map(_ => <SkeletonLoading key={_} style={{ height: '50px' }} className={"bg-comment-box my-2"} />) 
          }
        </FadeIn>
      )}

      <ul className={""}>
        <FadeIn>
          {[...(state?.comments ?? [])].reverse().map((item) => {
            return (
              <li key={`${item.id}`} className={"list-group-numbered"}>
                <ItemComment
                  comment={item}
                  mentions={item.mentions}
                  userToken={props.userToken}
                  curentUserName={curentUserName}
                  removeCommentListener={removeComment}
                  editCommentCallback={submitEditComment}
                />
              </li>
            );
          })}
        </FadeIn>
        <li ref={lastItemRef} className={"list-group-numbered"} />
      </ul>

      <ConfirmationModal
        id="delete-confirmation"
        title={"Delete Comment Confirmation?"}
        message={"Are you sure you want to delete this comment?"}
        onConfirmPress={doRemoveCommentCall}
      />
    </div>
  );
});

const ItemComment = (props) => {
  const [ state, setState ] = useState({ isEditing: false, isSubmiting: false });

  const [ comment, setComment ] = useState({ ...props.comment, mentions: Array.isArray(props.comment.mentions) ? props.comment.mentions : new Array( ...JSON.parse(props.comment.mentions  || JSON.stringify([])) ) });

  const removeComment = () => { props.removeCommentListener(comment.id); };

  const commentEditListener = e => {
    setComment({ ...comment, comment: e.target.value })
  }

  const inputCommentKeyPress = (ev) => {

    if (ev.key === "Escape") {
      setComment({ ...comment, comment: props.comment.comment });
      return setState({ ...state, isEditing: false });
    }

    if(ev.key === 'Enter' && ev.shiftKey) return;

    if (ev.keyCode === 13) {
      ev.preventDefault()
      submitEditComment();
    }
  };
  const submitEditComment = async () => {
    if (state.isSubmiting) return;
    try {
      setState({ ...state, isSubmiting: true });

      let mentions = comment.mentions;

      mentions = await Promise.all(mentions.filter(_ => comment.comment.split(' ').findIndex(w => w === _) !== -1));

      setComment({ ...comment, mentions });

      editCommentApi( comment.id, { comment: comment.comment.trim(), mentions: mentions }, props.userToken).then((data) => {
          props.editCommentCallback(comment.id, { ...comment, comment: comment.comment.trim() });

          setState({ ...state, isEditing: false, isSubmiting: false });
        }).catch(() => {
          toast.error("Somthing is wroing. Please check your internet connection!");
          setState({ ...state, isSubmiting: false });
        });
    } catch (e) {
    }
  };

  const editComment = () => {
    setState({ ...state, isEditing: true });
  };

  const mentionTagsListener = ({ item: { user_name } }) => {
    setComment({ ...comment, mentions: [ ...comment.mentions.filter(_ => _ !== user_name), user_name ] });
  }

  return (
    <div className="d-flex py-2 border-bottom w-100">
      <div className="flex-shrink-0 me-1">
        <div className="avatar-xs">
          <Link to={`/profile/${comment.user_name}`} className="d-flex align-items-center cursor-pointer">
            <img src={comment.profile_image ? `${REACT_APP_PROFILE_SERVICE}/v1/profile/image/${comment.user_name}` : "/images/user.png"} alt="Image" className="img-fluid ImgProfile me-2 " style={{ width: 35, height: 35 }} />
          </Link>
        </div>
      </div>
      <div className="flex-grow-1 ">
        <div className="w-100 bg-comment-box p-2 rounded-5">
          <h5 className="Fsize_14 mb-0 flex-grow-1">
            { comment.display_name || comment.user_name }
          </h5>
          {
            state.isEditing ? (
              <div className="d-flex justify-content-between mt-1 align-self-start">
                <InputIntellisense 
                  onChangeListener={commentEditListener}
                  onKeyPressedListener={inputCommentKeyPress}
                  textValue={comment.comment}
                  onTagItemSelected={mentionTagsListener}
                />
                <button disabled={comment.comment.length === 0} className={`btn ${comment.comment.length === 0 ? "BtnBorderBlack2 BtnEdit" : "BtnBorderBlack2 BtnEdit"} pt-1 pb-1 px-1 text-uppercase  d-xl-block ms-2`} style={{ width: 70, height: 35, fontSize: 12 }} onClick={submitEditComment}>
                  { state.isSubmiting ? <Spinner animation="border" style={{ width: 20, height: 20 }} /> : "EDIT" }
                </button>
                <button className={`btn BtnBorderBlack2 pt-1 pb-1 px-1 text-uppercase  d-xl-block ms-2`} onClick={() => {
                    setState({ ...state, isEditing: false });
                    setComment(props.comment);
                  }} style={{ width: 80, height: 35, fontSize: 12 }} > Cancel </button>
              </div>
            ) : (
                <Interweave className="text-muted Fsize_14 mb-1 text-break" content={comment.comment.split(' ').map(word => Array.isArray(comment.mentions) && comment.mentions.findIndex(_ => _ === word) === -1 ? word : `<a style="color:#0d6efd;" href="/profile/${word}">${word}</a>` ).join(' ')} matchers={[ new UrlMatcher("url") ]} />
            )
          }
        </div>

        <div className="w-100 mt-2" style={{padding: "0px 10px"}}>
          {props?.curentUserName === comment.user_name && (
            <>
              <a href="javascript: void(0);" className="text-7E8083  me-3 comentItemActionBtn " onClick={editComment}>
                <i className="fas fa-edit"></i> Edit
              </a>

              <a href="javascript: void(0);" className="text-7E8083 comentItemActionBtn" style={{}} onClick={() => {
                removeComment();
                document.getElementById(`delete-confirmation`).click();
              }}>
                <i className="fas fa-trash "></i> Delete
              </a>
            </>
          )}
          <div className="text-muted fw-light Fsize_10 float-end mt-1">
            { comment.update_time && "Edited | " }{" "}
            { timeAgo.ago(moment.utc(comment.update_time || comment.create_time).local()) }
          </div>
        </div>
      </div>
    </div>
  );
};


export default CommentSection;