import invoke from '../../utils/invoke';

const BASE_PATH = process.env.REACT_APP_PROFILE_SERVICE || 'http://localhost:4002';

export const businessVerificationRequest = (data = {}, token = null) => {
    return invoke({
        method: 'POST',
        baseURL: BASE_PATH,
        route:  'v1/business/verification/request',
        data: data,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}