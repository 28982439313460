import React, { Component } from 'react';
import { mnemonicValidate, validateAddress } from "@polkadot/util-crypto";
import { Keyring } from '@polkadot/api';
import { validatedNumber } from '../../../utils/utils';
import { getNSTBalanceByParamApi, getNUCBalanceByParamApi, swapCoinsApi } from '../../../api/wallet/wallet';
import MessageBox from '../../Layouts/MessageBox/MessageBox';
import $ from 'jquery';

class Conversion extends Component {

    state = {
        data: {
            from: 'NUC',
            to: 'NST',
            fromAddress: null,
            toAddress: null,
            fromValue: null,
            toValue: null,
            mnemonics: null
        },
        balances: {
            NUC: null,
            NST: null
        },
        balanceLoading: {
            NUC: false,
            NST: false
        },
        showMnemonicsInput: false,
        isError: false,
        message: null,
        isLoading: false
    }

    componentDidMount() {
        if(localStorage.getItem('theme') === 'dark') {
            $(".modal").addClass("DarkModal");
            $(".dropdown-menu").addClass("dropdown-menu-dark");
        }
    }

    onChangeTargets = (key, value) => {
        this.setState({
            ...this.state,
            data: {...this.state.data, [key]: value, [key === 'from' ? 'to' : 'from']: value === 'NST' ? 'NUC' : 'NST'}
        })
    }

    oc = e => {
        if(e.target.name === 'toAddress' && this.isValidAddress(e.target.value)) {
            this.updateRecieverBalance(e.target.value);
        }
        this.setState({...this.state, isError: null, balanceLoading: { ...this.state.balanceLoading, [this.state.data.to]: e.target.name === 'toAddress' && this.isValidAddress(e.target.value) }, message: null, data: {...this.state.data, [e.target.name]: e.target.value}});
    }

    async updateSenderBalance(address) {
        if(this.state.data.from === 'NUC') {
            getNUCBalanceByParamApi(address).then(res => {
                this.setState({ ...this.state, balanceLoading: { ...this.state.balanceLoading, [this.state.data.from]: false }, balances: { ...this.state.balances, [this.state.data.from]: res.data.balance } });
            }).catch(_ => {});
        } else {
            getNSTBalanceByParamApi(address).then(res => {
                this.setState({ ...this.state, balanceLoading: { ...this.state.balanceLoading, [this.state.data.from]: false }, balances: { ...this.state.balances, [this.state.data.from]: res.data.balance } });
            }).catch(_ => {});
        }
    }

    async updateRecieverBalance(address) {
        if(this.state.data.to === 'NUC') {
            getNUCBalanceByParamApi(address).then(res => {
                this.setState({ ...this.state, balanceLoading: { ...this.state.balanceLoading, [this.state.data.to]: false }, balances: { ...this.state.balances, [this.state.data.to]: res.data.balance } });
            }).catch(_ => {});
        } else {
            getNSTBalanceByParamApi(address).then(res => {
                this.setState({ ...this.state, balanceLoading: { ...this.state.balanceLoading, [this.state.data.to]: false }, balances: { ...this.state.balances, [this.state.data.to]: res.data.balance } });
            }).catch(_ => {});
        }
    }

    onChangeMnemonics = e => {
        e.preventDefault();

        if (mnemonicValidate(e.target.value)) {
            const keyring = new Keyring({
                type: 'sr25519',
                ss58Format: 42,
            });
            const newPair = keyring.addFromUri(e.target.value);
            
            this.setState({ ...this.state, balanceLoading: { ...this.state.balanceLoading, [this.state.data.from]: true }, data: { ...this.state.data, mnemonics: e.target.value, fromAddress: newPair.address } });

            this.updateSenderBalance(newPair.address);

        } else {
            this.setState({ ...this.state, data: { ...this.state.data, mnemonics: e.target.value, fromAddress: null } });
        }
    }

    isValidAddress(address) {
        try {
            return validateAddress(address);
        } catch(e) {
            return false;
        }
    }

    async submit(e) {
        e.preventDefault();
        await new Promise(resolve => this.setState({...this.state, isError: false, message: null}, resolve));

        try {
            const fromValue = parseFloat(this.state.data.fromValue || 0);

            if (fromValue <= 0) {
                return this.setState({...this.state, isError: true, message: 'Swap value must be a positive number.'})
            }

            if (!this.state.showMnemonicsInput) return this.setState({...this.state, showMnemonicsInput: true})

            if (this.state.data.mnemonics === null || this.state.data.mnemonics === '') return this.setState({
                ...this.state,
                isError: true,
                message: 'Mnemonics are required!'
            });

            this.setState({...this.state, isLoading: true});

            const { data: { burn_hash, mint_hash } } = await swapCoinsApi({
                mnemonics: this.state.data.mnemonics,
                coins: this.state.data.fromValue,
                currency: this.state.data.from,
                toAddress: this.state.data.toAddress
            });

            this.setState({
                ...this.state, isLoading: false, isError: false,
                message: <p><strong>Burn Hash:</strong> {burn_hash} <br/>
                          <strong>Mint Hash:</strong> {mint_hash} <br/>
                          <strong>Swap Successfull!</strong></p>
            });

            this.updateSenderBalance(this.state.data.fromAddress);
            this.updateRecieverBalance(this.state.data.toAddress);

        } catch (e) {
            this.setState({
                ...this.state,
                isError: true,
                isLoading: false,
                message: e.response ? e.response.data : e.message
            })
        }

        document.getElementById('messageBoxBtn').click();
    }

    render() {

        const { from, to, fromAddress, toAddress, fromValue, toValue, mnemonics } = this.state.data;
        const { balances, balanceLoading } = this.state;
        const { isError, message, showMnemonicsInput } = this.state;

        return (
            <React.Fragment>
                <div className="tab-pane fade" id="Swap-tab-panel-2" role="tabpanel" aria-labelledby="Swap-tab-2">
                    <div className="bgwhite p-3 p-md-4 p-lg-5">
                        <h3 className={'mb-4'}>Multi Wallet Swap</h3>
                        <div className="row justify-content-center">
                            <div className="col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                <div className="mb-1">
                                    <div className="d-sm-flex justify-content-between">
                                        <label className="form-label CeraMed d-block mb-0">Enter Mnemonics</label>
                                        {
                                            balanceLoading[from] && <i className="fas fa-asterisk fa-spin"></i>
                                        }
                                        {
                                            balances[from] !== null && <label className="form-label d-block mb-0">Available Balance: <span className="CeraMed ColorWhite">{balances[from]}</span></label> 
                                        }
                                        {/* <label className="form-label d-block mb-0">Available Balance: <span className="CeraMed ColorWhite"> N/A</span></label> */}
                                    </div>
                                    <div className="input-group mb-0 FullOnMob d-block d-sm-flex">
                                        <input onChange={this.onChangeMnemonics} style={{ borderColor: mnemonicValidate(mnemonics) ? '' : mnemonics === null ? '' : 'red' }} value={mnemonics} name="mnemonics" type="text" className="form-control shadow-none" placeholder={`Please enter ${from} account mnemonics`}/>
                                        <span className="input-group-text">
                                    <div className="dropdown bootstrap-select">
                                    <select className="SelectPicker" tabIndex="null">
                                        <option
                                            data-content="<img className='SelectPickerImg me-2 mb-1' src='images/icon-eth.png'><span className='text-dark'>ETH</span>"> Chrome</option>
                                        <option
                                            data-content="<img className='SelectPickerImg me-2 mb-1' src='images/icon-dai.png'><span className='text-dark'>DAI</span>"> Chrome</option>
                                        <option
                                            data-content="<img className='SelectPickerImg me-2 mb-1' src='images/icon-usdc.png'><span className='text-dark'>USDC</span>"> Chrome</option>
                                    </select>
                                    <button type="button" tabIndex="-1" className="btn dropdown-toggle btn-light"
                                            data-bs-toggle="dropdown" role="combobox" aria-owns="bs-select-1"
                                            aria-haspopup="listbox" aria-expanded="false" title={from}>
                                        <div className="filter-option">
                                            <div className="filter-option-inner">
                                                <div className="filter-option-inner-inner">
                                                    <img className="SelectPickerImg me-2 mb-1"
                                                            src={`/images/icon-${from}.png`}/>
                                                    <span className="text-dark">{from}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </button>
                                        <div className="dropdown-menu"
                                                style={{maxHeight: '210px', overflow: 'hidden', minHeight: '0px'}}>
                                            <div className="inner show" role="listbox" id="bs-select-1"
                                                    tabIndex="-1" style={{
                                                maxHeight: '192px',
                                                overflow: 'hidden auto',
                                                minHeight: '0px'
                                            }} aria-activedescendant="bs-select-1-0">
                                                <ul className="dropdown-menu inner show" role="presentation"
                                                    style={{marginTop: '0px', marginBottom: '0px'}}>
                                                    <li onClick={this.onChangeTargets.bind(this, 'from', 'NUC')}
                                                        className={from === 'NUC' ? "selected active" : ''}>
                                                        <a role="option" className="dropdown-item"
                                                            id="bs-select-1-0" tabIndex="0" aria-setsize="3"
                                                            aria-posinset="1" aria-selected="true">
                                                            <span className="text"><img
                                                                className="SelectPickerImg me-2 mb-1"
                                                                src="/images/icon-NUC.png"/>
                                                                <span>NUC</span>
                                                            </span>
                                                        </a>
                                                    </li>
                                                    <li onClick={this.onChangeTargets.bind(this, 'from', 'NST')}
                                                        className={from === 'NST' ? "selected active" : ''}>
                                                        <a role="option" className="dropdown-item"
                                                            id="bs-select-1-1" tabIndex="0" aria-setsize="3"
                                                            aria-posinset="2">
                                                            <span className="text"><img
                                                                className="SelectPickerImg me-2 mb-1"
                                                                src="images/icon-NST.png"/>
                                                                <span>NST</span>
                                                            </span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </span>
                                </div>
                                    <label style={{ textAlign: 'end', fontSize: '13px' }} className="text-secondary w-100">
                                        { fromAddress }
                                    </label>
                                </div>
                                <div className="mb-3 text-center">
                                    <div className="row">
                                        <div className="col-8">
                                            <div className="input-group mb-0">
                                                <input style={{ borderColor: validatedNumber(fromValue) ? '' : fromValue === null ? '' : 'red' }} onChange={this.oc} value={fromValue} name="fromValue" type="text" className="form-control shadow-none" placeholder={`Enter amount to convert in ${to}`}/>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="mt-2 text-center">
                                                {/* <i onClick={this.onChangeTargets.bind(this, 'to', this.state.data.to === 'NUC' ? 'NST' : 'NUC')} style={{ cursor: 'pointer' }} className="fas fa-exchange-alt Rotate_180"></i> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="mb-2 text-center">
                                    <i onClick={this.onChangeTargets.bind(this, 'to', this.state.data.to === 'NUC' ? 'NST' : 'NUC')} style={{ cursor: 'pointer' }} className="fas fa-exchange-alt Rotate_180"></i>
                                </div> */}
                                <div className="mb-3">
                                    <div className="d-sm-flex justify-content-between">
                                        <label
                                            className="form-label CeraMed d-block mb-0">To</label>
                                            {
                                                balanceLoading[to] && <i className="fas fa-asterisk fa-spin"></i>
                                            }
                                        {
                                            this.state.balances[to] && <label className="form-label d-block mb-0">Available Balance: <span className="CeraMed ColorWhite"> {this.state.balances[to]}</span></label>
                                        }
                                    </div>
                                    <div className="input-group mb-0  FullOnMob d-block d-sm-flex">
                                        <input style={{ borderColor: this.isValidAddress(toAddress || '') ? '' : toAddress === null ? '' : 'red' }} onChange={this.oc} value={toAddress}
                                                name="toAddress" type="text"
                                                className="form-control shadow-none"
                                                placeholder="Enter receiver address here"/>
                                        <span className="input-group-text">
                                    <div className="dropdown bootstrap-select">
                                    <select className="SelectPicker" tabIndex="null">
                                        <option
                                            data-content="<img className='SelectPickerImg me-2 mb-1' src='images/icon-eth.png'><span className='text-dark'>ETH</span>"> Chrome</option>
                                        <option
                                            data-content="<img className='SelectPickerImg me-2 mb-1' src='images/icon-dai.png'><span className='text-dark'>DAI</span>"> Chrome</option>
                                        <option
                                            data-content="<img className='SelectPickerImg me-2 mb-1' src='images/icon-usdc.png'><span className='text-dark'>USDC</span>"> Chrome</option>
                                    </select>
                                    <button type="button" tabIndex="-1"
                                            className="btn dropdown-toggle btn-light"
                                            data-bs-toggle="dropdown" role="combobox"
                                            aria-owns="bs-select-1"
                                            aria-haspopup="listbox" aria-expanded="false"
                                            title={to}>
                                        <div className="filter-option">
                                            <div className="filter-option-inner">
                                                <div className="filter-option-inner-inner">
                                                    <img className="SelectPickerImg me-2 mb-1"
                                                            src={`/images/icon-${to}.png`}/>
                                                    <span className="text-dark">{to}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </button>
                                        <div className="dropdown-menu">
                                            <div className="inner show" role="listbox"
                                                    id="bs-select-1"
                                                    tabIndex="-1"
                                                    aria-activedescendant="bs-select-1-0">
                                                <ul className="dropdown-menu inner show"
                                                    role="presentation"
                                                    style={{
                                                        marginTop: '0px',
                                                        marginBottom: '0px'
                                                    }}>
                                                    <li onClick={this.onChangeTargets.bind(this, 'to', 'NUC')}
                                                        className={to === 'NUC' ? "selected active" : ''}>
                                                        <a role="option"
                                                            className="dropdown-item"
                                                            id="bs-select-1-0" tabIndex="0"
                                                            aria-setsize="3"
                                                            aria-posinset="1"
                                                            aria-selected="true">
                                                            <span className="text"><img
                                                                className="SelectPickerImg me-2 mb-1"
                                                                src={`/images/icon-NUC.png`}/>
                                                                <span>NUC</span>
                                                            </span>
                                                        </a>
                                                    </li>
                                                    <li onClick={this.onChangeTargets.bind(this, 'to', 'NST')}
                                                        className={from === 'NST' ? "selected active" : ''}>
                                                        <a role="option"
                                                            className="dropdown-item"
                                                            id="bs-select-1-1" tabIndex="0"
                                                            aria-setsize="3"
                                                            aria-posinset="2">
                                                            <span className="text"><img
                                                                className="SelectPickerImg me-2 mb-1"
                                                                src="images/icon-NST.png"/>
                                                                <span>NST</span>
                                                            </span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </span>
                                    </div>
                                    <label style={{ textAlign: 'end', fontSize: '13px' }} className="text-secondary w-100">
                                        {/* {this.props.currentUser.public_address || ''} */}
                                    </label>
                                </div>
                                <div className="mb-3">
                                    <button disabled={this.state.isLoading || !( mnemonicValidate(mnemonics) && this.isValidAddress(toAddress) && validatedNumber(fromValue) )} 
                                        onClick={(e) => this.submit(e)}
                                        type="button" className="btn BtnBlack w-100 py-2">
                                        Swap {this.state.isLoading &&
                                    <i className="fas fa-asterisk fa-spin"></i>}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    document.getElementById('Swap-tab-2') && document.getElementById('Swap-tab-2').className.indexOf('active') !== -1 && <MessageBox title={this.state.isError ? 'Warning!' : 'Success'} message={this.state.message} isError={this.state.isError} style={{ maxWidth: '45%' }} callback={() => {
                        if(!this.state.isError) this.setState({ ...this.state, data: { ...this.state.data, mnemonics: '', fromValue: '' }})
                    }} /> 
                }
            </React.Fragment>
        )
    }
}

export default Conversion;