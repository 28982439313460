import { SET_SHARE_DETAIL } from "../types/share";

const INITIAL_STATE = {
    shareSocial: {}
}

function shareReducer(state = INITIAL_STATE, action) {
    switch(action.type) {
        case SET_SHARE_DETAIL: {
            return { ...state, shareSocial: action.payload }
        }
        default: {
            return state;
        }
    }
}

export default shareReducer;