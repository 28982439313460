import React from "react";
import { isVideo } from "../../../../utils/utils";

export default ({ id, title, resource_url, resource_type, unsaved, type, price, minimum_bid, is_baking = 0, isSelectedIndex = false, callback = () => {} }) => {
    return (
        <React.Fragment>
            <div className="custom-radio m-1 position-relative">
                <span onClick={() => {
                    callback(false)
                }} className="form-check-input text-danger fas fa-window-close position-absolute end-0 top-0 mt-1 me-1 cursor-pointer z-index-1 border-0" />

                <label onClick={() => callback(true)}  className="form-check-label d-block" for="flexCheckDefault1">
                    {
                        isVideo(resource_type) ? (
                            <video style={{ filter: isSelectedIndex ? 'grayscale(0%)': 'grayscale(100%)', borderColor: isSelectedIndex ? '#3CDFD5' : '', cursor: 'pointer' }} autoPlay className="w-100 rounded" loop muted>
                                <source src={resource_url} type="video/mp4"/>
                            </video>
                        ) : (
                            <img style={{ filter: isSelectedIndex ? 'grayscale(0%)': 'grayscale(100%)', borderColor: isSelectedIndex ? '#3CDFD5' : '', cursor: 'pointer' }} className={'w-100'} src={resource_url}/>
                        )
                    }
                    { id && !unsaved && <span style={{ color: 'green' }} title="Changes Saved" className="far fa-check-circle"/> }
                    { id && unsaved && <i style={{ color: '#000000' }} title="Unsaved Changes" className="fas fa-exclamation"></i> }
                </label>
            </div>
        </React.Fragment>
    )
}