import React from 'react';
import { Link } from 'react-router-dom';
import timeago from 'time-ago';

const REACT_APP_PROFILE_SERVICE = process.env.REACT_APP_PROFILE_SERVICE || 'http://localhost:4002';

export default ({prop}) => (
    <Link to={`/profile/${prop?.user_name}`} className="d-flex align-items-center mb-3">
        <img src={prop.profile_image ? `${REACT_APP_PROFILE_SERVICE}/v1/profile/image/${prop.user_name}` : '/images/user.png'}
            className="img-fluid ImgProfile me-2" />
        <div className="">
            <div
                className="form-label CeraBold BlackColor text-uppercase Fsize_16 m-0"> {prop.bid_amount} NST by {prop.display_name || prop.user_name}</div>
            <span className="d-block">{timeago.ago(new Date(prop.timestamp))}</span>
        </div>
    </Link>
)