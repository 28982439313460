import invoke from '../../utils/invoke';

const BASE_PATH = process.env.REACT_APP_PROFILE_SERVICE || 'http://localhost:4002';

export const getOwnProfile = (token = null) => {
    return invoke({
        method: 'GET',
        baseURL: BASE_PATH,
        route:  'v1/profile',
        headers: {
            Authorization: `Bearer ${token}`, redirectToken: localStorage.getItem('redirectToken')
        }
    });
}

export const getUserProfile = (user_name = null) => {
    return invoke({
        method: 'GET',
        baseURL: BASE_PATH,
        route:  `v1/profile/user/${user_name}`
       
    });
}
export const searchUserProfileWithStartKey = (user_name = null,token,controller) => {
    return invoke({
        method: 'GET',
        baseURL: BASE_PATH,
        route:  `v1/profile/search/${user_name}?limit=5`,
        headers: {
            Authorization: `Bearer ${token}`
        },
        signal: controller?.signal,
    });
}

export const updateProfile = (data = null, token = null) => {
    return invoke({
        method: 'POST',
        baseURL: BASE_PATH,
        route:  'v1/profile/update',
        data: data,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}


export const updateProfileEquityFlag = (data = null, token = null) => {
    return invoke({
        method: 'POST',
        baseURL: BASE_PATH,
        route:  'v1/profile/updateEquityFlag',
        data: data,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}
export const updateProfileCover = (data = null, token = null) => {
    return invoke({
        method: 'POST',
        baseURL: BASE_PATH,
        route:  'v1/profile/cover/update',
        data: data,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export const getOwnOnSaleItem = (token = null, page = 0) => {
    return invoke({
        method: 'GET',
        baseURL: BASE_PATH,
        route:  `v1/items/own/onsale?page=${page}`,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export const getSoldItem = (token = null, page = 0) => {
    return invoke({
        method: 'GET',
        baseURL: BASE_PATH,
        route: `v1/items/own/sold?page=${page}`,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export const getOwnedItems = (token = null, page = 0) => {
    return invoke({
        method: 'GET',
        baseURL: BASE_PATH,
        route:  `v1/items/own/owned?page=${page}`,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export const getOwnedCreatedItems = (token = null, page = 0) => {
    return invoke({
        method: 'GET',
        baseURL: BASE_PATH,
        route:  `v1/items/own/created?page=${page}`,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export const getOnSaleItem = (user_name = null, page = 0) => {
    return invoke({
        method: 'GET',
        baseURL: BASE_PATH,
        route:  `v1/items/onsale/${user_name}?page=${page}`
    });
}

export const getItems = (user_name = null, page = 0) => {
    return invoke({
        method: 'GET',
        baseURL: BASE_PATH,
        route:  `v1/items/owned/${user_name}?page=${page}`
    });
}

export const getCreatedItems = (user_name = null, page = 0) => {
    return invoke({
        method: 'GET',
        baseURL: BASE_PATH,
        route:  `v1/items/created/${user_name}?page=${page}`
    });
}

export const getOwnedPendingItems = (token = null, page = 0) => {
    return invoke({
        method: 'GET',
        baseURL: BASE_PATH,
        route:  `v1/items/own/pending?page=${page}`,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export const getOwnedEquityItems = (token = null, page = 0) => {
    return invoke({
        method: 'GET',
        baseURL: BASE_PATH,
        route:  `v1/items/own/equity?page=${page}`,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export const setPublicKey = (data = null, token = null) => {
    return invoke({
        method: 'POST',
        baseURL: BASE_PATH,
        route:  'v1/profile/public_address/update',
        data: data,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export const getActivityList = (token = null, page = 0) => {
    return invoke({
        method: 'GET',
        baseURL: BASE_PATH,
        route:  `v1/items/activities?page=${page}`,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}