import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getOnSaleItem, getItems, getCreatedItems, getUserProfile, updateProfileCover } from '../../api/profile/profile';
import { Link } from 'react-router-dom';
import NFTItem from '../Layouts/NFTItem/NFTItem';

const LISTING_URL = process.env.REACT_APP_LISTING_SERVICE || 'http://localhost:4001';
const REACT_APP_PROFILE_SERVICE = process.env.REACT_APP_PROFILE_SERVICE || 'http://localhost:4002';

class Profile extends Component {

    state = {
        profile: {
            user_name: null,
            public_address: null
        },
        itemsOnSale: {
            list: [],
            page: 0,
            loadMore: false,
            isLoading: true
        },
        itemsOwned: {
            list: [],
            page: 0,
            loadMore: false,
            isLoading: true
        },
        itemsCreated: {
            list: [],
            page: 0,
            loadMore: false,
            isLoading: true
        },
        imgSrc: null,
        image: null,
        isLoading: true
    }

    async componentDidMount() {

        getUserProfile(this.props.match.params.user_name).then(({ data }) => {
            this.setState({ ...this.state, profile: data, isLoading: false });
        }).catch(e => { });

        getOnSaleItem(this.props.match.params.user_name).then(({ data }) => {
            this.setState({ ...this.state, itemsOnSale: { ...this.state.itemsOnSale, list: data, loadMore: data.length > 19, page: 0, isLoading: false } });
        }).catch(e => { });
    }

    fetchOwnOnSaleItems(page = 0) {
        this.setState({ ...this.state, itemsOnSale: { ...this.state.itemsOnSale, isLoading: true } }, () => {
            getOnSaleItem(this.props.match.params.user_name, page).then(({ data }) => {
                this.setState({ ...this.state, itemsOnSale: { list: [...this.state.itemsOnSale.list, ...data], loadMore: data.length > 19, page: page, isLoading: false } });
            }).catch(e => { });
        })
    }

    async fetchItems(param, page = 0) {
        switch (param) {
            case 1: {
                if (this.state.itemsOwned.list.length > 0 && page === 0) return;
                getItems(this.props.match.params.user_name, page).then(({ data }) => {
                    this.setState({ ...this.state, itemsOwned: { list: [...this.state.itemsOwned.list, ...data], loadMore: data.length > 19, page: page, isLoading: false } });
                }).catch(e => { });
                break;
            }
            case 2: {
                if (this.state.itemsCreated.list.length > 0 && page === 0) return;
                getCreatedItems(this.props.match.params.user_name, page).then(({ data }) => {
                    this.setState({ ...this.state, itemsCreated: { list: [...this.state.itemsCreated.list, ...data], loadMore: data.length > 19, page: page, isLoading: false } });
                }).catch(e => { });
                break;
            }
        }
    }

    onUploadFile = async e => {
        try {
            const file = e.target.files[0];

            const reader = new FileReader();
            const url = reader.readAsDataURL(file);

            if ((file.size / 1024) / 1024 > 100) {
                document.getElementById('launch-modal-btn').click();
                return;
            }

            reader.onloadend = function (e) {
                this.setState({ ...this.state, isDisabled: false, imgSrc: reader.result, image: file })
            }.bind(this);
            const formData = new FormData();
            formData.append('img', file);
            await updateProfileCover(formData, this.props.token);
        } catch (e) { }
    }

    render() {
        const { user_name, public_address, profile_image, display_name, twitter_username, site_or_portfolio, cover_image } = this.state.profile;
        const { itemsOnSale, itemsOwned, itemsCreated } = this.state;

        return (
            <main>
                <section id="ItemsProfile" className="py-5 BgGrey">
                    <div className="container">
                        <div className='row' id='zIndexMob'>
                            <div className="text-center">
                                <div className="position-relative CoverImageBox">
                                    <input id="input-file" onChange={this.onUploadFile} type="file" accept="image/png, image/gif, image/jpeg" style={{ display: 'none' }} />
                                    <img style={{ minHeight: '300px' }} src={this.state.imgSrc ? this.state.imgSrc : cover_image ? `${REACT_APP_PROFILE_SERVICE}/v1/profile/cover/${user_name}` : "/images/cover.jpeg"} alt="Cover Image" className="w-100 rounded d-block loading" />
                                    {
                                        this.props.profile.user_name === user_name && <button onClick={() => {
                                            document.getElementById('input-file').click();
                                        }} className="rounded-pill btn BtnBlue px-3 position-absolute">
                                            Add Cover
                                        </button>
                                    }
                                </div>
                                {user_name && profile_image && <img style={{ backgroundColor: 'white', minWidth: '120px', minHeight: '120px' }} src={`${REACT_APP_PROFILE_SERVICE}/v1/profile/image/${user_name}`} alt="Profile Image" className="rounded-circle d-block mx-auto w-100 h-100 position-relative ProfileImg mb-3 loading" />}
                                {user_name && !profile_image && <img style={{ backgroundColor: 'white', minWidth: '120px', minHeight: '120px' }} src="/images/user.png" alt="Profile Image" className="rounded-circle d-block mx-auto w-100 h-100 position-relative ProfileImg mb-3 loading" />}
                                {/* { <img style={{ backgroundColor: 'white', width: '120px', height: '120px' }} src={this.generator.generateRandomAvatar(user_name) } alt="Profile Image" className="rounded-circle d-block mx-auto w-100 h-100 position-relative ProfileImg mb-3"/> } */}
                                <button style={{ width: '35%' }} className="rounded-pill TextEllipsis BgGrey px-3 GreyColor mb-3 border-0">
                                    {public_address || user_name}
                                </button>
                            </div>
                             <div className="text-center"> <p><strong>{display_name || user_name}</strong></p> </div>
                            {twitter_username && <div className="text-center"> <p><i className="fa fa-twitter me-2" /> {twitter_username} {site_or_portfolio ? <React.Fragment> <i className="fa fa-globe me-2" /> {site_or_portfolio}</React.Fragment> : ''} </p> </div>}
                            <div className="d-flex justify-content-center align-items-center mb-3">
                                {this.props.profile.user_name === user_name && this.props.profile.user_name !== null && this.props.profile.user_name !== '' && <Link style={{ paddingTop: '9px' }} to="/user/profile/edit" className="rounded-pill btn BtnBorder px-3 shadow-none me-2 RoundButtonHeight">Edit Profile</Link>}
                                {/* <button className="rounded-circle btn BtnBorder shadow-none me-2 RoundButtonWidth RoundButtonHeight"><i className="fas fa-cloud-upload-alt"></i></button>
                            <div className="dropdown">
                                <button className="btn rounded-circle btn BtnBorder shadow-none RoundButtonWidth RoundButtonHeight" type="button" id="FilterDropdown"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="fas fa-ellipsis-h"></i>
                                </button>
                                <div className="dropdown-menu dropdown-menu-end dropdown-box-shadow" aria-labelledby="FilterDropdown">
                                    <a href="#" className="dropdown-item">Report</a>
                                </div>
                            </div> */}
                            </div>
                            <div className="TabBox d-flex d-md-block">
                                <ul className="nav nav-tabs d-block d-md-flex justify-content-center BorderBottom mb-4" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="Sale-tab" data-bs-toggle="tab" data-bs-target="#Sale" type="button" role="tab" aria-controls="Sale" aria-selected="true">On Sale</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button onClick={this.fetchItems.bind(this, 1, 0)} className="nav-link" id="Owned-tab" data-bs-toggle="tab" data-bs-target="#Owned" type="button" role="tab" aria-controls="Owned" aria-selected="false">Owned</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button onClick={this.fetchItems.bind(this, 2, 0)} className="nav-link" id="Created-tab" data-bs-toggle="tab" data-bs-target="#Created" type="button" role="tab" aria-controls="Created" aria-selected="false">Created</button>
                                    </li>
                                    {/* <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="Liked-tab" data-bs-toggle="tab" data-bs-target="#Liked" type="button" role="tab" aria-controls="Liked" aria-selected="false">Liked</button>
                                </li> */}
                                    {/* <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="Activity-tab" data-bs-toggle="tab" data-bs-target="#Activity" type="button" role="tab" aria-controls="Activity" aria-selected="false">Activity</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="Following-tab" data-bs-toggle="tab" data-bs-target="#Following" type="button" role="tab" aria-controls="Following" aria-selected="false">Following</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="Follower-tab" data-bs-toggle="tab" data-bs-target="#Follower" type="button" role="tab" aria-controls="Follower" aria-selected="false">Follower</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="Hidden-tab" data-bs-toggle="tab" data-bs-target="#Hidden" type="button" role="tab" aria-controls="Hidden" aria-selected="false">Hidden</button>
                                </li> */}
                                </ul>
                                <div className="tab-content MarketDataCard" id="myTabContent">
                                    <div className="tab-pane fade show active" id="Sale" role="tabpanel" aria-labelledby="Sale-tab">
                                        {
                                            !itemsOnSale.isLoading && itemsOnSale.list.length === 0 ? (
                                                <Link to="/">
                                                    <div className="text-md-center py-5">
                                                        <h2>No Sales items found</h2>
                                                        <p className="GreyColor">Come back soon! Or try to browse <br /> something for you on our marketplace</p>
                                                        <a href="#" className="btn BtnBlack py-2 px-3">Browse Marketplace</a>
                                                    </div>
                                                </Link>
                                            ) : (
                                                // <div className="row row-cols-xxl-5 row-cols-xl-4 row-cols-lg-3 row-cols-md-2 row-cols-sm-2 g-3">
                                                <div className="d-flex justify-content-center flex-wrap">
                                                    {
                                                        !itemsOnSale.isLoading && itemsOnSale.list.map(data => <NFTItem key={data.payload} item={data} />)
                                                    }
                                                    {
                                                        itemsOnSale.isLoading && new Array(10).fill(1).map((_, index) => <NFTItem key={index} />)
                                                    }
                                                    <div className="w-100">
                                                        <center>
                                                            {this.state.itemsOnSale.loadMore && <button onClick={() => this.fetchOwnOnSaleItems(this.state.itemsOnSale.page + 1)} disabled={itemsOnSale.isLoading} style={{ width: '30%' }} className="btn BtnBlack">Load More {itemsOnSale.isLoading && <i className="fas fa-asterisk fa-spin" />}</button>}
                                                        </center>
                                                    </div>
                                                </div>
                                            )
                                        }

                                    </div>
                                    <div className="tab-pane fade MarketDataCard" id="Owned" role="tabpanel" aria-labelledby="Owned-tab">
                                        {
                                            !itemsOwned.isLoading && itemsOwned.list.length === 0 ? (
                                                <Link to="/">
                                                    <div className="text-md-center py-5">
                                                        <h2>No Owned items found</h2>
                                                        <p className="GreyColor">Come back soon! Or try to browse <br /> something for you on our marketplace</p>
                                                        <a href="#" className="btn BtnBlack py-2 px-3">Browse Marketplace</a>
                                                    </div>
                                                </Link>
                                            ) : (
                                                // <div className="row row-cols-xxl-5 row-cols-xl-4 row-cols-lg-3 row-cols-md-2 row-cols-sm-2 g-3">
                                                <div className="d-flex justify-content-center flex-wrap">
                                                    {
                                                        itemsOwned.list.map(data => <NFTItem key={data.payload} item={data} />)
                                                    }
                                                    {
                                                        itemsOwned.isLoading && new Array(10).fill(1).map((_, index) => <NFTItem key={index} />)
                                                    }
                                                    <div className="w-100">
                                                        <center>
                                                            {itemsOwned.loadMore && <button onClick={() => this.fetchItems(1, itemsOwned.page + 1)} disabled={itemsOwned.isLoading} style={{ width: '30%' }} className="btn BtnBlack">Load More {itemsOwned.isLoading && <i className="fas fa-asterisk fa-spin" />}</button>}
                                                        </center>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className="tab-pane fade MarketDataCard" id="Created" role="tabpanel" aria-labelledby="Created-tab">
                                        {
                                            !itemsCreated.isLoading && itemsCreated.list.length === 0 ? (
                                                <Link to="/">
                                                    <div className="text-md-center py-5">
                                                        <h2>No Created items found</h2>
                                                        <p className="GreyColor">Come back soon! Or try to browse <br /> something for you on our marketplace</p>
                                                        <a href="#" className="btn BtnBlack py-2 px-3">Browse Marketplace</a>
                                                    </div>
                                                </Link>
                                            ) : (
                                                // <div className="row row-cols-xxl-5 row-cols-xl-4 row-cols-lg-3 row-cols-md-2 row-cols-sm-2 g-3">
                                                <div className="d-flex justify-content-center flex-wrap">
                                                    {
                                                        itemsCreated.list.map(data => <NFTItem key={data.payload} item={data} />)
                                                    }
                                                    {
                                                        itemsCreated.isLoading && new Array(10).fill(1).map((_, index) => <NFTItem key={index} />)
                                                    }
                                                    <div className="w-100">
                                                        <center>
                                                            {itemsCreated.loadMore && <button onClick={() => this.fetchItems(2, itemsCreated.page + 1)} disabled={itemsCreated.isLoading} style={{ width: '30%' }} className="btn BtnBlack">Load More {itemsCreated.isLoading && <i className="fas fa-asterisk fa-spin" />}</button>}
                                                        </center>
                                                    </div>
                                                </div>
                                            )
                                        }

                                    </div>
                                    <div className="tab-pane fade" id="Liked" role="tabpanel" aria-labelledby="Liked-tab">
                                        <div className="text-md-center py-5">
                                            <h2>No Liked items found</h2>
                                            <p className="GreyColor">Come back soon! Or try to browse <br /> something for you on our marketplace</p>
                                            <a href="#" className="btn BtnBlack py-2 px-3">Browse Marketplace</a>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="Activity" role="tabpanel" aria-labelledby="Activity-tab">
                                        <div className="text-md-center py-5">
                                            <h2>No Activity found</h2>
                                            <p className="GreyColor">Come back soon! Or try to browse <br /> something for you on our marketplace</p>
                                            <a href="#" className="btn BtnBlack py-2 px-3">Browse Marketplace</a>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="Following" role="tabpanel" aria-labelledby="Following-tab">
                                        <div className="text-md-center py-5">
                                            <h2>No Following items found</h2>
                                            <p className="GreyColor">Come back soon! Or try to browse <br /> something for you on our marketplace</p>
                                            <a href="#" className="btn BtnBlack py-2 px-3">Browse Marketplace</a>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="Follower" role="tabpanel" aria-labelledby="Follower-tab">
                                        <div className="text-md-center py-5">
                                            <h2>No Follower items found</h2>
                                            <p className="GreyColor">Come back soon! Or try to browse <br /> something for you on our marketplace</p>
                                            <a href="#" className="btn BtnBlack py-2 px-3">Browse Marketplace</a>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="Hidden" role="tabpanel" aria-labelledby="Hidden-tab">
                                        <div className="text-md-center py-5">
                                            <h2>No Hidden items found</h2>
                                            <p className="GreyColor">Come back soon! Or try to browse <br /> something for you on our marketplace</p>
                                            <a href="#" className="btn BtnBlack py-2 px-3">Browse Marketplace</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        )
    }
}

const mapStateToProps = state => ({
    token: state.Auth.token,
    profile: state.Profile.profile
})

export default connect(mapStateToProps, {})(Profile);