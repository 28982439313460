import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { updatePassword } from '../../../api/authentication/authentication';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default class UpdatePassword extends Component {
    state = {
        data: {
            password: null,
            confirm_password: null,
            otp_code: null

        },
        isError: false,
        message: null,
        isLoading: false,
    }
    oc = e => {
        this.setState({ ...this.state, data: { ...this.state.data, [e.target.name]: e.target.value } });
    }
    async submit(e) {
        e.preventDefault();
        if (this.state.data.password === '' || this.state.data.password === null) {
            return this.setState({ ...this.state, isError: true, message: 'Password required!' });
        } else if (this.state.data.confirm_password === '' || this.state.data.confirm_password === null) {
            return this.setState({ ...this.state, isError: true, message: 'Confirm password required!' });
        }
        else if (this.state.data.opt_code === '' || this.state.data.opt_code === null) {
            return this.setState({ ...this.state, isError: true, message: 'Otp code required!' });
        }
        else if (this.state.data.password !== this.state.data.confirm_password) {
            return this.setState({ ...this.state, isError: true, message: 'Password and confirm password not match!' });
        }
        this.setState({ ...this.state, isError: null, message: null, isLoading: true });
        try {
            await updatePassword(this.state.data);
            this.setState({ ...this.state, isError: null, message: null, isLoading: false });
            toast.info('Your password has been successfully updated.')
            this.props.history.push("/login")


        } catch (e) {
            this.setState({ ...this.state, isError: true, isLoading: false, message: e.message === 'Request failed with status code 401' ? 'Invalid Username or Password!' : e.response && e.response.data ? e.response.data : e.message });
        }
    }

    renderHtml(htmlString) {
        let xmlString = htmlString;
        let doc = new DOMParser().parseFromString(xmlString, "text/xml");
        return doc.firstChild.innerHTML;
    }

    render() {

        return (
            <div className="container-fluid" id="SigningPage">
                <div className="row">
                    <div className="grid grid-cols-1 px-4 mb-5 col-md-3 sidebar mb-md-0 px-md-5">
                        <div className="mb-2 mb-md-0">
                            <Link to="/" className="back-to-home-page rounded d-flex align-items-center me-auto mb-5">
                                <svg className={'me-1'} xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
                                </svg>
                                Back To Homepage
                            </Link>

                            <form>
                                <h3 className="form-title"> Update Password </h3>

                                <input onChange={this.oc} className="form-control BorderDarkGrey shadow-none" id="signin_email_id" name="otp_code" placeholder="Enter 6 digit otp code" />

                                <div className="help d-flex justify-content-end align-items-center">
                                    <a href="#" className="d-flex align-items-center">

                                    </a>
                                </div>

                                <input onChange={this.oc} className="form-control BorderDarkGrey shadow-none" id="signin_password" type="password" name="password" placeholder="Enter the Password" />

                                <div className="help d-flex justify-content-end align-items-center">
                                    <a href="#" className="d-flex align-items-center">

                                    </a>
                                </div>

                                <input onChange={this.oc} className="form-control BorderDarkGrey shadow-none" id="signin_password" type="password" name="confirm_password" placeholder="Confirm Password" />
                                <div className="help d-flex justify-content-end align-items-center">
                                    <a href="#" className="d-flex align-items-center">

                                    </a>
                                </div>


                                {
                                    this.state.isError ? <div className="alert alert-primary" role="alert">
                                        {this.state.message.indexOf('label') !== 0 ? ReactHtmlParser(this.state.message) : this.state.message}
                                    </div> : null
                                }
                                {
                                    !this.state.isError && this.state.message ? <div className="alert alert-success" role="alert">
                                        {this.state.message}
                                    </div> : null
                                }
                                <div className="help d-flex justify-content-end align-items-center">
                                    <a href="#" className="d-flex align-items-center">

                                    </a>
                                </div>
                                <div className="sign-in">


                                    <button disabled={this.state.isLoading} onClick={this.submit.bind(this)} className="border-0 w-100 d-flex justify-content-center align-items-center sign-in-btn btn btn-block"> <span className="sign-in-text">
                                        Update Password
                                        </span> {this.state.isLoading && <i className="fas fa-asterisk fa-spin"></i>}</button>
                                </div>


                            </form>
                        </div>
                        <footer className="mx-auto d-flex align-items-end">
                            <div className="text-center">
                                Copyrights @2021, All Rights Reserved by Nucoin NFT.
                        </div>
                        </footer>
                    </div>
                    <div className="col-md-9 content">
                        <div className="row">
                            <div className="col-md-6 d-flex justify-content-center align-items-center hero">
                                <div className="hero-text ">
                                    <div className="upper-text d-inline">
                                        <span>
                                            The Largest NFT Marketplace
                                        </span>
                                    </div>
                                    <h1 className="title">
                                        NFT City
                                </h1>
                                    <p className="sub-text">
                                        Ut pharetra sit amet aliquam. Sollicitudin nibh sit amet commodo nulla facilisi
                                        nullam vehicula ipsum. Mauris sit amet massa vitae. Est ultricies integer quis
                                        auctor. Egestas purus viverra accumsan in nisl nisi scelerisque eu.
                                </p>
                                </div>
                            </div>
                            <div className="col-md-6 cards">
                                <div className="row">
                                    <div className="mb-5 col-md-6 mb-md-0">
                                        <div className="pt-4 pb-4 ps-4 pe-4 mb-5 card pt-md-0">
                                            <img src="/images/image6.jpg" alt="" className="img-fluid" />
                                        </div>
                                        <div className="p-4 mb-5 card">
                                            <img src="/images/img1.jpeg" alt="" className="img-fluid" />
                                        </div>
                                        <div className="p-4 card">
                                            <img src="/images/e9ee03457b929ea5dfc89380f4a2c20c.jpeg" alt=""
                                                className="img-fluid" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="pt-4 pb-4 ps-4 pe-4 mb-5 card pt-md-0">
                                            <img src="/images/image5.jpg" alt="" className="img-fluid" />
                                        </div>
                                        <div className="p-4 mb-5 card">
                                            <img src="/images/user.jpg" alt="" className="img-fluid" />
                                        </div>
                                        <div className="p-4 card">
                                            <img src="/images/imgone.jpeg" alt="" className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}